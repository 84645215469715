import dayjs from "dayjs";
import { useFormik } from "formik";
import { useState, useEffect, useContext, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import NoDataMsg from "../../../../common/components/NoDataMsg";
import OpenCardComponent from "../../../../common/components/OpenCardComponent";
import SearchableSelect from "../../../../common/components/SearchableSelect";
import AuthContext from "../../../../contexts/authContext";
import useDarkMode from "../../../../hooks/useDarkMode";
import useSortableData from "../../../../hooks/useSortableData";
import Page from "../../../../layout/Page/Page";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from "../../../../layout/SubHeader/SubHeader";
import AlertService from "../../../../services/AlertService";
import { downloadPdfDocument, downloadExcel } from "../../../../services/ExportService";
import { getLicenseKey } from "../../../../services/application.settings";
import { getCollegeDetails } from "../../../../services/college.service";
import { getCurrentDateAndTime, updateFilter, getGraduationType, getSectionDetails, getColumnsForDataTable, pictNotLoading, profilePic, onlyAllowNumber } from "../../../../services/common.service";
import { getStudentFeeConcession } from "../../../../services/concession.service";
import { getStudentFeeCollection, getStudentTotalFeeCollection, getFeeCollectionDetailsByFeeCollectionId, updReceiptDetailsByAdmin, deleteReceiptDetails, updReceiptPaymentType } from "../../../../services/fee.service";
import { showLoader, TableLoader } from "../../../../services/loader.services";
import { getDepartmentList, getCourse, getBatchByCourseId, getSemester } from "../../../../services/master.service";
import { getProfileForStudent, getStudentDetailsBySemesterId, getAllStudent } from "../../../../services/student.service";
import { toasts } from "../../../../services/toast.service";
import PaginationButtons, { dataPagination } from "../../../PaginationButtons";
import Button, { ButtonGroup } from "../../../bootstrap/Button";
import Card, { CardHeader, CardLabel, CardTitle, CardActions, CardBody, CardSubTitle } from "../../../bootstrap/Card";
import Collapse from "../../../bootstrap/Collapse";
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from "../../../bootstrap/Dropdown";
import Modal, { ModalHeader, ModalBody, ModalTitle, ModalFooter } from "../../../bootstrap/Modal";
import OffCanvas, { OffCanvasHeader, OffCanvasTitle, OffCanvasBody } from "../../../bootstrap/OffCanvas";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import Textarea from "../../../bootstrap/forms/Textarea";
import PayFees from "./PayFees";
import './receipt.css'
import Icon from "../../../icon/Icon";
import Input from "../../../bootstrap/forms/Input";
import CollegeLogo from "../../../../assets/logos/logo.jpg"

const TermFeesCollection = () => {

    const { navigatedStudentDetailsId } = useParams();
    const [studentDetailsId, setStudentDetailsId] = useState<any>(Number)
    const [navStudent, setNavStudent] = useState(false)
    const tableFontSize = 'fs-4';

    useEffect(() => {
        getGraduationList();
        getDepartment();
        getSectionList();
        getAllStudentDetails();
        getCollege();

        if (navigatedStudentDetailsId != undefined) {

            setNavStudent(true)
            viewParticularStudentForNav(navigatedStudentDetailsId)
        }
    }, [])

    const TABS = {
        FEE_DETAILS: 'Fees Details',
        PAID: 'Paid Bills',
        CONCESSION: 'Concession Details',
    };
    const [activeTab, setActiveTab] = useState(TABS.FEE_DETAILS);

    const { userAccountId, userTypeId, addAccess, editAccess, deleteAccess } = useContext(AuthContext);
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const [isStudentOrClassWise, setIsStudentOrClassWise] = useState(true)
    const { darkModeStatus } = useDarkMode();
    const navigate = useNavigate();

    const [departmentId, setDepartmentId] = useState<any>(Number)
    const [courseId, setCourseId] = useState<any>(Number)
    const [batchMasterId, setBatchMasterId] = useState<any>(Number)
    const [semesterDetailsId, setSemesterDetailsId] = useState<any>(Number)
    const [sectionDetailsId, setSectionDetailsId] = useState<any>(Number)
    const [graduationTypeId, setGraduationTypeId] = useState<any>(Number)
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [columnVisibilityDataForFeeCollection, setColumnVisibilityDataForFeeCollection] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [dataSuccess, setDataSuccess] = useState(false)

    const [departmentData, setDepartmentData] = useState<any>([])
    const [courseData, setCourseData] = useState<any>([])
    const [graduationTypeData, setGraduationTypeData] = useState<any>([])
    const [batchMasterData, setBatchMasterData] = useState<any>([])
    const [semesterDetailsData, setSemesterDetailsData] = useState<any>([])
    const [sectionDetailsData, setSectionDetailsData] = useState<any>([])
    const [studentProfileData, setStudentProfileData] = useState<any>([])
    const [allStudentData, setAllStudentData] = useState<any>([])
    const [studentDetailsData, setStudentDetailsData] = useState<any>([])
    const [studentFeeCollectionData, setStudentFeeCollectionData] = useState<any>([])
    const [studentFeeConcessionData, setStudentFeeConcessionData] = useState<any>([])
    const [studentTotalFeeCollectionData, setStudentTotalFeeCollectionData] = useState<any>([])
    // const [allStudentData, setAllStudentData] = useState([])
    const [showPayFees, setShowPayFees] = useState("")
    const [receiptOpenModal, setReceiptOpenModal] = useState(false)
    const [updateReceiptOpenModal, setUpdateReceiptOpenModal] = useState(false)
    const [updateFeePaymentTypeModal, setUpdateFeePaymentTypeModal] = useState(false)

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    // const { items, requestSort, getClassNamesFor } = useSortableData(studentFeeCollectionData);
    // const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const { items: items1, requestSort: requestSort1, getClassNamesFor: getClassNamesFor1 } = useSortableData(studentFeeConcessionData);
    const { items: items, requestSort: requestSort, getClassNamesFor: getClassNamesFor } = useSortableData(studentFeeCollectionData);

    const onCurrentPageData1 = dataPagination(items1, currentPage, perPage);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);


    const componentRef = useRef(null);
    const [collegeDetailsData, setCollegeDetailsData] = useState<any>([])
    const [studentFeeReceiptData, setStudentFeeReceiptData] = useState<any>([])
    const [deleteReceiptCanvas, setDeleteReceiptCanvas] = useState(false)
    const [isLoader, setIsLoader] = useState(false)
    const [pdfFileName, setPdfFileName] = useState('')
    const [concessionNoDataMsg, setConcessionNoDataMsg] = useState('')

    const [fivehunderd_total, setFivehunderd_total] = useState<any>("")
    const [twoHundred_total, setTwoHundred_total] = useState<any>("")
    const [hundred_total, setHundred_total] = useState<any>("")
    const [fifty_total, setFifty_total] = useState<any>("")
    const [twenty_total, setTwenty_total] = useState<any>("")
    const [ten_total, setTen_total] = useState<any>("")
    const [five_total, setFive_total] = useState<any>("")
    const [totalAmount, setTotalAmount] = useState<any>("")
    const [balCashAmount, setBalCashAmount] = useState<any>("")
    const [totalFeesAmount, setTotalFeesAmount] = useState<any>(0)
    const [updatedPaymentTypeId, setUpdatedPaymentTypeId] = useState<any>()

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [noDataMsg, setNoDataMsg] = useState('')
    const [nodataForPaidBills, setNodataForPaidBills] = useState('')
    const [feeReceiptUpdateStudentDetailsId, setFeeReceiptUpdateStudentDetailsId] = useState<any>('')

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // const updateStudentFeeReceiptForm = useFormik({
    //     enableReinitialize: true,
    //     initialValues: {
    //         studentFeeCollectionId: '',
    //         studentDetailsId: '',
    //     },
    //     validate: (values) => {
    //         const errors: {
    //             studentDetailsId?: string;
    //         } = {};
    //         if (feeReceiptUpdateStudentDetailsId?.value == undefined) {
    //             errors.studentDetailsId = 'Required';
    //         }
    //         return errors;
    //     },
    //     onSubmit: () => { updateReceiptSubmit() },
    // })

    const updateStudentFeeReceiptForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            studentFeeCollectionId: '',
            studentDetailsId: '',
            paymentTypeId: '',
        },
        validate: (values) => { },
        onSubmit: () => { updReceiptPaymentTypeSubmit() },
    })

    const deleteStudentFeeReceiptForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            studentFeeCollectionId: '',
            remarks: '',
        },
        validate: (values) => {
            const errors: {
                remarks?: string;
            } = {};
            if (!values.remarks) {
                errors.remarks = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { deleteReceiptSubmit() },
    })

    const payFeesDenominationForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            receiptDate: getCurrentDateAndTime('date'),
            paymentTypeId: '',
            twoThousand_total: '',
            twoThousand_count: '',
            thousand_total: '',
            thousand_count: '',
            fivehunderd_total: '',
            fivehunderd_count: '',
            twoHundred_total: '',
            twoHundred_count: '',
            hundred_total: '',
            hundred_count: '',
            fifty_total: '',
            fifty_count: '',
            twenty_total: '',
            twenty_count: '',
            ten_total: '',
            ten_count: '',
            five_total: '',
            five_count: '',
            totalAmount: '',
            chequeNumber: '',
            chequeDate: getCurrentDateAndTime('date'),
            chequeAmount: '',
            chequeBankName: '',
            neftRefNumber: '',
            neftTranDate: getCurrentDateAndTime('date'),
            neftAmount: '',
            neftBankName: '',
            cardNumber: '',
            cardDate: getCurrentDateAndTime('date'),
            cardAmount: '',
            cardBankName: '',
            balCashAmount: '',
            narration: ''
        },
        // validate: (values) => {
        //     const errors: {
        //         examMonthName?: string;
        //     } = {};
        //     if (!values.examMonthName) {
        //         errors.examMonthName = 'Required';
        //     }
        //     return errors;
        // },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityDataForFeeCollection, columnVisibilityForm);
    // Filter
    const filteredData1 = updateFilter(onCurrentPageData1, columnVisibilityData, columnVisibilityForm);

    function onActivate(i: any, text: any) {
        if (i != undefined) {
            let selectedStudent = i
            getFeeReceipt(selectedStudent.studentFeeCollectionId);
            setPdfFileName(selectedStudent.receiptNo)

            // updateStudentFeeReceiptForm.setValues({
            //     studentFeeCollectionId: selectedStudent.studentFeeCollectionId,
            //     studentDetailsId: '',

            // })

            updateStudentFeeReceiptForm.setValues({
                studentFeeCollectionId: selectedStudent.studentFeeCollectionId,
                studentDetailsId: selectedStudent.studentDetailsId,
                paymentTypeId: selectedStudent.paymentTypeId

            })

            deleteStudentFeeReceiptForm.setValues({
                studentFeeCollectionId: selectedStudent.studentFeeCollectionId,
                remarks: '',
            })

            if (text == 'updateReceipt') {
                //setUpdateReceiptOpenModal(true)
                setUpdateFeePaymentTypeModal(true)
                //totalPayFees()
                let totalFeesAmount1 = 0;
                let feeAmount = Number(selectedStudent.feesCollected)
                totalFeesAmount1 += feeAmount;
                setTotalFeesAmount(totalFeesAmount1);
                balanceAmountCal1(fivehunderd_total, twoHundred_total, hundred_total, fifty_total, twenty_total, ten_total, five_total, totalFeesAmount1);

            }

        }
    }

    const selectDepartment = (e: any) => {
        setGraduationTypeId('')
        setCourseId('')
        setCourseData([])
        setBatchMasterId('')
        setBatchMasterData([])
        setSemesterDetailsId('')
        setSemesterDetailsData([])
        setSectionDetailsId('')
        setStudentDetailsId('')
        setStudentDetailsData([])
        let departmentId = e
        setDepartmentId(departmentId)
    }

    const selectGraduationType = (e: any) => {
        setCourseId('')
        setCourseData([])
        setBatchMasterId('')
        setBatchMasterData([])
        setSemesterDetailsId('')
        setSemesterDetailsData([])
        setSectionDetailsId('')
        setStudentDetailsId('')
        setStudentDetailsData([])
        let graduationTypeId = e
        setGraduationTypeId(graduationTypeId)
        if (graduationTypeId?.value != undefined) {
            getCourseList(departmentId?.value, graduationTypeId?.value)
        }
    }

    const selectCourse = (e: any) => {
        setBatchMasterId('')
        setBatchMasterData([])
        setSemesterDetailsId('')
        setSemesterDetailsData([])
        setSectionDetailsId('')
        setStudentDetailsId('')
        setStudentDetailsData([])
        let courseId = e
        setCourseId(courseId)
        if (courseId?.value != undefined) {
            getBatchList(courseId?.value, graduationTypeId?.value)
        }
    }

    const selectBatch = (e: any) => {
        setSemesterDetailsId('')
        setSemesterDetailsData([])
        setSectionDetailsId('')
        setStudentDetailsId('')
        setStudentDetailsData([])
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        if (batchMasterId?.value != undefined) {
            getSemesterList(batchMasterId?.value)
        }
    }

    const selectSemester = (e: any) => {
        setSectionDetailsId('')
        setStudentDetailsId('')
        setStudentDetailsData([])
        let semesterDetailsId = e
        setSemesterDetailsId(semesterDetailsId)
    }

    const selectSection = (e: any) => {
        setStudentDetailsId('')
        setStudentDetailsData([])
        let sectionDetailsId = e
        setSectionDetailsId(sectionDetailsId)
        if (sectionDetailsId?.value != undefined) {
            getStudentListBySectionId(courseId?.value, semesterDetailsId?.value, sectionDetailsId?.value)
        }
    }

    const selectStudent = (e: any) => {
        setIsOpenListCard(true)
        setDataSuccess(false);
        let studentDetailsId = e
        setStudentDetailsId(studentDetailsId)
    }

    const selectPaymentType = (e: any) => {
        formReset();
        let updatedPaymentTypeId = e;
        setUpdatedPaymentTypeId(updatedPaymentTypeId)
    }

    function viewParticularStudent() {
        setDataSuccess(false);
        setIsLoader(true);
        setShowPayFees("");
        setActiveTab(TABS.FEE_DETAILS);
        getStudentProfile(studentDetailsId?.value);
    }

    function viewParticularStudentForNav(studentDetailsId: any) {
        setDataSuccess(false);
        setIsLoader(true);
        setShowPayFees("");
        setStudentDetailsId({ value: studentDetailsId, label: '' });
        getStudentProfile(studentDetailsId);
    }

    function getRefresh(studentDetailsId: any) {
        getFeeReceipt(studentDetailsId)
    }

    function refreshAfterFeeCollection(msg: any) {
        if (msg != '') {
            viewParticularStudent()
            setAlertStatus({ message: msg, type: "success" });
            setIsOpen(true);
        } else {
            viewParticularStudent()
        }
    }

    function getStudentProfile(studentDetailsId: number) {
        setIsLoader(true);
        getProfileForStudent(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentProfile;
                    if (data != undefined) {
                        setIsLoader(false);
                        setStudentProfileData(data);
                        // setDataSuccess(true)
                        setIsOpenListCard(false);
                        getStudentFeeCollectionList(studentDetailsId);
                        getStudentTotalFeeCollectionList(studentDetailsId);
                        getStudentFeeConcessionList(studentDetailsId);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        // toasts("Undefined Data", "Error")
                        // setDataSuccess(false)
                        setAlertStatus({ message: "Undefined Data", type: "error" });
                        setIsOpen(true);
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    // toasts(response.data.message, "Error")
                    // setDataSuccess(false)
                    setIsOpenListCard(true);
                    setAlertStatus({ message: response.data.message, type: "error" });
                    setIsOpen(true);

                } else {
                    setIsLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    // toasts(errorDescription, "Error")
                    // setDataSuccess(false)
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }, error => {
                setIsLoader(false);
                // toasts(error, "Error")
                setDataSuccess(false)
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
    }

    function getStudentListBySectionId(courseId: number, semesterDetailsId: number, sectionDetailsId: number) {
        getStudentDetailsBySemesterId(courseId, semesterDetailsId, sectionDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetailsBySemesterId;
                    if (data != undefined) {
                        setStudentDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setStudentDetailsId('')
                    setStudentDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setStudentDetailsId('')
                    setStudentDetailsData([]);
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getDepartment() {
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.department;
                    if (data != undefined) {
                        setDepartmentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setDepartmentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        setGraduationTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setGraduationTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCourseList(departmentId: number, graduationTypeId: number) {
        getCourse(departmentId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.course;
                    if (data != undefined) {
                        setCourseData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCourseData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchList(courseId: number, graduationTypeId: number) {
        getBatchByCourseId(courseId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchByCourseId;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSemesterList(batchMasterId: number) {
        getSemester(batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.semester;
                    if (data != undefined) {
                        setSemesterDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSemesterDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSectionList() {
        getSectionDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSectionDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getAllStudentDetails() {
        getAllStudent(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetails;
                    if (data != undefined) {
                        setAllStudentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setAllStudentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentFeeCollectionList(studentDetailsId: number) {
        getStudentFeeCollection(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentFeeCollection;
                    if (data != undefined) {
                        setStudentFeeCollectionData(data);
                        getColumnsForTableStudentFeeCollection('getStudentFeeCollection', 'get')
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts('Fees Not Collected Yet.', "Error")
                    getColumnsForTableStudentFeeCollection('getStudentFeeCollection', 'get')
                    setStudentFeeCollectionData([]);
                    setNodataForPaidBills('Fees Not Collected Yet.')
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentTotalFeeCollectionList(studentDetailsId: number) {
        getStudentTotalFeeCollection(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentTotalFeeCollection;
                    if (data != undefined) {
                        setStudentTotalFeeCollectionData(data);
                        setDataSuccess(true)
                    } else {
                        toasts("Undefined Data", "Error")
                        setDataSuccess(false)
                    }
                }
                else if (response.data.success === false) {
                    setStudentTotalFeeCollectionData([])
                    //toasts(response.data.message, "Error")
                    setDataSuccess(true)
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setDataSuccess(false)
                }
            }, error => {
                toasts(error, "Error")
                setDataSuccess(false)
            }
        )
    }

    function getStudentFeeConcessionList(studentDetailsId: number) {
        getStudentFeeConcession(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentFeeConcessionListByStudentDetailsId;
                    if (data != undefined) {
                        setStudentFeeConcessionData(data);
                        getColumnsForTable('getStudentFeeConcessionListByStudentDetailsId', 'get');
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setStudentFeeConcessionData([]);
                    getColumnsForTable('getStudentFeeConcessionListByStudentDetailsId', 'get');
                    setNoDataMsg(response.data.message)
                    //setConcessionNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            // setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            // updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function payFees() {
        setShowPayFees("payFees")
    }

    function selectStudentCard() {
        setIsStudentOrClassWise(!isStudentOrClassWise)
        setDataSuccess(false)
        setDepartmentId('')
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setGraduationTypeId('')
        setStudentDetailsId('')
        setStudentDetailsData([])
    }

    function getCollege() {
        getCollegeDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.collegeDetails;
                    if (data != undefined) {
                        setCollegeDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getFeeReceipt(studentFeeCollectionId: number) {
        getFeeCollectionDetailsByFeeCollectionId(studentFeeCollectionId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.feeCollectionDetailsByFeeCollectionId;
                    if (data != undefined) {
                        setStudentFeeReceiptData(data);
                        for (let i = 0; i < data.length; i++) {
                            let feeCollectionDetails = data[i].feeCollectionDetails
                            for (let i = 0; i < feeCollectionDetails.length; i++) {
                                feeCollectionDetails[i].sno = i + 1;
                            }
                        }

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setDeleteStudentFeeReceipt() {
        return {
            studentFeeCollectionId: deleteStudentFeeReceiptForm.values.studentFeeCollectionId,
            remarks: deleteStudentFeeReceiptForm.values.remarks,
            userAccountId: userAccountId,
            licenseKey: getLicenseKey
        }
    }

    function setUpdateStudentFeeReceipt() {
        return {
            studentDetailsId: feeReceiptUpdateStudentDetailsId?.value,
            studentFeeCollectionId: updateStudentFeeReceiptForm.values.studentFeeCollectionId,
            userAccountId: userAccountId,
            licenseKey: getLicenseKey
        }
    }

    function updateReceiptSubmit() {
        showLoader(true)
        if (updateStudentFeeReceiptForm.isValid) {
            let updateFeeReceiptPostData = setUpdateStudentFeeReceipt()
            updReceiptDetailsByAdmin(updateFeeReceiptPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        //viewParticularStudent()
                        getRefresh
                        setUpdateReceiptOpenModal(false)
                        updateStudentFeeReceiptForm.resetForm();
                        setFeeReceiptUpdateStudentDetailsId('')
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (updateStudentFeeReceiptForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function deleteReceiptSubmit() {
        showLoader(true)
        if (deleteStudentFeeReceiptForm.isValid) {
            let feeReceiptPostData = setDeleteStudentFeeReceipt()
            deleteReceiptDetails(feeReceiptPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        setDeleteReceiptCanvas(false)
                        viewParticularStudent();
                        deleteStudentFeeReceiptForm.resetForm()
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (deleteStudentFeeReceiptForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function getColumnsForTableStudentFeeCollection(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityDataForFeeCollection = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityDataForFeeCollection(columnVisibilityDataForFeeCollection)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            // setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            // updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }


    const multiply500 = (e: any) => {
        const count500 = e.target.value;
        setFivehunderd_total(count500 * 500)
        multiply(count500 * 500, twoHundred_total, hundred_total, fifty_total, twenty_total, ten_total, five_total)
        balanceAmountCal(count500 * 500, twoHundred_total, hundred_total, fifty_total, twenty_total, ten_total, five_total)
    }

    const multiply200 = (e: any) => {
        const count200 = e.target.value;
        setTwoHundred_total(count200 * 200);
        multiply(fivehunderd_total, count200 * 200, hundred_total, fifty_total, twenty_total, ten_total, five_total)
        balanceAmountCal(fivehunderd_total, count200 * 200, hundred_total, fifty_total, twenty_total, ten_total, five_total)

    }

    const multiply100 = (e: any) => {
        const count100 = e.target.value;
        setHundred_total(count100 * 100);
        multiply(fivehunderd_total, twoHundred_total, count100 * 100, fifty_total, twenty_total, ten_total, five_total)
        balanceAmountCal(fivehunderd_total, twoHundred_total, count100 * 100, fifty_total, twenty_total, ten_total, five_total)

    }

    const multiply50 = (e: any) => {
        const count50 = e.target.value;
        setFifty_total(count50 * 50);
        multiply(fivehunderd_total, twoHundred_total, hundred_total, count50 * 50, twenty_total, ten_total, five_total)
        balanceAmountCal(fivehunderd_total, twoHundred_total, hundred_total, count50 * 50, twenty_total, ten_total, five_total)

    }

    const multiply20 = (e: any) => {
        const count20 = e.target.value;
        setTwenty_total(count20 * 20);
        multiply(fivehunderd_total, twoHundred_total, hundred_total, fifty_total, count20 * 20, ten_total, five_total)
        balanceAmountCal(fivehunderd_total, twoHundred_total, hundred_total, fifty_total, count20 * 20, ten_total, five_total)

    }

    const multiply10 = (e: any) => {
        const count10 = e.target.value;
        setTen_total(count10 * 10);
        multiply(fivehunderd_total, twoHundred_total, hundred_total, fifty_total, twenty_total, count10 * 10, five_total)
        balanceAmountCal(fivehunderd_total, twoHundred_total, hundred_total, fifty_total, twenty_total, count10 * 10, five_total)
    }

    const multiply5 = (e: any) => {
        const count5 = e.target.value;
        setFive_total(count5 * 5);
        multiply(fivehunderd_total, twoHundred_total, hundred_total, fifty_total, twenty_total, ten_total, count5 * 5)
        balanceAmountCal(fivehunderd_total, twoHundred_total, hundred_total, fifty_total, twenty_total, ten_total, count5 * 5)
    }

    const multiply = (fivehunderd_total: any, twoHundred_total: any, hundred_total: any, fifty_total: any, twenty_total: any, ten_total: any, five_total: any) => {
        setTotalAmount(Number(fivehunderd_total) + Number(twoHundred_total) + Number(hundred_total) + Number(fifty_total) + Number(twenty_total) + Number(ten_total) + Number(five_total));
    }

    const balanceAmountCal = (fivehunderd_total: any, twoHundred_total: any, hundred_total: any, fifty_total: any, twenty_total: any, ten_total: any, five_total: any) => {
        setBalCashAmount(Number(fivehunderd_total) + Number(twoHundred_total) + Number(hundred_total) + Number(fifty_total) + Number(twenty_total) + Number(ten_total) + Number(five_total) - Number(totalFeesAmount));
    }

    const balanceAmountCal1 = (fivehunderd_total: any, twoHundred_total: any, hundred_total: any, fifty_total: any, twenty_total: any, ten_total: any, five_total: any, totalFeesAmount: any) => {
        setBalCashAmount(Number(fivehunderd_total) + Number(twoHundred_total) + Number(hundred_total) + Number(fifty_total) + Number(twenty_total) + Number(ten_total) + Number(five_total) - Number(totalFeesAmount));
    }

    //function totalPayFees() {
    // let totalFeesAmount1 = 0;
    // for (let data1 of studentFeeReceiptData) {
    //     let feeAmount = Number(data1.feesCollected)
    //     totalFeesAmount1 += feeAmount;
    // }
    // setTotalFeesAmount(totalFeesAmount1);
    // balanceAmountCal1(fivehunderd_total, twoHundred_total, hundred_total, fifty_total, twenty_total, ten_total, five_total, totalFeesAmount1);

    //}

    function setUpdatePaymentTypeDetails() {
        return ({
            studentDetailsId: updateStudentFeeReceiptForm.values.studentDetailsId,
            studentFeeCollectionId: updateStudentFeeReceiptForm.values.studentFeeCollectionId,
            paymentTypeId: updateStudentFeeReceiptForm.values.paymentTypeId,
            updatedPaymentTypeId: updatedPaymentTypeId?.value != undefined ? updatedPaymentTypeId?.value : null,
            ledgerRefId: null,
            twoThousand_total: Number(payFeesDenominationForm.values.twoThousand_total) != 0 ? Number(payFeesDenominationForm.values.twoThousand_total) : null,
            twoThousand_count: Number(payFeesDenominationForm.values.twoThousand_count) != 0 ? Number(payFeesDenominationForm.values.twoThousand_count) : null,
            thousand_total: Number(payFeesDenominationForm.values.thousand_total) != 0 ? Number(payFeesDenominationForm.values.thousand_total) : null,
            thousand_count: Number(payFeesDenominationForm.values.thousand_count) != 0 ? Number(payFeesDenominationForm.values.thousand_count) : null,
            fivehunderd_total: Number(fivehunderd_total) != 0 ? Number(fivehunderd_total) : null,
            fivehunderd_count: Number(payFeesDenominationForm.values.fivehunderd_count) != 0 ? Number(payFeesDenominationForm.values.fivehunderd_count) : null,
            twoHundred_total: Number(twoHundred_total) != 0 ? Number(twoHundred_total) : null,
            twoHundred_count: Number(payFeesDenominationForm.values.twoHundred_count) != 0 ? Number(payFeesDenominationForm.values.twoHundred_count) : null,
            hundred_total: Number(hundred_total) != 0 ? Number(hundred_total) : null,
            hundred_count: Number(payFeesDenominationForm.values.hundred_count) != 0 ? Number(payFeesDenominationForm.values.hundred_count) : null,
            fifty_total: Number(fifty_total) != 0 ? Number(fifty_total) : null,
            fifty_count: Number(payFeesDenominationForm.values.fifty_count) != 0 ? Number(payFeesDenominationForm.values.fifty_count) : null,
            twenty_total: Number(twenty_total) != 0 ? Number(twenty_total) : null,
            twenty_count: Number(payFeesDenominationForm.values.twenty_count) != 0 ? Number(payFeesDenominationForm.values.twenty_count) : null,
            ten_total: Number(ten_total) != 0 ? Number(ten_total) : null,
            ten_count: Number(payFeesDenominationForm.values.ten_count) != 0 ? Number(payFeesDenominationForm.values.ten_count) : null,
            five_total: Number(five_total) != 0 ? Number(five_total) : null,
            five_count: Number(payFeesDenominationForm.values.five_count) != 0 ? Number(payFeesDenominationForm.values.five_count) : null,
            totalAmount: Number(totalFeesAmount) != 0 ? Number(totalFeesAmount) : null,
            //totalAmount: Number(totalFeesAmount) != 0 ? Number(totalFeesAmount) : Number(payFineFeesForm.values.amount),

            /// Cheque Payment ///
            chequeNumber: payFeesDenominationForm.values.chequeNumber != '' ? payFeesDenominationForm.values.chequeNumber : null,
            chequeDate: updatedPaymentTypeId?.value == 2 ? payFeesDenominationForm.values.chequeDate : null,
            chequeAmount: Number(payFeesDenominationForm.values.chequeAmount) != 0 ? Number(payFeesDenominationForm.values.chequeAmount) : null,
            chequeBankName: payFeesDenominationForm.values.chequeBankName != '' ? payFeesDenominationForm.values.chequeBankName : null,

            /// Neft Payment ///
            neftRefNumber: payFeesDenominationForm.values.neftRefNumber != '' ? payFeesDenominationForm.values.neftRefNumber : null,
            neftTranDate: updatedPaymentTypeId?.value == 3 ? payFeesDenominationForm.values.neftTranDate : null,
            neftAmount: Number(payFeesDenominationForm.values.neftAmount) != 0 ? Number(payFeesDenominationForm.values.neftAmount) : null,
            neftBankName: payFeesDenominationForm.values.neftBankName != '' ? payFeesDenominationForm.values.neftBankName : null,

            /// Card Payment ///
            cardNumber: payFeesDenominationForm.values.cardNumber != '' ? payFeesDenominationForm.values.cardNumber : null,
            cardDate: updatedPaymentTypeId?.value == 4 ? payFeesDenominationForm.values.cardDate : null,
            cardAmount: Number(payFeesDenominationForm.values.cardAmount) != 0 ? Number(payFeesDenominationForm.values.cardAmount) : null,
            cardBankName: payFeesDenominationForm.values.cardBankName != '' ? payFeesDenominationForm.values.cardBankName : null,

            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function updReceiptPaymentTypeSubmit() {
        showLoader(true)
        if (updateStudentFeeReceiptForm.isValid) {
            let updatePaymentTypePostData = setUpdatePaymentTypeDetails()
            updReceiptPaymentType(updatePaymentTypePostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        viewParticularStudent()
                        setUpdateFeePaymentTypeModal(false)
                        updateStudentFeeReceiptForm.resetForm();
                        setUpdatedPaymentTypeId('');
                        payFeesDenominationForm.resetForm();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (updateStudentFeeReceiptForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function formReset() {
        payFeesDenominationForm.resetForm();
        setFivehunderd_total("")
        setTwoHundred_total("")
        setHundred_total("")
        setFifty_total("")
        setTwenty_total("")
        setTen_total("")
        setFive_total("")
        setTotalAmount("")
        setBalCashAmount("")
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function handlePDF() {
        downloadPdfDocument(componentRef.current, pdfFileName)
    }

    function closeAndReset() {
        updateStudentFeeReceiptForm.resetForm()
        setUpdatedPaymentTypeId('')
        setFeeReceiptUpdateStudentDetailsId('')
        setUpdateReceiptOpenModal(false)
        setUpdateFeePaymentTypeModal(false)
        formReset()

    }

    const paymentTypeData = [{ updatedPaymentTypeId: 1, paymentType: 'Cash' }, { updatedPaymentTypeId: 2, paymentType: 'Cheque' }, { updatedPaymentTypeId: 3, paymentType: 'NEFT/UPI' }, { updatedPaymentTypeId: 4, paymentType: 'Card' }]

    return (
        <PageWrapper title={`Fee Collection`}>
            <>
                {navStudent ?
                    <>
                        {showPayFees == "" ?
                            <SubHeader>
                                <SubHeaderLeft>
                                    <Button color='info' isLink icon='ArrowBack' onClick={() => navigate(-1)}>
                                        Back to List
                                    </Button>
                                    <SubheaderSeparator />
                                </SubHeaderLeft>
                            </SubHeader>
                            : null}
                    </>
                    : null}
            </>
            <Page container='fluid'>
                {navStudent ? null :
                    <>
                        {showPayFees == "" ?
                            <>
                                <Collapse isOpen={isOpenListCard}>
                                    <Card>
                                        <CardHeader borderSize={1}>
                                            <CardLabel icon='List' iconColor='info' className="col-lg-9">
                                                <CardTitle tag='div' className='h5'>Term Fees Collection</CardTitle>
                                            </CardLabel>
                                            <div className='col-lg-3'>
                                                <CardActions>
                                                    <Button
                                                        icon='Contacts'
                                                        color='info'
                                                        isLight={isStudentOrClassWise ? false : true}
                                                        onClick={selectStudentCard}>
                                                        Student
                                                    </Button>

                                                    <Button
                                                        icon='LocalPolice'
                                                        color='info'
                                                        isLight={isStudentOrClassWise ? true : false}
                                                        onClick={selectStudentCard}>
                                                        Class Wise
                                                    </Button>
                                                </CardActions>
                                            </div>
                                        </CardHeader>
                                        {isStudentOrClassWise ?
                                            <CardBody>
                                                <div className='row'>
                                                    <div className="col-4 mt-3">
                                                        <FormGroup id='studentDetailsId' label='Student' isFloating>
                                                            <SearchableSelect isFloating ariaLabel='Student' onChange={selectStudent} value={studentDetailsId}
                                                                list={allStudentData.map((data: any) => (
                                                                    { value: data.studentDetailsId, label: data.studentNameAndAdmissionNo }
                                                                ))}
                                                            />
                                                        </FormGroup>
                                                    </div>

                                                    <div className="col-3 mt-4">
                                                        <Button icon='ArrowDownward' color='primary' isDisable={studentDetailsId?.value == undefined} onClick={viewParticularStudent}>View</Button>
                                                    </div>
                                                </div>
                                            </CardBody> :
                                            <CardBody>
                                                <div className='row'>
                                                    <div className='col-3'>
                                                        <FormGroup id='departmentId' label='Department' isFloating>
                                                            <SearchableSelect isFloating ariaLabel='Department' onChange={selectDepartment} value={departmentId}
                                                                list={departmentData.map((data: any) => (
                                                                    { value: data.departmentId, label: data.departmentName }
                                                                ))}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    {departmentId?.value != undefined ? <div className='col-3'>
                                                        <FormGroup id='graduationTypeId' label='Graduation Type' isFloating>
                                                            <SearchableSelect isFloating ariaLabel='Graduation Type' onChange={selectGraduationType}
                                                                value={graduationTypeId} list={graduationTypeData.map((data: any) => (
                                                                    { value: data.graduationTypeId, label: data.graduationType }
                                                                ))}
                                                            />
                                                        </FormGroup>
                                                    </div> : null}

                                                    {graduationTypeId?.value != undefined ? <div className='col-3'>
                                                        <FormGroup id='courseId' label='Course' isFloating>
                                                            <SearchableSelect isFloating ariaLabel='Course' onChange={selectCourse} value={courseId}
                                                                list={courseData.map((data: any) => (
                                                                    { value: data.courseId, label: data.courseName }
                                                                ))}
                                                            />
                                                        </FormGroup>
                                                    </div> : null}

                                                    {courseId?.value != undefined ? <div className='col-3'>
                                                        <FormGroup id='batchMasterId' label='Batch' isFloating>
                                                            <SearchableSelect isFloating ariaLabel='Batch' onChange={selectBatch} value={batchMasterId}
                                                                list={batchMasterData.map((data: any) => (
                                                                    { value: data.batchMasterId, label: data.batchName }
                                                                ))}
                                                            />
                                                        </FormGroup>
                                                    </div> : null}

                                                    {batchMasterId?.value != undefined ? <div className='col-3 mt-3'>
                                                        <FormGroup id='semesterDetailsId' label='Semester' isFloating>
                                                            <SearchableSelect isFloating ariaLabel='Semester' onChange={selectSemester} value={semesterDetailsId}
                                                                list={semesterDetailsData.map((data: any) => (
                                                                    { value: data.semesterDetailsId, label: data.semesterNumber }
                                                                ))}
                                                            />
                                                        </FormGroup>
                                                    </div> : null}

                                                    {semesterDetailsId?.value != undefined ? <div className='col-3 mt-3'>
                                                        <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                                            <SearchableSelect isFloating ariaLabel='Section' onChange={selectSection} value={sectionDetailsId}
                                                                list={sectionDetailsData.map((data: any) => (
                                                                    { value: data.sectionDetailsId, label: data.sectionName }
                                                                ))}
                                                            />
                                                        </FormGroup>
                                                    </div> : null}

                                                    {sectionDetailsId?.value != undefined ? <div className="col-3 mt-3">
                                                        <FormGroup id='studentDetailsId' label='Student' isFloating>
                                                            <SearchableSelect isFloating ariaLabel='Student' onChange={selectStudent} value={studentDetailsId}
                                                                list={studentDetailsData.map((data: any) => (
                                                                    { value: data.studentDetailsId, label: data.studentNameAndAdmissionNo }
                                                                ))}
                                                            />
                                                        </FormGroup>
                                                    </div> : null}

                                                    {studentDetailsId?.value != undefined ? <div className="col-3 mt-4">
                                                        <Button icon='ArrowDownward' color='primary' isDisable={studentDetailsId?.value == undefined} onClick={viewParticularStudent}>View</Button>
                                                    </div> : null}
                                                </div>
                                            </CardBody>}
                                    </Card>
                                </Collapse>

                                <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />
                            </>
                            : null}

                    </>}
                {dataSuccess ?
                    <> {showPayFees == "" ?
                        <div className='row h-100 pb-3'>
                            <div className='col-lg-4 col-md-6'>
                                <Card stretch>
                                    <CardHeader borderSize={1}>
                                        <CardLabel icon='Person' iconColor='info'>
                                            <CardTitle tag='div' className='h5'>
                                                Account Details
                                            </CardTitle>
                                            <CardSubTitle tag='div' className='h6'>
                                                Personal Information - {studentProfileData[0].isActive ? <span className="text-success align-self-center mt-2 border border-success border-2 px-2 py-1 rounded sm">{studentProfileData[0].activeStatus}</span> : <span className="text-danger align-self-center mt-2 border border-danger border-2 px-2 py-1 rounded sm">{studentProfileData[0].activeStatus}</span>}
                                            </CardSubTitle>
                                        </CardLabel>
                                    </CardHeader>
                                    <CardBody>
                                        <div className='row g-3'>
                                            <div className='col-12'>
                                                {studentProfileData.map((student: any, index: any) => (
                                                    <div className="row" key={index}>
                                                        <div className="col-4 mt-3">
                                                            {student.profilePath != null ?
                                                                <img style={{ borderRadius: '50%' }} src={student.profilePath}
                                                                    width="100" height="100" onError={(e: any) => pictNotLoading(e, student.gender == 'Male' ? 1 : 2)} />
                                                                : <img className="rounded-0" src={profilePic(student.genderId)}
                                                                    width="100" height="100" />}

                                                        </div>
                                                        <div className="col-8">
                                                            <h5 className='fs-5 fw-bold me-2 text-uppercase mt-3'>{student.studentName}</h5>
                                                            <div><span className="text-success">{student.admissionNo} </span>({student.semesterNumber})</div>
                                                            <div>{student.courseName}</div>
                                                            <div>{student.departmentName}</div>
                                                            <div>{student.batchName}</div>
                                                        </div>
                                                    </div>
                                                ))}

                                            </div>
                                            <div className='col-12'>
                                                <Button
                                                    icon='LocalPolice'
                                                    color='info'
                                                    className='w-100 p-3'
                                                    isLight={TABS.FEE_DETAILS !== activeTab}
                                                    onClick={() => setActiveTab(TABS.FEE_DETAILS)}>
                                                    {TABS.FEE_DETAILS}
                                                </Button>
                                            </div>
                                            <div className='col-12'>
                                                <Button
                                                    icon='PendingActions'
                                                    color='info'
                                                    className='w-100 p-3'
                                                    isLight={TABS.PAID !== activeTab}
                                                    onClick={() => setActiveTab(TABS.PAID)}>
                                                    {TABS.PAID}
                                                </Button>
                                            </div>
                                            <div className='col-12'>
                                                <Button
                                                    icon='ReceiptLong'
                                                    color='info'
                                                    className='w-100 p-3'
                                                    isLight={TABS.CONCESSION !== activeTab}
                                                    onClick={() => setActiveTab(TABS.CONCESSION)}>
                                                    {TABS.CONCESSION}
                                                </Button>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className='col-lg-8 col-md-6'>
                                {TABS.FEE_DETAILS === activeTab && (
                                    <Card stretch>
                                        <CardHeader>
                                            <CardLabel icon='Task' iconColor='primary'>
                                                <CardTitle tag='div' className='h5'>
                                                    Fee Details
                                                </CardTitle>
                                            </CardLabel>
                                            <CardActions>
                                                <Button color='success' icon='Payment' isLight onClick={payFees}
                                                >
                                                    Pay Fees
                                                </Button>
                                                <Button color='info' icon='Payments' isLight onClick={() => setShowPayFees("payFineFees")}>
                                                    Pay Fine
                                                </Button>
                                            </CardActions>
                                        </CardHeader>
                                        <CardBody className='table-responsive'>
                                            {studentTotalFeeCollectionData ?
                                                <div className="table-responsive">
                                                    <table className="table table-modern table-hover" >
                                                        <thead className="text-center text-uppercase align-middle">
                                                            <tr>
                                                                <th className='table-info'></th>
                                                                <th className='table-info'>Sem <br />Fees</th>
                                                                <th className='table-info'>Prev - Sem <br />Fees</th>
                                                                <th className='table-info'>Fine <br />Fees</th>
                                                                <th className='table-info'>Bus <br />Fees</th>
                                                                <th className='table-info'>Prev - Sem Bus <br />Fees</th>
                                                                <th className='table-info'>Sem - Fine <br />Fees</th>
                                                            </tr>
                                                        </thead>

                                                        {studentTotalFeeCollectionData.map((studentTotalFeeCollectionData: any) => (
                                                            <tbody className="text-center align-middle" key={studentTotalFeeCollectionData.studentDetailsId}>
                                                                <tr>
                                                                    <th>GROSS <br />PAYABLE</th>
                                                                    <td><strong>{studentTotalFeeCollectionData.semFees_grossPayable}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.prevSemFees_grossPayable}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.totalFineAmount}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.busFees_grossPayable}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.prevBusFees_grossPayable}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.extraFees_grossPayable}</strong></td>
                                                                </tr>
                                                                <tr>
                                                                    <th>AMOUNT PAID</th>
                                                                    <td><strong>{studentTotalFeeCollectionData.semFees_amountPaid}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.prevSemFees_amountPaid}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.totalFineAmount}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.busFees_amountPaid}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.prevBusFees_amountPaid}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.extraFees_amountPaid}</strong></td>
                                                                </tr>
                                                                <tr>
                                                                    <th>CONCESSION</th>
                                                                    <td><strong>{studentTotalFeeCollectionData.semFees_concession}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.prevSemFees_concession}</strong></td>
                                                                    <td><strong>0</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.busFees_concession}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.prevBusFees_concession}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.extraFees_concession}</strong></td>
                                                                </tr>
                                                                <tr>
                                                                    <th>NET PAYABLE</th>
                                                                    <td>
                                                                        {studentTotalFeeCollectionData.semFees_netPayable === 0 ?
                                                                            <strong className="text-success">{studentTotalFeeCollectionData.semFees_netPayable}</strong>
                                                                            :
                                                                            <strong className="text-danger">{studentTotalFeeCollectionData.semFees_netPayable}</strong>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {studentTotalFeeCollectionData.prevSemFees_netPayable === 0 ?
                                                                            <strong className="text-success">{studentTotalFeeCollectionData.prevSemFees_netPayable}</strong>
                                                                            :
                                                                            <strong className="text-danger">{studentTotalFeeCollectionData.prevSemFees_netPayable}</strong>
                                                                        }
                                                                    </td>

                                                                    <td><strong>0</strong></td>

                                                                    <td>
                                                                        {studentTotalFeeCollectionData.busFees_netPayable === 0 ?
                                                                            <strong className="text-success">{studentTotalFeeCollectionData.busFees_netPayable}</strong>
                                                                            :
                                                                            <strong className="text-danger">{studentTotalFeeCollectionData.busFees_netPayable}</strong>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {studentTotalFeeCollectionData.prevBusFees_netPayable === 0 ?
                                                                            <strong className="text-success">{studentTotalFeeCollectionData.prevBusFees_netPayable}</strong>
                                                                            :
                                                                            <strong className="text-danger">{studentTotalFeeCollectionData.prevBusFees_netPayable}</strong>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {studentTotalFeeCollectionData.extraFees_netPayable === 0 ?
                                                                            <strong className="text-success">{studentTotalFeeCollectionData.extraFees_netPayable}</strong>
                                                                            :
                                                                            <strong className="text-danger">{studentTotalFeeCollectionData.extraFees_netPayable}</strong>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        ))}
                                                    </table>
                                                    <br />

                                                    {studentTotalFeeCollectionData.map((studentTotalFeeCollectionData: any) => (
                                                        <div className="col-12 d-flex justify-content-end" key={studentTotalFeeCollectionData.studentDetailsId}>
                                                            <p className='fw-bold fs-5 mb-0'> Balance : </p>
                                                            <strong className={studentTotalFeeCollectionData.semFees_netPayable + studentTotalFeeCollectionData.prevSemFees_netPayable +
                                                                studentTotalFeeCollectionData.busFees_netPayable +
                                                                studentTotalFeeCollectionData.prevBusFees_netPayable +
                                                                studentTotalFeeCollectionData.extraFees_netPayable == 0 ? "text-success" : "text-danger"}   >
                                                                <p className='fw-bold fs-5 mb-0 ms-2'>
                                                                    {studentTotalFeeCollectionData.semFees_netPayable + studentTotalFeeCollectionData.prevSemFees_netPayable +
                                                                        studentTotalFeeCollectionData.busFees_netPayable +
                                                                        studentTotalFeeCollectionData.prevBusFees_netPayable +
                                                                        studentTotalFeeCollectionData.extraFees_netPayable}
                                                                </p>
                                                            </strong>
                                                        </div>
                                                    ))}

                                                </div>
                                                :

                                                <div className='text-danger col-12 d-flex justify-content-center mt-5'>
                                                    <p className='fw-bold fs-5 mb-0 mt-5'>Fees Not Collected Yet . </p>
                                                </div>
                                            }
                                        </CardBody>
                                    </Card>
                                )}
                                {TABS.PAID === activeTab && (
                                    <Card stretch>
                                        <CardHeader>
                                            <CardLabel icon='PendingActions' iconColor='primary'>
                                                <CardTitle tag='div' className='h5'>
                                                    Paid Bill Details
                                                </CardTitle>
                                                <CardSubTitle tag='div' className='h6'>
                                                    Total Bills:{' '}
                                                    <small className='ms-2'>
                                                        {filteredData.length}
                                                    </small>
                                                </CardSubTitle>
                                            </CardLabel>
                                            {studentFeeCollectionData != "" ?
                                                <CardActions>
                                                    <ButtonGroup className="col-lg-12 d-print-none" color='primary'>
                                                        <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                                        <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                                                    </ButtonGroup>
                                                </CardActions> : null}
                                        </CardHeader>
                                        <CardBody className='table-responsive' isScrollable>
                                            <table className='table table-modern table-hover text-nowrap'>
                                                <thead>
                                                    <tr>
                                                        <th scope='col' onClick={() => requestSort('sno')}
                                                            className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                            <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                                        </th>
                                                        {columnVisibilityDataForFeeCollection.map((column: any) => (
                                                            <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                                className='cursor-pointer text-decoration-underline'>
                                                                {column.columnName}
                                                                <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                            </th>
                                                        ))}
                                                        <td className="d-print-none" />
                                                    </tr>
                                                </thead>
                                                <tbody>{filteredData != "" ? <>{filteredData.map((rowData: any) => (<tr key={rowData.studentFeeCollectionId}><td>{rowData.sno}</td>{columnVisibilityDataForFeeCollection.map((column: any) => ((column.isDisplay && rowData[column.keyName]) ? <td key={`${rowData.studentFeeCollectionId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />))}{editAccess ? <td className="d-print-none" onClick={() => { onActivate(rowData, '') }}><Dropdown isButtonGroup><DropdownToggle><Button color='dark' isLight icon='MoreHoriz' aria-label='MoreHoriz'>More</Button></DropdownToggle><DropdownMenu isAlignmentEnd><DropdownItem><Button icon="Print" isLight color="info" onClick={() => setReceiptOpenModal(true)}>Print</Button></DropdownItem><DropdownItem><Button icon="Receipt" isLight color="success" onClick={() => { onActivate(rowData, 'updateReceipt') }}>Update</Button></DropdownItem>{deleteAccess ? <DropdownItem><Button icon="Delete" isLight color="primary" onClick={() => { setDeleteReceiptCanvas(true) }}>Delete</Button></DropdownItem>: null}</DropdownMenu></Dropdown></td> : <td className="d-print-none" onClick={() => { onActivate(rowData, '') }}><Button icon="Print" isLight color="info" onClick={() => setReceiptOpenModal(true)}>Print</Button></td>}</tr>))}</> : <NoDataMsg columnsCount={columnVisibilityDataForFeeCollection.length + 2} msg={nodataForPaidBills} />}</tbody></table>
                                        </CardBody>
                                        {studentFeeCollectionData != "" ? <PaginationButtons
                                            data={items}
                                            label='items'
                                            setCurrentPage={setCurrentPage}
                                            currentPage={currentPage}
                                            perPage={perPage}
                                            setPerPage={setPerPage}
                                        /> : null}
                                    </Card>
                                )}
                                {TABS.CONCESSION === activeTab && (
                                    <Card stretch>
                                        <CardHeader>
                                            <CardLabel icon='ReceiptLong' iconColor='primary'>
                                                <CardTitle tag='div' className='h5'>
                                                    Given Concession
                                                </CardTitle>
                                                <CardSubTitle tag='div' className='h6'>
                                                    Total Concession:{''}
                                                    <small className='ms-2'>
                                                        {studentFeeConcessionData.length}
                                                    </small>
                                                </CardSubTitle>
                                            </CardLabel>
                                            {studentFeeConcessionData != "" ?
                                                <>
                                                    <CardActions>
                                                        <Button color='info' icon='CloudDownload' isLight onClick={() => downloadExcel("ConcessionList", studentFeeConcessionData, columnVisibilityData)}>
                                                            Excel
                                                        </Button>
                                                    </CardActions> </> : null}
                                        </CardHeader>
                                        <CardBody className='table-responsive' isScrollable>
                                            <table className='table table-modern mb-0 text-center'>
                                                <thead>
                                                    <tr>
                                                        <th scope='col' onClick={() => requestSort1('sno')}
                                                            className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                            <Icon size='lg' className={getClassNamesFor1('sno')} icon='FilterList' />
                                                        </th>
                                                        {columnVisibilityData.map((column: any) => (
                                                            <th key={column.keyName} scope='col' onClick={() => requestSort1(column.keyName)}
                                                                className='cursor-pointer text-decoration-underline'>
                                                                {column.columnName}
                                                                <Icon size='lg' className={getClassNamesFor1(column.keyName)} icon='FilterList' />
                                                            </th>
                                                        ))}
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>{filteredData1 != "" ? <>{filteredData1.map((rowData: any) => (<tr key={rowData.studentFeeConcessionId}><td>{rowData.sno}</td>{columnVisibilityData.map((column: any) => ((column.isDisplay && rowData[column.keyName]) ? <td key={`${rowData.studentFeeConcessionId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />))}<td><Button isLink color={rowData.concessionStatusId == 1 ? 'success' : rowData.concessionStatusId == 2 ? 'danger' : 'warning'} icon='Circle' className='text-nowrap'>{rowData.concessionStatus}</Button></td></tr>))}</> : <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}</tbody></table>
                                        </CardBody>
                                        {studentFeeConcessionData != "" ? <PaginationButtons
                                            data={items1}
                                            label='items'
                                            setCurrentPage={setCurrentPage}
                                            currentPage={currentPage}
                                            perPage={perPage}
                                            setPerPage={setPerPage}
                                        /> : null}
                                    </Card>
                                )}
                            </div>
                        </div> :
                        <PayFees studentDetailsId={studentDetailsId?.value} setShowPayFees={setShowPayFees} showPayFees={showPayFees} refreshAfterFeeCollection={refreshAfterFeeCollection} />}
                    </> : <>{navStudent ? <TableLoader /> : null}</>}
            </Page>

            <style type="text/css" media="print">
                {"@page { size: A4 landscape; margin-left: 0.5in; margin-right: 0.5in; margin-top: 0.5in; margin-bottom: 0.5in; }\ }"}
            </style>

            <Modal setIsOpen={setReceiptOpenModal} isOpen={receiptOpenModal} titleId='addCourseCapacity' isStaticBackdrop size='xl'>
                <ModalHeader className="'modal-header'" setIsOpen={() => { setReceiptOpenModal(false) }}>
                    <div className='d-flex col-4'>
                        <Button size='sm' icon="Downloading" isLight color="success" onClick={handlePDF}>Download</Button>

                        <Button className="ms-2" size='sm' icon="Print" isLight color="primary" onClick={handlePrint}>Print</Button>
                    </div>
                </ModalHeader>

                <ModalBody ref={componentRef}>
                    {studentFeeReceiptData.map((studentFeeData: any) => (
                        <div className="modal-body table-responsive" id='thForReceipt' key={studentFeeData.studentFeeCollectionId}>
                            <table className="table table-border border border-black table-responsive align-middle" style={{ fontFamily: 'Times New Roman' }} id="tdForReceipt">
                                <tbody style={{ border: "1px solid black" }}>
                                    {collegeDetailsData.map((collegeData: any) => (
                                        <tr key={collegeData.collegeDetailsId}>
                                            <td width="15%" rowSpan={1} align="center">
                                                <img height="100px" width="100px"
                                                    src={CollegeLogo} />
                                            </td>
                                            <th style={{ width: '64%' }} className="text-center table-border border border-black" align="center">
                                                <h2 className="text-uppercase" style={{
                                                    fontFamily: 'Bookman Old Style',
                                                    color: 'rgb(61, 57, 57)'
                                                }}>
                                                    <b style={{ fontSize: '120%' }}>{collegeData.collegeName}</b>
                                                </h2>

                                                <b className="fs-7 mg-t-10" style={{ fontFamily: 'Bookman Old Style', color: 'rgb(61, 57, 57)', width: '95%' }}>
                                                    <p className="fs-6">({collegeData.description})<br />{collegeData.address}<br />
                                                        <Icon icon="Email" /> {collegeData.website} <Icon icon="Phone" /> {collegeData.phone} <Icon icon="PhoneAndroid" /> {collegeData.cellNo}</p>
                                                </b>
                                            </th>
                                            <th style={{ width: '21%' }} className={tableFontSize} align="center">
                                                <div className='align-middle'>
                                                    <p>Receipt No
                                                        : <strong>{studentFeeData.receiptNo}</strong></p>
                                                    <p className="mg-lg-t-5">Date
                                                        : <strong>{dayjs(studentFeeData.receiptDate).format('DD/MM/YYYY')}</strong></p>
                                                </div>
                                            </th>
                                        </tr>
                                    ))}
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td colSpan={3}>
                                            <p className="mt-4 fs-4" style={{ marginLeft: "20px" }}>Roll Number : <b>{studentFeeData.studentRollNo}</b>
                                                <span style={{ marginRight: "20px", float: "right" }}>Semester : <b>
                                                    {studentFeeData.studentSemesterNumber}</b></span>
                                            </p>
                                            <p className="mt-4 fs-4" style={{ marginLeft: "20px" }}>Student Name : <b>{studentFeeData.studentName}</b>
                                                <span style={{ marginRight: "20px", float: "right" }}>Course / Section : <b>
                                                    {studentFeeData.studentCourseName} / {studentFeeData.sectionName}</b></span>
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>

                                <tbody>
                                    <tr className={tableFontSize}>
                                        <th className={'text-left border border-black'}>SI No.</th>
                                        <th className={'text-left border border-black'}>Particulars</th>
                                        <th className={'text-left border border-black'}>Amount</th>
                                    </tr>
                                    {studentFeeData.feeCollectionDetails.map((feeData: any) => (
                                        <tr className={tableFontSize} key={feeData.feeTypeId}>
                                            <td className="text-left border border-black">
                                                {feeData.sno}
                                            </td>
                                            <td className="text-left border border-black">
                                                {feeData.feeType} / {feeData.semesterNumber}
                                            </td>
                                            <td className="text-left border border-black">
                                                <b>{feeData.totalAmount}</b>
                                            </td>
                                        </tr>
                                    ))}
                                    <tr className={tableFontSize}>
                                        <td></td>
                                        <td className="text-left border border-black align-middle">
                                            Total amount
                                        </td>
                                        <td className="text-left border border-black">
                                            <b> {studentFeeData.feesCollected}</b>
                                        </td>
                                    </tr>
                                    <tr className={tableFontSize}>
                                        <td colSpan={2} align="left" className="align-middle">
                                            Amount in words : <b> {studentFeeData.feesCollectedInWords} Rupees Only/-</b>
                                        </td>
                                        <td align="left" className="align-middle" style={{ whiteSpace: 'nowrap' }}>
                                            Payment Type: <b>{studentFeeData.paymentType}</b>
                                        </td>
                                    </tr>
                                    <tr id='tdForReceiptNoBorder' style={{ borderTopColor: 'white' }} className={tableFontSize}>
                                        <td style={{ borderRightColor: 'white', borderTopColor: 'white' }}></td>
                                        <td align="center"
                                            style={{ borderRightColor: 'white', borderTopColor: 'white' }}>
                                            <span style={{ float: 'left', marginLeft: '20px' }}>
                                                <br />
                                                <b>Seal</b>
                                            </span>
                                        </td>
                                        <td aria-colspan={1} align="center">
                                            <br />
                                            <b>Signature</b>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {studentFeeData.paymentTypeId > 4 ?
                                <div className="row mt-4">
                                    <div className="col-12">
                                        <p>This is computer generated bill no signature required.</p>
                                    </div>
                                </div> : null}
                        </div>
                    ))
                    }
                </ModalBody>
            </Modal>

            <Modal setIsOpen={setUpdateReceiptOpenModal} isOpen={updateReceiptOpenModal} titleId="updateReceiptOpenModal" isStaticBackdrop
                size='lg'  >
                <ModalHeader setIsOpen={setUpdateReceiptOpenModal} onClick={closeAndReset}>
                    <ModalTitle id="updateReceiptOpenModal">Update Receipt</ModalTitle>
                </ModalHeader>
                <form noValidate onSubmit={updateStudentFeeReceiptForm.handleSubmit}>
                    <ModalBody className='px-4'>
                        {studentFeeReceiptData.map((student: any) => (
                            <div className='row g-4' key={student.studentFeeCollectionId}>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className='col-lg-6'>
                                            <div className="row g-4 justify-content-center">
                                                <div className='col-lg-7'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='flex-shrink-0'>
                                                            <Icon icon='person' size='2x' color='primary' />
                                                        </div>
                                                        <div className='flex-grow-1 ms-3'>
                                                            <div className='fw-bold mb-0'>
                                                                {student.studentName}                                                            </div>
                                                            <div className='text-muted'>
                                                                Student Name
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-5'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='flex-shrink-0'>
                                                            <Icon icon='HowToReg' size='2x' color='primary' />
                                                        </div>
                                                        <div className='flex-grow-1 ms-3'>
                                                            <div className='fw-bold mb-0'>
                                                                {student.studentRollNo}                                                </div>
                                                            <div className='text-muted'>
                                                                Admission No
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className="row g-4 justify-content-center">
                                                <div className='col-lg-6'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='flex-shrink-0'>
                                                            <Icon icon='ListAlt' size='2x' color='primary' />
                                                        </div>
                                                        <div className='flex-grow-1 ms-3'>
                                                            <div className='fw-bold mb-0'>
                                                                {student.receiptNo}                                                       </div>
                                                            <div className='text-muted'>
                                                                Receipt No
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='flex-shrink-0'>
                                                            <Icon icon='CalendarToday' size='2x' color='primary' />
                                                        </div>
                                                        <div className='flex-grow-1 ms-3'>
                                                            <div className='fw-bold mb-0'>
                                                                {student.receiptDate}                                              </div>
                                                            <div className='text-muted'>
                                                                Receipt Date
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <Card stretch className='rounded-1 mb-2'>
                                        <CardHeader>
                                            <CardLabel icon='ReceiptLong'>
                                                <CardTitle>Paid Bill Details</CardTitle>
                                            </CardLabel>
                                        </CardHeader>
                                        <CardBody>
                                            <table className='table table-modern table-hover text-nowrap'>
                                                <thead className='table-primary'>
                                                    <tr>
                                                        <th>
                                                            S.No
                                                        </th>
                                                        <th>
                                                            Particulars
                                                        </th>
                                                        <th>
                                                            Amount
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {student.feeCollectionDetails.map((feeData: any) => (
                                                        <tr key={feeData.feeTypeId}>
                                                            <td >
                                                                {feeData.sno}
                                                            </td>
                                                            <td>
                                                                {feeData.feeType} / {feeData.semesterNumber}
                                                            </td>
                                                            <td>
                                                                <b>{feeData.totalAmount}</b>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className='col-md-6'>
                                    <Card stretch className='rounded-1 mb-2'>
                                        <CardHeader>
                                            <CardLabel icon='Update'>
                                                <CardTitle>To Be Updated</CardTitle>
                                            </CardLabel>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="row g-4">
                                                <div className="col-lg-12">
                                                    <FormGroup id='studentDetailsId' label="Select Student" isFloating>
                                                        <SearchableSelect isFloating ariaLabel="Select Student"
                                                            onChange={(e: any) => setFeeReceiptUpdateStudentDetailsId(e)}
                                                            value={feeReceiptUpdateStudentDetailsId}
                                                            list={allStudentData.map((data: any) => (
                                                                { value: data.studentDetailsId, label: data.studentNameAndAdmissionNo }
                                                            ))}
                                                            isValid={updateStudentFeeReceiptForm.isValid}
                                                            isTouched={updateStudentFeeReceiptForm.touched.studentDetailsId}
                                                            invalidFeedback={updateStudentFeeReceiptForm.errors.studentDetailsId} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        ))}
                    </ModalBody>
                    <ModalFooter>
                        <Button color='info' isOutline className='border-0' onClick={closeAndReset}>Close</Button>
                        <Button color='info' icon='Save' type="submit" isDisable={feeReceiptUpdateStudentDetailsId?.value == undefined}>Update </Button>
                    </ModalFooter>
                </form>
            </Modal>

            <Modal setIsOpen={setUpdateFeePaymentTypeModal} isOpen={updateFeePaymentTypeModal} titleId="updateFeePaymentTypeModal" isStaticBackdrop
                size='lg'  >
                <ModalHeader setIsOpen={setUpdateFeePaymentTypeModal} onClick={closeAndReset}>
                    <ModalTitle id="updateFeePaymentTypeModal">Update Fee Payment Type</ModalTitle>
                </ModalHeader>
                <form noValidate onSubmit={updateStudentFeeReceiptForm.handleSubmit}>
                    <ModalBody className='px-4'>
                        {studentFeeReceiptData.map((student: any) => (
                            <div className='row g-4' key={student.studentFeeCollectionId}>
                                {/* <div className="col-md-3 d-flex justify-content-evenly">
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='person' size='2x' color='primary' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold mb-0'>
                                                {student.studentName}
                                            </div>
                                            <div className='text-muted'>
                                                Student Name
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 d-flex justify-content-evenly">
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='HowToReg' size='2x' color='primary' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold mb-0'>
                                                {student.studentRollNo}
                                            </div>
                                            <div className='text-muted'>
                                                Admission No
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 d-flex justify-content-evenly">
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='ListAlt' size='2x' color='primary' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold mb-0'>
                                                {student.receiptNo}
                                            </div>
                                            <div className='text-muted'>
                                                Receipt No
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 d-flex justify-content-evenly">
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='CalendarToday' size='2x' color='primary' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold mb-0'>
                                                {student.receiptDate}
                                            </div>
                                            <div className='text-muted'>
                                                Receipt Date
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 d-flex justify-content-evenly">
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='CalendarToday' size='2x' color='primary' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold mb-0'>
                                                {student.paymentType}
                                            </div>
                                            <div className='text-muted'>
                                                Payment Type
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 d-flex justify-content-evenly"></div> */}
                                {/* <div className='col-md-6'>
                                    <Card stretch className='rounded-1 mb-2'>
                                        <CardHeader>
                                            <CardLabel icon='ReceiptLong'>
                                                <CardTitle>Paid Bill Details</CardTitle>
                                            </CardLabel>
                                        </CardHeader>
                                        <CardBody>
                                            <table className='table table-modern table-hover text-nowrap'>
                                                <thead className='table-primary'>
                                                    <tr>
                                                        <th>
                                                            S.No
                                                        </th>
                                                        <th>
                                                            Particulars
                                                        </th>
                                                        <th>
                                                            Amount
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {student.feeCollectionDetails.map((feeData: any) => (
                                                        <tr key={feeData.feeTypeId}>
                                                            <td >
                                                                {feeData.sno}
                                                            </td>
                                                            <td>
                                                                {feeData.feeType} / {feeData.semesterNumber}
                                                            </td>
                                                            <td>
                                                                <b>{feeData.totalAmount}</b>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </CardBody>
                                    </Card>
                                </div> */}
                                {/* <div className='col-md-6'>
                                    <Card stretch className='rounded-1 mb-2'>
                                        <CardHeader>
                                            <CardLabel icon='Update'>
                                                <CardTitle>To Be Updated</CardTitle>
                                            </CardLabel>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="row g-4">
                                                <div className="col-lg-12">
                                                    <FormGroup id='updatedPaymentTypeId' label="Select Payment Type" isFloating>
                                                        <SearchableSelect isFloating ariaLabel="Select Payment Type"
                                                            onChange={selectPaymentType}
                                                            value={updatedPaymentTypeId}
                                                            list={paymentTypeData.map((data: any) => (
                                                                { value: data.updatedPaymentTypeId, label: data.paymentType }
                                                            ))}
                                                        //isValid={updateStudentFeeReceiptForm.isValid}
                                                        //isTouched={updateStudentFeeReceiptForm.touched.updatedPaymentTypeId}
                                                        //invalidFeedback={updateStudentFeeReceiptForm.errors.updatedPaymentTypeId} 
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div> */}


                                <div className='col-md-4'>
                                    <Card stretch>
                                        <CardHeader borderSize={1}>
                                            <CardLabel icon='list' iconColor='primary'>
                                                <CardTitle>
                                                    Paid Bill Details
                                                </CardTitle>
                                            </CardLabel>
                                        </CardHeader>
                                        <CardBody>
                                            <div className='row g-4'>
                                                <div className="col-md-12">
                                                    <div className='d-flex align-items-center'>
                                                        <div className='flex-shrink-0'>
                                                            <Icon icon='person' size='2x' color='primary' />
                                                        </div>
                                                        <div className='flex-grow-1 ms-3'>
                                                            <div className='fw-bold mb-0'>
                                                                {student.studentName}
                                                            </div>
                                                            <div className='text-muted'>
                                                                Student Name
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className='d-flex align-items-center'>
                                                        <div className='flex-shrink-0'>
                                                            <Icon icon='HowToReg' size='2x' color='primary' />
                                                        </div>
                                                        <div className='flex-grow-1 ms-3'>
                                                            <div className='fw-bold mb-0'>
                                                                {student.studentRollNo}
                                                            </div>
                                                            <div className='text-muted'>
                                                                Admission No
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className='d-flex align-items-center'>
                                                        <div className='flex-shrink-0'>
                                                            <Icon icon='ListAlt' size='2x' color='primary' />
                                                        </div>
                                                        <div className='flex-grow-1 ms-3'>
                                                            <div className='fw-bold mb-0'>
                                                                {student.receiptNo}
                                                            </div>
                                                            <div className='text-muted'>
                                                                Receipt No
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className='d-flex align-items-center'>
                                                        <div className='flex-shrink-0'>
                                                            <Icon icon='CalendarToday' size='2x' color='primary' />
                                                        </div>
                                                        <div className='flex-grow-1 ms-3'>
                                                            <div className='fw-bold mb-0'>
                                                                {student.receiptDate}
                                                            </div>
                                                            <div className='text-muted'>
                                                                Receipt Date
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className='d-flex align-items-center'>
                                                        <div className='flex-shrink-0'>
                                                            <Icon icon='CalendarToday' size='2x' color='primary' />
                                                        </div>
                                                        <div className='flex-grow-1 ms-3'>
                                                            <div className='fw-bold mb-0'>
                                                                {student.paymentType}
                                                            </div>
                                                            <div className='text-muted'>
                                                                Payment Type
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <FormGroup id='updatedPaymentTypeId' label="Select Payment Type" isFloating>
                                                        <SearchableSelect isFloating ariaLabel="Select Payment Type"
                                                            onChange={selectPaymentType}
                                                            value={updatedPaymentTypeId}
                                                            list={paymentTypeData.map((data: any) => (
                                                                { value: data.updatedPaymentTypeId, label: data.paymentType }
                                                            ))}
                                                        //isValid={updateStudentFeeReceiptForm.isValid}
                                                        //isTouched={updateStudentFeeReceiptForm.touched.updatedPaymentTypeId}
                                                        //invalidFeedback={updateStudentFeeReceiptForm.errors.updatedPaymentTypeId} 
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className='col-md-8'>
                                    <Card stretch>
                                        <CardHeader borderSize={1}>
                                            <CardLabel icon='AddLocationAlt' iconColor='primary'>
                                                <CardTitle>
                                                    Payment Denomination
                                                </CardTitle>
                                            </CardLabel>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="row">
                                                {updatedPaymentTypeId?.value === 1 && (

                                                    <div className='row'>
                                                        <div className="d-grid gap-2">
                                                            <div className="row justify-content-center">
                                                                <div className="col-lg-4 align-self-center h6">
                                                                </div>
                                                                <div className="col-lg-4 justify-content-center">
                                                                    Count
                                                                </div>

                                                                <div className="col-lg-4 justify-content-center">
                                                                    Amount
                                                                </div>
                                                            </div>
                                                            <div className="row justify-content-center">
                                                                <div className="col-lg-1 align-self-center h6">
                                                                </div>
                                                                <div className="col-lg-3 mt-2 align-self-center h6">
                                                                    <p>500 X</p>
                                                                </div>
                                                                <div className="col-lg-4 justify-content-center">
                                                                    <FormGroup id="fivehunderd_count" label="">
                                                                        <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.fivehunderd_count} onInput={multiply500} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                                    </FormGroup>
                                                                </div>

                                                                <div className="col-lg-4 justify-content-center">
                                                                    <FormGroup id="fivehunderd_total" label="">
                                                                        <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={fivehunderd_total} disabled />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                            <div className="row justify-content-center">
                                                                <div className="col-lg-1 align-self-center h6">
                                                                </div>
                                                                <div className="col-lg-3 mt-2 align-self-center h6">
                                                                    <p>200 X</p>
                                                                </div>
                                                                <div className="col-lg-4 justify-content-center">
                                                                    <FormGroup id="twoHundred_total" label="">
                                                                        <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.twoHundred_total} onInput={multiply200} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                                    </FormGroup>
                                                                </div>

                                                                <div className="col-lg-4 justify-content-center">
                                                                    <FormGroup id="twoHundred_total" label="">
                                                                        <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={twoHundred_total} disabled />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                            <div className="row justify-content-center">
                                                                <div className="col-lg-1 align-self-center h6">
                                                                </div>
                                                                <div className="col-lg-3 mt-2 align-self-center h6">
                                                                    <p>100 X</p>
                                                                </div>
                                                                <div className="col-lg-4 justify-content-center">
                                                                    <FormGroup id="hundred_count" label="">
                                                                        <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.hundred_count} onInput={multiply100} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                                    </FormGroup>
                                                                </div>

                                                                <div className="col-lg-4 justify-content-center">
                                                                    <FormGroup id="hundred_total" label="">
                                                                        <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={hundred_total} disabled />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                            <div className="row justify-content-center">
                                                                <div className="col-lg-1 align-self-center h6">
                                                                </div>
                                                                <div className="col-lg-3 mt-2 align-self-center h6">
                                                                    <p>50 X</p>
                                                                </div>
                                                                <div className="col-lg-4 justify-content-center">
                                                                    <FormGroup id="fifty_count" label="">
                                                                        <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.fifty_count} onInput={multiply50} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                                    </FormGroup>
                                                                </div>

                                                                <div className="col-lg-4 justify-content-center">
                                                                    <FormGroup id="fifty_total" label="">
                                                                        <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={fifty_total} disabled />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                            <div className="row justify-content-center">
                                                                <div className="col-lg-1 align-self-center h6">
                                                                </div>
                                                                <div className="col-lg-3 mt-2 align-self-center h6">
                                                                    <p>20 X</p>
                                                                </div>
                                                                <div className="col-lg-4 justify-content-center">
                                                                    <FormGroup id="twenty_count" label="">
                                                                        <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.twenty_count} onInput={multiply20} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                                    </FormGroup>
                                                                </div>

                                                                <div className="col-lg-4 justify-content-center">
                                                                    <FormGroup id="twenty_total" label="">
                                                                        <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={twenty_total} disabled />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                            <div className="row justify-content-center">
                                                                <div className="col-lg-1 align-self-center h6">
                                                                </div>
                                                                <div className="col-lg-3 mt-2 align-self-center h6">
                                                                    <p>10 X</p>
                                                                </div>
                                                                <div className="col-lg-4 justify-content-center">
                                                                    <FormGroup id="ten_count" label="">
                                                                        <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.ten_count} onInput={multiply10} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                                    </FormGroup>
                                                                </div>

                                                                <div className="col-lg-4 justify-content-center">
                                                                    <FormGroup id="ten_total" label="">
                                                                        <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={ten_total} disabled />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                            <div className="row justify-content-center">
                                                                <div className="col-lg-1 align-self-center h6">
                                                                </div>
                                                                <div className="col-lg-3 mt-2 align-self-center h6">
                                                                    <p>5 X</p>
                                                                </div>
                                                                <div className="col-lg-4 justify-content-center">
                                                                    <FormGroup id="five_count" label="">
                                                                        <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.five_count} onInput={multiply5} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                                    </FormGroup>
                                                                </div>

                                                                <div className="col-lg-4 justify-content-center">
                                                                    <FormGroup id="five_total" label="">
                                                                        <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={five_total} disabled />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                            <div className="row justify-content-center">
                                                                <div className="col-lg-1 align-self-center h6">
                                                                </div>
                                                                <div className="col-lg-3 mt-2 align-self-center h6">
                                                                    <p></p>
                                                                </div>
                                                                <div className="col-lg-4 mt-2 justify-content-center">
                                                                    <p>Total Amount</p>
                                                                </div>
                                                                <div className="col-lg-4 justify-content-center">
                                                                    <FormGroup id="totalAmount" label="">
                                                                        <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={totalAmount} disabled />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                            <div className="row justify-content-center">
                                                                <div className="col-lg-1 align-self-center h6">
                                                                </div>
                                                                <div className="col-lg-3 mt-2 align-self-center h6">
                                                                    <p></p>
                                                                </div>
                                                                <div className="col-lg-4 mt-2 justify-content-center">
                                                                    <p>Balance Cash Amount</p>
                                                                </div>
                                                                <div className="col-lg-4 justify-content-center">
                                                                    <FormGroup id="balCashAmount" label="">
                                                                        <Input className={balCashAmount < 0 ? 'border border-danger' : ''} type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={balCashAmount} readOnly />
                                                                        <>
                                                                            {balCashAmount < 0 ?
                                                                                <div className='ms-2 text-danger'>
                                                                                    Amount Not Matching !{' '}<Icon icon='Report' color='danger' className='mx-1' size='lg' />
                                                                                </div> : null}
                                                                        </>
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {updatedPaymentTypeId?.value === 2 && (

                                                    <div className='row'>
                                                        <div className="d-grid gap-4">
                                                            <div className="row justify-content-center">
                                                                <div className="col-lg-4 mt-2 align-self-center h6">
                                                                    <p>Cheque Number</p>
                                                                </div>
                                                                <div className="col-lg-6 justify-content-center">
                                                                    <FormGroup id="chequeNumber" label="">
                                                                        <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.chequeNumber} />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                            <div className="row justify-content-center">
                                                                <div className="col-lg-4 mt-2 align-self-center h6">
                                                                    <p>Amount</p>
                                                                </div>

                                                                <div className="col-lg-6 justify-content-center">
                                                                    <FormGroup id="chequeAmount" label="">
                                                                        <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} onBlur={payFeesDenominationForm.handleBlur} value={payFeesDenominationForm.values.chequeAmount} onKeyDown={(e: any) => onlyAllowNumber(e)} />

                                                                    </FormGroup>
                                                                    <>
                                                                        {totalFeesAmount == Number(payFeesDenominationForm.values.chequeAmount) ? null : (
                                                                            <div className="ms-2 mt-2 text-danger">
                                                                                Amount Not Matching !{' '}
                                                                                <Icon icon="Report" color="danger" className="mx-1" size="lg" />
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                </div>


                                                            </div>
                                                            <div className="row justify-content-center">
                                                                <div className="col-lg-4 mt-2 align-self-center h6">
                                                                    <p>Cheque Date</p>
                                                                </div>
                                                                <div className="col-lg-6 justify-content-center">
                                                                    <FormGroup id="chequeDate" label="">
                                                                        <Input type="date" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.chequeDate} />
                                                                    </FormGroup>
                                                                </div>

                                                            </div>
                                                            <div className="row justify-content-center">
                                                                <div className="col-lg-4 mt-2 align-self-center h6">
                                                                    <p>Bank Name</p>
                                                                </div>

                                                                <div className="col-lg-6 justify-content-center">
                                                                    <FormGroup id="chequeBankName" label="">
                                                                        <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.chequeBankName} />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {updatedPaymentTypeId?.value === 3 && (

                                                    <div className='row'>
                                                        <div className="d-grid gap-4">
                                                            <div className="row justify-content-center">
                                                                <div className="col-lg-4 mt-2 align-self-center h6">
                                                                    <p>NEFT / UPI Ref Number</p>
                                                                </div>
                                                                <div className="col-lg-6 justify-content-center">
                                                                    <FormGroup id="neftRefNumber" label="">
                                                                        <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.neftRefNumber} />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                            <div className="row justify-content-center">
                                                                <div className="col-lg-4 mt-2 align-self-center h6">
                                                                    <p>Amount</p>
                                                                </div>

                                                                <div className="col-lg-6 justify-content-center">
                                                                    <FormGroup id="neftAmount" label="">
                                                                        <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.neftAmount} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                                    </FormGroup>
                                                                    <>
                                                                        {totalFeesAmount == Number(payFeesDenominationForm.values.neftAmount) ? null : (
                                                                            <div className="ms-2 mt-2 text-danger">
                                                                                Amount Not Matching !{' '}
                                                                                <Icon icon="Report" color="danger" className="mx-1" size="lg" />
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                </div>
                                                            </div>
                                                            <div className="row justify-content-center">
                                                                <div className="col-lg-4 mt-2 align-self-center h6">
                                                                    <p>Transaction Date</p>
                                                                </div>

                                                                <div className="col-lg-6 justify-content-center">
                                                                    <FormGroup id="neftTranDate" label="">
                                                                        <Input type="date" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.neftTranDate} />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                            <div className="row justify-content-center">
                                                                <div className="col-lg-4 mt-2 align-self-center h6">
                                                                    <p>Bank Name</p>
                                                                </div>

                                                                <div className="col-lg-6 justify-content-center">
                                                                    <FormGroup id="neftBankName" label="">
                                                                        <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.neftBankName} />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {updatedPaymentTypeId?.value === 4 && (

                                                    <div className='row'>
                                                        <div className="d-grid gap-4">
                                                            <div className="row justify-content-center">
                                                                <div className="col-lg-4 mt-2 align-self-center h6">
                                                                    <p>Card Number</p>
                                                                </div>
                                                                <div className="col-lg-6 justify-content-center">
                                                                    <FormGroup id="cardNumber" label="">
                                                                        <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.cardNumber} />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                            <div className="row justify-content-center">
                                                                <div className="col-lg-4 mt-2 align-self-center h6">
                                                                    <p>Amount</p>
                                                                </div>

                                                                <div className="col-lg-6 justify-content-center">
                                                                    <FormGroup id="cardAmount" label="">
                                                                        <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.cardAmount} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                                    </FormGroup>
                                                                    <>
                                                                        {totalFeesAmount == Number(payFeesDenominationForm.values.cardAmount) ? null : (
                                                                            <div className="ms-2 mt-2 text-danger">
                                                                                Amount Not Matching !{' '}
                                                                                <Icon icon="Report" color="danger" className="mx-1" size="lg" />
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                </div>
                                                            </div>
                                                            <div className="row justify-content-center">
                                                                <div className="col-lg-4 mt-2 align-self-center h6">
                                                                    <p>Transaction Date</p>
                                                                </div>

                                                                <div className="col-lg-6 justify-content-center">
                                                                    <FormGroup id="cardDate" label="">
                                                                        <Input type="date" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.cardDate} />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                            <div className="row justify-content-center">
                                                                <div className="col-lg-4 mt-2 align-self-center h6">
                                                                    <p>Bank Name</p>
                                                                </div>

                                                                <div className="col-lg-6 justify-content-center">
                                                                    <FormGroup id="cardBankName" label="">
                                                                        <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.cardBankName} />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {updatedPaymentTypeId?.value === undefined && (

                                                    <div className='row'>
                                                        <div className="d-grid gap-4">
                                                            <div className="row justify-content-center">
                                                                <div className="col-lg-6 mt-2 h6">
                                                                    <p>Please Select Payment Type</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div></div>
                        ))}

                    </ModalBody>
                    <ModalFooter>

                        {updatedPaymentTypeId?.value == 2 ?
                            <Button color='info' icon='Save' type="submit" isDisable={totalFeesAmount != payFeesDenominationForm.values.chequeAmount && totalFeesAmount != 0}>Save</Button>
                            : updatedPaymentTypeId?.value == 3 ?
                                <Button color='info' icon='Save' type="submit" isDisable={totalFeesAmount != payFeesDenominationForm.values.neftAmount && totalFeesAmount != 0}>Save</Button>
                                : updatedPaymentTypeId?.value == 4 ?
                                    <Button color='info' icon='Save' type="submit" isDisable={totalFeesAmount != payFeesDenominationForm.values.cardAmount && totalFeesAmount != 0}>Save</Button>
                                    : <><Button color='info' isOutline className='border-0' onClick={closeAndReset}>Close</Button>
                                        <Button color='info' icon='Save' type="submit" isDisable={totalFeesAmount != totalAmount && totalFeesAmount != 0}>Save</Button></>
                        }
                    </ModalFooter>
                </form>
            </Modal>

            <OffCanvas
                setOpen={setDeleteReceiptCanvas}
                isOpen={deleteReceiptCanvas}
                titleId='deleteReceiptCanvas'
                isBodyScroll
                placement='end'
                tag="form" noValidate onSubmit={deleteStudentFeeReceiptForm.handleSubmit}>
                <OffCanvasHeader setOpen={setDeleteReceiptCanvas}>
                    <OffCanvasTitle id="deleteReceiptCanvas">Delete Receipt</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className="row">
                        <div className='col-lg-12 mt-3'>
                            <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                }-warning bg-l${darkModeStatus ? 'o50' : '10'
                                }-warning-hover transition-base rounded-2 mb-4`}
                                shadow='sm'>
                                <CardBody>
                                    <div className='d-flex align-items-center pb-3'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='Delete' size='3x' color='danger' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-6 text-danger mb-0'>
                                                Are you sure you want to Delete ?
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col-12 mt-3">
                            <FormGroup id='remarks' label="Remarks" >
                                <Textarea
                                    placeholder="Enter Remarks"
                                    onChange={deleteStudentFeeReceiptForm.handleChange}
                                    value={deleteStudentFeeReceiptForm.values.remarks}
                                    onBlur={deleteStudentFeeReceiptForm.handleBlur}
                                    isValid={deleteStudentFeeReceiptForm.isValid}
                                    isTouched={deleteStudentFeeReceiptForm.touched.remarks}
                                    invalidFeedback={deleteStudentFeeReceiptForm.errors.remarks}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className='row m-0'>
                        <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                            <Button
                                color='danger'
                                icon='Delete'
                                className='w-100' type="submit" isDisable={!deleteStudentFeeReceiptForm.isValid && !!deleteStudentFeeReceiptForm.submitCount}>
                                Delete
                            </Button>
                        </div>
                    </div>
                </OffCanvasBody>
            </OffCanvas>

            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />

        </PageWrapper>
    )
}
export default TermFeesCollection