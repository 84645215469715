import React, { useContext, useEffect, useState } from 'react';
import { useTour } from '@reactour/tour';
import useDarkMode from '../../../hooks/useDarkMode';
import { demoPagesMenu } from '../../../menu';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import Page from '../../../layout/Page/Page';
import ThemeContext from '../../../contexts/themeContext';
import TodayFeeCollection from './dashboard-component/TodayFeeCollection';
import FeesCollection from './dashboard-component/FeesCollection';
import AttendanceNotSubmitted from './dashboard-component/AttendanceNotSubmitted';
import PendingFees from './dashboard-component/PendingFees';
import AuthContext from '../../../contexts/authContext';
import TodayAbsentCount from './dashboard-component/TodayAbsentCount';
import StaffActivity from './dashboard-component/StaffActivity';
import StaffPeriodSchedule from './dashboard-component/StaffPeriodSchedule';
import StudentFeeTransaction from './dashboard-component/StudentFeeTransaction';
import AttendancePercentage from './dashboard-component/AttendancePercentage';
import Circular from '../../../components/management/circular-details/Circular';
import ComplaintList from '../../../components/management/complaint/ComplaintList';
import Announcement from '../../../components/management/announcement/Announcement';

const DashboardPage = () => {

	const { mobileDesign } = useContext(ThemeContext);
	const { userAccountId, userData, userTypeId } = useContext(AuthContext);

	/**
	 * Tour Start
	 */

	const { setIsOpen } = useTour();
	useEffect(() => {
		if (localStorage.getItem('tourModalStarted') !== 'shown' && !mobileDesign) {
			setTimeout(() => {
				setIsOpen(true);
				localStorage.setItem('tourModalStarted', 'shown');
			}, 7000);
		}
		return () => { };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { themeStatus } = useDarkMode();

	return (
		<PageWrapper title={demoPagesMenu.sales.subMenu.dashboard.text}>
			<SubHeader>
				<SubHeaderLeft>
					<span className='fs-5'>Today Overview </span>
					<SubheaderSeparator />
					<strong className='fs-5'>Hi, <span className='text-primary'>{`${userData.fullname}`}</span> !</strong>
				</SubHeaderLeft>
			</SubHeader>
			<Page container='fluid'>
				<div className='row' >

					{/* ----------------For Admin DashBoard----------------*/}
					{userTypeId == 1 ?
						<>
							{/* <div className='col-xl-12'>
								<Announcement />
							</div> */}
							<div className='col-xl-6'>
								<TodayFeeCollection />
							</div>
							<div className='col-xl-6'>
								<TodayAbsentCount />
							</div>
							<div className='col-xl-8'>
								<AttendancePercentage />
							</div>
							<div className='col-xl-4'>
								<StaffActivity />
							</div>
							<div className='col-xl-6'>
								<StudentFeeTransaction />
							</div>
							{/* <div className='col-xl-6 ' >
								<TotalPresentAbsenteesReport />
							</div> */}
							<div className='col-xl-6'>
								<FeesCollection />
							</div>
							<div className='col-xl-6'>
								<PendingFees />
							</div>
							<div className='col-xl-6'>
								<AttendanceNotSubmitted />
							</div>
							<div className='col-xl-6'>
								<Circular />
							</div>
							<div className='col-xl-6'>
								<ComplaintList type={'dashboard'} />
							</div>
						</>
						: null
					}

					{userTypeId == 6 ?
						<>
							<div className='row'>
								<div className='col-xl-12'>
									<Announcement />
								</div>
								<div className='col-xl-12'>
									<TodayAbsentCount />
								</div>
								<div className='col-xl-8'>
									<AttendancePercentage />
								</div>
								<div className='col-xl-4'>
									<StaffActivity />
								</div>
								<div className='col-xl-6'>
									<AttendanceNotSubmitted />
								</div>
								<div className='col-xl-6'>
									<Circular />
								</div>
							</div>
						</>
						: null
					}

					{userTypeId == 3 ?
						<>
							<div className='row'>
								<div className='col-xl-12'>
									<Announcement />
								</div>
								<div className='col-xl-6'>
									<StaffActivity />
								</div>
							</div>
						</>
						: null}

					{userTypeId == 8 ?

						<div className='row'>
							<div className='col-xl-12'>
								<Announcement />
							</div>
							<div className='col-xl-12'>
								<TodayFeeCollection />
							</div>
							<div className='col-xl-6'>
								<StudentFeeTransaction />
							</div>
							<div className='col-xl-6'>
								<StaffActivity />
							</div>
							<div className='col-xl-6'>
								<FeesCollection />
							</div>
							<div className='col-xl-6'>
								<PendingFees />
							</div>
						</div>

						: null}

					{/* ----------------For Staff DashBoard----------------*/}
					{userTypeId == 7 ? (
						<div className='row'>
							<div className='col-xl-12'>
								<Announcement />
							</div>
							<div className='col-8'>
								<StaffPeriodSchedule />
							</div>
							<div className='col-xl-4'>
								<StaffActivity />
							</div>
						</div>
					) : null}

				</div>
			</Page>
		</PageWrapper>
	);
};

export default DashboardPage;