import React, { useContext, useEffect, useState } from 'react'
import PageWrapper from '../../../layout/PageWrapper/PageWrapper'
import Page from '../../../layout/Page/Page'
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card'
import Button from '../../bootstrap/Button'
import { useFormik } from 'formik'
import { getBlood, getCity, getCurrentDateAndTime, getGenderDetails, getGraduationType, getSectionDetails, getStates, onlyAllowNumber } from '../../../services/common.service'
import { showLoader } from '../../../services/loader.services'
import { addStudentDetails, checkCourseCapacity, getTempStudentList, getTempStudentListByGraduationTypeId } from '../../../services/student.service'
import AuthContext from '../../../contexts/authContext'
import { getBatchByCourseId, getBatchByGraduationTypeId, getCourse, getCourseCapacityByBatchCourse, getDepartmentList, getSemester, updateCourseCapacity } from '../../../services/master.service'
import { toasts } from '../../../services/toast.service'
import { getShift } from '../../../services/timeTable.service'
import { getLicenseKey } from '../../../services/application.settings'
import FormGroup from '../../bootstrap/forms/FormGroup'
import Select from '../../bootstrap/forms/Select'
import Input from '../../bootstrap/forms/Input'
import AlertService from '../../../services/AlertService'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../bootstrap/OffCanvas'
import Icon from '../../icon/Icon'
import useDarkMode from '../../../hooks/useDarkMode'
import SearchableSelect from '../../../common/components/SearchableSelect'
import { getTransportAreaMaster } from '../../../services/transport.service'

const QuickStudentCreation = () => {

    useEffect(() => {
        getGender();
        getGraduationList();
        getDepartment();
        getStatesList();
        getSectionList();
        getBloodList();
        getShiftList();
        selectState1(31);
        getTransportAreaMasterDetails();
    }, [])

    const { userAccountId, userTypeId , addAccess, editAccess, deleteAccess } = useContext(AuthContext);
    const [departmentId, setDepartmentId] = useState<any>(Number)
    const [courseId, setCourseId] = useState<any>(Number)
    const [graduationTypeId, setGraduationTypeId] = useState<any>(Number)
    const [tempStudentDetailsId, setTempStudentDetailsId] = useState<any>(Number)
    const [commAddr_StateId, setCommAddr_StateId] = useState<any>({ value: 31, label: 'Tamil Nadu' })
    const [commAddr_CityId, setCommAddr_CityId] = useState<any>(Number)
    const [batchMasterId, setBatchMasterId] = useState<any>(Number)
    const [semesterDetailsId, setSemesterDetailsId] = useState<any>(Number)
    const [sectionDetailsId, setSectionDetailsId] = useState<any>({ value: 1, label: 'A' })
    const [genderId, setGenderId] = useState<any>('')
    const [shiftId, setShiftId] = useState<any>({ value: 1, label: 'Morning Shift' })
    const [bloodGroupId, setBloodGroupId] = useState<any>('')

    const [departmentData, setDepartmentData] = useState<any>([])
    const [tempStudentData, setTempStudentData] = useState<any>([])
    const [courseData, setCourseData] = useState<any>([])
    const [graduationTypeData, setGraduationTypeData] = useState<any>([])
    const [genderData, setGenderData] = useState<any>([])
    const [statesData, setStatesData] = useState<any>([])
    const [cityData, setCityData] = useState<any>([])
    const [batchMasterData, setBatchMasterData] = useState<any>([])
    const [semesterDetailsData, setSemesterDetailsData] = useState<any>([])
    const [sectionDetailsData, setSectionDetailsData] = useState<any>([])
    const [bloodGroupData, setBloodGroupData] = useState<any>([])
    const [shiftData, setShiftData] = useState<any>([])

    const [courseCapacityCanvas, setCourseCapacityCanvas] = useState(false)
    const [courseCapacityData, setCourseCapacityData] = useState<any>([])

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [isNavigate, setIsNavigate] = useState<any>(null);
    const { themeStatus, darkModeStatus } = useDarkMode();

    const [transportAreaMasterData, setTransportAreaMasterData] = useState<any>([])
    const [isCollegeTransport, setIsCollegeTransport] = useState('')
    const [transportAreaMasterId, setTransportAreaMasterId] = useState<any>('')
    const [modeOfWays, setModeOfWays] = useState('')


    const studentForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstName: '',
            lastName: '',
            genderId: '',
            smsToBeSentNumber: '',
            courseId: 0,
            departmentId: 0,
            semesterDetailsId: 0,
            batchMasterId: 0,
            sectionDetailsId: 0,
            commAddr_FlatNo: '',
            commAddr_Street: '',
            commAddr_Area: '',
            commAddr_StateId: '',
            commAddr_CityId: '',
            commAddr_Pincode: '',
            admissionNo: '',
            tempStudentDetailsId: 0,
            email: '',
            emisNo: '',
            graduationTypeId: 0,
            aadharCard: '',
            bloodGroupId: '',
            dateOfBirth: '',
            shiftId: 1,
            admissionDateStamp: getCurrentDateAndTime('date'),
            transportAreaMasterId: '',
            modeOfWays: ''
        },
        validate: (values: any) => {
            const errors: {
                firstName?: string;
                admissionNo?: string;
                graduationTypeId?: any;
                genderId?: any;
                dateOfBirth?: any;
                smsToBeSentNumber?: string;
                email?: string;
                aadharCard?: string;
                departmentId?: string;
                courseId?: string;
                batchMasterId?: string;
                semesterDetailsId?: string;
                sectionDetailsId?: string;
                shiftId?: string;
                commAddr_FlatNo?: string;
                commAddr_StateId?: string;
                commAddr_CityId?: string;
                commAddr_Pincode?: string;
                transportAreaMasterId?: string;
                modeOfWays?: string;
            } = {};

            if (!values.firstName) {
                errors.firstName = 'Required'
            }
            else if (values.firstName.length < 3) {
                errors.firstName = 'Must be 3 characters or more';
            } else if (values.firstName.length > 20) {
                errors.firstName = 'Must be 20 characters or less';
            }
            if (!values.admissionNo) {
                errors.admissionNo = 'Required';
            }
            if (!graduationTypeId) {
                errors.graduationTypeId = 'Required';
            }
            if (!genderId) {
                errors.genderId = 'Required';
            }
            if (!values.dateOfBirth) {
                errors.dateOfBirth = 'Required';
            }
            if (!values.smsToBeSentNumber) {
                errors.smsToBeSentNumber = 'Required';
            } else if (values.smsToBeSentNumber.length < 10 || values.smsToBeSentNumber.length > 10) {
                errors.smsToBeSentNumber = 'Must be 10 Numbers'
            } else if (!/^[6-9]\d{9}$/i.test(values.smsToBeSentNumber)) {
                errors.smsToBeSentNumber = 'Please enter valid mobile number'
            }
            // if (values.email) {
            //     errors.email = 'Required'
            // }
            // else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            //     errors.email = 'Invalid email address';
            // }
            if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                errors.email = 'Invalid email address';
            }
            if (!values.aadharCard) {
                errors.aadharCard = 'Required'
            }
            else if (!/^\d{12}$/.test(values.aadharCard)) {
                errors.aadharCard = 'Must be 12 Numbers';
            }
            if (!departmentId) {
                errors.departmentId = 'Required'
            }
            if (!courseId) {
                errors.courseId = 'Required'
            }
            if (!batchMasterId) {
                errors.batchMasterId = 'Required'
            }
            if (!semesterDetailsId) {
                errors.semesterDetailsId = 'Required'
            }
            if (!sectionDetailsId) {
                errors.sectionDetailsId = 'Required'
            }
            if (!shiftId) {
                errors.shiftId = 'Required'
            }
            if (!values.commAddr_FlatNo) {
                errors.commAddr_FlatNo = 'Required'
            }
            if (!commAddr_StateId) {
                errors.commAddr_StateId = 'Required'
            }
            if (!commAddr_CityId) {
                errors.commAddr_CityId = 'Required'
            }
            if (!values.commAddr_Pincode) {
                errors.commAddr_Pincode = 'Required'
            }
            else if (!/^\d{6}$/.test(values.commAddr_Pincode)) {
                errors.commAddr_Pincode = 'Must be 6 Numbers';
            }
            if (isCollegeTransport == 'Yes') {
                if (!transportAreaMasterId) {
                    errors.transportAreaMasterId = 'Required'
                }
                if (!modeOfWays) {
                    errors.modeOfWays = 'Required'
                }
            }

            return errors;
        },
        validateOnChange: true,
        onSubmit: (values) => { studentSubmit() },
    });

    const courseCapacityEditForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            courseCapacityId: 0,
            govQuota: '',
            managementQuota: '',
            totalAllocated: '',
        },
        validate: (values: any) => {
            const errors: {
                govQuota?: string;
                managementQuota?: any;
                totalAllocated?: any;
            } = {};

            if (!values.govQuota) {
                errors.govQuota = 'Required';
            }
            if (!values.managementQuota) {
                errors.managementQuota = 'Required';
            }
            // if (!values.totalAllocated) {
            //     errors.totalAllocated = 'Required';
            // }

            return errors;
        },
        validateOnChange: true,
        onSubmit: (values) => { updateCourseCapacitySubmit() },
    });

    const selectGraduationType = (e: any) => {
        studentForm.resetForm();
        setTempStudentDetailsId('')
        setTempStudentData([]);
        setDepartmentId('')
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        // setSectionDetailsId('')
        setGenderId('')
        setCommAddr_StateId({ value: 31, label: 'Tamil Nadu' })
        setCommAddr_CityId('')
        let graduationTypeId = e
        setGraduationTypeId(graduationTypeId)
        if (graduationTypeId?.value != undefined) {
            getTempStudentListByGraduationType(graduationTypeId?.value)
        }
    }

    const selectGender = (e: any) => {
        let genderId = e
        setGenderId(genderId)
    }

    const selectBloodGroup = (e: any) => {
        let bloodGroupId = e
        setBloodGroupId(bloodGroupId)
    }

    const selectShift = (e: any) => {
        let shiftId = e
        setShiftId(shiftId)
    }

    const selectTempStudent = (e: any) => {
        let tempStudentDetailsId = e
        setTempStudentDetailsId(tempStudentDetailsId)
        if (tempStudentDetailsId?.value != undefined) {
            getTempStudent(tempStudentDetailsId?.value)
        }
    }

    const selectDepartment = (e: any) => {
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        // setSectionDetailsId('')
        let departmentId = e
        setDepartmentId(departmentId)
        if (departmentId?.value != undefined) {
            getCourseList(departmentId?.value, graduationTypeId?.value)
        }
    }

    const selectCourse = (e: any) => {
        setBatchMasterId('')
        setSemesterDetailsId('')
        // setSectionDetailsId('')
        let courseId = e
        setCourseId(courseId)
        if (courseId?.value != undefined) {
            getBatchList(courseId?.value, graduationTypeId?.value)

        }
    }

    const selectBatch = (e: any) => {
        setSemesterDetailsId('')
        // setSectionDetailsId('')
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        if (batchMasterId?.value != undefined) {
            getSemesterList(batchMasterId?.value)
            checkCourseCapacitySubmit(courseId?.value, batchMasterId?.value)
        }
    }

    const selectSemester = (e: any) => {
        // setSectionDetailsId('')
        let semesterDetailsId = e
        setSemesterDetailsId(semesterDetailsId)
    }

    const selectSection = (e: any) => {
        let sectionDetailsId = e
        setSectionDetailsId(sectionDetailsId)
    }

    const selectState = (e: any) => {
        setCityData([])
        setCommAddr_StateId('')
        let commAddr_StateId = e
        setCommAddr_StateId(commAddr_StateId)
        if (commAddr_StateId?.value != undefined) {
            getCityList(commAddr_StateId?.value)
        }
    }

    const selectState1 = (e: any) => {
        let commAddr_StateId = e
        setCommAddr_StateId({ value: 31, label: 'Tamil Nadu' })
        getCityList(commAddr_StateId)
    }

    const selectCity = (e: any) => {
        let commAddr_CityId = e
        setCommAddr_CityId(commAddr_CityId)
    }

    function getDepartment() {
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.department;
                    if (data != undefined) {
                        setDepartmentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setDepartmentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        setGraduationTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGraduationTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getTempStudentListByGraduationType(graduationTypeId: number) {
        getTempStudentListByGraduationTypeId(graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.tempStudentListByGraduationTypeId;
                    if (data != undefined) {
                        setTempStudentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setTempStudentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getTempStudent(tempStudentDetailsId: number) {
        getTempStudentList(tempStudentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.tempStudentList[0];
                    if (data != undefined) {
                        setDepartmentId({ value: data.departmentId, label: data.departmentName })
                        setCourseId({ value: data.courseId, label: data.courseName })
                        setCommAddr_StateId({ value: data.commAddr_StateId, label: data.stateName })
                        setCommAddr_CityId({ value: data.commAddr_CityId, label: data.cityName })
                        setGenderId({ value: data.genderId, label: data.gender })
                        // setBloodGroupId([{ value: data.bloodGroupId, label: data.groupName }])
                        // setShiftId([{ value: data.shiftId, label: data.shift }])
                        setSectionDetailsId({ value: 1, label: 'A' })

                        getCourseList(data.departmentId, graduationTypeId?.value)
                        getCityList(data.commAddr_StateId)
                        getBatchList(data.courseId, graduationTypeId?.value)

                        studentForm.setValues({
                            admissionNo: '',
                            tempStudentDetailsId: data.tempStudentDetailsId,
                            firstName: data.firstname,
                            lastName: data.lastname,
                            genderId: data.genderId,
                            graduationTypeId: graduationTypeId,
                            departmentId: data.departmentId,
                            courseId: data.courseId,
                            batchMasterId: batchMasterId,
                            semesterDetailsId: semesterDetailsId,
                            sectionDetailsId: sectionDetailsId,
                            smsToBeSentNumber: data.mobile,
                            commAddr_FlatNo: data.commAddr_FlatNo,
                            commAddr_Street: data.commAddr_Street,
                            commAddr_Area: data.commAddr_Area,
                            commAddr_StateId: data.commAddr_StateId,
                            commAddr_CityId: data.commAddr_CityId,
                            commAddr_Pincode: data.commAddr_Pincode,
                            email: '',
                            aadharCard: '',
                            bloodGroupId: '',
                            admissionDateStamp: getCurrentDateAndTime('date'),
                            dateOfBirth: '',
                            emisNo: '',
                            shiftId: 1,
                            transportAreaMasterId: '',
                            modeOfWays: ''
                        })
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCourseList(departmentId: number, graduationTypeId: number) {
        getCourse(departmentId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.course;
                    if (data != undefined) {
                        setCourseData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCourseData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchList(courseId: number, graduationTypeId: number) {
        getBatchByCourseId(courseId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchByCourseId;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSemesterList(batchMasterId: number) {
        getSemester(batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.semester;
                    if (data != undefined) {
                        setSemesterDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setSemesterDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSectionList() {
        getSectionDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSectionDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getGender() {
        getGenderDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.genderDetails;
                    if (data != undefined) {
                        setGenderData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGenderData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStatesList() {
        getStates(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity;
                    if (data != undefined) {
                        setStatesData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStatesData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCityList(stateId: number) {
        getCity(stateId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity[0]['city'];
                    if (data != undefined) {
                        setCityData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCityData([]);
                } else {

                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBloodList() {
        getBlood(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.blood;
                    if (data != undefined) {
                        setBloodGroupData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setBloodGroupData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getShiftList() {
        getShift(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.shift;
                    if (data != undefined) {
                        setShiftData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setShiftData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getTransportAreaMasterDetails() {
        getTransportAreaMaster(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.transportAreaMaster;
                    if (data != undefined) {
                        setTransportAreaMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setTransportAreaMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const selectTransport = (e: any) => {
        let isCollegeTransport = e.target.value
        setIsCollegeTransport(isCollegeTransport)
        if (isCollegeTransport == 'No') {
            setTransportAreaMasterId('')
            setModeOfWays('')
        }
    }

    function selectTransportArea(e: any) {
        setModeOfWays('')
        let transportAreaMasterId = e
        setTransportAreaMasterId(transportAreaMasterId)
    }

    function setStudentDetails() {
        return ({
            ///----General Info----////
            graduationTypeId: graduationTypeId?.value,
            admissionNo: studentForm.values.admissionNo,
            tempNo: tempStudentDetailsId?.value,
            firstName: studentForm.values.firstName ? studentForm.values.firstName : null,
            lastName: studentForm.values.lastName ? studentForm.values.lastName : null,
            surName: null,
            studentNameTamil: null,
            surNameTamil: null,
            dateOfBirth: studentForm.values.dateOfBirth ? studentForm.values.dateOfBirth : null,
            genderId: genderId?.value,
            bloodGroupId: bloodGroupId?.value,
            motherTongue: null,
            nationality: null,
            otherNation: null,
            religionId: null,
            casteUniqueId: null,
            casteDetailsId: null,
            subcaste: null,
            category: null,
            aadharCard: studentForm.values.aadharCard ? studentForm.values.aadharCard : null,
            physicallyChallenged: null,
            physicallyChallengedType: null,
            physicallyChallengedPercentage: null,
            martialStatus: null,
            email: studentForm.values.email ? studentForm.values.email : null,
            emisNo: studentForm.values.emisNo ? studentForm.values.emisNo : null,
            ///----Address INFO----////
            commAddr_FlatNo: studentForm.values.commAddr_FlatNo ? studentForm.values.commAddr_FlatNo : null,
            commAddr_Street: studentForm.values.commAddr_Street ? studentForm.values.commAddr_Street : null,
            commAddr_Area: studentForm.values.commAddr_Area ? studentForm.values.commAddr_Area : null,
            commAddr_StateId: commAddr_StateId?.value,
            commAddr_CityId: commAddr_CityId?.value,
            commAddr_Pincode: studentForm.values.commAddr_Pincode ? studentForm.values.commAddr_Pincode : null,

            permAddr_FlatNo: null,
            permAddr_Street: null,
            permAddr_Area: null,
            permAddr_StateId: null,
            permAddr_CityId: null,
            permAddr_Pincode: null,

            bankName: null,
            bankAccountNo: null,
            branch: null,
            ifscCode: null,
            micrCode: null,
            // ///----Class INFO----////
            departmentId: departmentId?.value ? departmentId?.value : null,
            courseId: courseId?.value ? courseId?.value : null,
            batchMasterId: batchMasterId?.value ? batchMasterId?.value : null,
            semesterDetailsId: semesterDetailsId?.value ? semesterDetailsId?.value : null,
            sectionDetailsId: sectionDetailsId?.value ? sectionDetailsId?.value : null,
            shiftId: shiftId?.value ? shiftId?.value : null,
            studentRollNo: null,
            admissionDateStamp: studentForm.values.admissionDateStamp ? studentForm.values.admissionDateStamp : null,
            sourceName: null,
            transferCourseId: null,
            referralTypeId: null,
            referredId: null,
            referredName: null,
            interimCollegeTransfer: null,
            collegeName: null,
            concessionTypeId: null,
            concessionRemarks: null,
            transportAreaMasterId: transportAreaMasterId?.value ? transportAreaMasterId?.value : null,
            modeOfWays: modeOfWays != "" ? modeOfWays : null,
            isCollegeTransport: isCollegeTransport != "" ? isCollegeTransport : null,
            ///----Family INFO----////
            fatherName: null,
            father_AdharNumber: null,
            fatherOccupation: null,
            natureofJoborBusiness: null,
            fatherContactNumber: null,
            fatherEmailId: null,
            motherName: null,
            mother_AdharNumber: null,
            yearlyIncome: null,
            positionHeld: null,
            motherContactNumber: null,
            smsToBeSentNumber: studentForm.values.smsToBeSentNumber ? studentForm.values.smsToBeSentNumber : null,
            guardian_Name: null,
            guardian_email: null,
            guardian_phone: null,
            guardian_AdharNumber: null,
            isGuardian: 0,
            ///----MARK INFO----////
            registerNo_X: null,
            institutionNameAndAddress_X: null,
            boardName_X: null,
            mediumOfInstitution_X: null,
            monthAndYearOfPassing_X: null,

            registerNo_XI: null,
            institutionNameAndAddress_XI: null,
            boardName_XI: null,
            mediumOfInstitution_XI: null,
            monthAndYearOfPassing_XI: null,

            registerNo_XII: null,
            XIISchoolNameId: null,
            boardName_XII: null,
            mediumOfInstitution_XII: null,
            monthAndYearOfPassing_XII: null,

            registerNo_UG: null,
            institutionNameAndAddress_UG: null,
            universityName_UG: null,
            mediumOfInstitution_UG: null,
            monthAndYearOfPassing_UG: null,

            registerNo_PG: null,
            institutionNameAndAddress_PG: null,
            universityName_PG: null,
            mediumOfInstitution_PG: null,
            monthAndYearOfPassing_PG: null,
            registerNo_MPhil: null,
            institutionNameAndAddress_MPhil: null,
            universityName_MPhil: null,
            mediumOfInstitution_MPhil: null,
            monthAndYearOfPassing_MPhil: null,

            XI_S1_studentSchoolSubject: null,
            XI_S1_markSecured: null,
            XI_S1_maximumMarks: null,
            XI_S1_monthAndYearOfPassing: null,
            XI_S1_registerNo: null,
            XI_S1_noOfAttempts: null,
            XI_S2_studentSchoolSubject: null,
            XI_S2_markSecured: null,
            XI_S2_maximumMarks: null,
            XI_S2_monthAndYearOfPassing: null,
            XI_S2_registerNo: null,
            XI_S2_noOfAttempts: null,
            XI_S3_studentSchoolSubject: null,
            XI_S3_markSecured: null,
            XI_S3_maximumMarks: null,
            XI_S3_monthAndYearOfPassing: null,
            XI_S3_registerNo: null,
            XI_S3_noOfAttempts: null,
            XI_S4_studentSchoolSubject: null,
            XI_S4_markSecured: null,
            XI_S4_maximumMarks: null,
            XI_S4_monthAndYearOfPassing: null,
            XI_S4_registerNo: null,
            XI_S4_noOfAttempts: null,
            XI_S5_studentSchoolSubject: null,
            XI_S5_markSecured: null,
            XI_S5_maximumMarks: null,
            XI_S5_monthAndYearOfPassing: null,
            XI_S5_registerNo: null,
            XI_S5_noOfAttempts: null,
            XI_S6_studentSchoolSubject: null,
            XI_S6_markSecured: null,
            XI_S6_maximumMarks: null,
            XI_S6_monthAndYearOfPassing: null,
            XI_S6_registerNo: null,
            XI_S6_noOfAttempts: null,

            XII_S1_studentSchoolSubject: null,
            XII_S1_markSecured: null,
            XII_S1_maximumMarks: null,
            XII_S1_monthAndYearOfPassing: null,
            XII_S1_registerNo: null,
            XII_S1_noOfAttempts: null,
            XII_S2_studentSchoolSubject: null,
            XII_S2_markSecured: null,
            XII_S2_maximumMarks: null,
            XII_S2_monthAndYearOfPassing: null,
            XII_S2_registerNo: null,
            XII_S2_noOfAttempts: null,
            XII_S3_studentSchoolSubject: null,
            XII_S3_markSecured: null,
            XII_S3_maximumMarks: null,
            XII_S3_monthAndYearOfPassing: null,
            XII_S3_registerNo: null,
            XII_S3_noOfAttempts: null,
            XII_S4_studentSchoolSubject: null,
            XII_S4_markSecured: null,
            XII_S4_maximumMarks: null,
            XII_S4_monthAndYearOfPassing: null,
            XII_S4_registerNo: null,
            XII_S4_noOfAttempts: null,
            XII_S5_studentSchoolSubject: null,
            XII_S5_markSecured: null,
            XII_S5_maximumMarks: null,
            XII_S5_monthAndYearOfPassing: null,
            XII_S5_registerNo: null,
            XII_S5_noOfAttempts: null,
            XII_S6_studentSchoolSubject: null,
            XII_S6_markSecured: null,
            XII_S6_maximumMarks: null,
            XII_S6_monthAndYearOfPassing: null,
            XII_S6_registerNo: null,
            XII_S6_noOfAttempts: null,
            XI_OverAll_markSecured: null,
            XI_OverAll_maximumMarks: null,
            XII_OverAll_markSecured: null,
            XII_OverAll_maximumMarks: null,

            // UG_courseName: studentForm.values.UG_courseName,
            // UG_Part1_SubjectName: studentForm.values.UG_Part1_SubjectName,
            // UG_Part1_MarksObtained: studentForm.values.UG_Part1_MarksObtained,
            // UG_Part1_MaximumMarks: studentForm.values.UG_Part1_MaximumMarks,
            // UG_Part1_Percentage: studentForm.values.UG_Part1_Percentage,
            // UG_Part1_ClassObtained: studentForm.values.UG_Part1_ClassObtained,
            // UG_Part1_NoOfAttempts: studentForm.values.UG_Part1_NoOfAttempts,
            // UG_Part1_RegisterNo: studentForm.values.UG_Part1_RegisterNo,
            // UG_Part1_Passing: studentForm.values.UG_Part1_Passing,
            // UG_Part2_SubjectName: studentForm.values.UG_Part2_SubjectName,
            // UG_Part2_MarksObtained: studentForm.values.UG_Part2_MarksObtained,
            // UG_Part2_MaximumMarks: studentForm.values.UG_Part2_MaximumMarks,
            // UG_Part2_Percentage: studentForm.values.UG_Part2_Percentage,
            // UG_Part2_ClassObtained: studentForm.values.UG_Part2_ClassObtained,
            // UG_Part2_NoOfAttempts: studentForm.values.UG_Part2_NoOfAttempts,
            // UG_Part2_RegisterNo: studentForm.values.UG_Part2_RegisterNo,
            // UG_Part2_Passing: studentForm.values.UG_Part2_Passing,
            // UG_Part3_SubjectName: studentForm.values.UG_Part3_SubjectName,
            // UG_Part3_MarksObtained: studentForm.values.UG_Part3_MarksObtained,
            // UG_Part3_MaximumMarks: studentForm.values.UG_Part3_MaximumMarks,
            // UG_Part3_Percentage: studentForm.values.UG_Part3_Percentage,
            // UG_Part3_ClassObtained: studentForm.values.UG_Part3_ClassObtained,
            // UG_Part3_NoOfAttempts: studentForm.values.UG_Part3_NoOfAttempts,
            // UG_Part3_RegisterNo: studentForm.values.UG_Part3_RegisterNo,
            // UG_Part3_Passing: studentForm.values.UG_Part3_Passing,
            // UG_Allied1_SubjectName: studentForm.values.UG_Allied1_SubjectName,
            // UG_Allied1_MarksObtained: studentForm.values.UG_Allied1_MarksObtained,
            // UG_Allied1_MaximumMarks: studentForm.values.UG_Allied1_MaximumMarks,
            // UG_Allied1_Percentage: studentForm.values.UG_Allied1_Percentage,
            // UG_Allied1_ClassObtained: studentForm.values.UG_Allied1_ClassObtained,
            // UG_Allied1_NoOfAttempts: studentForm.values.UG_Allied1_NoOfAttempts,
            // UG_Allied1_RegisterNo: studentForm.values.UG_Allied1_RegisterNo,
            // UG_Allied1_Passing: studentForm.values.UG_Allied1_Passing,
            // UG_Allied2_SubjectName: studentForm.values.UG_Allied2_SubjectName,
            // UG_Allied2_MarksObtained: studentForm.values.UG_Allied2_MarksObtained,
            // UG_Allied2_MaximumMarks: studentForm.values.UG_Allied2_MaximumMarks,
            // UG_Allied2_Percentage: studentForm.values.UG_Allied2_Percentage,
            // UG_Allied2_ClassObtained: studentForm.values.UG_Allied2_ClassObtained,
            // UG_Allied2_NoOfAttempts: studentForm.values.UG_Allied2_NoOfAttempts,
            // UG_Allied2_RegisterNo: studentForm.values.UG_Allied2_RegisterNo,
            // UG_Allied2_Passing: studentForm.values.UG_Allied2_Passing,
            // UG_Overall_Main_Core: studentForm.values.UG_Overall_Main_Core,
            // UG_Overall_Ancy_Allied: studentForm.values.UG_Overall_Ancy_Allied,
            // UG_Overall_Part3: studentForm.values.UG_Overall_Part3,

            // PG_courseName: studentForm.values.PG_courseName,
            // PG_Part1_SubjectName: studentForm.values.PG_Part1_SubjectName,
            // PG_Part1_MarksObtained: studentForm.values.PG_Part1_MarksObtained,
            // PG_Part1_MaximumMarks: studentForm.values.PG_Part1_MaximumMarks,
            // PG_Part1_Percentage: studentForm.values.PG_Part1_Percentage,
            // PG_Part1_ClassObtained: studentForm.values.PG_Part1_ClassObtained,
            // PG_Part1_NoOfAttempts: studentForm.values.PG_Part1_NoOfAttempts,
            // PG_Part1_RegisterNo: studentForm.values.PG_Part1_RegisterNo,
            // PG_Part1_Passing: studentForm.values.PG_Part1_Passing,
            // PG_Part2_SubjectName: studentForm.values.PG_Part2_SubjectName,
            // PG_Part2_MarksObtained: studentForm.values.PG_Part2_MarksObtained,
            // PG_Part2_MaximumMarks: studentForm.values.PG_Part2_MaximumMarks,
            // PG_Part2_Percentage: studentForm.values.PG_Part2_Percentage,
            // PG_Part2_ClassObtained: studentForm.values.PG_Part2_ClassObtained,
            // PG_Part2_NoOfAttempts: studentForm.values.PG_Part2_NoOfAttempts,
            // PG_Part2_RegisterNo: studentForm.values.PG_Part2_RegisterNo,
            // PG_Part2_Passing: studentForm.values.PG_Part2_Passing,
            // PG_Part3_SubjectName: studentForm.values.PG_Part3_SubjectName,
            // PG_Part3_MarksObtained: studentForm.values.PG_Part3_MarksObtained,
            // PG_Part3_MaximumMarks: studentForm.values.PG_Part3_MaximumMarks,
            // PG_Part3_Percentage: studentForm.values.PG_Part3_Percentage,
            // PG_Part3_ClassObtained: studentForm.values.PG_Part3_ClassObtained,
            // PG_Part3_NoOfAttempts: studentForm.values.PG_Part3_NoOfAttempts,
            // PG_Part3_RegisterNo: studentForm.values.PG_Part3_RegisterNo,
            // PG_Part3_Passing: studentForm.values.PG_Part3_Passing,
            // PG_Allied1_SubjectName: studentForm.values.PG_Allied1_SubjectName,
            // PG_Allied1_MarksObtained: studentForm.values.PG_Allied1_MarksObtained,
            // PG_Allied1_MaximumMarks: studentForm.values.PG_Allied1_MaximumMarks,
            // PG_Allied1_Percentage: studentForm.values.PG_Allied1_Percentage,
            // PG_Allied1_ClassObtained: studentForm.values.PG_Allied1_ClassObtained,
            // PG_Allied1_NoOfAttempts: studentForm.values.PG_Allied1_NoOfAttempts,
            // PG_Allied1_RegisterNo: studentForm.values.PG_Allied1_RegisterNo,
            // PG_Allied1_Passing: studentForm.values.PG_Allied1_Passing,
            // PG_Allied2_SubjectName: studentForm.values.PG_Allied2_SubjectName,
            // PG_Allied2_MarksObtained: studentForm.values.PG_Allied2_MarksObtained,
            // PG_Allied2_MaximumMarks: studentForm.values.PG_Allied2_MaximumMarks,
            // PG_Allied2_Percentage: studentForm.values.PG_Allied2_Percentage,
            // PG_Allied2_ClassObtained: studentForm.values.PG_Allied2_ClassObtained,
            // PG_Allied2_NoOfAttempts: studentForm.values.PG_Allied2_NoOfAttempts,
            // PG_Allied2_RegisterNo: studentForm.values.PG_Allied2_RegisterNo,
            // PG_Allied2_Passing: studentForm.values.PG_Allied2_Passing,
            // PG_Overall_Main_Core: studentForm.values.PG_Overall_Main_Core,
            // PG_Overall_Ancy_Allied: studentForm.values.PG_Overall_Ancy_Allied,
            // PG_Overall_Part3: studentForm.values.PG_Overall_Part3,

            // MPhil_courseName: studentForm.values.MPhil_courseName,
            // MPhil_Part1_SubjectName: studentForm.values.MPhil_Part1_SubjectName,
            // MPhil_Part1_MarksObtained: studentForm.values.MPhil_Part1_MarksObtained,
            // MPhil_Part1_MaximumMarks: studentForm.values.MPhil_Part1_MaximumMarks,
            // MPhil_Part1_Percentage: studentForm.values.MPhil_Part1_Percentage,
            // MPhil_Part1_ClassObtained: studentForm.values.MPhil_Part1_ClassObtained,
            // MPhil_Part1_NoOfAttempts: studentForm.values.MPhil_Part1_NoOfAttempts,
            // MPhil_Part1_RegisterNo: studentForm.values.MPhil_Part1_RegisterNo,
            // MPhil_Part1_Passing: studentForm.values.MPhil_Part1_Passing,
            // MPhil_Part2_SubjectName: studentForm.values.MPhil_Part2_SubjectName,
            // MPhil_Part2_MarksObtained: studentForm.values.MPhil_Part2_MarksObtained,
            // MPhil_Part2_MaximumMarks: studentForm.values.MPhil_Part2_MaximumMarks,
            // MPhil_Part2_Percentage: studentForm.values.MPhil_Part2_Percentage,
            // MPhil_Part2_ClassObtained: studentForm.values.MPhil_Part2_ClassObtained,
            // MPhil_Part2_NoOfAttempts: studentForm.values.MPhil_Part2_NoOfAttempts,
            // MPhil_Part2_RegisterNo: studentForm.values.MPhil_Part2_RegisterNo,
            // MPhil_Part2_Passing: studentForm.values.MPhil_Part2_Passing,
            // MPhil_Part3_SubjectName: studentForm.values.MPhil_Part3_SubjectName,
            // MPhil_Part3_MarksObtained: studentForm.values.MPhil_Part3_MarksObtained,
            // MPhil_Part3_MaximumMarks: studentForm.values.MPhil_Part3_MaximumMarks,
            // MPhil_Part3_Percentage: studentForm.values.MPhil_Part3_Percentage,
            // MPhil_Part3_ClassObtained: studentForm.values.MPhil_Part3_ClassObtained,
            // MPhil_Part3_NoOfAttempts: studentForm.values.MPhil_Part3_NoOfAttempts,
            // MPhil_Part3_RegisterNo: studentForm.values.MPhil_Part3_RegisterNo,
            // MPhil_Part3_Passing: studentForm.values.MPhil_Part3_Passing,
            // MPhil_Allied1_SubjectName: studentForm.values.MPhil_Allied1_SubjectName,
            // MPhil_Allied1_MarksObtained: studentForm.values.MPhil_Allied1_MarksObtained,
            // MPhil_Allied1_MaximumMarks: studentForm.values.MPhil_Allied1_MaximumMarks,
            // MPhil_Allied1_Percentage: studentForm.values.MPhil_Allied1_Percentage,
            // MPhil_Allied1_ClassObtained: studentForm.values.MPhil_Allied1_ClassObtained,
            // MPhil_Allied1_NoOfAttempts: studentForm.values.MPhil_Allied1_NoOfAttempts,
            // MPhil_Allied1_RegisterNo: studentForm.values.MPhil_Allied1_RegisterNo,
            // MPhil_Allied1_Passing: studentForm.values.MPhil_Allied1_Passing,
            // MPhil_Allied2_SubjectName: studentForm.values.MPhil_Allied2_SubjectName,
            // MPhil_Allied2_MarksObtained: studentForm.values.MPhil_Allied2_MarksObtained,
            // MPhil_Allied2_MaximumMarks: studentForm.values.MPhil_Allied2_MaximumMarks,
            // MPhil_Allied2_Percentage: studentForm.values.MPhil_Allied2_Percentage,
            // MPhil_Allied2_ClassObtained: studentForm.values.MPhil_Allied2_ClassObtained,
            // MPhil_Allied2_NoOfAttempts: studentForm.values.MPhil_Allied2_NoOfAttempts,
            // MPhil_Allied2_RegisterNo: studentForm.values.MPhil_Allied2_RegisterNo,
            // MPhil_Allied2_Passing: studentForm.values.MPhil_Allied2_Passing,
            // MPhil_Overall_Main_Core: studentForm.values.MPhil_Overall_Main_Core,
            // MPhil_Overall_Ancy_Allied: studentForm.values.MPhil_Overall_Ancy_Allied,
            // MPhil_Overall_Part3: studentForm.values.MPhil_Overall_Part3,
            ///----Admission----////
            quotaTypeId: null,
            discription: null,
            isCitizenOfIndia: null,
            isClaimingNriQuotaSeat: null,
            isMedicalCertificateEnclosed: null,
            extraCurricularActivities: null,
            ///----Certificate----////
            certificateTypeId: null,
            submittedDate: null,
            status: null,
            ///----Scholarship Details----////
            scholarshipTypeId: null,
            assignedBy: null,
            // documentCertificateTypeId : studentForm.values.documentCertificateTypeId,
            // certificatePath :  "in",
            licenseKey: getLicenseKey,
            createdBy: userAccountId,
            userAccountId: userAccountId
        })
    }

    function studentSubmit() {
        showLoader(true)
        if (studentForm.isValid) {
            let studentPostData = setStudentDetails()
            addStudentDetails(studentPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        setIsNavigate(`../academic/studentList`)
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                    setIsNavigate(null);
                }
            )
        } else if (studentForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
            setIsNavigate(null);
        }
    }

    function setCourseCapacityEditDetails() {
        return ({
            courseCapacityId: courseCapacityEditForm.values.courseCapacityId,
            batchMasterId: batchMasterId?.value,
            courseId: courseId?.value,
            govQuota: courseCapacityEditForm.values.govQuota,
            managementQuota: courseCapacityEditForm.values.managementQuota,
            totalAllocated: Number(courseCapacityEditForm.values.govQuota) + Number(courseCapacityEditForm.values.managementQuota),
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
        })
    }

    function checkCourseCapacitySubmit(courseId: any, batchMasterId: any) {

        if (courseId != '' && batchMasterId != "") {

            let courseCapacityStudentPostData = {
                courseId: courseId,
                batchMasterId: batchMasterId,
                licenseKey: getLicenseKey
            }
            checkCourseCapacity(courseCapacityStudentPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        // setAlertStatus({ message: data.message, type: "success" });
                        // setIsOpen(true);
                    }
                    else if (data.success == false) {
                        // setAlertStatus({ message: data.message, type: "error" });
                        // setIsOpen(true);
                        setCourseCapacityCanvas(true)
                        getCourseCapacityCourse(batchMasterId, courseId)

                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        // setAlertStatus({ message: errorDescription, type: "error" });
                        // setIsOpen(true);
                    }
                }
                , (error) => {
                    // setAlertStatus({ message: error, type: "error" });
                    // setIsOpen(true);
                }
            )
        } else if (courseId == '' && batchMasterId == "") {
            // setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            // setIsOpen(true);
        }
    }

    function getCourseCapacityCourse(batchMasterId: any, courseId: any) {
        getCourseCapacityByBatchCourse(batchMasterId, courseId,
            (response) => {
                if (response.data.success) {

                    let data = response.data.data.courseCapacityByBatchMasterId[0];
                    if (data != undefined) {
                        setCourseCapacityData(data)
                        courseCapacityEditForm.setValues({
                            courseCapacityId: data.courseCapacityId,
                            govQuota: data.govQuota,
                            managementQuota: data.managementQuota,
                            totalAllocated: data.totalAllocated
                        });
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }

                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    getBatchByGraduationTypeId(courseId, batchMasterId, graduationTypeId?.value,
                        (response) => {
                            if (response.data.success) {
                                let data = response.data.data.batchByCourseId[0];
                                if (data != undefined) {
                                    setCourseCapacityData(data)
                                    courseCapacityEditForm.setValues({
                                        courseCapacityId: 0,
                                        govQuota: '',
                                        managementQuota: '',
                                        totalAllocated: ''
                                    });
                                }
                            }
                        }, error => {
                            toasts(error, "Error")
                        }
                    )
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function updateCourseCapacitySubmit() {
        showLoader(true)
        if (courseCapacityEditForm.isValid) {
            let courseCapacityEditPostData = setCourseCapacityEditDetails()
            updateCourseCapacity(courseCapacityEditPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        courseCapacityEditForm.resetForm();
                        setCourseCapacityCanvas(false)
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                }, (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (courseCapacityEditForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function resetState() {
        studentForm.resetForm();
        setTempStudentDetailsId('')
        setTempStudentData([]);
        setDepartmentId('')
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId({ value: 1, label: 'A' })
        setCommAddr_StateId({ value: 31, label: 'Tamil Nadu' })
        setCommAddr_CityId('')
        setGraduationTypeId('')
        selectState1(31)
        setShiftId({ value: 1, label: 'Morning Shift' })
        setGenderId('')
        setBloodGroupId('')
    }

    return (
        <PageWrapper title="Create Student">
            <Page container='fluid'>
                <Card stretch tag='form' noValidate onSubmit={studentForm.handleSubmit}>
                    <CardHeader borderSize={1}>
                        <CardLabel icon='List' iconColor='info'>
                            <CardTitle tag='div' className='h5'>Quick Student Creation</CardTitle>
                        </CardLabel>
                        <CardActions>
                            <Button color='info' isLight icon='PublishedWithChanges' onClick={() => { resetState() }}>
                                Refresh
                            </Button>
                            {addAccess?
                            <Button
                                icon='Add'
                                color='primary'
                                isLight
                                tag='a'
                                to={`../academic/createStudent`}>
                                Create Student
                            </Button>
                            :null
                            }
                        </CardActions>
                    </CardHeader>
                    <CardBody>
                        <div className='row g-4'>
                            <div className='col-3'>
                                <FormGroup id='graduationTypeId' label='Graduation Type' isFloating>
                                    <SearchableSelect isFloating
                                        ariaLabel='Graduation Type'
                                        onChange={selectGraduationType}
                                        onBlur={studentForm.handleBlur}
                                        value={graduationTypeId}
                                        isValid={studentForm.isValid}
                                        isTouched={studentForm.touched.graduationTypeId}
                                        invalidFeedback={studentForm.errors.graduationTypeId}
                                        list={graduationTypeData.map((data: any) => (
                                            { value: data.graduationTypeId, label: data.graduationType }
                                        ))}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-3'>
                                <FormGroup id='tempStudentDetailsId' label='Temp No' isFloating>
                                    <SearchableSelect isFloating
                                        ariaLabel='Temp No'
                                        onChange={selectTempStudent}
                                        value={tempStudentDetailsId}
                                        list={tempStudentData.map((data: any) => (
                                            { value: data.tempStudentDetailsId, label: data.tempNoAndStudentName }
                                        ))}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-3'>
                                <FormGroup id='admissionNo' label='Admission No' isFloating>
                                    <Input
                                        placeholder='Enter Admission No'
                                        onChange={studentForm.handleChange}
                                        onBlur={studentForm.handleBlur}
                                        value={studentForm.values.admissionNo}
                                        isValid={studentForm.isValid}
                                        isTouched={studentForm.touched.admissionNo}
                                        invalidFeedback={studentForm.errors.admissionNo}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-3'>
                                <FormGroup id='genderId' label='Gender' isFloating>
                                    <SearchableSelect isFloating
                                        ariaLabel='Gender'
                                        onChange={selectGender}
                                        onBlur={studentForm.handleBlur}
                                        value={genderId}
                                        isValid={studentForm.isValid}
                                        isTouched={studentForm.touched.genderId}
                                        invalidFeedback={studentForm.errors.genderId}
                                        list={genderData.map((data: any) => (
                                            { value: data.genderId, label: data.gender }
                                        ))}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-3'>
                                <FormGroup id='firstName' label='First Name' isFloating>
                                    <Input
                                        placeholder='Enter First Name'
                                        onChange={studentForm.handleChange}
                                        onBlur={studentForm.handleBlur}
                                        value={studentForm.values.firstName}
                                        isValid={studentForm.isValid}
                                        isTouched={studentForm.touched.firstName}
                                        invalidFeedback={studentForm.errors.firstName}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-3'>
                                <FormGroup id='lastName' label='Last Name' isFloating>
                                    <Input
                                        placeholder='Last Name'
                                        onChange={studentForm.handleChange}
                                        value={studentForm.values.lastName}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-3'>
                                <FormGroup id='dateOfBirth' label='Date Of Birth' isFloating>
                                    <Input placeholder='Date Of Birth'
                                        onChange={studentForm.handleChange}
                                        onBlur={studentForm.handleBlur}
                                        value={studentForm.values.dateOfBirth}
                                        isValid={studentForm.isValid}
                                        isTouched={studentForm.touched.dateOfBirth}
                                        invalidFeedback={studentForm.errors.dateOfBirth}
                                        type='date' />
                                </FormGroup>
                            </div>
                            <div className='col-3'>
                                <FormGroup id='smsToBeSentNumber' label='Mobile/ SMS Number' isFloating>
                                    <Input
                                        placeholder='Mobile No'
                                        onKeyDown={(e: any) => onlyAllowNumber(e)}
                                        onChange={studentForm.handleChange}
                                        onBlur={studentForm.handleBlur}
                                        value={studentForm.values.smsToBeSentNumber}
                                        isValid={studentForm.isValid}
                                        isTouched={studentForm.touched.smsToBeSentNumber}
                                        invalidFeedback={studentForm.errors.smsToBeSentNumber}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-3'>
                                <FormGroup id='email' label='Email' isFloating>
                                    <Input
                                        placeholder='Enter Email'
                                        onChange={studentForm.handleChange}
                                        onBlur={studentForm.handleBlur}
                                        value={studentForm.values.email}
                                        isValid={studentForm.isValid}
                                        isTouched={studentForm.touched.email}
                                        invalidFeedback={studentForm.errors.email}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-3'>
                                <FormGroup id='bloodGroupId' label='Blood Group' isFloating>
                                    <SearchableSelect isFloating
                                        ariaLabel='Blood Group'
                                        onChange={selectBloodGroup}
                                        // onBlur={studentForm.handleBlur}
                                        value={bloodGroupId}
                                        // isValid={studentForm.isValid}
                                        // isTouched={studentForm.touched.bloodGroupId}
                                        // invalidFeedback={studentForm.errors.bloodGroupId}
                                        list={bloodGroupData.map((data: any) => (
                                            { value: data.bloodGroupId, label: data.groupName }
                                        ))}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-3'>
                                <FormGroup id='emisNo' label='EMIS No' isFloating>
                                    <Input
                                        placeholder='Enter EMIS No'
                                        onKeyDown={(e: any) => onlyAllowNumber(e)}
                                        onChange={studentForm.handleChange}
                                        // onBlur={studentForm.handleBlur}
                                        value={studentForm.values.emisNo}
                                    // isValid={studentForm.isValid}
                                    // isTouched={studentForm.touched.emisNo}
                                    // invalidFeedback={studentForm.errors.emisNo}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-3'>
                                <FormGroup id='aadharCard' label='Aadhar Number' isFloating>
                                    <Input
                                        placeholder='Enter Aadhar Number'
                                        onKeyDown={(e: any) => onlyAllowNumber(e)}
                                        onChange={studentForm.handleChange}
                                        onBlur={studentForm.handleBlur}
                                        value={studentForm.values.aadharCard}
                                        isValid={studentForm.isValid}
                                        isTouched={studentForm.touched.aadharCard}
                                        invalidFeedback={studentForm.errors.aadharCard}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-6'>
                                <Card className='rounded-1 mb-2' borderSize={2}>
                                    <CardHeader>
                                        <CardLabel icon='Class'>
                                            <CardTitle>Class Info</CardTitle>
                                        </CardLabel>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="row g-4">
                                            <div className='col-6'>
                                                <FormGroup id='departmentId' label='Department' isFloating>
                                                    <SearchableSelect isFloating
                                                        ariaLabel='Department'
                                                        onChange={selectDepartment}
                                                        onBlur={studentForm.handleBlur}
                                                        value={departmentId}
                                                        isValid={studentForm.isValid}
                                                        isTouched={studentForm.touched.departmentId}
                                                        invalidFeedback={studentForm.errors.departmentId}
                                                        list={departmentData.map((data: any) => (
                                                            { value: data.departmentId, label: data.departmentName }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className='col-6'>
                                                <FormGroup id='courseId' label='Course' isFloating>
                                                    <SearchableSelect isFloating
                                                        ariaLabel='Course'
                                                        onChange={selectCourse}
                                                        onBlur={studentForm.handleBlur}
                                                        value={courseId}
                                                        isValid={studentForm.isValid}
                                                        isTouched={studentForm.touched.courseId}
                                                        invalidFeedback={studentForm.errors.courseId}
                                                        list={courseData.map((data: any) => (
                                                            { value: data.courseId, label: data.courseName }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className='col-6'>
                                                <FormGroup id='batchMasterId' label='Batch' isFloating>
                                                    <SearchableSelect isFloating
                                                        ariaLabel='Batch'
                                                        onChange={selectBatch}
                                                        onBlur={studentForm.handleBlur}
                                                        value={batchMasterId}
                                                        isValid={studentForm.isValid}
                                                        isTouched={studentForm.touched.batchMasterId}
                                                        invalidFeedback={studentForm.errors.batchMasterId}
                                                        list={batchMasterData.map((data: any) => (
                                                            { value: data.batchMasterId, label: data.batchName }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className='col-6'>
                                                <FormGroup id='semesterDetailsId' label='Semester' isFloating>
                                                    <SearchableSelect isFloating
                                                        ariaLabel='Semester'
                                                        onChange={selectSemester}
                                                        onBlur={studentForm.handleBlur}
                                                        value={semesterDetailsId}
                                                        isValid={studentForm.isValid}
                                                        isTouched={studentForm.touched.semesterDetailsId}
                                                        invalidFeedback={studentForm.errors.semesterDetailsId}
                                                        list={semesterDetailsData.map((data: any) => (
                                                            { value: data.semesterDetailsId, label: data.semesterNumber }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className='col-6'>
                                                <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                                    <SearchableSelect isFloating
                                                        ariaLabel='Section'
                                                        onChange={selectSection}
                                                        onBlur={studentForm.handleBlur}
                                                        value={sectionDetailsId}
                                                        isValid={studentForm.isValid}
                                                        isTouched={studentForm.touched.sectionDetailsId}
                                                        invalidFeedback={studentForm.errors.sectionDetailsId}
                                                        list={sectionDetailsData.map((data: any) => (
                                                            { value: data.sectionDetailsId, label: data.sectionName }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className='col-6'>
                                                <FormGroup id='shiftId' label='Shift' isFloating>
                                                    <SearchableSelect isFloating
                                                        ariaLabel='Shift'
                                                        onBlur={studentForm.handleBlur}
                                                        onChange={selectShift}
                                                        value={shiftId}
                                                        isValid={studentForm.isValid}
                                                        isTouched={studentForm.touched.shiftId}
                                                        invalidFeedback={studentForm.errors.shiftId}
                                                        list={shiftData.map((data: any) => (
                                                            { value: data.shiftId, label: data.shift }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className='col-6'>
                                                <FormGroup id='admissionDateStamp' label='Admission Date' isFloating>
                                                    <Input placeholder='Admission Date'
                                                        onChange={studentForm.handleChange}
                                                        // onBlur={studentForm.handleBlur}
                                                        value={studentForm.values.admissionDateStamp}
                                                        // isValid={studentForm.isValid}
                                                        // isTouched={studentForm.touched.admissionDateStamp}
                                                        // invalidFeedback={studentForm.errors.admissionDateStamp}
                                                        type='date' />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-6">
                                                <FormGroup id="isCollegeTransport" label="Transport" isFloating>
                                                    <Select ariaLabel="" placeholder="Select Transport"
                                                        onChange={selectTransport}
                                                        value={isCollegeTransport} onBlur={studentForm.handleBlur}>
                                                        <option value='Yes'>Yes</option>
                                                        <option value='No'>No</option>
                                                    </Select>
                                                </FormGroup>
                                            </div>
                                            {isCollegeTransport == 'Yes' ?
                                                <>
                                                    <div className="col-lg-6">
                                                        <FormGroup id="transportAreaMasterId" label="Area of Student" isFloating>
                                                            <SearchableSelect isFloating ariaLabel="" placeholder="Select Area"
                                                                onChange={selectTransportArea}
                                                                value={transportAreaMasterId}
                                                                list={transportAreaMasterData.map((data: any) => (
                                                                    { value: data.transportAreaMasterId, label: data.areaName }
                                                                ))}
                                                                onBlur={studentForm.handleBlur}
                                                                isValid={studentForm.isValid}
                                                                isTouched={studentForm.touched.transportAreaMasterId}
                                                                invalidFeedback={studentForm.errors.transportAreaMasterId} />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <FormGroup id="modeOfWays" label="Mode Of Ways" isFloating>
                                                            <Select ariaLabel="" placeholder="Select Ways"
                                                                onChange={(e: any) => { setModeOfWays(e.target.value) }}
                                                                value={modeOfWays}
                                                                onBlur={studentForm.handleBlur}
                                                                isValid={studentForm.isValid}
                                                                isTouched={studentForm.touched.modeOfWays}
                                                                invalidFeedback={studentForm.errors.modeOfWays}>
                                                                <option value='1'>One Way</option>
                                                                <option value='2'>Two Way</option>
                                                            </Select>
                                                        </FormGroup>
                                                    </div>
                                                </> : null}

                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className='col-6'>
                                <Card className='rounded-1 mb-2' borderSize={2}>
                                    <CardHeader>
                                        <CardLabel icon='ReceiptLong'>
                                            <CardTitle>Address Info</CardTitle>
                                        </CardLabel>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="row g-4">
                                            <div className='col-12'>
                                                <FormGroup id='commAddr_FlatNo' label='Flat No/Door No' isFloating>
                                                    <Input
                                                        placeholder='Enter Flat No/Door No'
                                                        onChange={studentForm.handleChange}
                                                        onBlur={studentForm.handleBlur}
                                                        value={studentForm.values.commAddr_FlatNo}
                                                        isValid={studentForm.isValid}
                                                        isTouched={studentForm.touched.commAddr_FlatNo}
                                                        invalidFeedback={studentForm.errors.commAddr_FlatNo}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className='col-6'>
                                                <FormGroup id='commAddr_Street' label='Street' isFloating>
                                                    <Input
                                                        placeholder='Enter Street'
                                                        onChange={studentForm.handleChange}
                                                        // onBlur={studentForm.handleBlur}
                                                        value={studentForm.values.commAddr_Street}
                                                    // isValid={studentForm.isValid}
                                                    // isTouched={studentForm.touched.commAddr_Street}
                                                    // invalidFeedback={studentForm.errors.commAddr_Street}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className='col-6'>
                                                <FormGroup id='commAddr_Area' label='Area/Locality' isFloating>
                                                    <Input
                                                        placeholder='Enter Area/Locality'
                                                        onChange={studentForm.handleChange}
                                                        // onBlur={studentForm.handleBlur}
                                                        value={studentForm.values.commAddr_Area}
                                                    // isValid={studentForm.isValid}
                                                    // isTouched={studentForm.touched.commAddr_Area}
                                                    // invalidFeedback={studentForm.errors.commAddr_Area}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className='col-6'>
                                                <FormGroup id='commAddr_StateId' label='State' isFloating>
                                                    <SearchableSelect isFloating
                                                        ariaLabel='State'
                                                        onChange={(e: any) => selectState(e)}
                                                        onBlur={studentForm.handleBlur}
                                                        value={commAddr_StateId}
                                                        isValid={studentForm.isValid}
                                                        isTouched={studentForm.touched.commAddr_StateId}
                                                        invalidFeedback={studentForm.errors.commAddr_StateId}
                                                        list={statesData.map((data: any) => (
                                                            { value: data.stateId, label: data.stateName }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className='col-6'>
                                                <FormGroup id='commAddr_CityId' label='City' isFloating>
                                                    <SearchableSelect isFloating
                                                        ariaLabel='City'
                                                        onChange={selectCity}
                                                        onBlur={studentForm.handleBlur}
                                                        value={commAddr_CityId}
                                                        isValid={studentForm.isValid}
                                                        isTouched={studentForm.touched.commAddr_CityId}
                                                        invalidFeedback={studentForm.errors.commAddr_CityId}
                                                        list={cityData.map((data: any) => (
                                                            { value: data.cityId, label: data.cityName }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className='col-6'>
                                                <FormGroup id='commAddr_Pincode' label='Pincode' isFloating>
                                                    <Input
                                                        placeholder='Enter Pincode'
                                                        onKeyDown={(e: any) => onlyAllowNumber(e)}
                                                        onChange={studentForm.handleChange}
                                                        onBlur={studentForm.handleBlur}
                                                        value={studentForm.values.commAddr_Pincode}
                                                        isValid={studentForm.isValid}
                                                        isTouched={studentForm.touched.commAddr_Pincode}
                                                        invalidFeedback={studentForm.errors.commAddr_Pincode}
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col-lg-12 d-flex justify-content-end">
                                <Button icon="Save" color="info" type="submit" isDisable={!studentForm.isValid && !!studentForm.submitCount}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Page>

            <OffCanvas setOpen={setCourseCapacityCanvas} isOpen={courseCapacityCanvas} titleId='courseCapacityCanvas' placement='end' isBackdrop tag="form" noValidate onSubmit={courseCapacityEditForm.handleSubmit} isNotClose>
                <OffCanvasHeader>
                    <OffCanvasTitle id='courseCapacityCanvas'>Update Course Capacity</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className="row g-4">

                        <div className='col-12'>
                            <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                }-info bg-l${darkModeStatus ? 'o50' : '10'
                                }-info-hover transition-base rounded-2 mb-4`}
                                shadow='sm'>
                                <CardBody>
                                    <div className='d-flex align-items-center pb-3'>
                                        <div className='flex-shrink-0 ms-2'>
                                            <Icon icon='Assignment' size='4x' color='info' />
                                        </div>
                                        <div className="row">
                                            <div className='flex-grow-1 ms-3'>
                                                <div className='fw-bold fs-5 text-dark mb-0'>
                                                    {courseCapacityData.batchName}
                                                </div>
                                                <div className='text-muted'>
                                                    Batch
                                                </div>
                                            </div>
                                            <div className='flex-grow-1 ms-3'>
                                                <div className='fw-bold fs-5 text-dark mb-0'>
                                                    {courseCapacityData.courseName}
                                                </div>
                                                <div className='text-muted'>
                                                    Course
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>

                        <div className='col-12'>
                            <FormGroup id='govQuota' label='Gov Quota'>
                                <Input onChange={courseCapacityEditForm.handleChange} value={courseCapacityEditForm.values.govQuota} isValid={courseCapacityEditForm.isValid} onBlur={courseCapacityEditForm.handleBlur} isTouched={courseCapacityEditForm.touched.govQuota} invalidFeedback={courseCapacityEditForm.errors.govQuota} type='text' placeholder="Gov Quota" required
                                    onKeyDown={(e: any) => onlyAllowNumber(e)} />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='managementQuota' label='Management Quota'>
                                <Input onChange={courseCapacityEditForm.handleChange} value={courseCapacityEditForm.values.managementQuota} isValid={courseCapacityEditForm.isValid} onBlur={courseCapacityEditForm.handleBlur} isTouched={courseCapacityEditForm.touched.managementQuota} invalidFeedback={courseCapacityEditForm.errors.managementQuota} type='text' placeholder="Management Quota" required onKeyDown={(e: any) => onlyAllowNumber(e)} />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='totalAllocated' label='Total Allocated'>
                                <Input onChange={courseCapacityEditForm.handleChange} value={Number(courseCapacityEditForm.values.govQuota) + Number(courseCapacityEditForm.values.managementQuota)} isValid={courseCapacityEditForm.isValid} onBlur={courseCapacityEditForm.handleBlur} isTouched={courseCapacityEditForm.touched.totalAllocated} invalidFeedback={courseCapacityEditForm.errors.totalAllocated} type='text' placeholder="Total Allocated" required onKeyDown={(e: any) => onlyAllowNumber(e)} disabled />
                            </FormGroup>
                        </div>
                    </div>
                </OffCanvasBody>
                <div className='row m-0'>
                    <div className='col-12 p-3'>
                        <Button color='info' icon="Save" className='w-100' type="submit" isDisable={!courseCapacityEditForm.isValid && !!courseCapacityEditForm.submitCount}> Update</Button>
                    </div>
                </div>
            </OffCanvas>

            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} isNavigate={isNavigate} />
        </PageWrapper>
    )
}

export default QuickStudentCreation
