import classNames from 'classnames';
import { useFormik } from 'formik';
import { useEffect, useState, useRef, useContext } from 'react';
import { useReactToPrint } from 'react-to-print';
import useDarkMode from '../../../hooks/useDarkMode';
import useSortableData from '../../../hooks/useSortableData';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import { toasts } from '../../../services/toast.service';
import Page from '../../../layout/Page/Page';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card';
import Icon from '../../icon/Icon';
import Input from '../../bootstrap/forms/Input';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown';
import FormGroup from '../../bootstrap/forms/FormGroup';
import { convertJsonToPdf, downloadExcel, downloadFile } from '../../../services/ExportService';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../bootstrap/OffCanvas';
import AlertService from '../../../services/AlertService';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { getColumnsForDataTable, updateColumnsForDataTable, updateFilter } from '../../../services/common.service';
import AuthContext from '../../../contexts/authContext';
import { getLicenseKey } from '../../../services/application.settings';
import NoDataMsg from '../../../common/components/NoDataMsg';
import { TableLoader, showLoader } from '../../../services/loader.services';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import DeleteComponents from '../../../common/components/DeleteComponents';
import { addTransportRange, deleteTransportRange, getTransportRange, updateTransportRange } from '../../../services/transport.service';

function TransportRange() {

	useEffect(() => {
		getTransportRangeList()
	}, [])

	const { userAccountId, userTypeId, addAccess, editAccess, deleteAccess } = useContext(AuthContext);
	const componentRef = useRef(null);
	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnDataById, setColumnDataById] = useState([]);
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const [isChecked, setIsChecked] = useState(false);
	const [dataStatus, setDataStatus] = useState(false);
	const [transportRangeData, setTransportRangeData] = useState([])

	// ForModal
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { items, requestSort, getClassNamesFor } = useSortableData(transportRangeData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);

	const [addTransportRangeOffCanvas, setAddTransportRangeOffCanvas] = useState(false);
	const [editTransportRangeOffCanvas, setEditTransportRangeOffCanvas] = useState(false);
	const [isDeleteOpen, setIsDeleteOpen] = useState(false);

	// For Alert
	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);
	const [noDataMsg, setNoDataMsg] = useState('');

	const transportRangeForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			rangeName: '',
			amount: '',
		},
		validate: (values) => {
			const errors: {
				rangeName?: string;
				amount?: string;
			} = {};
			if (!values.rangeName) {
				errors.rangeName = "Required"
			}
			if (!values.amount) {
				errors.amount = "Required"
			}
			return errors;
		},
		//validateOnChange: false,
		onSubmit: (values) => { addTransportRangeSubmit() },
	});

	const transportRangeEditForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			transportRangeId: '',
			rangeName: '',
			amount: '',
		},
		validate: (values) => {
			const errors: {
				rangeName?: string;
				amount?: string;
			} = {};
			if (!values.rangeName) {
				errors.rangeName = "Required"
			}
			if (!values.amount) {
				errors.amount = "Required"
			}
			return errors;
		},
		//validateOnChange: false,
		onSubmit: (values) => { updateTransportRangeSubmit() },
	});

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => { },
		//validateOnChange: false,
		onSubmit: () => { },
	});

	// Filter
	const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

	function onActivate(i: any) {
		if (i != undefined) {
			let selectedTransportRange = i
			setEditTransportRangeOffCanvas(true)
			transportRangeEditForm.setValues({
				transportRangeId: selectedTransportRange.transportRangeId,
				rangeName: selectedTransportRange.rangeName,
				amount: selectedTransportRange.amount,
			})
		}
	}

	function setTransportRangeDetails() {
		return ({
			rangeName: transportRangeForm.values.rangeName,
			amount: transportRangeForm?.values.amount,
			licenseKey: getLicenseKey,
			staffDetailsId: userAccountId
		})
	}

	function setTransportRangeEditDetails() {
		return ({
			transportRangeId: transportRangeEditForm.values.transportRangeId,
			rangeName: transportRangeEditForm.values.rangeName,
			amount: transportRangeEditForm?.values.amount,
			licenseKey: getLicenseKey,
			staffDetailsId: userAccountId
		})
	}

	function setTransportRangeDeleteDetails() {
		return ({
			transportRangeId: transportRangeEditForm.values.transportRangeId,
			staffDetailsId: userAccountId,
			licenseKey: getLicenseKey,
		})
	}

	function addTransportRangeSubmit() {
		showLoader(true);
		if (transportRangeForm.isValid) {
			let transportRangePostData = setTransportRangeDetails()
			addTransportRange(transportRangePostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: "success" });
						setIsOpen(true);
						closeAndReset();
						getTransportRangeList();
					}
					else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: "error" });
						setIsOpen(true);
					}
					else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: "error" });
						setIsOpen(true);
					}
				}
				, (error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: "error" });
					setIsOpen(true);
				}
			)
		} else if (transportRangeForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: "error" });
			setIsOpen(true);
		}
	}

	function updateTransportRangeSubmit() {
		showLoader(true);
		if (transportRangeEditForm.isValid) {
			let transportRangePostData = setTransportRangeEditDetails()
			updateTransportRange(transportRangePostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: "success" });
						setIsOpen(true);
						closeAndReset();
						getTransportRangeList();
					}
					else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: "error" });
						setIsOpen(true);
					}
					else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: "error" });
						setIsOpen(true);
					}
				}
				, (error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: "error" });
					setIsOpen(true);
				}
			)
		} else if (transportRangeEditForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: "error" });
			setIsOpen(true);
		}
	}

	function deleteTransportRangeSubmit() {
		showLoader(true);
		if (transportRangeEditForm.isValid) {
			let transportRangePostData = setTransportRangeDeleteDetails()
			deleteTransportRange(transportRangePostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: "success" });
						setIsOpen(true);
						closeAndReset();
						getTransportRangeList();
					}
					else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: "error" });
						setIsOpen(true);
					}
					else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: "error" });
						setIsOpen(true);
					}
				}
				, (error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: "error" });
					setIsOpen(true);
				}
			)
		} else if (transportRangeEditForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: "error" });
			setIsOpen(true);
		}
	}

	function getTransportRangeList() {
		setDataStatus(false)
		getTransportRange(0,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.transportRange;
					if (data != undefined) {
						setTransportRangeData(data);
						getColumnsForTable('getTransportRange', 'get');
						setDataStatus(true);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
					} else {
						setDataStatus(true);
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					setDataStatus(true);
					// toasts(response.data.message, "Error")
					getColumnsForTable('getTransportAreaMaster', 'get');
					setTransportRangeData([]);
					setNoDataMsg(response.data.message)
				} else {
					setDataStatus(true);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				setDataStatus(true);
				toasts(error, "Error")
			}
		)
	}

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(userTypeId, apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData)
						let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
						setColumnVisibilityData(columnVisibilityData)

						const allData = allColumnsData.map((item: any) => item.isDisplay);
						const allColumns = allData.filter((item: any) => item == false);

						if (type == 'get') {
							setIsChecked(allColumns == '' ? true : false);
						}
						else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

							for (let i = 0; i < nonDefaultValue.length; i++) {

								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: allColumns == '' ? 0 : 1
								}
								all.push(obj);
							}
							updateColumnsSubmit('', '', all)
						}
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {
		if (columnVisibilityForm.isValid) {
			setColumnDataById(isDisplay)
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type ? type : [
					{
						columnVisibilityId: columnVisibilityId,
						isDisplay: isDisplay ? 0 : 1
					}
				]
			}
			updateColumnsForDataTable(columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForTable('getTransportRange', 'get');
					}
					else if (data.success == false) {
						// toasts(data.success, "Error")
					}
					else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, "Error")
					}
				}
				, (error) => {
					toasts(error, "Error")
				}
			)
		} else if (columnVisibilityForm.isValid == false) {
			toasts("Please fill all the details!", "Error")
		}
	}

	function closeAndReset() {
		setAddTransportRangeOffCanvas(false)
		setEditTransportRangeOffCanvas(false)
		transportRangeForm.resetForm();
		transportRangeEditForm.resetForm();
	}

	// Print
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<>
			<PageWrapper title='Transport Range'>
				<Page container='fluid'>
					<Card stretch data-tour='list' ref={componentRef} id='pdf'>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info' className={addAccess ? "col-lg-5" : "col-lg-6"}>
								<CardTitle tag='div' className='h5'>
									Transport Range List
								</CardTitle>
							</CardLabel>
							&nbsp;&nbsp;
							<ButtonGroup className='col-lg-3 d-print-none' color='primary'>
								<Icon className='mt-1' icon='Search' size='2x' color='primary' />
								<Input
									id='searchInput'
									type='search'
									placeholder='Search...'
									onChange={columnVisibilityForm.handleChange}
									value={columnVisibilityForm.values.searchInput}
								/>
							</ButtonGroup>
							<CardActions className='d-print-none'>
								<Dropdown
									isOpen={columnVisibilityMenu}
									setIsOpen={setColumnVisibilityMenu}
									isButtonGroup>
									<DropdownToggle>
										<Button icon='FilterAlt' color='primary' isLight>
											Filter
										</Button>
									</DropdownToggle>
									<DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
										<DropdownItem>
											<div className='container py-2'>
												<form className='row g-3'>
													<div className='col-12'>
														<FormGroup>
															<h6>Select All</h6>
															<Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getTransportRange', 'post')} checked={isChecked} ariaLabel='Available status' />
														</FormGroup>
													</div>
													<div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll', }}>
														<FormGroup>
															<h6>Columns</h6>
															<ChecksGroup>
																{allColumnsData.map((i: any) => (
																	<Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
																))}
															</ChecksGroup>
														</FormGroup>
													</div>
												</form>
											</div>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
								<Dropdown isButtonGroup>
									<DropdownToggle>
										<Button color='primary' isLight icon='CloudDownload'> Export </Button>
									</DropdownToggle>
									<DropdownMenu isAlignmentEnd>
										<DropdownItem>
											<Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("TransportRangeList", transportRangeData, columnVisibilityData)}> Excel </Button>
										</DropdownItem>
										<DropdownItem>
											<Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(transportRangeData, columnVisibilityData, "TransportRangeList")}> PDF
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("TransportRangeList", transportRangeData, columnVisibilityData)}> CSV </Button>
										</DropdownItem>
										<DropdownItem>
											<Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
								{addAccess ? <Button color='primary' icon='Add' isLight onClick={() => setAddTransportRangeOffCanvas(true)}>
									Add New
								</Button> : null}
							</CardActions>
						</CardHeader>
						<CardBody className='table-responsive' isScrollable>
							{!dataStatus ?
								<TableLoader /> :
								<table className='table table-modern table-hover text-nowrap'>
									<thead><tr><th scope='col' onClick={() => requestSort('sno')} className='cursor-pointer text-decoration-underline'>S.No{' '}<Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' /></th>{columnVisibilityData.map((column: any) => (<th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)} className='cursor-pointer text-decoration-underline'>{column.columnName}<Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' /></th>))}{editAccess ? <th scope='col' className='d-print-none'>Action</th> : null}</tr></thead>
									<tbody>{filteredData != "" ? <>{filteredData.map((rowData: any) => (<tr key={rowData.transportRangeId}><td>{rowData.sno}</td>{columnVisibilityData.map((column: any) => ((column.isDisplay && rowData[column.keyName]) ? <td key={`${rowData.transportRangeId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />))}{editAccess ? <td className="d-print-none"><Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} /></td> : null}</tr>))}</> : <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}</tbody>
								</table>}
						</CardBody>
						<PaginationButtons
							className='d-print-none'
							data={items}
							label='items'
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
						/>
					</Card>
				</Page>

				<OffCanvas
					setOpen={setAddTransportRangeOffCanvas}
					isOpen={addTransportRangeOffCanvas}
					titleId='addTransportRangeOffCanvas'
					isBodyScroll
					tag='form'
					noValidate
					onSubmit={transportRangeForm.handleSubmit}>
					<OffCanvasHeader setOpen={setAddTransportRangeOffCanvas} onClick={closeAndReset}>
						<OffCanvasTitle id='addTransportRangeOffCanvas'>
							Add Transport Range
						</OffCanvasTitle>
					</OffCanvasHeader>
					<OffCanvasBody>
						<div className='row g-4'>
							<div className='col-12'>
								<FormGroup id='rangeName' label='Range Name'>
									<Input
										placeholder='Enter Range Name'
										onChange={transportRangeForm.handleChange}
										value={transportRangeForm.values.rangeName}
										onBlur={transportRangeForm.handleBlur}
										isValid={transportRangeForm.isValid}
										isTouched={transportRangeForm.touched.rangeName}
										invalidFeedback={transportRangeForm.errors.rangeName}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='amount' label='Amount'>
									<Input
										placeholder='Enter Amount'
										onChange={transportRangeForm.handleChange}
										value={transportRangeForm.values.amount}
										onBlur={transportRangeForm.handleBlur}
										isValid={transportRangeForm.isValid}
										isTouched={transportRangeForm.touched.amount}
										invalidFeedback={transportRangeForm.errors.amount}
									/>
								</FormGroup>
							</div>
						</div>
					</OffCanvasBody>
					<div className='row m-0'>
						<div className='col-12 p-3'>
							<Button color='info' icon='Save' className='w-100' type='submit'>
								Save
							</Button>
						</div>
					</div>
				</OffCanvas>

				<OffCanvas
					setOpen={setEditTransportRangeOffCanvas}
					isOpen={editTransportRangeOffCanvas}
					titleId='editTransportRangeOffCanvas'
					isBodyScroll
					tag='form'
					noValidate
					onSubmit={transportRangeEditForm.handleSubmit}>
					<OffCanvasHeader setOpen={setEditTransportRangeOffCanvas} onClick={closeAndReset}>
						<OffCanvasTitle id='editTransportRangeOffCanvas'>
							Update Transport Range
						</OffCanvasTitle>
					</OffCanvasHeader>
					<OffCanvasBody>
						<div className='row g-4'>
							<div className='col-12'>
								<FormGroup id='rangeName' label='Range Name'>
									<Input
										placeholder='Enter Range Name'
										onChange={transportRangeEditForm.handleChange}
										value={transportRangeEditForm.values.rangeName}
										onBlur={transportRangeEditForm.handleBlur}
										isValid={transportRangeEditForm.isValid}
										isTouched={transportRangeEditForm.touched.rangeName}
										invalidFeedback={transportRangeEditForm.errors.rangeName}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='amount' label='Amount'>
									<Input
										placeholder='Enter Amount'
										onChange={transportRangeEditForm.handleChange}
										value={transportRangeEditForm.values.amount}
										onBlur={transportRangeEditForm.handleBlur}
										isValid={transportRangeEditForm.isValid}
										isTouched={transportRangeEditForm.touched.amount}
										invalidFeedback={transportRangeEditForm.errors.amount}
									/>
								</FormGroup>
							</div>
						</div>
					</OffCanvasBody>
					<div className='row'>
						<div className="d-flex justify-content-center position-absolute top-65 start-50 translate-middle">
							<DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteTransportRangeSubmit} />
						</div>
						{deleteAccess ? <div className='col-6 position-absolute bottom-0 start-0 p-3'>
							<Button color='danger' className='w-100' icon="Delete" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
								Delete
							</Button>
						</div> : null}
						<div className={deleteAccess ? 'col-6 position-absolute bottom-0 end-0 p-3' : 'col-12 position-absolute bottom-0 end-0 p-3'}>
							<Button color='info' icon="Save" className='w-100' type="submit" isDisable={!transportRangeEditForm.isValid && !!transportRangeEditForm.submitCount}>Update</Button>
						</div>
					</div>
				</OffCanvas>

				<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
			</PageWrapper>
		</>
	);
}
export default TransportRange;