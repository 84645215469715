import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import SubHeader, {
    SubHeaderLeft,
    SubHeaderRight,
    SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import Button from '../../../components/bootstrap/Button';
import Card, {
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../../../components/bootstrap/Card';
import Icon from '../../../components/icon/Icon';
import useDarkMode from '../../../hooks/useDarkMode';
import { toasts } from '../../../services/toast.service';
import { getProfileForStaff } from '../../../services/staff.service';
import { useReactToPrint } from 'react-to-print';
import AuthContext from '../../../contexts/authContext';
import { pictNotLoading, profilePic } from '../../../services/common.service';


const StaffProfile = () => {
    const { darkModeStatus } = useDarkMode();

    const { staffDetailsId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        getStaffProfile(staffDetailsId)
    }, [])

    const [profileForStaffData, setProfileForStaffData] = useState<any>([])
    const { userAccountId, userTypeId } = useContext(AuthContext);

    function getStaffProfile(staffDetailsId: any) {
        getProfileForStaff(staffDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.profileForStaff;
                    if (data != undefined) {
                        setProfileForStaffData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    return (
        <>
            {profileForStaffData.map((staff: any) => (
                <PageWrapper title={`${staff.fullname}`} key={staff.staffUserAccountId}>
                    <SubHeader>
                        <SubHeaderLeft>
                            <Button color='info' isLink icon='ArrowBack' onClick={() => navigate(-1)}>
                                Back to List
                            </Button>
                            <SubheaderSeparator />
                        </SubHeaderLeft>
                        {userTypeId == 1 ?
                            <SubHeaderRight>
                                <Button
                                    color='primary'
                                    isLight
                                    icon='Edit'
                                    tag='a'
                                    to={`../academic/updateStaff/${staff.staffUserAccountId}`}>
                                    Edit
                                </Button>
                            </SubHeaderRight>
                            : <></>}
                    </SubHeader>
                    <Page container='fluid'>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <Card className='shadow-3d-info'>
                                    <CardBody>
                                        <div className='row g-3'>
                                            <div className='col-12 d-flex justify-content-center'>
                                                {staff.profilePath != null ?
                                                    <img style={{ borderRadius: '50%' }} src={staff.profilePath}
                                                        width="120" height="120" onError={(e: any) => pictNotLoading(e, staff.genderId)} />
                                                    : <img className="rounded-0" src={profilePic(staff.genderId)}
                                                        width="120" height="120" />}
                                            </div>
                                            <div className='col-12'>
                                                <div className='row g-2'>
                                                    <span className='fs-4 fw-bold me-2  d-flex justify-content-center text-center text-uppercase'>{`${staff.fullname}`}</span>
                                                    <div className='h5 text-muted text-lowercase opacity-50 d-flex justify-content-center text-uppercase'>
                                                        {staff.designationName}
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon='ViewList' size='3x' color='info' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold fs-5 mb-0'>
                                                                    {staff.empNumber ? staff.empNumber : <Icon icon='Splitscreen' className='mb-0 text-dark fw-bold h2' />}
                                                                </div>
                                                                <div className='text-muted'>
                                                                    Emp Number
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon='DateRange' size='3x' color='info' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold fs-5 mb-0'>
                                                                    {staff.dateOfJoining ? staff.dateOfJoining : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                </div>
                                                                <div className='text-muted'>
                                                                    Date Of Joining
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon={staff.genderId == 1 ? 'Male' : 'Female'} size='3x' color='info' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold fs-5 mb-0'>
                                                                    {staff.gender ? staff.gender : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}                                                                    </div>
                                                                <div className='text-muted'>
                                                                    Gender
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon='PhoneIphone' size='3x' color='info' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold fs-5 mb-0'>
                                                                    {staff.contactNumber ? staff.contactNumber : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}                                                                     </div>
                                                                <div className='text-muted'>
                                                                    Mobile Number
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-12'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon='Mail' size='3x' color='info' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold fs-5 mb-0'>
                                                                    {staff.email ? staff.email : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                </div>
                                                                <div className='text-muted'>
                                                                    Email Address
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className='col-lg-8'>
                                <Card className='shadow-3d-primary'>
                                    <CardHeader>
                                        <CardLabel icon='Summarize' iconColor='success'>
                                            <CardTitle tag='div' className='h5'>
                                                Other Info
                                            </CardTitle>
                                        </CardLabel>
                                    </CardHeader>
                                    <CardBody>
                                        <div className='row g-4'>
                                            <div className='col-md-6'>
                                                <Card
                                                    className={`bg-l${darkModeStatus ? 'o25' : '25'
                                                        }-primary bg-l${darkModeStatus ? 'o50' : '10'
                                                        }-primary-hover transition-base rounded-2 mb-4`}
                                                    shadow='sm'>
                                                    <CardHeader className='bg-transparent'>
                                                        <CardLabel>
                                                            <CardTitle tag='div' className='h5'>
                                                                Permanent Address
                                                            </CardTitle>
                                                        </CardLabel>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <div className='d-flex align-items-center pb-3'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon
                                                                    icon='House'
                                                                    size='4x'
                                                                    color='primary'
                                                                />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold mb-0'>
                                                                    {staff.address ? staff.address : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                            <div className='col-md-6'>
                                                <Card
                                                    className={`bg-l${darkModeStatus ? 'o25' : '25'
                                                        }-success bg-l${darkModeStatus ? 'o50' : '10'
                                                        }-success-hover transition-base rounded-2 mb-0`}
                                                    stretch
                                                    shadow='sm'>
                                                    <CardHeader className='bg-transparent'>
                                                        <CardLabel>
                                                            <CardTitle tag='div' className='h5'>
                                                                Other details
                                                            </CardTitle>
                                                        </CardLabel>
                                                    </CardHeader>
                                                    <CardBody className='pt-0'>
                                                        <div className='row g-2 d-flex align-items-center'>
                                                            <div className='col-12'>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className='flex-shrink-0'>
                                                                        <Icon icon='School' size='2x' color='info' />
                                                                    </div>
                                                                    <div className='flex-grow-1 ms-3'>
                                                                        <div className='fw-bold fs-5 mb-0'>
                                                                            {staff.qualification1 ? staff.qualification1 : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}                                                                            </div>
                                                                        <div className='text-muted'>
                                                                            Qualification
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-12'>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className='flex-shrink-0'>
                                                                        <Icon icon='Mail' size='2x' color='info' />
                                                                    </div>
                                                                    <div className='flex-grow-1 ms-3'>
                                                                        <div className='fw-bold fs-5 mb-0'>
                                                                            {staff.departmentName ? staff.departmentName : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                        </div>
                                                                        <div className='text-muted'>
                                                                            Department Name
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-12'>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className='flex-shrink-0'>
                                                                        <Icon icon='DateRange' size='2x' color='info' />
                                                                    </div>
                                                                    <div className='flex-grow-1 ms-3'>
                                                                        <div className='fw-bold fs-5 mb-0'>
                                                                            {staff.dateOfBirth ? staff.dateOfBirth : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                        </div>
                                                                        <div className='text-muted'>
                                                                            Date of Birth
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-12'>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className='flex-shrink-0'>
                                                                        <Icon icon='Bloodtype' size='2x' color='info' />
                                                                    </div>
                                                                    <div className='flex-grow-1 ms-3'>
                                                                        <div className='fw-bold fs-5 mb-0'>
                                                                            {staff.groupName ? staff.groupName : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}                                                                          </div>
                                                                        <div className='text-muted'>
                                                                            Blood Group
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </Page>
                </PageWrapper>))}
        </>
    );
};

export default StaffProfile;
