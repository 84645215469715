import React, { useEffect, useState } from 'react';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { toasts } from '../../../services/toast.service';
import Card, { CardBody, CardLabel, CardTitle } from '../../bootstrap/Card';
import useDarkMode from '../../../hooks/useDarkMode';
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import Chart from '../../extras/Chart';
import { convertDateToEpoch, getCurrentDateAndTime } from '../../../services/common.service';
import Button from '../../bootstrap/Button';
import { Calendar as DatePicker } from 'react-date-range';
import Popovers from '../../bootstrap/Popovers';
import { getLabel } from '../../extras/calendarHelper';
import { View as TView } from 'react-big-calendar';
import Icon from '../../icon/Icon';
import { getStudentAttendancePercentageByCourseId } from '../../../services/dashboard.service';
import { getCourseDetails } from '../../../services/master.service';
import { TableLoader } from '../../../services/loader.services';

const AllTransactionReport = () => {

    const [date, setDate] = useState(new Date());
    const [viewMode, setViewMode] = useState<TView>('month');
    const calendarDateLabel = getLabel(date, viewMode);

    const [transactionDate, setTransactionDate] = useState<any>('');
    const [day, setDay] = useState<any>('');

    const [courseDetailsData, setCourseDetailsData] = useState<any>([]);
    const [attendancePercentage, setAttendancePercentage] = useState<any>([]);
    const [dataStatus, setDataStatus] = useState(false)
    const [dataStatusForCourse, setDataStatusForCourse] = useState(false)
    const [activeTab, setActiveTab] = useState('');

    const [noDataMsg, setNoDataMsg] = useState('');
    const { darkModeStatus } = useDarkMode();

    useEffect(() => {
        const day = date.getDate().toString().padStart(2, '0');;
        setDay(day + ' ' + calendarDateLabel)
        getCourseList()

    }, []);

    function getCourseList() {
        setDataStatusForCourse(false)
        getCourseDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.course;
                    if (data != undefined) {
                        setCourseDetailsData(data);
                        setActiveTab(data[0].courseId)
                        setDataStatusForCourse(true)
                        getStudentAttendancePercentageByCourse(data[0].courseId, convertDateToEpoch(getCurrentDateAndTime('date')))

                        setDataStatusForCourse(true)
                    } else {
                        setDataStatusForCourse(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataStatusForCourse(true)
                    setCourseDetailsData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setDataStatusForCourse(true)
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatusForCourse(true)
                toasts(error, "Error")
            }
        )
    }

    function getStudentAttendancePercentageByCourse(courseId: any, dateInMilliSec: any) {
        setDataStatus(false)
        getStudentAttendancePercentageByCourseId(courseId, dateInMilliSec,
            (response) => {
                if (response.data.success) {
                    let overAllDetails = response.data.data.studentAttendancePercentageByCourseId

                    const overAll = [{
                        semesterNumber: 'Over All',
                        totalStudents: overAllDetails[0].overAllTotalStudentCount,
                        totalPresent: 0,
                        totalPresentPercentage: overAllDetails[0].overAllTotalPresentPercentage,
                        totalAbsent: 0,
                        totalAbsentPercentage: overAllDetails[0].overAllTotalAbsentPercentage,
                    }]

                    let data = [...overAll, ...response.data.data.studentAttendancePercentageByCourseId[0].semesterWiseStudentAttendancePercentage];

                    if (data != undefined) {
                        setAttendancePercentage(
                            {
                                series: [
                                    {
                                        name: 'Total Present Percentage',
                                        data: data.map((i: any) => i.totalPresentPercentage !== null ? i.totalPresentPercentage : 0),
                                    },
                                    {
                                        name: 'Total Absent Percentage',
                                        data: data.map((i: any) => i.totalAbsentPercentage !== null ? i.totalAbsentPercentage : 0),
                                    },
                                ],
                                options: {
                                    chart: {
                                        type: 'bar',
                                        height: 350,
                                        stacked: true,
                                        stackType: '100%',
                                    },
                                    responsive: [
                                        {
                                            breakpoint: 480,
                                            options: {
                                                legend: {
                                                    position: 'bottom',
                                                    offsetX: -10,
                                                    offsetY: 0,
                                                },
                                            },
                                        },
                                    ],
                                    xaxis: {
                                        categories: data.map((i: any) => i.semesterNumber !== null ? i.semesterNumber : 'No semester'),
                                    },
                                    fill: {
                                        opacity: 1,
                                    },
                                    title: {
                                        text: overAllDetails[0].courseName,
                                        floating: true,
                                        offsetX: -5,
                                        offsetY: 2,
                                        align: 'center',
                                        style: {
                                            color: '#444',
                                        },
                                    },
                                },
                            }
                        );

                        setDataStatus(true);
                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataStatus(true)
                    setAttendancePercentage([]);
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setDataStatus(true)
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function selectTransactionDate(e: any) {
        setDate(e)
        const calendarDateLabel = getLabel(e, 'month')
        const day = e.getDate().toString().padStart(2, '0');;
        setDay(day + ' ' + calendarDateLabel)
        const formattedDate = `${e.getFullYear()}-${String(e.getMonth() + 1).padStart(2, '0')}-${String(e.getDate()).padStart(2, '0')}`;

        let dateInMilliSec = convertDateToEpoch(formattedDate);
        setTransactionDate(formattedDate);
        if (activeTab != '') {
            getStudentAttendancePercentageByCourse(activeTab, dateInMilliSec)
        }
        else {
            getStudentAttendancePercentageByCourse(activeTab, dateInMilliSec)
        }
    }

    function chartData(e: any) {
        setAttendancePercentage([])

        if (e !== undefined && transactionDate !== '') {
            getStudentAttendancePercentageByCourse(e, convertDateToEpoch(transactionDate))
        }
        else {
            getStudentAttendancePercentageByCourse(e, convertDateToEpoch(getCurrentDateAndTime('date')))
        }
    }

    return (
        <PageWrapper title='Attendance Percentage'>
            <SubHeader>
                <SubHeaderLeft>
                    <CardLabel icon='BarChart' iconColor='info'>
                        <CardTitle className='fs-5'>Attendance Percentage</CardTitle>
                    </CardLabel>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <SubheaderSeparator />
                    <Popovers
                        desc={
                            <DatePicker
                                onChange={selectTransactionDate}
                                date={date}
                                color={process.env.REACT_APP_PRIMARY_COLOR}
                            />
                        }
                        placement='bottom-end'
                        className='mw-100'
                        trigger='click'>
                        <Button color={darkModeStatus ? 'light' : 'dark'} isLight>
                            {day}
                        </Button>
                    </Popovers>
                </SubHeaderRight>
            </SubHeader>
            <Page container='fluid'>
                <Card stretch data-tour='list'>
                    <CardBody className=' d-flex align-items-center'>
                        {!dataStatusForCourse ? (
                            <TableLoader />
                        ) : (
                            <div className='row'>
                                <div className='col-xl-3 mt-2'>
                                    <div className='row g-3' style={{ maxHeight: '70vh', overflow: 'scroll' }}>
                                        {courseDetailsData.map((company: any) => (
                                            <div key={company.courseId} className='col-xl-12' >
                                                <Button
                                                    isLight={activeTab !== company.courseId}
                                                    onClick={() => { chartData(company.courseId), setActiveTab(company.courseId) }}
                                                    color='info'
                                                    className='w-100 py-4'>
                                                    {company.courseName}
                                                </Button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {attendancePercentage != '' ?
                                    <div className='col-xl-9'>
                                        <Chart
                                            series={attendancePercentage.series}
                                            options={attendancePercentage.options}
                                            type='bar'
                                            height={500}
                                        />
                                    </div>
                                    :
                                    <div className=' col-xl-9 d-flex align-items-center'>
                                        <TableLoader />
                                    </div>
                                }
                            </div>
                        )}
                    </CardBody>
                </Card>
            </Page>
        </PageWrapper>
    );
};

export default AllTransactionReport;
