import classNames from "classnames";
import { useFormik } from "formik";
import { useEffect, useState, useRef, useContext } from "react";
import { useReactToPrint } from "react-to-print";
import useDarkMode from "../../../hooks/useDarkMode";
import useSortableData from "../../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import { toasts } from "../../../services/toast.service";
import Page from "../../../layout/Page/Page";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import Icon from "../../icon/Icon";
import Input from "../../bootstrap/forms/Input";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../bootstrap/Dropdown";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../services/ExportService";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../bootstrap/OffCanvas";
import DeleteComponents from "../../../common/components/DeleteComponents";
import AlertService from "../../../services/AlertService";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import { addStudentSchoolName, deleteStudentSchoolName, getStudentSchoolName, updateStudentSchoolName } from "../../../services/administration.service";
import { getColumnsForDataTable, updateColumnsForDataTable, updateFilter } from "../../../services/common.service";
import AuthContext from "../../../contexts/authContext";
import { getLicenseKey } from "../../../services/application.settings";
import { TableLoader, showLoader } from "../../../services/loader.services";
import NoDataMsg from "../../../common/components/NoDataMsg";


function StudentSchoolName() {

    useEffect(() => {
        getStudentSchoolList()
    }, [])

    const { userAccountId, userTypeId, addAccess, editAccess, deleteAccess } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [studentSchoolData, setStudentSchoolData] = useState([]);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    const [dataStatus, setDataStatus] = useState(false)

    // ForModal
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(studentSchoolData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [addStudentSchoolNameOffCanvas, setAddStudentSchoolNameOffCanvas] = useState(false)
    const [editStudentSchoolNameOffCanvas, setEditStudentSchoolNameOffCanvas] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [noDataMsg, setNoDataMsg] = useState('')
    const studentSchoolForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            schoolName: '',
        },
        validate: (values) => {
            const errors: {
                schoolName?: string;
            } = {};
            if (!values.schoolName) {
                errors.schoolName = 'Required';
            }
            return errors;
        },

        //validateOnChange: false,
        onSubmit: () => { studentSchoolSubmit() },
    });

    const studentSchoolEditForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            schoolName: '',
            studentSchoolNameId: '',
        },
        validate: (values) => {
            const errors: {
                schoolName?: string;
            } = {};
            if (!values.schoolName) {
                errors.schoolName = 'Required';
            }
            return errors;
        },

        //validateOnChange: false,
        onSubmit: () => { updateStudentSchool() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function onActivate(i: any) {
        if (i != undefined) {

            let selectedSchoolName = i
            setEditStudentSchoolNameOffCanvas(true)

            studentSchoolEditForm.setValues({
                schoolName: selectedSchoolName.schoolName,
                studentSchoolNameId: selectedSchoolName.studentSchoolNameId,
            });
        }
    }

    function getStudentSchoolList() {
        setDataStatus(false)
        getStudentSchoolName(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentSchoolName;
                    if (data != undefined) {
                        getColumnsForTable('getStudentSchoolName', 'get');
                        setStudentSchoolData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        setDataStatus(true)
                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataStatus(true)
                    //toasts(response.data.message, "Error")
                    getColumnsForTable('getStudentSchoolName', 'get');
                    setStudentSchoolData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setDataStatus(true)
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function setStudentSchoolName() {

        return ({
            schoolName: studentSchoolForm.values.schoolName,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function setEditStudentSchoolName() {
        return ({
            studentSchoolNameId: studentSchoolEditForm.values.studentSchoolNameId,
            schoolName: studentSchoolEditForm.values.schoolName,
            userAccountId: userAccountId,
            licenseKey: getLicenseKey,
        })
    }

    function studentSchoolSubmit() {
        showLoader(true)
        if (studentSchoolForm.isValid) {
            let studentSchoolPostData = setStudentSchoolName();
            addStudentSchoolName(studentSchoolPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getStudentSchoolList();
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (studentSchoolForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function updateStudentSchool() {
        showLoader(true)
        if (studentSchoolEditForm.isValid) {
            let studentSchoolPostData = setEditStudentSchoolName();
            updateStudentSchoolName(studentSchoolPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getStudentSchoolList();
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (studentSchoolEditForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function deleteStudentSchool() {
        showLoader(true)
        // if (studentSchoolEditForm.isValid) {
        let studentSchoolPostData = setEditStudentSchoolName();
        deleteStudentSchoolName(studentSchoolPostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "success" });
                    setIsOpen(true);
                    getStudentSchoolList();
                    closeAndReset();
                }
                else if (data.success == false) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "error" });
                    setIsOpen(true);
                }
                else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    showLoader(false)
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }
            , (error) => {
                showLoader(false)
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
        // } else if (studentSchoolEditForm.isValid == false) {
        //     showLoader(false)
        //     setAlertStatus({ message: 'Please fill all the details!', type: "error" });
        //     setIsOpen(true);
        // }
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getStudentSchoolName', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeAndReset() {
        setAddStudentSchoolNameOffCanvas(false);
        setEditStudentSchoolNameOffCanvas(false);
        setIsDeleteOpen(false);
        studentSchoolForm.resetForm();
        studentSchoolEditForm.resetForm();
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <PageWrapper title="Student School Creation">
                <Page container='fluid'>
                    <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className={addAccess ? "col-lg-5" : "col-lg-6"}>
                                <CardTitle tag='div' className='h5'>School Name List</CardTitle>
                            </CardLabel>&nbsp;&nbsp;

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getStudentSchoolName', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("School Name List", studentSchoolData, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(studentSchoolData, columnVisibilityData, "School Name List")}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("School Name List", studentSchoolData, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                {addAccess ?
                                    <Button color='primary' icon='Add' isLight onClick={() => setAddStudentSchoolNameOffCanvas(true)}>
                                        Add New
                                    </Button>
                                    : null
                                }
                            </CardActions>
                        </CardHeader>

                        <CardBody className='table-responsive' isScrollable >
                            {!dataStatus ?
                                <TableLoader /> :
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}
                                            {editAccess ?
                                                <th scope='col' className='d-print-none'>
                                                    Action
                                                </th>
                                                : null
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>{filteredData != "" ? <>{filteredData.map((rowData: any) => (<tr key={rowData.studentSchoolNameId}><td>{rowData.sno}</td>{columnVisibilityData.map((column: any) => ((column.isDisplay && rowData[column.keyName]) ? <td key={`${rowData.studentSchoolNameId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />))}
                                        {editAccess ?
                                            <td className="d-print-none"><Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} /></td>
                                            :
                                            null}
                                    </tr>))}</> : <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}</tbody></table>}
                        </CardBody>
                        <PaginationButtons
                            className="d-print-none"
                            data={items}
                            label='items'
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            perPage={perPage}
                            setPerPage={setPerPage}
                        />
                    </Card>
                </Page>

                <OffCanvas
                    setOpen={setAddStudentSchoolNameOffCanvas}
                    isOpen={addStudentSchoolNameOffCanvas}
                    titleId='addStudentSchoolNameOffCanvas'
                    isBodyScroll tag="form" noValidate onSubmit={studentSchoolForm.handleSubmit}>
                    <OffCanvasHeader setOpen={setAddStudentSchoolNameOffCanvas} onClick={closeAndReset}>
                        <OffCanvasTitle id='addStudentSchoolNameOffCanvas'>Add School Name</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className='col-12'>
                            <FormGroup id='schoolName' label='School Name'>
                                <Input placeholder='School Name' onChange={studentSchoolForm.handleChange}
                                    value={studentSchoolForm.values.schoolName}
                                    onBlur={studentSchoolForm.handleBlur}
                                    isValid={studentSchoolForm.isValid}
                                    isTouched={studentSchoolForm.touched.schoolName}
                                    invalidFeedback={studentSchoolForm.errors.schoolName} />
                            </FormGroup>
                        </div>
                    </OffCanvasBody>
                    <div className='row m-0'>
                        <div className='col-12 p-3'>
                            <Button
                                color='info' icon="Save"
                                className='w-100' type="submit" isDisable={!studentSchoolForm.isValid && !!studentSchoolForm.submitCount}>
                                Save
                            </Button>
                        </div>
                    </div>
                </OffCanvas>

                <OffCanvas
                    setOpen={setEditStudentSchoolNameOffCanvas}
                    isOpen={editStudentSchoolNameOffCanvas}
                    titleId='editStudentSchoolNameOffCanvas'
                    isBodyScroll tag="form" noValidate onSubmit={studentSchoolEditForm.handleSubmit}>
                    <OffCanvasHeader setOpen={setEditStudentSchoolNameOffCanvas} onClick={closeAndReset}>
                        <OffCanvasTitle id='editStudentSchoolNameOffCanvas'>Update School Name</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className='col-12'>
                            <FormGroup id='schoolName' label='School Name'>
                                <Input placeholder='School Name' onChange={studentSchoolEditForm.handleChange}
                                    value={studentSchoolEditForm.values.schoolName}
                                    onBlur={studentSchoolEditForm.handleBlur}
                                    isValid={studentSchoolEditForm.isValid}
                                    isTouched={studentSchoolEditForm.touched.schoolName}
                                    invalidFeedback={studentSchoolEditForm.errors.schoolName} />
                            </FormGroup>
                        </div>
                    </OffCanvasBody>

                    <div className='row'>
                        <div className="d-flex justify-content-center position-absolute top-65 start-50 translate-middle">
                            <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteStudentSchool} />
                        </div>
                        {deleteAccess ?
                            <div className='col-6 position-absolute bottom-0 start-0 p-3'>
                                <Button color='danger' className='w-100' icon="Delete" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                                    Delete
                                </Button>
                            </div>
                            : null
                        }
                        <div className={deleteAccess ? 'col-6 position-absolute bottom-0 end-0 p-3' : 'col-12 position-absolute bottom-0 start-0 p-3'}>
                            <Button color='info' icon="Save" className='w-100' type="submit"
                                isDisable={!studentSchoolEditForm.isValid && !!studentSchoolEditForm.submitCount}> update</Button>
                        </div>
                    </div>
                </OffCanvas>

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </PageWrapper>
        </>
    )
} export default StudentSchoolName;