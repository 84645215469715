import { useEffect, useState } from "react";
import CommonDesc from "../../../common/other/CommonDesc";
import Page from "../../../layout/Page/Page";
import { TABS } from "../../../pages/presentation/dashboard/common/helper";
import Avatar from "../../Avatar";
import Button from "../../bootstrap/Button";
import Card, { CardBody, CardFooter, CardFooterLeft, CardFooterRight, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Select from "../../bootstrap/forms/Select";
import Input from "../../bootstrap/forms/Input";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import { getAcademicPeriod, getBatchMasterDetails, getSemester } from "../../../services/master.service";
import { toasts } from "../../../services/toast.service";
import SearchableSelect from "../../../common/components/SearchableSelect";
import { getStudentDetailsByAcademicPeriodId, getStudentDetailsByYear } from "../../../services/sms.service";
import { getStaffListByUserTypeId } from "../../../services/staff.service";

type TTabs = 'Student General Message' | 'Staff General Message' | 'Holiday General Message';

interface ITabs {
    [key: string]: TTabs;
}

function GeneralMessage() {

    useEffect(() => {
        getBatchMaster();
        getAcademicPeriodList();
    }, [])

    const TABS: ITabs = {
        ACCOUNT_DETAIL: 'Student General Message',
        ADDRESS: 'Staff General Message',
        THIRD: 'Holiday General Message'
    };

    const [activeTab, setActiveTab] = useState<TTabs>(TABS.ACCOUNT_DETAIL);
    const [batchMasterData, setBatchMasterData] = useState<any>([]);
    const [batchMasterId, setBatchMasterId] = useState<any>('');
    const [semesterDetailsId, setSemesterDetailsId] = useState<any>(Number)
    const [semesterDetailsData, setSemesterDetailsData] = useState<any>([])
    const [academicPeriodId, setAcademicPeriodId] = useState<any>('')
    const [academicPeriodData, setAcademicPeriodData] = useState<any>([])

    const [state, setState] = useState(false)

    const [staffTypeIdValue, setStaffTypeIdValue] = useState<any>('')

    const[studentDetailsByAcademicPeriodData,setStudentDetailsByAcademicPeriodData] = useState<any>([])
    const[studentDetailsByYearWiseData,setStudentDetailsByYearWiseData] = useState<any>([])
    const[staffListByUserTypeData,setStaffListByUserTypeData] = useState<any>([])
    const[totalCount, setTotalCount] = useState<any>()

    const selectStaffType = (e: any) => {
        setState(false)
        setTotalCount('')
        let staffTypeIdValue = e
        setStaffTypeIdValue(staffTypeIdValue)
    }

    const selectBatch = (e: any) => {
        setState(false)
        setTotalCount('')
        setSemesterDetailsId('')
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        if (batchMasterId?.value != undefined) {
            getSemesterList(batchMasterId?.value)
        }
    }

    const selectSemester = (e: any) => {
        setState(false)
        setTotalCount('')
        let semesterDetailsId = e
        setSemesterDetailsId(semesterDetailsId)
    }

    function getBatchMaster() {
        getBatchMasterDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchMasterDetails;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setBatchMasterData([{ batchMasterId: "", batchName: response.data.message }]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSemesterList(batchMasterId: number) {
        getSemester(batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.semester;
                    if (data != undefined) {
                        setSemesterDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSemesterDetailsData([{ semesterDetailsId: "", semesterNumber: response.data.message }]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const selectAcademicPeriod = (e: any) => {
        setState(false)
        setTotalCount('')
        let academicPeriodId = e
        setAcademicPeriodId(academicPeriodId)
    }

    function getAcademicPeriodList() {
        getAcademicPeriod(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.academicPeriod;
                    if (data != undefined) {
                        setAcademicPeriodData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setAcademicPeriodData([{ academicPeriodId: "", period: response.data.message }]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function viewHoliday(){
        getStudentDetailsByAcademicPeriod(academicPeriodId?.value)
    }

    function viewStudent(){
        getStudentDetailsByYearWise(batchMasterId?.value,semesterDetailsId?.value)
    }

    function viewStaff(){
        getStaffListByUserType(staffTypeIdValue?.value)
    }

    function getStudentDetailsByAcademicPeriod(academicPeriodId: number) {
        getStudentDetailsByAcademicPeriodId(academicPeriodId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetailsByAcademicPeriodId;
                    if (data != undefined) {
                        setStudentDetailsByAcademicPeriodData(data);
                        setTotalCount(data.length)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStudentDetailsByAcademicPeriodData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentDetailsByYearWise(batchMasterId: number, semesterDetailsId: number) {
        getStudentDetailsByYear(batchMasterId,semesterDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetailsByYear;
                    if (data != undefined) {
                        setStudentDetailsByYearWiseData(data);
                        setTotalCount(data.length)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStudentDetailsByYearWiseData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStaffListByUserType(staffTypeIdValue: number) {
        getStaffListByUserTypeId(staffTypeIdValue,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffListByUserTypeId;
                    if (data != undefined) {
                        setStaffListByUserTypeData(data);
                        setTotalCount(data.length)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStaffListByUserTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function tabEmpty() {
        setState(false)
        setBatchMasterId('')
        setSemesterDetailsId('')
        setAcademicPeriodId('')
        setStaffTypeIdValue('')
        setTotalCount('')
    }

    const staffTypeData = [{ staffTypeId: 0, staffType: 'All' }, { staffTypeId: 7, staffType: 'Teaching Staff' }, { staffTypeId: 8, staffType: 'Non-Teaching Staff' }, { staffTypeId: 9, staffType: 'Driver' }]

    return (
        <PageWrapper title="SMS">
            <Page container='fluid'>
                <div className='row h-100'>
                    <div className='col-xxl-3 col-xl-4 col-lg-6'>
                        <Card stretch>
                            <CardHeader>
                                <CardLabel icon='Person' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>
                                        Message Admin
                                    </CardTitle>

                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className='row g-3'>
                                    <div className='col-12'>
                                        <Button
                                            icon='Contacts'
                                            color='info'
                                            className='w-100 p-3'
                                            isLight={TABS.ACCOUNT_DETAIL !== activeTab}
                                            onClick={() => { setActiveTab(TABS.ACCOUNT_DETAIL); tabEmpty() }}>
                                            {TABS.ACCOUNT_DETAIL}
                                        </Button>
                                    </div>
                                    <div className='col-12'>
                                        <Button
                                            icon='Place'
                                            color='info'
                                            className='w-100 p-3'
                                            isLight={TABS.ADDRESS !== activeTab}
                                            onClick={() => { setActiveTab(TABS.ADDRESS); tabEmpty() }}>
                                            {TABS.ADDRESS}
                                        </Button>
                                    </div>
                                    <div className='col-12'>
                                        <Button
                                            icon='Place'
                                            color='info'
                                            className='w-100 p-3'
                                            isLight={TABS.THIRD !== activeTab}
                                            onClick={() => { setActiveTab(TABS.THIRD); tabEmpty() }}>
                                            {TABS.THIRD}
                                        </Button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>

                    <div className='col-xxl-9 col-xl-8 col-lg-6'>
                        {TABS.ACCOUNT_DETAIL === activeTab && (
                            <Card stretch>
                                <CardHeader borderSize={1}>
                                    <CardLabel icon='Message' iconColor='info'>
                                        <CardTitle >
                                            GENERAL MESSAGE DETAILS
                                        </CardTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody >
                                    <div className="row g-4">
                                        <div className="col-lg-12">
                                            <label>Select Student's Course and Semester and then Send SMS</label>
                                        </div>
                                        <div className="col-lg-4">
                                            <FormGroup id='batchMasterId' label='Batch' isFloating>
                                                <SearchableSelect
                                                    isFloating
                                                    ariaLabel='Batch'
                                                    onChange={selectBatch}
                                                    value={batchMasterId}
                                                    list={batchMasterData.map((data: any) => ({
                                                        value: data.batchMasterId,
                                                        label: data.batchName,
                                                    }))}
                                                    placeholder="Select Batch"
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                            <FormGroup id='semesterDetailsId' label='Semester' isFloating>
                                                <SearchableSelect
                                                    isFloating
                                                    ariaLabel='Semester'
                                                    placeholder="Select Semester"
                                                    onChange={selectSemester}
                                                    value={semesterDetailsId}
                                                    list={semesterDetailsData.map((data: any) => ({
                                                        value: data.semesterDetailsId,
                                                        label: data.semesterNumber,
                                                    }))}
                                                />
                                            </FormGroup>
                                        </div>
                                        {state ? <>
                                            <div className="col-lg-4 justify-content-center">
                                                <FormGroup id="noOfStudents" label="Number Of Students" isFloating>
                                                    <Input type="text" value={totalCount} disabled />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-12">
                                                <div>
                                                    <strong>Message :</strong>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 align-self-center">
                                                Dear Parents, College Start for
                                            </div>
                                            <div className="col-lg-4 justify-content-center">
                                                <FormGroup id="noOfStudents" label="" isFloating>
                                                    <Input type="text" />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-4 align-self-center">
                                                Buses, will be available.
                                            </div>
                                            <div className="col-lg-4 align-self-center">
                                                For Enq : Phone:
                                            </div>
                                            <div className="col-lg-4 justify-content-center">
                                                <FormGroup id="noOfStudents" label="" isFloating>
                                                    <Input type="text" />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-4 align-self-center">
                                                SUNARTS.
                                            </div>
                                            <div className="col-lg-12 d-flex justify-content-end">
                                                <FormGroup>
                                                    <Button icon='send' color='info' >Send</Button>
                                                </FormGroup>
                                            </div>
                                        </> :
                                            <div className="col-lg-4 justify-content-center">
                                                <FormGroup>
                                                    <Button icon='East' color='info' isDisable={batchMasterId?.value == undefined || semesterDetailsId?.value == undefined} onClick={() => { setState(true);viewStudent() }}>Next</Button>
                                                </FormGroup>
                                            </div>}
                                    </div>
                                </CardBody>

                            </Card>
                        )}
                        {TABS.ADDRESS === activeTab && (
                            <Card stretch>
                                <CardHeader borderSize={1}>
                                    <CardLabel icon='Message' iconColor='info'>
                                        <CardTitle>STAFF GENERAL MESSAGE DETAILS</CardTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody >

                                    <div className='row g-4'>
                                        <div className="col-lg-12">
                                            <label>Select Staff Designation and Send SMS</label>
                                        </div>
                                        <div className='col-lg-6'>
                                            <FormGroup id="staffTypeIdValue" label="Select Staff Type" isFloating>
                                                <SearchableSelect placeholder="Select Staff Type" isFloating
                                                    ariaLabel='Staff Type'
                                                    onChange={selectStaffType}
                                                    value={staffTypeIdValue}
                                                    list={staffTypeData.map((data: any) => (
                                                        { value: data.staffTypeId, label: data.staffType }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div>
                                        {state ? <>
                                            <div className="col-lg-4 justify-content-center">
                                                <FormGroup id="noOfStudents" label="Number Of Staff" isFloating>
                                                    <Input type="text" value={totalCount} disabled />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-12">
                                                <div>
                                                    <strong>Message :</strong>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 align-self-center">
                                                Dear Staffs , Due to
                                            </div>
                                            <div className="col-lg-3 justify-content-center">
                                                <FormGroup id="noOfStudents" label="" isFloating>
                                                    <Input type="text" />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-2 align-self-center">
                                                college
                                            </div>
                                            <div className="col-lg-3 justify-content-center">
                                                <FormGroup id="noOfStudents" label="" isFloating>
                                                    <Input type="text" />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-2 align-self-center">
                                                From Sun college .
                                            </div>
                                            <div className="col-lg-12 d-flex justify-content-end">
                                                <FormGroup>
                                                    <Button icon='send' color='info' >Send</Button>
                                                </FormGroup>
                                            </div>
                                        </> :
                                            <div className="col-lg-4 justify-content-center">
                                                <FormGroup>
                                                    <Button icon='East' color='info' isDisable={staffTypeIdValue?.value == undefined} onClick={() => { setState(true); viewStaff() }}>Next</Button>
                                                </FormGroup>
                                            </div>}
                                    </div>
                                </CardBody>

                            </Card>
                        )}
                        {TABS.THIRD === activeTab && (
                            <Card stretch>
                                <CardHeader borderSize={1}>
                                    <CardLabel icon="Message" iconColor='info'>
                                        <CardTitle tag='div' className='h5'>
                                            HOLIDAY MESSAGE DETAILS
                                        </CardTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody >

                                    <div className='row g-4'>
                                        <div className="col-lg-12">
                                            <label>Select Student's Course and Semester and then Send SMS:</label>
                                        </div>
                                        <div className='col-lg-6'>
                                            <FormGroup id='academicPeriodId' label='Academic Period' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Academic Period' placeholder="Select Academic Period"
                                                    value={academicPeriodId}
                                                    onChange={(e: any) => selectAcademicPeriod(e)}
                                                    list={academicPeriodData.map((data: any) => (
                                                        { value: data.academicPeriodId, label: data.period }
                                                    ))} required />
                                            </FormGroup>
                                        </div>
                                        {state ? <>
                                            <div className="col-lg-4 justify-content-center">
                                                <FormGroup id="noOfStudents" label="Number Of Students" isFloating>
                                                    <Input type="text" value={totalCount} disabled />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-12">
                                                <div>
                                                    <strong>Message :</strong>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 align-self-center">
                                                Dear Parents , College is Declared Holiday on
                                            </div>
                                            <div className="col-lg-3 justify-content-center">
                                                <FormGroup id="noOfStudents" label="" isFloating>
                                                    <Input type="text" />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-2 align-self-center">
                                                due to
                                            </div>
                                            <div className="col-lg-3 justify-content-center">
                                                <FormGroup id="noOfStudents" label="" isFloating>
                                                    <Input type="text" />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-12 align-self-center">
                                                For Enq : Phone : 04175-298027 Mobile : 8825567253 . SUNARTS
                                            </div>
                                            <div className="col-lg-12 d-flex justify-content-end">
                                                <FormGroup>
                                                    <Button icon='send' color='info' >Send</Button>
                                                </FormGroup>
                                            </div>
                                        </> :
                                            <div className="col-lg-4 justify-content-center">
                                                <FormGroup>
                                                    <Button icon='East' color='info' isDisable={academicPeriodId?.value == undefined} onClick={() => { setState(true); viewHoliday() }}>Next</Button>
                                                </FormGroup>
                                            </div>}
                                    </div>
                                </CardBody>

                            </Card>
                        )}
                    </div>
                </div>
            </Page>
        </PageWrapper>
    )
}
export default GeneralMessage