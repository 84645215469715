import classNames from "classnames";
import { useFormik } from "formik";
import { useEffect, useState, useRef, useContext } from "react";
import { useReactToPrint } from "react-to-print";
import useDarkMode from "../../../hooks/useDarkMode";
import useSortableData from "../../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import Page from "../../../layout/Page/Page";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import Icon from "../../icon/Icon";
import Input from "../../bootstrap/forms/Input";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../bootstrap/Dropdown";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import Select from "../../bootstrap/forms/Select";
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../services/ExportService";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../bootstrap/OffCanvas";
import DeleteComponents from "../../../common/components/DeleteComponents";
import AlertService from "../../../services/AlertService";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import { addPeriod, deletePeriod, getAllPeriod, getPeriod, getShift, updatePeriod } from "../../../services/timeTable.service";
import { getColumnsForDataTable, updateColumnsForDataTable, updateFilter } from "../../../services/common.service";
import { getLicenseKey } from "../../../services/application.settings";
import AuthContext from "../../../contexts/authContext";
import { toasts } from "../../../services/toast.service";
import { TableLoader, showLoader } from "../../../services/loader.services";
import NoDataMsg from "../../../common/components/NoDataMsg";
import SearchableSelect from "../../../common/components/SearchableSelect";


function Period() {

    useEffect(() => {
        getPeriodList();
        getAllPeriodList();
        getShiftList();
    }, [])

    const { userAccountId, userTypeId, addAccess, editAccess, deleteAccess } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [periodData, setPeriodData] = useState([]);
    const [allPeriodData, setAllPeriodData] = useState<any>([]);
    const [shiftData, setShiftData] = useState<any>([]);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    const [dataStatus, setDataStatus] = useState(false)
    // ForModal
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(periodData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [addPeriodOffCanvas, setAddPeriodOffCanvas] = useState(false)
    const [editPeriodOffCanvas, setEditPeriodOffCanvas] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('')

    const [periodName, setPeriodName] = useState<any>('');
    const [shift, setShift] = useState<any>('');

    const periodForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            periodName: '',
            shift: '',
            startTime: '',
            endTime: '',
        },
        validate: (values) => {
            const errors: {
                periodName?: string;
                shift?: string;
                startTime?: string;
                endTime?: string
            } = {};
            if (!periodName) {
                errors.periodName = 'Required';
            }
            if (!shift) {
                errors.shift = 'Required';
            }
            if (!values.startTime) {
                errors.startTime = 'Required';
            }
            if (!values.endTime) {
                errors.endTime = 'Required';
            }
            return errors;
        },

        //validateOnChange: false,
        onSubmit: () => { periodSubmit() },
    });

    const periodEditForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            periodName: '',
            shift: '',
            startTime: '',
            endTime: '',
            periodDetailsId: '',
        },
        validate: (values) => {
            const errors: {
                periodName?: string;
                shift?: string;
                startTime?: string;
                endTime?: string;
                periodDetailsId?: string;
            } = {};
            if (!periodName) {
                errors.periodName = 'Required';
            }
            if (!shift) {
                errors.shift = 'Required';
            }
            if (!values.startTime) {
                errors.startTime = 'Required';
            }
            if (!values.endTime) {
                errors.endTime = 'Required';
            }
            if (!values.periodDetailsId) {
                errors.periodDetailsId = 'Required';
            }
            return errors;
        },

        //validateOnChange: false,
        onSubmit: () => { updatePeriodSubmit() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    const selectPeriod = (e: any) => {
        let periodName = e
        setPeriodName(periodName)
    }

    const selectShift = (e: any) => {
        let shift = e
        setShift(shift)
    }

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function getPeriodList() {
        setDataStatus(false)
        getPeriod(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.period;
                    if (data != undefined) {
                        getColumnsForTable('getPeriod', 'get');
                        setPeriodData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        setDataStatus(true)
                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataStatus(true)
                    //toasts(response.data.message, "Error")
                    getColumnsForTable('getPeriod', 'get');
                    setPeriodData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setDataStatus(true)
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function onActivate(i: any) {

        if (i != undefined) {

            let selectedPeriod = i
            setEditPeriodOffCanvas(true)
            setPeriodName({ value: i.periodName, label: i.periodName })
            setShift({ value: i.shift, label: i.shift })
            periodEditForm.setValues({
                periodName: periodName,
                startTime: selectedPeriod.startTime.substring(0, 5),
                endTime: selectedPeriod.endTime.substring(0, 5),
                shift: shift,
                periodDetailsId: selectedPeriod.periodDetailsId,
            })
        }
    }

    function setPeriod() {

        return ({
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
            periodName: periodName?.value,
            shift: shift?.value,
            startTime: periodForm.values.startTime,
            endTime: periodForm.values.endTime
        })
    }

    function setEditPeriod() {
        return ({
            periodDetailsId: periodEditForm.values.periodDetailsId,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
            periodName: periodName?.value,
            shift: shift?.value,
            startTime: periodEditForm.values.startTime,
            endTime: periodEditForm.values.endTime,
            isActive: 1
        })
    }

    function periodSubmit() {
        showLoader(true)
        if (periodForm.isValid) {
            let periodFormPostData = setPeriod();
            addPeriod(periodFormPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset();
                        getPeriodList();

                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (periodForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function updatePeriodSubmit() {
        showLoader(true)
        if (periodEditForm.isValid) {
            let periodFormPostData = setEditPeriod();
            updatePeriod(periodFormPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset();
                        getPeriodList();

                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (periodEditForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function deletePeriodSubmit() {
        showLoader(true)
        // if (periodEditForm.isValid) {
        let periodFormPostData = setEditPeriod();
        deletePeriod(periodFormPostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "success" });
                    setIsOpen(true);
                    closeAndReset();
                    getPeriodList();

                }
                else if (data.success == false) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "error" });
                    setIsOpen(true);
                }
                else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    showLoader(false)
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }
            , (error) => {
                showLoader(false)
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
        // } else if (periodEditForm.isValid == false) {
        //     showLoader(false)
        //     setAlertStatus({ message: 'Please fill all the details!', type: "error" });
        //     setIsOpen(true);
        // }
    }

    function getAllPeriodList() {
        getAllPeriod(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.period;
                    if (response.data.success) {
                        let data = response.data.data.allPeriod;
                        if (data != undefined) {
                            setAllPeriodData(data);
                        } else {
                            toasts("Undefined Data", "Error")
                        }
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setAllPeriodData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getShiftList() {
        getShift(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.shift;
                    if (data != undefined) {
                        setShiftData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setShiftData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getPeriod', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeAndReset() {
        setAddPeriodOffCanvas(false);
        setEditPeriodOffCanvas(false);
        setIsDeleteOpen(false);
        periodForm.resetForm();
        periodEditForm.resetForm();
        setPeriodName('');
        setShift('');
    }

    function resetStateAddNew() {
        periodForm.resetForm();
        periodEditForm.resetForm();
        setPeriodName('');
        setShift('');
    }

    // Excel Configuration
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });



    return (
        <>
            <PageWrapper title={`Period`}>
                <Page container='fluid'>
                    <Card stretch data-tour='list' ref={componentRef}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className={addAccess ? 'col-lg-5' : 'col-lg-6'}>
                                <CardTitle tag='div' className='h5'> Period List</CardTitle>
                            </CardLabel>&nbsp;&nbsp;

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getPeriod', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("Period List", periodData, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(periodData, columnVisibilityData, "Period List")}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("Period List", periodData, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                {addAccess ?
                                    <Button color='primary' icon='Add' isLight onClick={() => { setAddPeriodOffCanvas(true); resetStateAddNew() }}>
                                        Add New
                                    </Button>
                                    : null
                                }
                            </CardActions>
                        </CardHeader>

                        <CardBody className='table-responsive' isScrollable >
                            {!dataStatus ?
                                <TableLoader /> :
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}
                                            {editAccess ?
                                                <th scope='col' className='d-print-none'>
                                                    Action
                                                </th>
                                                : null
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != "" ?
                                            <>
                                                {filteredData.map((rowData: any) => (
                                                    <tr key={rowData.periodDetailsId}>
                                                        <td>{rowData.sno}</td>
                                                        {columnVisibilityData.map((column: any) => (
                                                            (column.isDisplay && rowData[column.keyName]) ?
                                                                <td key={`${rowData.periodDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                        ))}
                                                        {editAccess ?
                                                            <td className="d-print-none" >
                                                                <Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} /></td>
                                                                : null
                                                        }
                                                    </tr>
                                                ))
                                                }</>
                                            :
                                            <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                    </tbody>
                                </table>
                            }
                        </CardBody>
                        <PaginationButtons className="d-print-none" data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                    </Card>

                    <OffCanvas setOpen={setAddPeriodOffCanvas} isOpen={addPeriodOffCanvas} titleId='addPeriodOffCanvas' isBodyScroll tag="form" noValidate onSubmit={periodForm.handleSubmit}>
                        <OffCanvasHeader setOpen={setAddPeriodOffCanvas} onClick={closeAndReset}>
                            <OffCanvasTitle id='addPeriodOffCanvas'>Add Period</OffCanvasTitle>
                        </OffCanvasHeader>
                        <OffCanvasBody>
                            <div className="row g-4">
                                <div className='col-12'>
                                    <FormGroup id='shift' label='Shift ' >
                                        <SearchableSelect ariaLabel='Shift' onChange={(e: any) => selectShift(e)} value={shift} onBlur={periodForm.handleBlur} isValid={periodForm.isValid} isTouched={periodForm.touched.shift} invalidFeedback={periodForm.errors.shift} list={shiftData.map((data: any) => ({ value: data.shift, label: data.shift }))} placeholder="Select Shift"
                                        />
                                    </FormGroup>
                                </div>

                                <div className='col-12'>
                                    <FormGroup id='periodName' label='Period Name' >
                                        <SearchableSelect ariaLabel='Period Name' onChange={(e: any) => selectPeriod(e)} value={periodName} onBlur={periodForm.handleBlur} isValid={periodForm.isValid} isTouched={periodForm.touched.periodName} invalidFeedback={periodForm.errors.periodName}
                                            list={allPeriodData.map((data: any) => ({ value: data.periodName, label: data.periodName }))} placeholder="Select Period Name" />
                                    </FormGroup>
                                </div>

                                <div className='col-12 mt-3'>
                                    <FormGroup id='startTime' label='Start Time'>
                                        <Input onChange={periodForm.handleChange} value={periodForm.values.startTime} onBlur={periodForm.handleBlur} isValid={periodForm.isValid} isTouched={periodForm.touched.startTime} invalidFeedback={periodForm.errors.startTime} type='time' />
                                    </FormGroup>
                                </div>

                                <div className='col-12 mt-3'>
                                    <FormGroup id='endTime' label='End Time'>
                                        <Input onChange={periodForm.handleChange} value={periodForm.values.endTime} onBlur={periodForm.handleBlur} isValid={periodForm.isValid} isTouched={periodForm.touched.endTime} invalidFeedback={periodForm.errors.endTime} type='time' />
                                    </FormGroup>
                                </div>
                            </div>
                        </OffCanvasBody>
                        <div className='row m-0'>
                            <div className='col-12 p-3'>
                                <Button color='info' icon="Save" className='w-100' type="submit" isDisable={!periodForm.isValid && !!periodForm.submitCount}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </OffCanvas>

                    <OffCanvas setOpen={setEditPeriodOffCanvas} isOpen={editPeriodOffCanvas} titleId='editPeriodOffCanvas' isBodyScroll tag="form" noValidate onSubmit={periodEditForm.handleSubmit}>
                        <OffCanvasHeader setOpen={setEditPeriodOffCanvas} onClick={closeAndReset}>
                            <OffCanvasTitle id='editPeriodOffCanvas'>Update Period</OffCanvasTitle>
                        </OffCanvasHeader>
                        <OffCanvasBody>
                            <div className="row g-4">
                                <div className='col-12'>
                                    <FormGroup id='shift' label='Shift' >
                                        <SearchableSelect ariaLabel='Shift' onChange={(e: any) => selectShift(e)} value={shift} onBlur={periodEditForm.handleBlur} isValid={periodEditForm.isValid} isTouched={periodEditForm.touched.shift} invalidFeedback={periodEditForm.errors.shift} list={shiftData.map((data: any) => ({ value: data.shift, label: data.shift }))} placeholder="Select Shift"
                                        />
                                    </FormGroup>
                                </div>

                                <div className='col-12'>
                                    <FormGroup id='periodName' label='Period Name'>
                                        <SearchableSelect ariaLabel='Period Name' onChange={(e: any) => selectPeriod(e)} value={periodName} onBlur={periodEditForm.handleBlur} isValid={periodEditForm.isValid} isTouched={periodEditForm.touched.periodName} invalidFeedback={periodEditForm.errors.periodName}
                                            list={allPeriodData.map((data: any) => ({ value: data.periodName, label: data.periodName }))} placeholder="Select Period Name" />
                                    </FormGroup>
                                </div>

                                <div className='col-12 mt-3'>
                                    <FormGroup id='startTime' label='Start Time' >
                                        <Input onChange={periodEditForm.handleChange}
                                            value={periodEditForm.values.startTime}
                                            onBlur={periodEditForm.handleBlur}
                                            isValid={periodEditForm.isValid}
                                            isTouched={periodEditForm.touched.startTime}
                                            invalidFeedback={periodEditForm.errors.startTime}
                                            type='time' />
                                    </FormGroup>

                                </div>


                                <div className='col-12 mt-3'>
                                    <FormGroup id='endTime' label='End Time' >
                                        <Input onChange={periodEditForm.handleChange}
                                            value={periodEditForm.values.endTime}
                                            onBlur={periodEditForm.handleBlur}
                                            isValid={periodEditForm.isValid}
                                            isTouched={periodEditForm.touched.endTime}
                                            invalidFeedback={periodEditForm.errors.endTime}
                                            type='time' />
                                    </FormGroup>
                                </div>
                            </div>
                        </OffCanvasBody>
                        <div className='row'>
                            <div className="d-flex justify-content-center position-absolute top-65 start-50 translate-middle">
                                <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deletePeriodSubmit} />
                            </div>
                            {deleteAccess?
                            <div className='col-6 position-absolute bottom-0 start-0 p-3'>
                                <Button color='danger' className='w-100' icon="Delete" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                                    Delete
                                </Button>
                            </div>
                            : null
                            }
                            <div className={deleteAccess ?'col-6 position-absolute bottom-0 end-0 p-3' : 'col-12 position-absolute bottom-0 start-0 p-3'}>
                                <Button color='info' icon="Save" className='w-100' type="submit" isDisable={!periodEditForm.isValid && !!periodEditForm.submitCount}> Update</Button>
                            </div>
                        </div>
                    </OffCanvas>
                </Page>
                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </PageWrapper>
        </>
    )
} export default Period;