import React, { useContext, useEffect, useRef, useState } from 'react'
import Button from '../../bootstrap/Button';
import Page from '../../../layout/Page/Page';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Select from '../../bootstrap/forms/Select';
import { toasts } from '../../../services/toast.service';
import Checks from '../../bootstrap/forms/Checks';
import Collapse from '../../bootstrap/Collapse';
import AlertService from '../../../services/AlertService';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { getUserType } from '../../../services/common.service';
import { assignScreenForUserType, getScreenListByUserTypeId } from '../../../services/screen.service';
import AuthContext from '../../../contexts/authContext';
import { getLicenseKey } from '../../../services/application.settings';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import useDarkMode from '../../../hooks/useDarkMode';
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import { showLoader } from '../../../services/loader.services';
import SearchableSelect from '../../../common/components/SearchableSelect';

const AssignScreensForUserType = () => {

    useEffect(() => {
        getUserTypeList()
    }, [])

    const { userAccountId } = useContext(AuthContext);
    const [userTypeId, setUserTypeId] = useState<any>('')
    const [userTypeData, setUserTypeData] = useState<any>([])
    const [assignScreenData, setAssignScreenData] = useState([])
    const [checkArray, setCheckArray] = useState<any>([])
    const [data, setData] = useState<any>([])

    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const componentRef = useRef(null);
    const [isOpenListCard, setIsOpenListCard] = useState(true);
    const [dataStatus, setDataStatus] = useState(false);

    const [isLoader, setIsLoader] = useState(false)
    const { darkModeStatus } = useDarkMode();

    const selectUserType = (e: any) => {
        setDataStatus(false)
        setIsOpenListCard(true)
        setUserTypeId(e)
    }

    const viewScreenList = () => {
        setIsLoader(true);
        setDataStatus(false)
        setCheckArray([])
        if (userTypeId?.value != undefined) {
            getScreenList(userTypeId?.value)
        }
    }

    function onSelect(isChecked: any, screenId: any, addAccess: any, editAccess: any, deleteAccess: any) {

        assignScreenData.forEach((screenGroupMain: any) => {

            let screenGroupMainData = screenGroupMain.screenGroup

            screenGroupMainData.forEach((screenGroup: any) => {

                let screenGroupData = screenGroup.screens

                const newData: any = [];

                screenGroupData.forEach((item: any) => {

                    if (item.screenId == screenId) {

                        if (isChecked == true) {
                            item.isChecked = isChecked
                            item.addAccess = addAccess
                            item.editAccess = editAccess
                            item.deleteAccess = deleteAccess

                            updateScreenAccess(screenId, addAccess, editAccess, deleteAccess)

                        } else {
                            item.isChecked = false
                            item.addAccess = false
                            item.editAccess = false
                            item.deleteAccess = false

                            let unCheckArrayId = checkArray.indexOf(Number(screenId))
                            checkArray.splice(unCheckArrayId, 1);
                        }
                    }
                    newData.push(item)
                })
                setData(newData)
            })
            generateMenu(assignScreenData)
        });


        // if (event.target.checked == true) {
        //     checkArray.push(screenId);
        // }
        // else if (event.target.checked == false) {
        //     let unCheckArrayId = checkArray.indexOf(Number(screenId))
        //     checkArray.splice(unCheckArrayId, 1);
        // }
    }


    function selectAllScreenGroupMain(allScreens: any, e: any) {
        if (e == true) {
            let screenGroupMainData = allScreens.screenGroup

            screenGroupMainData.forEach((screenGroup: any) => {

                let screenGroupData = screenGroup.screens

                const newData: any = [];

                screenGroupData.forEach((item: any) => {

                    if (item.screenId == item.screenId) {
                        item.isChecked = true
                        screenGroup.isScreenGroupChecked = true
                        allScreens.isScreenMainGroupChecked = true
                    }
                    newData.push(item.screenId)
                    updateScreenAccess(item.screenId, item.addAccess, item.editAccess, item.deleteAccess)
                })
                setData(newData)
            })
        } else {
            let screenGroupMainData = allScreens.screenGroup

            screenGroupMainData.forEach((screenGroup: any) => {

                let screenGroupData = screenGroup.screens

                const newData: any = [];

                screenGroupData.forEach((item: any) => {

                    if (item.screenId == item.screenId) {
                        item.isChecked = false
                        screenGroup.isScreenGroupChecked = false
                        allScreens.isScreenMainGroupChecked = false
                        item.addAccess = false
                        item.editAccess = false
                        item.deleteAccess = false
                    }
                    newData.push(item.screenId)
                    let unCheckArrayId = checkArray.indexOf(Number(item.screenId))
                    checkArray.splice(unCheckArrayId, 1);
                })
                setData(newData)
            })
        }
    }

    function selectAllScreenGroup(screenGroup: any, e: any) {

        if (e == true) {
            let screenGroupData = screenGroup.screens

            const newData: any = [];

            screenGroupData.forEach((item: any) => {

                if (item.screenId == item.screenId) {
                    item.isChecked = true
                    screenGroup.isScreenGroupChecked = true
                }
                newData.push(item)
                updateScreenAccess(item.screenId, item.addAccess, item.editAccess, item.deleteAccess)
            })

            setData(newData)
            generateMenu(assignScreenData)

        } else {
            let screenGroupData = screenGroup.screens

            const newData: any = [];

            screenGroupData.forEach((item: any) => {

                if (item.screenId == item.screenId) {
                    item.isChecked = false
                    screenGroup.isScreenGroupChecked = false
                    item.addAccess = false
                    item.editAccess = false
                    item.deleteAccess = false
                }
                newData.push(item)
                let unCheckArrayId = checkArray.indexOf(Number(item.screenId))
                checkArray.splice(unCheckArrayId, 1);
            })
            setData(newData)
            generateMenu(assignScreenData)
        }
    }

    function setScreenData() {
        return {
            userTypeId: userTypeId?.value,
            userAccountId: userAccountId,
            screenId: checkArray,
            licenseKey: getLicenseKey
        }
    }

    function assignScreenForUserTypeSubmit() {
        showLoader(true)
        if (checkArray.length > 0) {
            let screenPostData = setScreenData();

            assignScreenForUserType(screenPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        setIsOpenListCard(true);
                        setDataStatus(false);
                        setUserTypeId('');
                        setCheckArray([]);
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (checkArray.length == 0) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function getUserTypeList() {
        getUserType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.userType;
                    if (data != undefined) {
                        setUserTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setUserTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getScreenList(userTypeId: number) {
        getScreenListByUserTypeId(userTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.screenListByUserTypeId;
                    if (data != undefined) {
                        setIsLoader(false);
                        setAssignScreenData(data);
                        setDataStatus(true)
                        setIsOpenListCard(false)
                        generateMenu(data)

                        // Getting Assigned screensId
                        for (let i = 0; i < data.length; i++) {
                            let screenGroupData = data[i].screenGroup;
                            for (let j = 0; j < screenGroupData.length; j++) {
                                let screensData = screenGroupData[j].screens;
                                for (let k = 0; k < screensData.length; k++) {
                                    if (screensData[k].isChecked == true) {
                                        const obj = {
                                            screenId: screensData[k].screenId,
                                            addAccess: screensData[k].addAccess,
                                            editAccess: screensData[k].editAccess,
                                            deleteAccess: screensData[k].deleteAccess
                                        }
                                        checkArray.push(obj);
                                    }
                                }
                            }
                        }
                        setCheckArray(checkArray)                        
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function generateMenu(screenData: any) {
        for (let i = 0; i < screenData.length; i++) {

            let screenGroupMain = screenData[i]
            let screenGroupMainData = screenData


            for (let j = 0; j < screenGroupMain.screenGroup.length; j++) {
                let screenGroup = screenGroupMain.screenGroup[j]
                let screenGroupData = screenGroupMain.screenGroup

                for (let k = 0; k < screenGroup.screens.length; k++) {

                    let screen = screenGroup.screens
                    let checkedArray = screen.filter((item: any) => item.isChecked).length
                    let allScreensCount = screenGroup.screens.length

                    if (checkedArray == allScreensCount) {
                        const newData: any = [];
                        screenGroupData.forEach((item: any) => {
                            if (item.screenGroupId == screenGroup.screenGroupId) {
                                item.isScreenGroupChecked = true
                            }
                            newData.push(item)
                        })
                        setData(newData)

                    } else {
                        const newData: any = [];
                        screenGroupData.forEach((item: any) => {
                            if (item.screenGroupId == screenGroup.screenGroupId) {
                                item.isScreenGroupChecked = false
                            }
                            newData.push(item)
                        })
                        setData(newData)
                    }

                    let checkedArrayForScreenGroup = screenGroupData.filter((item: any) => item.isScreenGroupChecked).length
                    let allScreensCountForScreenGroup = screenGroupMain.screenGroup.length

                    if (checkedArrayForScreenGroup == allScreensCountForScreenGroup) {
                        const screenData: any = [];
                        screenGroupMainData.forEach((item: any) => {
                            if (item.screenGroupMainId == screenGroupMain.screenGroupMainId) {
                                item.isScreenMainGroupChecked = true
                            }
                            screenData.push(item)
                        })
                        setData(screenData)
                    } else {
                        const screenData: any = [];
                        screenGroupMainData.forEach((item: any) => {
                            if (item.screenGroupMainId == screenGroupMain.screenGroupMainId) {
                                item.isScreenMainGroupChecked = false
                            }
                            screenData.push(item)
                        })
                        setData(screenData)
                    }
                }
            }
        }
    }

    const updateScreenAccess = (screenId: any, addAccess: any, editAccess: any, deleteAccess: any) => {

        const newData: any = [];

        if (checkArray.length == 0) {
            const obj = {
                screenId: screenId,
                addAccess: addAccess,
                editAccess: editAccess,
                deleteAccess: deleteAccess,
            };
            checkArray.push(obj);
        } else {
            let selectedItem: any;
            checkArray.forEach((item: any, index: any) => {
                if (item['screenId'] == screenId) {
                    selectedItem = item
                    selectedItem['screenId'] = screenId
                    selectedItem['addAccess'] = addAccess
                    selectedItem['editAccess'] = editAccess
                    selectedItem['deleteAccess'] = deleteAccess
                }
            });
            if (selectedItem != undefined) {
                selectedItem['screenId'] = screenId
                selectedItem['addAccess'] = addAccess
                selectedItem['editAccess'] = editAccess
                selectedItem['deleteAccess'] = deleteAccess
            } else {
                var obj
                obj = {
                    screenId: screenId,
                    addAccess: addAccess,
                    editAccess: editAccess,
                    deleteAccess: deleteAccess,
                };
                checkArray.push(obj);
            }
        }
    };

    return (
        <PageWrapper title='Assign Screens For User Type'>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle tag='div' className='h5'>
                                    Screen Assign For User Type
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row'>
                                <div className='col-3'>
                                    <FormGroup id='academicPeriodId' label='Select Staff Type' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Select Staff Type'
                                            onChange={selectUserType}
                                            value={userTypeId}
                                            list={userTypeData.map((data: any) => (
                                                { value: data.userTypeId, label: data.userType }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>
                                {userTypeId?.value ?
                                    <div className='col-3 mt-2'>
                                        <Button
                                            icon='ArrowDownward'
                                            color='primary'
                                            onClick={viewScreenList}
                                        >
                                            View
                                        </Button>
                                    </div> : null
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                {dataStatus ?
                    <>
                        <Card ref={componentRef}>
                            <CardHeader borderSize={1}>
                                <div className='d-flex col-5 justify-content-start'>
                                    <CardLabel icon='List' iconColor='info'>
                                        <CardTitle tag='div' className='h5'>Assigned Screens For User Type</CardTitle>
                                    </CardLabel>
                                </div>
                            </CardHeader>

                            <CardBody>
                                <div className='row'>
                                    {assignScreenData?.map((allScreens: any) => (
                                        <Card stretch shadow='none' key={allScreens.screenGroupMainId}>
                                            <CardHeader borderSize={1}>
                                                <CardLabel className="col-lg-12">
                                                    <CardTitle tag='div' className='h5 text-danger'>
                                                        <Checks className='border border-danger mt-1'
                                                            label={allScreens.screenGroupMainDisplayName}
                                                            onChange={(e: any) => selectAllScreenGroupMain(allScreens, e.target.checked)}
                                                            checked={allScreens.isScreenMainGroupChecked} />
                                                    </CardTitle>
                                                </CardLabel>
                                            </CardHeader>
                                            <CardBody>
                                                <div className="row">
                                                    {allScreens?.screenGroup.map((screensGroup: any) => {

                                                        return (
                                                            <div className='col-6 mt-2' key={screensGroup.screenGroupId}>
                                                                <Card stretch>
                                                                    <CardHeader borderSize={1}>
                                                                        <CardLabel className="col-lg-12">
                                                                            <CardTitle tag='div' className='h5 text-info'>
                                                                                <Checks className='border border-info mt-1' label={screensGroup.screenGroupDisplayName}
                                                                                    onChange={(e: any) => selectAllScreenGroup(screensGroup, e.target.checked)}
                                                                                    checked={screensGroup.isScreenGroupChecked}
                                                                                />
                                                                            </CardTitle>
                                                                        </CardLabel>
                                                                    </CardHeader>
                                                                    <CardBody>

                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <table className='table table-modern text-nowrap'>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope='col'>
                                                                                                Screen
                                                                                            </th>
                                                                                            <th scope='col'>
                                                                                                Add
                                                                                            </th>
                                                                                            <th scope='col'>
                                                                                                Edit
                                                                                            </th>
                                                                                            <th scope='col'>
                                                                                                Delete
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {screensGroup?.screens.map((screens: any) => {
                                                                                            return (
                                                                                                <tr key={screens.screenId}>
                                                                                                    <td>
                                                                                                        <Checks
                                                                                                            className='border border-primary'
                                                                                                            label={screens.displayName}
                                                                                                            onChange={(e: any) => onSelect(e.target.checked, screens.screenId, screens.addAccess, screens.editAccess, screens.deleteAccess)}
                                                                                                            checked={screens.isChecked}
                                                                                                        />
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div className='d-flex justify-content-center'>
                                                                                                            <Checks
                                                                                                                className='border border-info'
                                                                                                                // label={" "}
                                                                                                                onChange={(e: any) => onSelect(screens.isChecked, screens.screenId, e.target.checked, screens.editAccess, screens.deleteAccess)}
                                                                                                                checked={screens.addAccess}
                                                                                                                disabled={!screens.isChecked}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div className='d-flex justify-content-center'>
                                                                                                            <Checks
                                                                                                                className='border border-info'
                                                                                                                onChange={(e: any) => onSelect(screens.isChecked, screens.screenId, screens.addAccess, e.target.checked, screens.deleteAccess)}
                                                                                                                checked={screens.editAccess}
                                                                                                                disabled={!screens.isChecked}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div className='d-flex justify-content-center'>
                                                                                                            <Checks
                                                                                                                className='border border-info'
                                                                                                                onChange={(e: any) => onSelect(screens.isChecked, screens.screenId, screens.addAccess, screens.editAccess, e.target.checked)}
                                                                                                                checked={screens.deleteAccess}
                                                                                                                disabled={!screens.isChecked}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                        })
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </CardBody>
                                        </Card>
                                    ))}
                                </div >
                            </CardBody>
                        </Card>
                        <SubHeader>
                            <SubHeaderLeft><></></SubHeaderLeft>
                            <SubHeaderRight>
                                <SubheaderSeparator />
                                <Button icon='Save' color='primary' isDisable={checkArray.length == 0} onClick={assignScreenForUserTypeSubmit}>Submit</Button>
                            </SubHeaderRight>
                        </SubHeader>
                    </> : null
                }

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </Page>
        </PageWrapper>
    );
}

export default AssignScreensForUserType