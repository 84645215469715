import { useFormik } from "formik";
import { useEffect, useState, useRef, useContext } from "react";
import { useReactToPrint } from "react-to-print";
import useDarkMode from "../../../hooks/useDarkMode";
import useSortableData from "../../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import { toasts } from "../../../services/toast.service";
import Page from "../../../layout/Page/Page";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import Icon from "../../icon/Icon";
import Input from "../../bootstrap/forms/Input";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../bootstrap/Dropdown";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../services/ExportService";
import Select from "../../bootstrap/forms/Select";
import OffCanvas, { OffCanvasHeader, OffCanvasTitle, OffCanvasBody } from "../../bootstrap/OffCanvas";
import InputGroup, { InputGroupText } from "../../bootstrap/forms/InputGroup";
import AlertService from "../../../services/AlertService";
import DeleteComponents from "../../../common/components/DeleteComponents";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import { getProfileForStaff, getStaffListByDepartmentId, getStaffListByUserTypeId } from "../../../services/staff.service";
import { getDepartmentList } from "../../../services/master.service";
import { getColumnsForDataTable, getUserType, updateColumnsForDataTable, updateFilter } from "../../../services/common.service";
import { addStaffLoginDetails, deleteStaffLoginDetails, getStaffLoginDetails, updateStaffLoginDetails } from "../../../services/login.service";
import AuthContext from "../../../contexts/authContext";
import { getLicenseKey } from "../../../services/application.settings";
import { TableLoader, showLoader } from "../../../services/loader.services";
import NoDataMsg from "../../../common/components/NoDataMsg";
import SearchableSelect from "../../../common/components/SearchableSelect";


function ManagerStaffLogins() {

    useEffect(() => {
        getDepartment();
        getUserTypeList();
        getStaffLoginList();
    }, [])

    const { userAccountId, userTypeId, addAccess, editAccess, deleteAccess } = useContext(AuthContext);
    const [staffLoginData, setStaffLoginData] = useState([]);
    const [departmentData, setDepartmentData] = useState<any>([])
    const [userTypeData, setUserTypeData] = useState<any>([])
    const [staffData, setStaffData] = useState<any>([])
    const [staffDataByDepartment, setStaffDataByDepartment] = useState<any>([])
    const [staffProfileData, setStaffProfileData] = useState(Object)
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)

    const [staffUserTypeId, setStaffUserTypeId] = useState<any>('')
    const [departmentId, setDepartmentId] = useState<any>('')
    const [staffDetailsId, setStaffDetailsId] = useState<any>('')
    const [dataStatus, setDataStatus] = useState(false)

    // ForModal And Tables States
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(staffLoginData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [addStaffLoginOffCanvas, setAddStaffLoginOffCanvas] = useState(false)
    const [editStaffLoginOffCanvas, setEditStaffLoginOffCanvas] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)
    const [passwordVisible, isPasswordVisible] = useState(false)
    const [isTeachingStaff, setIsTeachingStaff] = useState(false)

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [noDataMsg, setNoDataMsg] = useState('')

    const addStaffLoginForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            staffUserTypeId: '',
            departmentId: '',
            staffDetailsId: '',
            password: '',
            confirmPassword: '',
            email: '',
        },
        validate: (values) => {
            const errors: {
                staffUserTypeId?: string;
                departmentId?: string;
                staffDetailsId?: string;
                password?: string;
                confirmPassword?: string;
                email?: string;
            } = {};
            if (!staffUserTypeId) {
                errors.staffUserTypeId = 'Required';
            }
            if (staffUserTypeId == 7) {
                if (!departmentId) {
                    errors.departmentId = 'Required';
                }
            }
            if (!staffDetailsId) {
                errors.staffDetailsId = 'Required';
            }
            if (!values.email) {
                errors.email = 'Required';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                errors.email = 'Invalid email address';
            }
            if (!values.password) {
                errors.password = 'Please provide a valid password.';
            } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/i.test(values.password)) {
                errors.password =
                    'Password must be 8-16 characters, containing at least 1 special character, 1 capital letter, 1 small letter, and 1 number.';
            }
            if (!values.confirmPassword) {
                errors.confirmPassword = 'Please provide a valid password.';
            } else if (values.password !== values.confirmPassword) {
                errors.confirmPassword = 'Passwords do not match.';
            }

            return errors;
        },
        validateOnChange: true,
        onSubmit: () => { addStaffLoginSubmit() },
    });

    const editStaffLoginForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            staffLoginId: '',
            email: '',
            password: '',
            confirmPassword: '',
        },
        validate: (values) => {
            const errors: {
                password?: string;
                confirmPassword?: string;
                email?: string;
            } = {};
            if (!values.email) {
                errors.email = 'Required';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                errors.email = 'Invalid email address';
            }
            if (!values.password) {
                errors.password = 'Please provide a valid password.';
            } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/i.test(values.password)) {
                errors.password =
                    'Password must be 8-16 characters, containing at least 1 special character, 1 capital letter, 1 small letter, and 1 number.';
            }
            if (!values.confirmPassword) {
                errors.confirmPassword = 'Please provide a valid password.';
            } else if (values.password !== values.confirmPassword) {
                errors.confirmPassword = 'Passwords do not match.';
            }

            return errors;
        },
        validateOnChange: true,
        onSubmit: () => { updateStaffLoginSubmit() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function onActivate(i: any) {
        if (i != undefined) {

            let selectedStaffLogin = i
            setEditStaffLoginOffCanvas(true)

            editStaffLoginForm.setValues({
                staffLoginId: selectedStaffLogin.staffLoginId,
                email: selectedStaffLogin.email,
                password: "",
                confirmPassword: ""
            });
        }
    }

    const selectDepartment = (e: any) => {
        setStaffData([])
        setStaffDetailsId('')
        let departmentId = e
        setDepartmentId(departmentId)
        if (departmentId?.value != undefined) {
            getStaffList(departmentId?.value)
        }
    }

    const selectStaff = (e: any) => {
        let staffDetailsId = e
        setStaffDetailsId(staffDetailsId)
        if (staffDetailsId?.value != undefined) {
            getStaffProfile(staffDetailsId?.value)
        }
    }

    const handleMouseDown = () => {
        isPasswordVisible(true);
    }

    const handleMouseUp = () => {
        isPasswordVisible(false);
    }

    function getUserTypeId(e: any) {
        setDepartmentId('')
        setStaffDetailsId('')
        setStaffData([])
        let staffUserTypeId = e
        setStaffUserTypeId(staffUserTypeId)
        if (staffUserTypeId?.value != undefined) {
            getStaffListByUserType(staffUserTypeId?.value)
            if (staffUserTypeId?.value == 7) {
                setIsTeachingStaff(true)
            } else {
                setIsTeachingStaff(false)
            }
        }
    }

    function getStaffLoginList() {
        setDataStatus(false)
        getStaffLoginDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffLoginDetails;
                    if (data != undefined) {
                        getColumnsForTable('getStaffLoginDetails', 'get');
                        setStaffLoginData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        setDataStatus(true)
                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataStatus(true)
                    //toasts(response.data.message, "Error")
                    getColumnsForTable('getStaffLoginDetails', 'get');
                    setStaffLoginData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setDataStatus(true)
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function getDepartment() {
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.department;
                    if (data != undefined) {
                        setDepartmentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setDepartmentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getUserTypeList() {
        getUserType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.userType;
                    if (data != undefined) {
                        setUserTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setUserTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStaffList(departmentId: any) {
        getStaffListByDepartmentId(departmentId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffListByDepartmentId;
                    if (data != undefined) {
                        setStaffDataByDepartment(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStaffDataByDepartment([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStaffListByUserType(userTypeId: any) {
        getStaffListByUserTypeId(userTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffListByUserTypeId;
                    if (data != undefined) {
                        setStaffData(data);

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStaffData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStaffProfile(staffDetailsId: number) {
        getProfileForStaff(staffDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.profileForStaff;
                    if (data != undefined) {
                        setStaffProfileData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setAddStaffLogin() {

        return ({
            staffDetailsId: staffDetailsId?.value,
            userTypeId: staffUserTypeId?.value,
            email: addStaffLoginForm.values.email,
            password: addStaffLoginForm.values.password,
            mobile: staffProfileData[0].contactNumber,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function setEditStaffLogin() {
        return ({
            staffLoginId: editStaffLoginForm.values.staffLoginId,
            email: editStaffLoginForm.values.email,
            password: editStaffLoginForm.values.password,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
        })
    }

    function setDeleteStaffLogin() {
        return ({
            staffLoginId: editStaffLoginForm.values.staffLoginId,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
        })
    }

    function addStaffLoginSubmit() {
        showLoader(true)
        if (addStaffLoginForm.isValid) {
            let addStaffLoginPostData = setAddStaffLogin();
            addStaffLoginDetails(addStaffLoginPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getStaffLoginList();
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (addStaffLoginForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function updateStaffLoginSubmit() {
        showLoader(true)
        if (editStaffLoginForm.isValid) {
            let editStaffLoginPostData = setEditStaffLogin();
            updateStaffLoginDetails(editStaffLoginPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getStaffLoginList();
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (editStaffLoginForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function deleteStaffLoginSubmit() {
        showLoader(true)
        // if (editStaffLoginForm.isValid) {
        let editStaffLoginPostData = setDeleteStaffLogin();
        deleteStaffLoginDetails(editStaffLoginPostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "success" });
                    setIsOpen(true);
                    getStaffLoginList();
                    closeAndReset();
                }
                else if (data.success == false) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "error" });
                    setIsOpen(true);
                }
                else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    showLoader(false)
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }
            , (error) => {
                showLoader(false)
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
        // } else if (editStaffLoginForm.isValid == false) {
        //     setAlertStatus({ message: 'Please fill all the details!', type: "error" });
        //     setIsOpen(true);
        // }
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getStaffLoginDetails', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeAndReset() {
        setAddStaffLoginOffCanvas(false);
        setEditStaffLoginOffCanvas(false);
        setIsDeleteOpen(false)
        addStaffLoginForm.resetForm();
        editStaffLoginForm.resetForm();
        resetState();
    }

    function resetState() {
        setStaffUserTypeId('');
        setStaffData([]);
        setStaffProfileData([]);
        setDepartmentId('');
        setStaffDetailsId('');
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <PageWrapper title="Manager User">
            <Page container='fluid'>
                <Card stretch data-tour='list' ref={componentRef}>
                    <CardHeader borderSize={1}>
                        <CardLabel icon='List' iconColor='info' className={addAccess ? "col-lg-5" : "col-lg-6"}>
                            <CardTitle tag='div' className='h5'> Manage User Login</CardTitle>
                        </CardLabel>&nbsp;&nbsp;

                        <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                            <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                            <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                        </ButtonGroup>

                        <CardActions className="d-print-none">
                            <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                <DropdownToggle>
                                    <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                </DropdownToggle>
                                <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                    <DropdownItem>
                                        <div className='container py-2'>
                                            <form className='row g-3'>
                                                <div className='col-12'>
                                                    <FormGroup>
                                                        <h6>Select All</h6>
                                                        <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getStaffLoginDetails', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                    </FormGroup>
                                                </div>
                                                <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                    <FormGroup>
                                                        <h6>Columns</h6>
                                                        <ChecksGroup>
                                                            {allColumnsData.map((i: any) => (
                                                                <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                            ))}
                                                        </ChecksGroup>
                                                    </FormGroup>
                                                </div>
                                            </form>
                                        </div>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <Dropdown isButtonGroup>
                                <DropdownToggle>
                                    <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                </DropdownToggle>
                                <DropdownMenu isAlignmentEnd>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("User List", staffLoginData, columnVisibilityData)}> Excel </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(staffLoginData, columnVisibilityData, "User List")}> PDF
                                        </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("User List", staffLoginData, columnVisibilityData)}> CSV </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            {addAccess ?
                                <Button color='primary' icon='Add' isLight onClick={() => setAddStaffLoginOffCanvas(true)}>
                                    Add New
                                </Button>
                                : null
                            }
                        </CardActions>
                    </CardHeader>

                    <CardBody className='table-responsive' isScrollable >
                        {!dataStatus ?
                            <TableLoader /> :
                            <table className='table table-modern table-hover text-nowrap'>
                                <thead>
                                    <tr>
                                        <th scope='col' onClick={() => requestSort('sno')}
                                            className='cursor-pointer text-decoration-underline'>S.No{' '}
                                            <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                        </th>
                                        {columnVisibilityData.map((column: any) => (
                                            <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                className='cursor-pointer text-decoration-underline'>
                                                {column.columnName}
                                                <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                            </th>
                                        ))}
                                        {editAccess ?
                                            <th scope='col' className='d-print-none'>
                                                Action
                                            </th>
                                            : null
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData != "" ?
                                        <>
                                            {filteredData.map((rowData: any) => (
                                                <tr key={rowData.staffDetailsId}>
                                                    <td>{rowData.sno}</td>
                                                    {columnVisibilityData.map((column: any) => (
                                                        (column.isDisplay && rowData[column.keyName]) ?
                                                            <td key={`${rowData.staffDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                    ))}
                                                    {editAccess ?
                                                        <td className="d-print-none" >
                                                            <Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} /></td>
                                                            : null
                                                    }
                                                </tr>
                                            ))
                                            } </>
                                        :
                                        <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                </tbody>
                            </table>
                        }
                    </CardBody>
                    <PaginationButtons
                        className="d-print-none"
                        data={items}
                        label='items'
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        perPage={perPage}
                        setPerPage={setPerPage}
                    />
                </Card>
            </Page>

            <OffCanvas
                setOpen={setAddStaffLoginOffCanvas}
                isOpen={addStaffLoginOffCanvas}
                titleId='addStaffLoginOffCanvas'
                isBodyScroll
                placement='end'
                tag="form" noValidate onSubmit={addStaffLoginForm.handleSubmit}>
                <OffCanvasHeader setOpen={setAddStaffLoginOffCanvas} onClick={closeAndReset}>
                    <OffCanvasTitle id="addStaffLoginOffCanvas">Existing User Login Creation</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className="row g-4">
                        <div className='col-12'>
                            <FormGroup id='staffUserTypeId' label='Role'>
                                <SearchableSelect onChange={getUserTypeId}
                                    value={staffUserTypeId}
                                    onBlur={addStaffLoginForm.handleBlur}
                                    isValid={addStaffLoginForm.isValid}
                                    isTouched={addStaffLoginForm.touched.staffUserTypeId}
                                    invalidFeedback={addStaffLoginForm.errors.staffUserTypeId}
                                    list={userTypeData.map((data: any) => (
                                        { value: data.userTypeId, label: data.userType }
                                    ))}
                                    placeholder="Select Role" ariaLabel={""} />
                            </FormGroup>
                        </div>
                        {staffUserTypeId?.value == '7' ?
                            <div className='col-12'>
                                <FormGroup id='departmentId' label='Department'>
                                    <SearchableSelect onChange={selectDepartment} value={departmentId}
                                        onBlur={addStaffLoginForm.handleBlur}
                                        isValid={addStaffLoginForm.isValid}
                                        isTouched={addStaffLoginForm.touched.departmentId}
                                        invalidFeedback={addStaffLoginForm.errors.departmentId}
                                        list={departmentData.map((data: any) => (
                                            { value: data.departmentId, label: data.departmentName }
                                        ))}
                                        placeholder="Select Department" ariaLabel={""} />
                                </FormGroup>
                            </div>
                            : null}
                        {isTeachingStaff ? <div className='col-12'>
                            <FormGroup id='staffDetailsId' label='Staff'>
                                <SearchableSelect onChange={selectStaff} value={staffDetailsId}
                                    onBlur={addStaffLoginForm.handleBlur}
                                    isValid={addStaffLoginForm.isValid}
                                    isTouched={addStaffLoginForm.touched.staffDetailsId}
                                    invalidFeedback={addStaffLoginForm.errors.staffDetailsId}
                                    list={staffDataByDepartment.map((data: any) => (
                                        { value: data.staffDetailsId, label: data.staffName }
                                    ))}
                                    placeholder="Select Staff" ariaLabel={""} />
                            </FormGroup>
                        </div> : <div className='col-12'>
                            <FormGroup id='staffDetailsId' label='Staff'>
                                <SearchableSelect onChange={selectStaff} value={staffDetailsId}
                                    onBlur={addStaffLoginForm.handleBlur}
                                    isValid={addStaffLoginForm.isValid}
                                    isTouched={addStaffLoginForm.touched.staffDetailsId}
                                    invalidFeedback={addStaffLoginForm.errors.staffDetailsId}
                                    list={staffData.map((data: any) => (
                                        { value: data.staffDetailsId, label: data.staffName }
                                    ))}
                                    placeholder="Select Staff" ariaLabel={""} />
                            </FormGroup>
                        </div>}


                        <div className='col-12'>
                            <FormGroup id='email' label='Email'>
                                <Input onChange={addStaffLoginForm.handleChange} value={addStaffLoginForm.values.email}
                                    onBlur={addStaffLoginForm.handleBlur}
                                    isValid={addStaffLoginForm.isValid}
                                    isTouched={addStaffLoginForm.touched.email}
                                    invalidFeedback={addStaffLoginForm.errors.email}
                                    placeholder="Email" />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id="password" label='New Password'>
                                <InputGroup>
                                    <Input id='password' onChange={addStaffLoginForm.handleChange} value={addStaffLoginForm.values.password}
                                        onBlur={addStaffLoginForm.handleBlur}
                                        isValid={addStaffLoginForm.isValid}
                                        isTouched={addStaffLoginForm.touched.password}
                                        invalidFeedback={addStaffLoginForm.errors.password}
                                        placeholder="NewPassword"
                                        type={passwordVisible ? 'text' : 'password'}
                                    />
                                    <InputGroupText><Icon icon="RemoveRedEye" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseOut={handleMouseUp} /></InputGroupText>
                                </InputGroup>

                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id="confirmPassword" label='Confirm New Password'>
                                <InputGroup>
                                    <Input id="confirmPassword" onChange={addStaffLoginForm.handleChange} value={addStaffLoginForm.values.confirmPassword}
                                        onBlur={addStaffLoginForm.handleBlur}
                                        isValid={addStaffLoginForm.isValid}
                                        isTouched={addStaffLoginForm.touched.confirmPassword}
                                        invalidFeedback={addStaffLoginForm.errors.confirmPassword}
                                        placeholder="Confirm New Password"
                                        type={passwordVisible ? 'text' : 'password'} />
                                    <InputGroupText><Icon icon="RemoveRedEye" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseOut={handleMouseUp} /></InputGroupText>

                                </InputGroup>
                            </FormGroup>
                        </div>
                    </div>
                </OffCanvasBody>
                <div className='row m-0'>
                    <div className='col-12 p-3'>
                        <Button
                            color='info' icon="Save"
                            className='w-100' type="submit" isDisable={!addStaffLoginForm.isValid && !!addStaffLoginForm.submitCount}>
                            Save
                        </Button>
                    </div>
                </div>
            </OffCanvas>

            <OffCanvas
                setOpen={setEditStaffLoginOffCanvas}
                isOpen={editStaffLoginOffCanvas}
                titleId='editStaffLoginOffCanvas'
                isBodyScroll
                placement='end'
                tag="form" noValidate onSubmit={editStaffLoginForm.handleSubmit}>
                <OffCanvasHeader setOpen={setEditStaffLoginOffCanvas} onClick={closeAndReset}>
                    <OffCanvasTitle id="editStaffLoginOffCanvas">Update Login Details</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className="row g-4">
                        <div className='col-12'>
                            <FormGroup id='email' label='Email'>
                                <Input onChange={editStaffLoginForm.handleChange} value={editStaffLoginForm.values.email} type='text' onBlur={editStaffLoginForm.handleBlur}
                                    isValid={editStaffLoginForm.isValid}
                                    isTouched={editStaffLoginForm.touched.email}
                                    invalidFeedback={editStaffLoginForm.errors.email} required />
                            </FormGroup>
                        </div>

                        <div className='col-12'>
                            <FormGroup id='password' label='New password' >
                                <InputGroup>
                                    <Input id='password' placeholder='New password' autoComplete='new-password'
                                        onChange={editStaffLoginForm.handleChange}
                                        value={editStaffLoginForm.values.password}
                                        onBlur={editStaffLoginForm.handleBlur}

                                        isValid={editStaffLoginForm.isValid}
                                        isTouched={editStaffLoginForm.touched.password}
                                        invalidFeedback={editStaffLoginForm.errors.password}
                                        type={passwordVisible ? 'text' : 'password'}
                                    />
                                    <InputGroupText><Icon icon="RemoveRedEye" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseOut={handleMouseUp} /></InputGroupText>

                                </InputGroup>
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id="confirmPassword" label='Confirm new password'>
                                <InputGroup>
                                    <Input id='confirmPassword' placeholder='Confirm new password' autoComplete='new-password'
                                        onChange={editStaffLoginForm.handleChange}
                                        value={editStaffLoginForm.values.confirmPassword}
                                        onBlur={editStaffLoginForm.handleBlur}

                                        isValid={editStaffLoginForm.isValid}
                                        isTouched={editStaffLoginForm.touched.confirmPassword}
                                        invalidFeedback={editStaffLoginForm.errors.confirmPassword}
                                        type={passwordVisible ? 'text' : 'password'}
                                    />
                                    <InputGroupText><Icon icon="RemoveRedEye" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseOut={handleMouseUp} /></InputGroupText>
                                </InputGroup>
                            </FormGroup>
                        </div>

                    </div>
                </OffCanvasBody>
                <div className='row'>
                    <div className="d-flex justify-content-center position-absolute top-65 start-50 translate-middle">
                        <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteStaffLoginSubmit} />
                    </div>
                    {deleteAccess ?
                    <div className='col-6 position-absolute bottom-0 start-0 p-3'>
                        <Button color='danger' className='w-100' icon="Delete" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                            Delete
                        </Button>
                    </div>
                    : null}
                    <div className={deleteAccess ? 'col-6 position-absolute bottom-0 end-0 p-3':'col-12 position-absolute bottom-0 start-0 p-3'}>
                        <Button color='info' icon="Save" className='w-100' type="submit" isDisable={!editStaffLoginForm.isValid && !!editStaffLoginForm.submitCount}>Update</Button>
                    </div>
                </div>
            </OffCanvas>

            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </PageWrapper>
    )
} export default ManagerStaffLogins;