import dayjs from "dayjs";
import service from "../api/api.services";
import { AxiosResponse } from "axios";
import { toasts } from "./toast.service";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const checkIsWorkingDay = (dateInMilliSec: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `checkIsWorkingDay/${getLicenseKey}/${dateInMilliSec}`,
        response => {
            success(response)
        },
        error => {
            failure("checkIsWorkingDay - " + error.message)
            console.log('error caught in service : checkIsWorkingDay')
        },
    );

export const getStudentAttendanceReport = (dateOfAttendance: number, courseId: string, semesterDetailsId: string, sectionDetailsId: string, periodDetailsId: string, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentAttendanceReportList/${getLicenseKey}/${dateOfAttendance}/${courseId}/${semesterDetailsId}/${sectionDetailsId}/${periodDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentAttendanceReportList - " + error.message)
            console.log('error caught in service : getStudentAttendanceReportList')
        },
    );

export const getStudentAttendanceSummaryReport = (dateOfAttendance: number, courseId: string, batchMasterId: string, semesterDetailsId: string, sectionDetailsId: string, shift: number, periodDetailsId: string, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentAttendanceSummaryReport/${getLicenseKey}/${dateOfAttendance}/${courseId}/${batchMasterId}/${semesterDetailsId}/${sectionDetailsId}/${shift}/${periodDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentAttendanceSummaryReport - " + error.message)
            console.log('error caught in service : getStudentAttendanceSummaryReport')
        },
    );

export const addStudentAttendanceForWeb = (studentAttendancePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStudentAttendanceForWeb`, studentAttendancePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStudentAttendanceForWeb')
        }
    );

export const getStudentLateAttendanceList = (userAccount: any, dateOfAttendance: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentLateAttendanceList/${getLicenseKey}/${userAccount}/${dateOfAttendance}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentLateAttendanceList - " + error.message)
            console.log('error caught in service : getStudentLateAttendanceList')
        },
    );

export const addStudentLateAttendance = (attendancePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStudentLateAttendance`, attendancePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStudentLateAttendance')
        }
    );

export const updateLateAttendance = (attendanceReasonPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateLateAttendance`, attendanceReasonPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateLateAttendance')
        }
    );

export const getLateAttendanceReason = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getLateAttendanceReason/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getLateAttendanceReason - " + error.message)
            console.log('error caught in service : getLateAttendanceReason')
        },
    );

export const addStudentLateAttendanceReason = (attendanceReasonPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStudentLateAttendanceReason`, attendanceReasonPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStudentLateAttendanceReason')
        }
    );

export const updateLateAttendanceReason = (attendanceReasonPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateLateAttendanceReason`, attendanceReasonPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateLateAttendanceReason')
        }
    );

export const deleteLateAttendanceReason = (attendanceReasonPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteLateAttendanceReason`, attendanceReasonPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteLateAttendanceReason')
        }
    );

export const addStaffAttendance = (attendancePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStaffAttendance`, attendancePostData,
        response => {
            success(response)
        },
        error => {
            failure("addStaffAttendance - " + error.message)
            console.log('error caught in service : addStaffAttendance')
        },
    );


export const getStaffAttendanceReportList = (dateOfAttendance: any, userTypeId: any, isReport: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffAttendanceReportList/${getLicenseKey}/${dateOfAttendance}/${userTypeId}/${isReport}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffAttendanceReportList - " + error.message)
            console.log('error caught in service : getStaffAttendanceReportList')
        },
    );
