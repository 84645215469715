import dayjs from "dayjs";
import service from "../api/api.services";
import { AxiosResponse } from "axios";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()


export const getAnnouncementDetails = (userTypeId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,
) => api.get(getAPIURL() + `getAnnouncementDetails/${getLicenseKey}/${userTypeId}`,
    (response) => {
        success(response);
    },
    (error) => {
        failure('getAnnouncementDetails - ' + error.message);
        console.log('error caught in service : getAnnouncementDetails');
    },
);

export const addAnnouncementDetails = (reminderTypePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addAnnouncementDetails`, reminderTypePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addAnnouncementDetails')
        }
    );

export const updateAnnouncementDetails = (updateReminderPost: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateAnnouncementDetails`, updateReminderPost,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateAnnouncementDetails')
        }
    );

export const deleteAnnouncementDetails = (updateReminderPost: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteAnnouncementDetails`, updateReminderPost,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteAnnouncementDetails')
        }
    );

export const getComplaintDetails = (userTypeId: any, staffOrStudentDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getComplaintDetails/${getLicenseKey}/${userTypeId}/${staffOrStudentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getComplaintDetails - " + error.message)
            console.log('error caught in service : getComplaintDetails')
        },
    );

export const getComplaintType = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,
) => api.get(getAPIURL() + `getComplaintType/${getLicenseKey}`,
    (response) => {
        success(response);
    },
    (error) => {
        failure('getComplaintType - ' + error.message);
        console.log('error caught in service : getComplaintType');
    },
);

export const addComplaintDetails = (complaintData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addComplaintDetails`, complaintData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addComplaintDetails')
        }
    );

export const getCircularDetails = (circularDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,
) =>
    api.get(getAPIURL() + `getCircularDetails/${getLicenseKey}/1/${circularDetailsId}`,
        (response) => {
            success(response);
        },
        (error) => {
            failure('getCircularDetails - ' + error.message);
            console.log('error caught in service : getCircularDetails');
        },
    );

export const getCircularDetailsForCircularContent = (userTypeId: any, circularDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,
) =>
    api.get(getAPIURL() + `getCircularDetails/${getLicenseKey}/${userTypeId}/${circularDetailsId}`,
        (response) => {
            success(response);
        },
        (error) => {
            failure('getCircularDetails - ' + error.message);
            console.log('error caught in service : getCircularDetails');
        },
    );

export const addCircularDetails = (messageData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addCircularDetails`, messageData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addCircularDetails')
        }
    );

export const updateCircularDetails = (messageData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateCircularDetails`, messageData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateCircularDetails')
        }
    );

export const deleteCircularDetails = (messageData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteCircularDetails`, messageData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteCircularDetails')
        }
    );

