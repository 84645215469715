import React, { useContext, useEffect, useState } from 'react';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Timeline, { TimelineItem } from '../../../../components/extras/Timeline';
import { toasts } from '../../../../services/toast.service';
import AuthContext from '../../../../contexts/authContext';
import { convertDateToEpoch, getCurrentDateAndTime } from '../../../../services/common.service';
import { getActivityLog } from '../../../../services/staff.service';
import { TableLoader } from '../../../../services/loader.services';

const StaffActivity = () => {
	const { userAccountId, userTypeId } = useContext(AuthContext);

	useEffect(() => {

		const upto = getCurrentDateAndTime('date')
		const currentDate = new Date();
		// Subtract 7 days to get the start date
		const startDate = new Date(currentDate);
		startDate.setDate(startDate.getDate() - 7);
		const formattedDate = startDate.toDateString();

		getActivityLogList(userAccountId, convertDateToEpoch(formattedDate), convertDateToEpoch(upto))

	}, []);

	const [activityData, setActivityData] = useState<any>([]);
	const colors = ['info', 'success', 'warning', 'secondary', 'primary'];


	function getActivityLogList(
		staffDetailsId: any,
		fromDateInMilliSec: any,
		toDateInMilliSec: any,
	) {
		getActivityLog(
			staffDetailsId,
			fromDateInMilliSec,
			toDateInMilliSec,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.activityLog;
					if (data != undefined) {
						for (let i = 0; i < data.length; i++) {
							let activities = data[i].activities
							for (let i = 0; i < activities.length; i++) {
								activities[i].color = colors[Math.floor(Math.random() * colors.length)];
							}
						}
						setActivityData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}
	return (
		<Card stretch className='rounded-1 border border-light'>
			<CardHeader borderSize={1}>
				<CardLabel icon='NotificationsActive' iconColor='info'>
					<CardTitle tag='div' className='h5'>
						Recent Activities
					</CardTitle>
					<CardSubTitle tag='div' className='h6'>
						last 1 week
					</CardSubTitle>
				</CardLabel>
			</CardHeader>
			<CardBody isScrollable style={{ height: '50vh' }}>
				{activityData != '' ?
					<>
						{activityData.map((item: any) => (
							<div key={item.userTypeId}>
							<Timeline >
								{item.activities.map((i: any) => (
									<TimelineItem
										key={i.activityLogsId}
										label={i.duration}
										color={i.color}>
										{i.activity}
									</TimelineItem>
								))}
							</Timeline>
							</div>
						))}
					</> :
					<TableLoader />}
			</CardBody>
		</Card>
	);
};

export default StaffActivity