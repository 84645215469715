import React, { useEffect, useState } from 'react';

import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import dayjs from 'dayjs';
import useDarkMode from '../../../../hooks/useDarkMode';
import classNames from 'classnames';
import Icon from '../../../../components/icon/Icon';
import { toasts } from '../../../../services/toast.service';
import { getOverAllPendingFees } from '../../../../services/dashboard.service';
import Button from '../../../../components/bootstrap/Button';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../../components/bootstrap/Modal';
import useSortableData from '../../../../hooks/useSortableData';
import PaginationButtons, { dataPagination } from '../../../../components/PaginationButtons';
import NoDataMsg from '../../../../common/components/NoDataMsg';
import { TableLoader } from '../../../../services/loader.services';

const PendingFees = () => {

	useEffect(() => {
		getOverAllPendingFeesList();
	}, []);

	const { darkModeStatus } = useDarkMode();
	const [dataStatus, setDataStatus] = useState(false);
	const [isLoader, setIsLoader] = useState(false);
	const [overAllPendingFeesData, setOverAllPendingFeesData] = useState<any>([])
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(5);
	const { items, requestSort, getClassNamesFor } = useSortableData(overAllPendingFeesData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [noDataMsg, setNoDataMsg] = useState('')

	const handleOpenModal = () => {
		getOverAllPendingFeesList();
		setIsModalOpen(true);
	};

	function getOverAllPendingFeesList() {
		setDataStatus(false);
		getOverAllPendingFees(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.overAllPendingFees;
					if (data != undefined) {
						setIsLoader(false);
						setDataStatus(true);
						setOverAllPendingFeesData(data);
					} else {
						setDataStatus(true);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setDataStatus(true);
					// toasts(response.data.message, 'Error');
					setOverAllPendingFeesData([]);
					setNoDataMsg(response.data.message)
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setDataStatus(true);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setDataStatus(true);
				toasts(error, 'Error');
			},
		);
	}

	return (
		<>
			<Card stretch className='rounded-1 border border-light'>
				<CardHeader borderSize={1}>
					<CardLabel icon='MenuOpen' iconColor='secondary'>
						<CardTitle>Pending Fees</CardTitle>
					</CardLabel>
					<CardActions>
						<Button
							color='info'
							isLink
							icon='Summarize'
							onClick={handleOpenModal}>
							Detailed Report
						</Button>
					</CardActions>
				</CardHeader>
				<CardBody style={{ height: '50vh' }} className='table-responsive' isScrollable>
					{!dataStatus ?
						<TableLoader /> :
						<table className='table table-modern table-hover '>
							<thead >
								<tr>
									<th
										scope='col'
										onClick={() => requestSort('departmentName')}
										className='cursor-pointer text-decoration-underline'>
										Department Name{' '}
										<Icon
											size='lg'
											className={getClassNamesFor('departmentName')}
											icon='FilterList'
										/>
									</th>
									<th
										scope='col'
										onClick={() => requestSort('courseName')}
										className='cursor-pointer text-decoration-underline'>
										Tuition Fees{' '}
										<Icon
											size='lg'
											className={getClassNamesFor('courseName')}
											icon='FilterList'
										/>
									</th>
									<th
										scope='col'
										onClick={() => requestSort('semester')}
										className='cursor-pointer text-decoration-underline'>
										Transport Fees{' '}
										<Icon
											size='lg'
											className={getClassNamesFor('semester')}
											icon='FilterList'
										/>
									</th>
									<th
										scope='col'
										onClick={() => requestSort('semester')}
										className='cursor-pointer text-decoration-underline'>
										Over All Pending Fees{' '}
										<Icon
											size='lg'
											className={getClassNamesFor('semester')}
											icon='FilterList'
										/>
									</th>
								</tr>
							</thead>
							<tbody>
								{overAllPendingFeesData != '' ? <>
									{onCurrentPageData.map((i: any, index: any) => (
										<tr key={index}>
											<td>{i.departmentName}</td>
											<td>{i.totalBalanceTutionFees}</td>
											<td>{i.balanceTransportFees}</td>
											<td>{i.overAllPendingFees}</td>
										</tr>
									))}
								</> : <NoDataMsg columnsCount={4} msg={noDataMsg} />}
							</tbody>
						</table>
					}
				</CardBody>
				<PaginationButtons
					data={items}
					label='items'
					setCurrentPage={setCurrentPage}
					currentPage={currentPage}
					perPage={perPage}
					setPerPage={setPerPage}
				/>
			</Card>

			<Modal
				setIsOpen={setIsModalOpen}
				isOpen={isModalOpen}
				titleId='pendingFeesDepartments'
				isCentered
				isStaticBackdrop
				isScrollable
				size='lg'>
				<ModalHeader
					className="'modal-header'"
					setIsOpen={() => {
						setIsModalOpen(false);
					}}
					onClick={() => setIsModalOpen(false)}>
					<ModalTitle id='d'>
						Pending Fees Departments
					</ModalTitle>
				</ModalHeader>

				<ModalBody>
					<div className='col-lg-5'></div>
					<div className='col-lg-12'>
						<table className='table table-modern table-hover'>
							<thead >
								<tr>
									<th className='bg-l25-info  fs-6'>DEPARTMENT NAME</th>
									<th className='bg-l25-info  fs-6'>TUITION FEES</th>
									<th className='bg-l25-info  fs-6'>TRANSPORT FEES</th>
									<th className='bg-l25-info  fs-6'>OVER ALL PENDING FEES</th>
								</tr>
							</thead>
							<tbody>
								{overAllPendingFeesData != '' ?
									<>
										{overAllPendingFeesData.map((i: any) => (
											<tr key={i.departmentId}>
												<td>{i.departmentName}</td>
												<td>{i.totalBalanceTutionFees}</td>
												<td>{i.balanceTransportFees}</td>
												<td>{i.overAllPendingFees}</td>
											</tr>
										))}
									</>
									: <NoDataMsg columnsCount={3} msg={noDataMsg} />}
							</tbody>
						</table>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
};

export default PendingFees;
