import { useFormik } from "formik";
import { useEffect, useContext, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import DeleteComponents from "../../../../common/components/DeleteComponents";
import NoDataMsg from "../../../../common/components/NoDataMsg";
import AuthContext from "../../../../contexts/authContext";
import useDarkMode from "../../../../hooks/useDarkMode";
import useSortableData from "../../../../hooks/useSortableData";
import Page from "../../../../layout/Page/Page";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import AlertService from "../../../../services/AlertService";
import { downloadExcel, convertJsonToPdf, downloadFile } from "../../../../services/ExportService";
import { getStudentSchoolSubject } from "../../../../services/administration.service";
import { getLicenseKey } from "../../../../services/application.settings";
import { updateFilter, getColumnsForDataTable, updateColumnsForDataTable } from "../../../../services/common.service";
import { showLoader, TableLoader } from "../../../../services/loader.services";
import { addStudentSchoolSubject, updateStudentSchoolSubject, deleteStudentSchoolSubject } from "../../../../services/subject.service";
import { toasts } from "../../../../services/toast.service";
import PaginationButtons, { dataPagination } from "../../../PaginationButtons";
import Button, { ButtonGroup } from "../../../bootstrap/Button";
import Card, { CardHeader, CardLabel, CardTitle, CardActions, CardBody } from "../../../bootstrap/Card";
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from "../../../bootstrap/Dropdown";
import OffCanvas, { OffCanvasHeader, OffCanvasTitle, OffCanvasBody } from "../../../bootstrap/OffCanvas";
import Checks, { ChecksGroup } from "../../../bootstrap/forms/Checks";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import Icon from "../../../icon/Icon";
import Input from "../../../bootstrap/forms/Input";

function StudentSchoolSubject() {

    useEffect(() => {
        getStudentSchoolSubjectDetails()
    }, [])

    const { userAccountId, userTypeId, addAccess, editAccess, deleteAccess } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [subjectData, setSubjectData] = useState([]);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    const [dataStatus, setDataStatus] = useState(false)

    // ForModal
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(subjectData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [addSubjectOffCanvas, setAddFineOffCanvas] = useState(false)
    const [editSubjectOffCanvas, setEditSubjectOffCanvas] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('')
    
    const subjectForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            subjectName: '',
            description: '',
        },
        validate: (values) => {
            const errors: {
                subjectName?: string;
            } = {};
            if (!values.subjectName) {
                errors.subjectName = 'Required';
            }
            return errors;
        },
        validateOnChange: true,
        onSubmit: () => { addSubjectSubmit() },
    });

    const editSubjectForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            subjectName: '',
            description: '',
            studentSchoolSubjectId: '',
        },
        validate: (values) => {
            const errors: {
                subjectName?: string;
                description?: string;
                studentSchoolSubjectId?: number;
            } = {};
            if (!values.subjectName) {
                errors.subjectName = 'Required';
            }
            return errors;
        },
        validateOnChange: true,
        onSubmit: () => { updateSubjectSubmit() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function getStudentSchoolSubjectDetails() {
        setDataStatus(false)
        getStudentSchoolSubject(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentSchoolSubject;
                    if (data != undefined) {
                        getColumnsForTable('getStudentSchoolSubject', 'get');
                        setSubjectData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        setDataStatus(true)
                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataStatus(true)
                    //toasts(response.data.message, "Error")
                    getColumnsForTable('getStudentSchoolSubject', 'get');
                    setSubjectData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setDataStatus(true)
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function onActivate(i: any) {
        if (i != undefined) {

            let selectedCaste = i
            setEditSubjectOffCanvas(true)

            editSubjectForm.setValues({
                subjectName: selectedCaste.subjectName,
                description: selectedCaste.description,
                studentSchoolSubjectId: selectedCaste.studentSchoolSubjectId
            })
        }
    }

    function setSubjectDetails() {

        return ({
            subjectName: subjectForm.values.subjectName,
            description: subjectForm.values.subjectName,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function setEditSubjectDetails() {
        return ({
            studentSchoolSubjectId: editSubjectForm.values.studentSchoolSubjectId,
            subjectName: editSubjectForm.values.subjectName,
            description: editSubjectForm.values.subjectName,
            userAccountId: userAccountId,
            isActive: 1,
            licenseKey: getLicenseKey,
        })
    }

    function addSubjectSubmit() {
        showLoader(true)
        if (subjectForm.isValid) {
            let subjectPostData = setSubjectDetails()
            addStudentSchoolSubject(subjectPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset();
                        getStudentSchoolSubjectDetails();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (subjectForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function updateSubjectSubmit() {
        showLoader(true)
        if (editSubjectForm.isValid) {
            let subjectPostData = setEditSubjectDetails()
            updateStudentSchoolSubject(subjectPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset();
                        getStudentSchoolSubjectDetails();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (editSubjectForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function deleteSubjectSubmit() {
        showLoader(true)
        // if (editSubjectForm.isValid) {
        let subjectPostData = setEditSubjectDetails()
        deleteStudentSchoolSubject(subjectPostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "success" });
                    setIsOpen(true);
                    closeAndReset();
                    getStudentSchoolSubjectDetails();
                }
                else if (data.success == false) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "error" });
                    setIsOpen(true);
                }
                else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    showLoader(false)
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }
            , (error) => {
                showLoader(false)
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
        // } else if (editSubjectForm.isValid == false) {
        //     showLoader(false)
        //     setAlertStatus({ message: 'Please fill all the details!', type: "error" });
        //     setIsOpen(true);
        // }
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getStudentSchoolSubject', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeAndReset() {
        setAddFineOffCanvas(false);
        setEditSubjectOffCanvas(false);
        setIsDeleteOpen(false);
        subjectForm.resetForm();
        editSubjectForm.resetForm();
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            {/* <PageWrapper title={`Student School Subject Details`}>
                <Page container='fluid'> */}
                    <Card stretch data-tour='list' ref={componentRef}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className={addAccess?"col-lg-5":"col-lg-6"}>
                                <CardTitle tag='div' className='h5'>Student School Subject List</CardTitle>
                            </CardLabel>&nbsp;&nbsp;

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getStudentSchoolSubject', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("StudentSchoolSubject", subjectData, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(subjectData, columnVisibilityData, "StudentSchoolSubject")}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("StudentSchoolSubject", subjectData, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                {addAccess?
                                <Button color='primary' icon='Add' isLight onClick={() => setAddFineOffCanvas(true)}>
                                    Add New
                                </Button>
                                :null
                                }
                            </CardActions>
                        </CardHeader>

                        <CardBody className='table-responsive' isScrollable>
                            {!dataStatus ?
                                <TableLoader /> :
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}
                                            {editAccess?
                                            <th scope='col' className='d-print-none'>
                                                Action
                                            </th>
                                            :null
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != "" ?
                                            <>
                                                {filteredData.map((rowData: any) => (
                                                    <tr key={rowData.studentSchoolSubjectId}>
                                                        <td>{rowData.sno}</td>
                                                        {columnVisibilityData.map((column: any) => (
                                                            (column.isDisplay && rowData[column.keyName]) ?
                                                                <td key={`${rowData.studentSchoolSubjectId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                        ))}
                                                        {editAccess?
                                                        <td className="d-print-none" >
                                                            <Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} /></td>
                                                            : null
                                                            }
                                                    </tr>
                                                ))
                                                }</>
                                            :
                                            <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg}/>}
                                    </tbody>
                                </table>
                            }
                        </CardBody>
                        <PaginationButtons
                            className="d-print-none"
                            data={items}
                            label='items'
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            perPage={perPage}
                            setPerPage={setPerPage}
                        />
                    </Card>

                    <OffCanvas
                        setOpen={setAddFineOffCanvas}
                        isOpen={addSubjectOffCanvas}
                        titleId='addSubjectOffCanvas'
                        isBodyScroll
                        placement='end' tag="form" noValidate onSubmit={subjectForm.handleSubmit}>
                        <OffCanvasHeader setOpen={setAddFineOffCanvas} onClick={closeAndReset}>
                            <OffCanvasTitle id="addSubjectOffCanvas">Student School Subject Creation</OffCanvasTitle>
                        </OffCanvasHeader>
                        <OffCanvasBody>
                            <div className="row g-4">
                                <div className='col-12'>
                                    <FormGroup id='subjectName' label='Subject'>
                                        <Input onChange={subjectForm.handleChange} value={subjectForm.values.subjectName}
                                            isValid={subjectForm.isValid}
                                            onBlur={subjectForm.handleBlur}
                                            isTouched={subjectForm.touched.subjectName}
                                            invalidFeedback={subjectForm.errors.subjectName}
                                            placeholder="Enter Subject" />
                                    </FormGroup>
                                </div>
                            </div>
                        </OffCanvasBody>
                        <div className='row m-0'>
                            <div className='col-12 p-3'>
                                <Button
                                    color='info' icon="Save"
                                    className='w-100' type="submit" isDisable={!subjectForm.isValid && !!subjectForm.submitCount} >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </OffCanvas>

                    <OffCanvas
                        setOpen={setEditSubjectOffCanvas}
                        isOpen={editSubjectOffCanvas}
                        titleId='editSubjectOffCanvas'
                        isBodyScroll
                        placement='end'
                        tag="form" noValidate onSubmit={editSubjectForm.handleSubmit}>
                        <OffCanvasHeader setOpen={setEditSubjectOffCanvas} onClick={closeAndReset}>
                            <OffCanvasTitle id="editSubjectOffCanvas">Update Subject</OffCanvasTitle>
                        </OffCanvasHeader>
                        <OffCanvasBody>
                            <div className="row g-4">
                                <div className='col-12'>
                                    <FormGroup id='subjectName' label='Subject'>
                                        <Input onChange={editSubjectForm.handleChange} value={editSubjectForm.values.subjectName}
                                            isValid={editSubjectForm.isValid}
                                            onBlur={editSubjectForm.handleBlur}
                                            isTouched={editSubjectForm.touched.subjectName}
                                            invalidFeedback={editSubjectForm.errors.subjectName}
                                            placeholder="Enter Subject" />
                                    </FormGroup>
                                </div>
                            </div>
                        </OffCanvasBody>

                        <div className='row'>
                            <div className="d-flex justify-content-center position-absolute top-65 start-50 translate-middle">
                                <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteSubjectSubmit} />
                            </div>
                            {deleteAccess?
                            <div className='col-6 position-absolute bottom-0 start-0 p-3'>
                                <Button color='danger' className='w-100' icon="Delete" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                                    Delete
                                </Button>
                            </div>
                            : null
                            }
                            <div className={deleteAccess?'col-6 position-absolute bottom-0 end-0 p-3':'col-12 position-absolute bottom-0 start-0 p-3'}>
                                <Button color='info' icon="Save" type="submit" className='w-100' isDisable={!editSubjectForm.isValid && !!editSubjectForm.submitCount}> Update</Button>
                            </div>
                        </div>
                    </OffCanvas>

                {/* </Page> */}
                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            {/* </PageWrapper> */}
        </>
    )
} export default StudentSchoolSubject;