import React, { useContext, useEffect, useRef, useState } from 'react';
import Card, {
    CardBody,
    CardFooter,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle,
} from '../../bootstrap/Card';
import Page from '../../../layout/Page/Page';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Button from '../../bootstrap/Button';
import Icon from '../../icon/Icon';
import Collapse from '../../bootstrap/Collapse';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { toasts } from '../../../services/toast.service';
import Input from '../../bootstrap/forms/Input';
import useDarkMode from '../../../hooks/useDarkMode';
import { useFormik } from 'formik';
import { useReactToPrint } from 'react-to-print';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import { convertDateToEpoch, getCurrentDateAndTime, getUserType, } from '../../../services/common.service';
import AuthContext from '../../../contexts/authContext';
import { getCircularDetailsReport, } from '../../../services/report.service';
import SearchableSelect from '../../../common/components/SearchableSelect';

function CircularReport() {
    useEffect(() => {
        getUserTypeList()
    }, []);

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();

    const [staffTypeId, setStaffTypeId] = useState<any>({ value: 0, label: 'Select All' })
    const [userTypeData, setUserTypeData] = useState<any>([])
    const [circularData, setCircularData] = useState<any>([]);

    const [dataSuccess, setDataSuccess] = useState(false);
    const [isOpenListCard, setIsOpenListCard] = useState(true);
    const componentRef = useRef(null);
    const [isLoader, setIsLoader] = useState(false);

    const [noDataMsg, setNoDataMsg] = useState('');

    const circularReportForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            fromDate: getCurrentDateAndTime('date'),
            toDate: getCurrentDateAndTime('date'),
            //staffTypeId: '',
        },
        validate: (values) => {
            const errors: {
                fromDate?: string;
                toDate?: string;
                //staffTypeId?: any;
            } = {};
            if (!values.fromDate) {
                errors.fromDate = 'Required';
            }
            if (!values.toDate) {
                errors.toDate = 'Required';
            }
            // if (!staffTypeId?.value) {
            //     errors.staffTypeId = 'Required';
            // }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    function viewCircularReportList() {
        setIsLoader(true);
        setDataSuccess(false)
        setCircularData([])

        let fromDateInMilliSec = convertDateToEpoch(circularReportForm.values.fromDate)
        let toDateInMilliSec = convertDateToEpoch(circularReportForm.values.toDate)
        getCircularList(fromDateInMilliSec, toDateInMilliSec)
    }

    const selectUserType = (e: any) => {
        setDataSuccess(false)
        setIsOpenListCard(true)
        let staffTypeId = e
        setStaffTypeId(staffTypeId)
    }

    function getUserTypeList() {
        getUserType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.userType;
                    if (data != undefined) {
                        const userTypeData = [{ userTypeId: 0, userType: 'Select All' }];
                        data.forEach((obj: { userTypeId: number; userType: string }) => {
                            let userType = userTypeData.push(obj);
                        });
                        setUserTypeData(userTypeData);

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setUserTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCircularList(fromDate: any, toDate: any) {
        setIsLoader(true);
        getCircularDetailsReport(
            1, fromDate, toDate,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.circularDetails;
                    if (data != undefined) {
                        setIsLoader(false);
                        setDataSuccess(true);
                        setCircularData(data);
                        setIsOpenListCard(false);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        setDataSuccess(false);
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setIsLoader(false);
                    setIsOpenListCard(false);
                    setNoDataMsg(response.data.message)
                    setDataSuccess(true);
                    // toasts(response.data.message, 'Error');
                } else {
                    setIsLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setDataSuccess(true);
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                setIsLoader(false);
                setDataSuccess(true);
                toasts(error, 'Error');
            },
        );
    }

    function onPrint(i: any) {
        if (i != undefined) {
            let selectedData = i;
            window.open(`/circularContent/${selectedData.circularDetailsId}`);
        }
    }

    return (
        <PageWrapper title='Circular Report'>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='list' iconColor='primary'>
                                <CardTitle>Circular Report</CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row'>
                                {/* <div className="col-lg-3">
                                    <FormGroup id='staffTypeId' label='User Type' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Type'
                                            onChange={selectUserType}
                                            value={staffTypeId}
                                            list={userTypeData.map((data: any) => (
                                                { value: data.userTypeId, label: data.userType }
                                            ))}
                                        />
                                    </FormGroup>
                                </div> */}
                                <div className="col-lg-2">
                                    <FormGroup id="fromDate" label="From Date" isFloating>
                                        <Input
                                            type='date'
                                            ariaLabel='From Date'
                                            onChange={circularReportForm.handleChange}
                                            onBlur={circularReportForm.handleBlur}
                                            value={circularReportForm.values.fromDate}
                                            isValid={circularReportForm.isValid}
                                            isTouched={circularReportForm.touched.fromDate}
                                            invalidFeedback={circularReportForm.errors.fromDate}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-2">
                                    <FormGroup id="toDate" label="To Date" isFloating>
                                        <Input
                                            type='date'
                                            ariaLabel='To Date'
                                            onChange={circularReportForm.handleChange}
                                            onBlur={circularReportForm.handleBlur}
                                            value={circularReportForm.values.toDate}
                                            isValid={circularReportForm.isValid}
                                            isTouched={circularReportForm.touched.toDate}
                                            invalidFeedback={circularReportForm.errors.toDate}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-2 align-self-center mt-1">
                                    <FormGroup >
                                        <Button icon="South" color="primary" isDisable={staffTypeId?.value == undefined ? true : false}
                                            onClick={viewCircularReportList}>View</Button>
                                    </FormGroup>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent
                    isOpenListCard={isOpenListCard}
                    setIsOpenListCard={setIsOpenListCard}
                    isLoader={isLoader}
                />

                {dataSuccess ? (
                    <Card stretch>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className='col-lg-6'>
                                <CardTitle tag='div' className='h5'>
                                    Circular List
                                </CardTitle>
                            </CardLabel>
                            &nbsp;&nbsp;
                            {/* <ButtonGroup
									className='col-lg-3 justify-content-end d-print-none'
									color='primary'>
									<Icon
										className='mt-1'
										icon='Search'
										size='2x'
										color='primary'
									/>
									<Input
										id='searchInput'
										type='search'
										placeholder='Search...'
										onChange={columnVisibilityForm.handleChange}
										value={columnVisibilityForm.values.searchInput}
									/>
								</ButtonGroup>
								<CardActions className='d-print-none'>
									<Dropdown
										isOpen={columnVisibilityMenu}
										setIsOpen={setColumnVisibilityMenu}
										isButtonGroup>
										<DropdownToggle>
											<Button icon='FilterAlt' color='primary' isLight>
												Filter
											</Button>
										</DropdownToggle>
										<DropdownMenu
											isAlignmentEnd
											size='lg'
											isCloseAfterLeave={false}>
											<DropdownItem>
												<div className='container py-2'>
													<form className='row g-3'>
														<div className='col-12'>
															<FormGroup>
																<h6>Select All</h6>
																<Checks
																	id='available'
																	type='switch'
																	label='Select All Columns'
																	onChange={() =>
																		getColumnsForTable(
																			'getStaffReferralReport',
																			'post',
																		)
																	}
																	checked={isChecked}
																	ariaLabel='Available status'
																/>
															</FormGroup>
														</div>
														<div
															className='col-12'
															style={{
																maxHeight: '200px',
																overflowY: 'scroll',
															}}>
															<FormGroup>
																<h6>Columns</h6>
																<ChecksGroup>
																	{allColumnsData.map(
																		(i: any) => (
																			<Checks
																				key={
																					i.columnVisibilityId
																				}
																				id='{i.columnVisibilityId}'
																				label={i.columnName}
																				onChange={() =>
																					updateColumnsSubmit(
																						i.columnVisibilityId,
																						i.isDisplay,
																						'',
																					)
																				}
																				checked={
																					i.isDisplay
																				}
																				disabled={
																					i.isDefault ==
																					true
																				}
																			/>
																		),
																	)}
																</ChecksGroup>
															</FormGroup>
														</div>
													</form>
												</div>
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
									<Dropdown isButtonGroup>
										<DropdownToggle>
											<Button color='primary' isLight icon='CloudDownload'>
												{' '}
												Export{' '}
											</Button>
										</DropdownToggle>
										<DropdownMenu isAlignmentEnd>
											<DropdownItem>
												<Button
													color='primary'
													isLight
													icon='CloudDownload'
													onClick={() =>
														downloadExcel(
															'Announcement List Data',
															announcementDetailsData,
															columnVisibilityData,
														)
													}>
													{' '}
													Excel{' '}
												</Button>
											</DropdownItem>
											<DropdownItem>
												<Button
													color='primary'
													isLight
													icon='PictureAsPdf'
													onClick={() =>
														convertJsonToPdf(
															announcementDetailsData,
															columnVisibilityData,
															'Announcement List Data',
														)
													}>
													{' '}
													PDF
												</Button>
											</DropdownItem>
											<DropdownItem>
												<Button
													color='primary'
													isLight
													icon='CloudDownload'
													onClick={() =>
														downloadFile(
															'Announcement List Data',
															announcementDetailsData,
															columnVisibilityData,
														)
													}>
													{' '}
													CSV{' '}
												</Button>
											</DropdownItem>
											<DropdownItem>
												<Button
													color='primary'
													isLight
													icon='Print'
													onClick={handlePrint}>
													{' '}
													Print{' '}
												</Button>
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
								</CardActions> */}
                        </CardHeader>
                        {circularData != '' ?
                            <Card className='' shadow={'none'}>
                                <CardBody >
                                    <table className='table table-modern table-hover text-nowrap'>
                                        <thead>
                                            <tr className='table-primary' style={{ textAlign: 'center' }}>
                                                <td colSpan={13} style={{
                                                    fontFamily: 'Bookman Old Style',
                                                    color: 'rgb(61, 57, 57)'
                                                }}>
                                                    <h5>USER TYPE  : {circularData[0].userType} - DATE : ({circularData[0].fromDateForView} to {circularData[0].toDateForView})</h5>
                                                </td>
                                            </tr>
                                        </thead>
                                    </table>
                                    <div className='row'>
                                        {circularData.map((i: any) => (
                                            <Card key={i.circularDetailsId} className='col-lg-4 border border-light ms-3' style={{ width: '32%' }} stretch shadow={'md'}>
                                                <CardHeader>
                                                    <CardLabel>
                                                        <CardTitle tag='div' className='h5'>
                                                            {i.subject}
                                                        </CardTitle>
                                                        <CardSubTitle tag='div' className='h6'><span className='text-success'>{i.circularDateForView}</span></CardSubTitle>
                                                    </CardLabel>
                                                </CardHeader>
                                                <CardBody isScrollable className='align-items-center' style={{ maxHeight: '70%' }}>
                                                    <div className='row align-items-center'>
                                                        <div dangerouslySetInnerHTML={{ __html: i.content }} />
                                                    </div>
                                                </CardBody>
                                                <CardFooter className='shadow-3d-container'>
                                                    <Button color='primary'
                                                        className={`w-100 mb-4 shadow-3d-up-hover shadow-3d-${darkModeStatus ? 'light' : 'dark'}`} size='lg' onClick={() => onPrint(i)}>
                                                        Preview
                                                    </Button>
                                                </CardFooter>
                                            </Card>
                                        ))}
                                    </div>
                                </CardBody>
                            </Card>
                            :
                            <div className='row'>
                                <div className="col-12" ></div>
                                <div className='col-12 d-flex justify-content-center'>
                                    <Icon icon='DoNotDisturb' size={'10x'} />
                                </div>
                                <div className='fs-4 fw-bold text-center text-uppercase text-danger'>
                                    {noDataMsg}
                                </div>
                            </div>
                        } </Card>
                ) : null}
            </Page>
        </PageWrapper>
    );
}

export default CircularReport;
