import { useContext, useEffect, useRef, useState } from "react";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import Page from "../../../layout/Page/Page";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Input from "../../bootstrap/forms/Input";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import useSortableData from "../../../hooks/useSortableData";
import { toasts } from "../../../services/toast.service";
import { useFormik } from "formik";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../bootstrap/Dropdown";
import { convertJsonToPdf, downloadExcel, downloadFile, printPage } from "../../../services/ExportService";
import useDarkMode from "../../../hooks/useDarkMode";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import { useReactToPrint } from "react-to-print";
import Icon from "../../icon/Icon";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../bootstrap/OffCanvas";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import AlertService from "../../../services/AlertService";
import DeleteComponents from "../../../common/components/DeleteComponents";
import { getColumnsForDataTable, updateColumnsForDataTable, updateFilter } from "../../../services/common.service";
import AuthContext from "../../../contexts/authContext";
import { addDepartment, addDepartmentForPayroll, deleteDepartment, deleteDepartmentForPayroll, getDepartmentList, updateDepartment, updateDepartmentForPayroll } from "../../../services/master.service";
import { getLicenseKey } from "../../../services/application.settings";
import { TableLoader, showLoader } from "../../../services/loader.services";
import NoDataMsg from "../../../common/components/NoDataMsg";

const Department = () => {

    useEffect(() => {
        getDepartment()
    }, [])

    const { userAccountId, userTypeId, isPayrollConnected , addAccess, editAccess, deleteAccess} = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [departmentData, setDepartmentData] = useState<any>([]);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    const [dataStatus, setDataStatus] = useState(false)

    // For Table and offCanvas
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(departmentData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [addDepartmentOffCanvas, setAddDepartmentOffCanvas] = useState(false)
    const [editDepartmentOffCanvas, setEditDepartmentOffCanvas] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('')

    const addDepartmentForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            departmentName: '',
            departmentCode: '',
        },
        validate: (values) => {
            const errors: {
                departmentName?: string;
                departmentCode?: string;
            } = {};
            if (!values.departmentName) {
                errors.departmentName = 'Required';
            }
            if (!values.departmentCode) {
                errors.departmentCode = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { departmentSubmit() },
    });

    const editDepartmentForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            departmentName: '',
            departmentCode: '',
            departmentId: '',
        },
        validate: (values) => {
            const errors: {
                departmentName?: string;
                departmentId?: number;
                departmentCode?: string;
            } = {};
            if (!values.departmentId) {
                errors.departmentName = 'Required';
            }
            if (!values.departmentName) {
                errors.departmentName = 'Required';
            }
            if (!values.departmentCode) {
                errors.departmentCode = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { updateDepartmentSubmit() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function getDepartment() {
        setDataStatus(false)
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.department;
                    if (data != undefined) {
                        getColumnsForTable('getDepartments', 'get');
                        setDepartmentData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        setDataStatus(true)
                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setDataStatus(true)
                    getColumnsForTable('getDepartments', 'get');
                    setDepartmentData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    setDataStatus(true)
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function onActivate(i: any) {
        if (i != undefined) {

            let selectedDepartment = i
            setEditDepartmentOffCanvas(true)

            editDepartmentForm.setValues({
                departmentId: selectedDepartment.departmentId,
                departmentName: selectedDepartment.departmentName,
                departmentCode: selectedDepartment.departmentCode
            });
        }
    }

    function setDepartmentDetails() {

        return ({
            licenseKey: getLicenseKey,
            createdBy: userAccountId,
            departmentName: addDepartmentForm.values.departmentName,
            departmentCode: addDepartmentForm.values.departmentCode,
            description: addDepartmentForm.values.departmentName
        })
    }

    function setEditDepartmentDetails() {
        return ({
            departmentId: editDepartmentForm.values.departmentId,
            departmentName: editDepartmentForm.values.departmentName,
            departmentCode: editDepartmentForm.values.departmentCode,
            description: editDepartmentForm.values.departmentName,
            licenseKey: getLicenseKey,
            isActive: 1,
            userAccountId: userAccountId
        })
    }

    function departmentSubmit() {
        showLoader(true);
        if (addDepartmentForm.isValid) {
            let departmentPostData = setDepartmentDetails()
            addDepartment(departmentPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        if (isPayrollConnected) {
                            addDepartmentForPayroll(departmentPostData,
                                (response) => {
                                    const data1 = response.data;
                                    if (data1.success == true) {
                                        showLoader(false);
                                        setAlertStatus({ message: data.message, type: "success" });
                                        setIsOpen(true);
                                        getDepartment()
                                        closeAndFormReset();
                                    } else if (data1.success == false) {
                                        showLoader(false);
                                        setAlertStatus({ message: data1.message, type: "error" });
                                        setIsOpen(true);
                                    }
                                    else {
                                        showLoader(false);
                                        let errorCode = response.data.error[0].error_code;
                                        let errorDescription = response.data.error[0].error_description;
                                        setAlertStatus({ message: errorDescription, type: "error" });
                                        setIsOpen(true);
                                    }
                                }
                                , (error) => {
                                    setAlertStatus({ message: error, type: "error" });
                                    setIsOpen(true);
                                }
                            )

                        } else {
                            showLoader(false);
                            setAlertStatus({ message: data.message, type: "success" });
                            setIsOpen(true);
                            getDepartment()
                            closeAndFormReset();
                        }
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )

        } else if (addDepartmentForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function updateDepartmentSubmit() {
        showLoader(true);
        if (editDepartmentForm.isValid) {
            let departmentPostData = setEditDepartmentDetails()
            updateDepartment(departmentPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        if (isPayrollConnected) {
                            updateDepartmentForPayroll(departmentPostData,
                                (response) => {
                                    const data1 = response.data;
                                    if (data1.success == true) {
                                        showLoader(false);
                                        setAlertStatus({ message: data.message, type: "success" });
                                        setIsOpen(true);
                                        getDepartment()
                                        closeAndFormReset();
                                    } else if (data1.success == false) {
                                        showLoader(false);
                                        setAlertStatus({ message: data1.message, type: "error" });
                                        setIsOpen(true);
                                    }
                                    else {
                                        showLoader(false);
                                        let errorCode = response.data.error[0].error_code;
                                        let errorDescription = response.data.error[0].error_description;
                                        setAlertStatus({ message: errorDescription, type: "error" });
                                        setIsOpen(true);
                                    }
                                }
                                , (error) => {
                                    setAlertStatus({ message: error, type: "error" });
                                    setIsOpen(true);
                                }
                            )

                        } else {
                            showLoader(false);
                            setAlertStatus({ message: data.message, type: "success" });
                            setIsOpen(true);
                            getDepartment()
                            closeAndFormReset();
                        }
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )

        } else if (editDepartmentForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function deleteDepartmentSubmit() {
        showLoader(true);
        let departmentPostData = setEditDepartmentDetails()
        deleteDepartment(departmentPostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    if (isPayrollConnected) {
                        deleteDepartmentForPayroll(departmentPostData,
                            (response) => {
                                const data1 = response.data;
                                if (data1.success == true) {
                                    showLoader(false);
                                    setAlertStatus({ message: data.message, type: "success" });
                                    setIsOpen(true);
                                    getDepartment()
                                    closeAndFormReset()
                                } else if (data1.success == false) {
                                    showLoader(false);
                                    setAlertStatus({ message: data1.message, type: "error" });
                                    setIsOpen(true);
                                }
                                else {
                                    showLoader(false);
                                    let errorCode = response.data.error[0].error_code;
                                    let errorDescription = response.data.error[0].error_description;
                                    setAlertStatus({ message: errorDescription, type: "error" });
                                    setIsOpen(true);
                                }
                            }
                            , (error) => {
                                showLoader(false);
                                setAlertStatus({ message: error, type: "error" });
                                setIsOpen(true);
                            }
                        )
                    } else {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getDepartment()
                        closeAndFormReset()
                    }
                }
                else if (data.success == false) {
                    showLoader(false);
                    setAlertStatus({ message: data.message, type: "error" });
                    setIsOpen(true);
                }
                else {
                    showLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }
            , (error) => {
                showLoader(false);
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getDepartments', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeAndFormReset() {
        setAddDepartmentOffCanvas(false);
        setEditDepartmentOffCanvas(false);
        setIsDeleteOpen(false);
        addDepartmentForm.resetForm();
        editDepartmentForm.resetForm();
    }

    // Export Configuration
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <PageWrapper title={`Department List`}>
                <Page container='fluid'>
                    <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className={addAccess ? 'col-lg-5' : 'col-lg-6'}>
                                <CardTitle tag='div' className='h5'> Department List</CardTitle>
                            </CardLabel>

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getDepartments', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("Department List", departmentData, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(departmentData, columnVisibilityData, "Department List")}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("Department List", departmentData, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                {addAccess ?
                                    <Button color='primary' icon='Add' isLight onClick={() => setAddDepartmentOffCanvas(true)}>
                                        Add New
                                    </Button>
                                    : null
                                }
                            </CardActions>
                        </CardHeader>

                        <CardBody className='table-responsive' isScrollable>

                            {!dataStatus ?
                                <TableLoader /> :

                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort(`sno`)}
                                                className='cursor-pointer text-decoration-underline'>S.No
                                                <Icon size='lg' className={getClassNamesFor(`sno`)} icon='FilterList' />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}
                                            {editAccess ?
                                                <th scope='col' className='d-print-none'>
                                                    Action
                                                </th>
                                                : null
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != "" ?
                                            <>
                                                {filteredData.map((rowData: any) => (
                                                    <tr key={rowData.departmentId}>
                                                        <td>{rowData.sno}</td>
                                                        {columnVisibilityData.map((column: any) => (
                                                            (column.isDisplay && rowData[column.keyName]) ?
                                                                <td key={`${rowData.departmentId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                        ))}
                                                        { editAccess ?
                                                            <td className="d-print-none">
                                                                <Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} />
                                                            </td>
                                                            : null
                                                        }
                                                    </tr>
                                                ))
                                                }</>
                                            :
                                            <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                    </tbody>
                                </table>
                            }
                        </CardBody>
                        <PaginationButtons className='d-print-none' data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                    </Card>
                </Page>

                <OffCanvas setOpen={setAddDepartmentOffCanvas} isOpen={addDepartmentOffCanvas} titleId='addDepartmentOffCanvas' placement='end'>
                    <OffCanvasHeader setOpen={setAddDepartmentOffCanvas} onClick={closeAndFormReset}>
                        <OffCanvasTitle id="addDepartmentOffCanvas">Department Creation</OffCanvasTitle>
                    </OffCanvasHeader>
                    <form noValidate onSubmit={addDepartmentForm.handleSubmit}>
                        <OffCanvasBody>
                            <div className="row g-4">
                                <div className='col-12'>
                                    <FormGroup id='departmentCode' label='Department Code'>
                                        <Input onChange={addDepartmentForm.handleChange} value={addDepartmentForm.values.departmentCode} isValid={addDepartmentForm.isValid} onBlur={addDepartmentForm.handleBlur} isTouched={addDepartmentForm.touched.departmentCode} invalidFeedback={addDepartmentForm.errors.departmentCode} placeholder="Enter Department Code" required />
                                    </FormGroup>
                                </div>

                                <div className='col-12'>
                                    <FormGroup id='departmentName' label='Department Name'>
                                        <Input onChange={addDepartmentForm.handleChange} value={addDepartmentForm.values.departmentName} isValid={addDepartmentForm.isValid} onBlur={addDepartmentForm.handleBlur} isTouched={addDepartmentForm.touched.departmentName} invalidFeedback={addDepartmentForm.errors.departmentName} placeholder="Enter Department Name" required />
                                    </FormGroup>
                                </div>
                            </div>
                        </OffCanvasBody>
                        <div className='row m-0'>
                            <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                                <Button color='info' icon="Save" className='w-100' type="submit" isDisable={!addDepartmentForm.isValid && !!addDepartmentForm.submitCount}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </form>
                </OffCanvas>

                <OffCanvas setOpen={setEditDepartmentOffCanvas} isOpen={editDepartmentOffCanvas} titleId='editDepartmentOffCanvas' placement='end'>
                    <OffCanvasHeader setOpen={setEditDepartmentOffCanvas} onClick={closeAndFormReset}>
                        <OffCanvasTitle id="editDepartmentOffCanvas">Update Department</OffCanvasTitle>
                    </OffCanvasHeader>
                    <form noValidate onSubmit={editDepartmentForm.handleSubmit}>
                        <OffCanvasBody>
                            <div className="row g-4">
                                <div className='col-12'>
                                    <FormGroup id='departmentCode' label='Department Code'>
                                        <Input onChange={editDepartmentForm.handleChange} value={editDepartmentForm.values.departmentCode} isValid={editDepartmentForm.isValid} onBlur={editDepartmentForm.handleBlur} isTouched={editDepartmentForm.touched.departmentCode} invalidFeedback={editDepartmentForm.errors.departmentCode} placeholder="Enter Department Code" />
                                    </FormGroup>
                                </div>

                                <div className='col-12'>
                                    <FormGroup id='departmentName' label='Department Name'>
                                        <Input onChange={editDepartmentForm.handleChange} value={editDepartmentForm.values.departmentName} isValid={editDepartmentForm.isValid} onBlur={editDepartmentForm.handleBlur} isTouched={editDepartmentForm.touched.departmentName} invalidFeedback={editDepartmentForm.errors.departmentName} placeholder="Enter Department Name" />
                                    </FormGroup>
                                </div>
                                <div className="d-flex justify-content-center position-absolute top-65 start-50 translate-middle">
                                    <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteDepartmentSubmit} />
                                </div>
                            </div>
                            <div className='row top-90 m-0'>
                               {deleteAccess? <div className='col-6 position-absolute bottom-0 start-0 p-3'>
                                    <Button color='danger' className='w-100' icon="Delete" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                                        Delete
                                    </Button>
                                </div>
                                : null}
                                <div className={deleteAccess ? 'col-6 position-absolute bottom-0 end-0 p-3':'col-12 position-absolute bottom-0 p-3 start-0'}>
                                    <Button color='info' icon="Save" className='w-100' type="submit" isDisable={!editDepartmentForm.isValid && !!editDepartmentForm.submitCount}>Update</Button>
                                </div>
                            </div>
                        </OffCanvasBody>
                    </form>
                </OffCanvas>

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />

            </PageWrapper>
        </>
    );
}
export default Department;