import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getStudentDetailsByAcademicPeriodId = (academicPeriodId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentDetailsByAcademicPeriodId/${getLicenseKey}/${academicPeriodId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentDetailsByAcademicPeriodId - " + error.message)
            console.log('error caught in service : getStudentDetailsByAcademicPeriodId')
        },
    );

export const getStudentDetailsByYear = (batchMasterId: number, semesterDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentDetailsByYear/${getLicenseKey}/${batchMasterId}/${semesterDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentDetailsByYear - " + error.message)
            console.log('error caught in service : getStudentDetailsByYear')
        },
    );