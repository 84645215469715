import { useContext, useEffect, useState } from "react";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import Page from "../../../layout/Page/Page";
import Collapse from "../../bootstrap/Collapse";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import Button from "../../bootstrap/Button";
import useDarkMode from "../../../hooks/useDarkMode";
import { toasts } from "../../../services/toast.service";
import FormGroup from "../../bootstrap/forms/FormGroup";
import OpenCardComponent from "../../../common/components/OpenCardComponent";
import { getAllStudent, getProfileForStudent, getStudentDetailsBySemesterId } from "../../../services/student.service";
import { getBatchByCourseId, getCourse, getDepartmentList, getSemester } from "../../../services/master.service";
import { getGraduationType, getSectionDetails, onlyAllowNumber, pictNotLoading, profilePic } from "../../../services/common.service";
import AuthContext from "../../../contexts/authContext";
import Icon from "../../icon/Icon";
import { addOrUpdStudentFeeConcession, addStudentAdvanceFee, addStudentFeeConcession, getNotAssignedSemesterFees, getStudentFeeDetails, getStudentFeeDetailsHistory } from "../../../services/fee.service";
import Input from "../../bootstrap/forms/Input";
import Textarea from "../../bootstrap/forms/Textarea";
import { useFormik } from "formik";
import { getLicenseKey } from "../../../services/application.settings";
import AlertService from "../../../services/AlertService";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../bootstrap/OffCanvas";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import { showLoader, TableLoader } from "../../../services/loader.services";
import Label from "../../bootstrap/forms/Label";
import SearchableSelect from "../../../common/components/SearchableSelect";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../bootstrap/Modal";
import NoDataMsg from "../../../common/components/NoDataMsg";
import classNames from "classnames";

const ApprovedConcession = () => {

    useEffect(() => {
        getGraduationList();
        getDepartment();
        getSectionList();
        getAllStudentDetails();
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const [isStudentOrClassWise, setIsStudentOrClassWise] = useState(true)
    const { darkModeStatus } = useDarkMode();

    const [departmentId, setDepartmentId] = useState<any>(Number)
    const [courseId, setCourseId] = useState<any>(Number)
    const [batchMasterId, setBatchMasterId] = useState<any>(Number)
    const [semesterDetailsId, setSemesterDetailsId] = useState<any>(Number)
    const [sectionDetailsId, setSectionDetailsId] = useState<any>(Number)
    const [graduationTypeId, setGraduationTypeId] = useState<any>(Number)
    const [studentDetailsId, setStudentDetailsId] = useState<any>(Number)
    const [dataSuccess, setDataSuccess] = useState(false)

    const [departmentData, setDepartmentData] = useState<any>([])
    const [courseData, setCourseData] = useState<any>([])
    const [graduationTypeData, setGraduationTypeData] = useState<any>([])
    const [batchMasterData, setBatchMasterData] = useState<any>([])
    const [semesterDetailsData, setSemesterDetailsData] = useState<any>([])
    const [sectionDetailsData, setSectionDetailsData] = useState<any>([])
    const [studentProfileData, setStudentProfileData] = useState<any>([])
    const [allStudentData, setAllStudentData] = useState<any>([])
    const [studentDetailsData, setStudentDetailsData] = useState<any>([])
    const [studentFeeDetails, setStudentFeeDetails] = useState<any>([])
    const [filteredDataToRefresh, setFilteredDataToRefresh] = useState<any>([])
    const [concessionDetails, setConcessionDetails] = useState<any>([])

    const [semesterFeesForTutionFeesData, setSemesterFeesForTuitionFeesData] = useState<any>([])
    const [semesterFeesForTransportFeesData, setSemesterFeesForTransportFeesData] = useState<any>([])
    const [advanceTuitionFeesNoData, setAdvanceTuitionFeesNoData] = useState("")
    const [advanceTransportFeesNoData, setAdvanceTransportFeesNoData] = useState("")
    const [studentAdvanceFee, setStudentAdvanceFee] = useState<any>([])
    const [isLoader, setIsLoader] = useState(false)

    const [editConcessionFeesAmount, setEditConcessionFeesAmount] = useState(false)
    const [isDelete, setIsDelete] = useState(false)
    const [viewUpdateHistory, setViewUpdateHistory] = useState(false)

    const [semester, setSemester] = useState('')
    const [feeType, setFeeType] = useState('')

    const [isRemove, setIsRemove] = useState(0);
    const [balance, setBAlance] = useState<any>();
    const [editTotal, setEditTotal] = useState(0);
    const [editConcession, setEditConcession] = useState(0);
    const [editFeesReceived, setEditFeesReceived] = useState(0);

    const [studentFeeDetailsHistoryData, setStudentFeeDetailsHistoryData] = useState<any>([])
    const [noDataMsg, setNoDataMsg] = useState('');

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [addAdvanceFeeOffCanvas, setAddAdvanceFeeOffCanvas] = useState(false)

    const approvedConcessionForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            remarks: '',

        },
        // validate: (values) => {
        //     const errors: {
        //         fineTypeId?: string;
        //     } = {};
        //     if (!values.fineTypeId) {
        //         errors.fineTypeId = 'Required';
        //     }
        //     return errors;
        // },
        //validateOnChange: false,
        onSubmit: (values) => { },
    });

    const editConcessionFeesForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            studentFeeDetailsId: '',
            totalFees: '',
            feesReceived: '',
            concessionFees: '',
            balance: '',
            remarks: ''

        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: (values) => { addOrUpdStudentConcessionFeeDetailsSubmit() },
    });

    function onActivate(i: any) {
        if (i != undefined) {

            let selectedFees = i
            setSemester(selectedFees.semesterNumber)
            setFeeType(selectedFees.feeType)
            setEditConcessionFeesAmount(true)
            setBAlance(selectedFees.balanceToBePaid)
            setEditConcession(selectedFees.concession)
            setEditTotal(selectedFees.totalFees)
            setEditFeesReceived(selectedFees.totalFeesReceived)

            editConcessionFeesForm.setValues({
                studentFeeDetailsId: selectedFees.studentFeeDetailsId,
                totalFees: selectedFees.totalFees,
                feesReceived: selectedFees.totalFeesReceived,
                concessionFees: selectedFees.concession,
                balance: selectedFees.balanceToBePaid,
                remarks: selectedFees.remarks
            });
        }
    }

    function viewHistory(i: any) {
        if (i != undefined) {

            let selectedFeesHistory = i

            setSemester(selectedFeesHistory.semesterNumber)
            setFeeType(selectedFeesHistory.feeType)
            getStudentFeeDetailsHistoryList(selectedFeesHistory.studentFeeDetailsId, 1)
            setViewUpdateHistory(true);
        }
    }

    const selectDepartment = (e: any) => {
        setGraduationTypeId('')
        setCourseId('')
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setStudentDetailsId('')
        let departmentId = e
        setDepartmentId(departmentId)
    }

    const selectGraduationType = (e: any) => {
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setStudentDetailsId('')
        let graduationTypeId = e
        setGraduationTypeId(graduationTypeId)
        if (graduationTypeId?.value != undefined) {
            getCourseList(departmentId?.value, graduationTypeId?.value)
        }
    }

    const selectCourse = (e: any) => {
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setStudentDetailsId('')
        let courseId = e
        setCourseId(courseId)
        if (courseId?.value != undefined) {
            getBatchList(courseId?.value, graduationTypeId?.value)
        }
    }

    const selectBatch = (e: any) => {
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setStudentDetailsId('')
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        if (batchMasterId?.value != undefined) {
            getSemesterList(batchMasterId?.value)
        }
    }

    const selectSemester = (e: any) => {
        setSectionDetailsId('')
        setStudentDetailsId('')
        let semesterDetailsId = e
        setSemesterDetailsId(semesterDetailsId)
    }

    const selectSection = (e: any) => {
        setStudentDetailsId('')
        setStudentDetailsData([])
        let sectionDetailsId = e
        setSectionDetailsId(sectionDetailsId)
        if (sectionDetailsId?.value != undefined) {
            getStudentListBySectionId(courseId?.value, semesterDetailsId?.value, sectionDetailsId?.value)
        }

    }

    const selectStudent = (e: any) => {
        let studentDetailsId = e
        setStudentDetailsId(studentDetailsId)
    }

    function viewParticularStudent() {
        setDataSuccess(false)
        setIsLoader(true);
        getStudentProfile(studentDetailsId?.value);
        getStudentFeeList(userTypeId, studentDetailsId?.value);
    }

    function getStudentProfile(studentDetailsId: number) {
        getProfileForStudent(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentProfile;
                    if (data != undefined) {
                        setStudentProfileData(data);
                        setDataSuccess(true)
                        setIsOpenListCard(false);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                        setDataSuccess(false)
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                    setDataSuccess(false)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setDataSuccess(false)
                }
            }, error => {
                toasts(error, "Error")
                setDataSuccess(false)
            }
        )
    }

    function getStudentListBySectionId(courseId: number, semesterDetailsId: number, sectionDetailsId: number) {
        getStudentDetailsBySemesterId(courseId, semesterDetailsId, sectionDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetailsBySemesterId;
                    if (data != undefined) {
                        setStudentDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setStudentDetailsId('')
                    setStudentDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setStudentDetailsId('')
                    setStudentDetailsData([]);
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getDepartment() {
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.department;
                    if (data != undefined) {
                        setDepartmentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setDepartmentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        setGraduationTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGraduationTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCourseList(departmentId: number, graduationTypeId: number) {
        getCourse(departmentId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.course;
                    if (data != undefined) {
                        setCourseData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCourseData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchList(courseId: number, graduationTypeId: number) {
        getBatchByCourseId(courseId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchByCourseId;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSemesterList(batchMasterId: number) {
        getSemester(batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.semester;
                    if (data != undefined) {
                        setSemesterDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSemesterDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSectionList() {
        getSectionDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSectionDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getAllStudentDetails() {
        getAllStudent(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetails;
                    if (data != undefined) {
                        setAllStudentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setAllStudentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function selectStudentCard() {
        setIsStudentOrClassWise(!isStudentOrClassWise)
        setDataSuccess(false)
        setDepartmentId('')
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setGraduationTypeId('')
        setStudentDetailsId('')
        setStudentDetailsData([])
    }

    function getStudentFeeList(userTypeId: number, studentDetailsId: number) {
        setIsLoader(true);
        getStudentFeeDetails(userTypeId, studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentFeeDetails[0]['feeType'];
                    if (data != undefined) {
                        setIsLoader(false);
                        setStudentFeeDetails(data);
                    } else {
                        setIsLoader(false);
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    toasts(response.data.message, "Error")
                } else {
                    setIsLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
            }
        )
    }

    const updateFeesReceivedValue = (cell: number, amount: any) => {

        studentFeeDetails.forEach((feeType: any) => {

            let feesData = feeType.studentFees
            const newData: any = [];

            feesData.forEach((item: any) => {

                if (item.studentFeeDetailsId == cell) {
                    item.amount = amount
                }
                newData.push(item)
                setFilteredDataToRefresh(newData)
            })
        });

        if (concessionDetails.length == 0) {
            const obj = {
                studentFeeDetailsId: cell,
                amount: amount,
            };
            concessionDetails.push(obj);
        } else {
            let selectedItem: any;
            concessionDetails.forEach((item: any, index: any) => {
                if (item['studentFeeDetailsId'] == cell) {
                    selectedItem = item
                    selectedItem['amount'] = amount
                }
            });
            if (selectedItem != undefined) {
                selectedItem['amount'] = amount
            } else {
                var obj
                obj = {
                    studentFeeDetailsId: cell,
                    amount: amount,
                };
                concessionDetails.push(obj);
            }
        }
    }

    function setStudentFeeConcessionDetails() {
        return ({
            studentDetailsId: studentDetailsId?.value,
            concessionTypeId: 3,
            concessionDetails: concessionDetails,
            remarks: approvedConcessionForm.values.remarks,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function payFeesSubmit() {
        showLoader(true)
        if (approvedConcessionForm.isValid) {
            let approvedConcessionPostData = setStudentFeeConcessionDetails();
            addStudentFeeConcession(approvedConcessionPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getStudentFeeList(userTypeId, studentDetailsId?.value);
                        approvedConcessionForm.resetForm()
                        setConcessionDetails([]);
                        setDataSuccess(false)
                        setIsOpenListCard(true);
                        //selectStudentCard()
                        setDepartmentId('')
                        setCourseId('')
                        setBatchMasterId('')
                        setSemesterDetailsId('')
                        setSectionDetailsId('')
                        setGraduationTypeId('')
                        setStudentDetailsId('')
                        setStudentDetailsData([])
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (approvedConcessionForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function selectSemesterFee() {
        getSemesterFeesForTuitionFees(studentDetailsId?.value, 1);
        getSemesterFeesForTransportFees(studentDetailsId?.value, 4);
    }

    function addStudentAdvanceFeeForTutionValue(cell: any, courseFeeMasterId: number, feeTypeId: number, feeAmount: number) {

        const newData: any = [];

        semesterFeesForTutionFeesData.forEach((item: any) => {

            if (item.semesterDetailsId == cell) {
                item.isChecked = true
            }
            newData.push(item)
            setFilteredDataToRefresh(newData)
        });

        if (studentAdvanceFee.length == 0) {

            var obj
            obj = {
                semesterDetailsId: cell,
                cfmOrTrasportDetailsId: courseFeeMasterId,
                feeTypeId: feeTypeId,
                amount: feeAmount,
            };
            studentAdvanceFee.push(obj);
        }
        else {
            let selectedItem: any
            studentAdvanceFee.forEach((item: any, index: number) => {
                if (item['semesterDetailsId'] == cell) {
                    selectedItem = item
                }
            });
            if (selectedItem != undefined) {
                const newData: any = [];

                semesterFeesForTutionFeesData.forEach((item: any) => {

                    if (item.semesterDetailsId == cell) {
                        item.isChecked = false
                    }
                    newData.push(item)
                    setFilteredDataToRefresh(newData)
                });

                studentAdvanceFee.splice(studentAdvanceFee.indexOf(selectedItem), 1);
                selectedItem['semesterDetailsId'] = cell
            }
            else {
                var obj
                obj = {
                    semesterDetailsId: cell,
                    cfmOrTrasportDetailsId: courseFeeMasterId,
                    feeTypeId: feeTypeId,
                    amount: feeAmount,
                };
                studentAdvanceFee.push(obj);
            }
        }
    }

    function addStudentAdvanceFeeForTrasportValue(cell: number, cfmOrTrasportDetailsId: any, feeTypeId: number, feeAmount: number) {

        const newData: any = [];

        semesterFeesForTransportFeesData.forEach((item: any) => {

            if (item.semesterDetailsId == cell) {
                item.cfmOrTrasportDetailsId = cfmOrTrasportDetailsId
                item.isChecked = true
            }
            newData.push(item)
            setFilteredDataToRefresh(newData)
        });

        if (studentAdvanceFee.length == 0) {

            var obj
            obj = {
                semesterDetailsId: cell,
                cfmOrTrasportDetailsId: cfmOrTrasportDetailsId,
                feeTypeId: feeTypeId,
                amount: feeAmount,
            };
            studentAdvanceFee.push(obj);
        }
        else {
            let selectedItem: any
            studentAdvanceFee.forEach((item: any, index: number) => {
                if (item['semesterDetailsId'] == cell) {
                    selectedItem = item
                }
            });
            if (selectedItem != undefined) {
                studentAdvanceFee.splice(studentAdvanceFee.indexOf(selectedItem), 1);

                const newData: any = [];

                semesterFeesForTransportFeesData.forEach((item: any) => {

                    if (item.semesterDetailsId == cell) {
                        item.cfmOrTrasportDetailsId = cfmOrTrasportDetailsId
                        item.isChecked = false
                    }
                    newData.push(item)
                    setFilteredDataToRefresh(newData)
                });

                selectedItem['semesterDetailsId'] = cell
            }
            else {
                var obj
                obj = {
                    semesterDetailsId: cell,
                    cfmOrTrasportDetailsId: cfmOrTrasportDetailsId,
                    feeTypeId: feeTypeId,
                    amount: feeAmount,
                };
                studentAdvanceFee.push(obj);
            }
        }
    }

    function getSemesterFeesForTuitionFees(studentDetailsId: number, feeTypeId: number) {
        getNotAssignedSemesterFees(studentDetailsId, feeTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.notAssignedSemesterFees;
                    if (data != undefined) {
                        setSemesterFeesForTuitionFeesData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setAdvanceTuitionFeesNoData(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSemesterFeesForTransportFees(studentDetailsId: number, feeTypeId: number) {
        getNotAssignedSemesterFees(studentDetailsId, feeTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.notAssignedSemesterFees;
                    if (data != undefined) {
                        setSemesterFeesForTransportFeesData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setAdvanceTransportFeesNoData(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setCollectAdvanceFeeDetails() {
        return ({
            studentAdvanceFee: studentAdvanceFee,
            studentDetailsId: studentDetailsId?.value,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function payAdvanceFeesSubmit() {

        if (studentAdvanceFee.length > 0) {
            showLoader(true)
            let payAdvanceFeesPostData = setCollectAdvanceFeeDetails();
            addStudentAdvanceFee(payAdvanceFeesPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getStudentFeeList(userTypeId, studentDetailsId?.value);
                        setStudentAdvanceFee([])
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (studentAdvanceFee.length == 0) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function EditConcession(e: any) {
        const c = e.target.value
        setEditConcession(c)
        balanceCalculation(editTotal, editFeesReceived, c)
    }

    function balanceCalculation(count1: any, count2: any, count3: any) {
        const b = count1 - count2 - count3;
        setBAlance(b)
    }

    function setEditConcessionFeeDetails() {
        return {
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
            studentFeeDetailsId: editConcessionFeesForm.values.studentFeeDetailsId,
            totalFees: editConcessionFeesForm.values.totalFees,
            feeReceived: editConcessionFeesForm.values.feesReceived,
            concession: editConcessionFeesForm.values.concessionFees != '' ? editConcessionFeesForm.values.concessionFees : 0,
            balanceToBePaid: balance,
            isRemove: isRemove,
            remarks: editConcessionFeesForm.values.remarks
        }
    }

    function addOrUpdStudentConcessionFeeDetailsSubmit() {
        showLoader(true)
        if (editConcessionFeesForm.isValid) {
            let addOrUpdateStudentConcessionFeeDetailsPostData = setEditConcessionFeeDetails();
            console.log(addOrUpdateStudentConcessionFeeDetailsPostData);
            addOrUpdStudentFeeConcession(addOrUpdateStudentConcessionFeeDetailsPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset();
                        getStudentFeeList(userTypeId, studentDetailsId?.value);
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (editConcessionFeesForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    const [dataStatus, setDataStatus] = useState(false)

    function getStudentFeeDetailsHistoryList(studentFeeDetailsId: any, isFeesDetails: any) {
        setDataStatus(false)
        getStudentFeeDetailsHistory(
            studentFeeDetailsId, isFeesDetails,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentFeeDetailsHistory;
                    if (data != undefined) {
                        setStudentFeeDetailsHistoryData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        setDataStatus(true)

                    } else {
                        setDataStatus(true)
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    //toasts(response.data.message, 'Error');
                    setStudentFeeDetailsHistoryData([]);
                    setNoDataMsg(response.data.message);
                    setDataStatus(true)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                    setDataStatus(true)
                }
            },
            (error) => {
                setDataStatus(true)
                toasts(error, 'Error');
            },
        );
    }

    function closeAndReset() {
        setStudentAdvanceFee([])
        setAddAdvanceFeeOffCanvas(false)
        editConcessionFeesForm.resetForm()
        setStudentAdvanceFee([])
        setIsRemove(0)
        setIsDelete(false)
        setEditConcessionFeesAmount(false)
    }

    return (
        <PageWrapper title={`Approved Concession`}>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-9">
                                <CardTitle tag='div' className='h5'>Add Concession</CardTitle>
                            </CardLabel>
                            <div className='col-lg-3'>
                                <CardActions>
                                    <Button
                                        icon='Contacts'
                                        color='info'
                                        isLight={isStudentOrClassWise ? false : true}
                                        onClick={selectStudentCard}>
                                        Student
                                    </Button>

                                    <Button
                                        icon='LocalPolice'
                                        color='info'
                                        isLight={isStudentOrClassWise ? true : false}
                                        onClick={selectStudentCard}>
                                        Class Wise
                                    </Button>
                                </CardActions>
                            </div>
                        </CardHeader>
                        {isStudentOrClassWise ?
                            <CardBody>
                                <div className='row'>
                                    <div className="col-4 mt-3">
                                        <FormGroup id='studentDetailsId' label='Student' isFloating>
                                            <SearchableSelect isFloating ariaLabel='Student' onChange={selectStudent} value={studentDetailsId}
                                                list={allStudentData.map((data: any) => (
                                                    { value: data.studentDetailsId, label: data.studentNameAndAdmissionNo }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div>

                                    <div className="col-3 mt-4">
                                        <Button icon='ArrowDownward' color='primary' isDisable={studentDetailsId == 0} onClick={viewParticularStudent}>View</Button>
                                    </div>
                                </div>
                            </CardBody> :
                            <CardBody>
                                <div className='row'>
                                    <div className='col-3'>
                                        <FormGroup id='departmentId' label='Department' isFloating>
                                            <SearchableSelect isFloating ariaLabel='Department' onChange={selectDepartment} value={departmentId}
                                                list={departmentData.map((data: any) => (
                                                    { value: data.departmentId, label: data.departmentName }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div>
                                    {departmentId ? <div className='col-3'>
                                        <FormGroup id='graduationTypeId' label='Graduation Type' isFloating>
                                            <SearchableSelect isFloating ariaLabel='Graduation Type' onChange={selectGraduationType}
                                                value={graduationTypeId} list={graduationTypeData.map((data: any) => (
                                                    { value: data.graduationTypeId, label: data.graduationType }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div> : null}

                                    {graduationTypeId != '' ? <div className='col-3'>
                                        <FormGroup id='courseId' label='Course' isFloating>
                                            <SearchableSelect isFloating ariaLabel='Course' onChange={selectCourse} value={courseId}
                                                list={courseData.map((data: any) => (
                                                    { value: data.courseId, label: data.courseName }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div> : null}

                                    {courseId != '' ? <div className='col-3'>
                                        <FormGroup id='batchMasterId' label='Batch' isFloating>
                                            <SearchableSelect isFloating ariaLabel='Batch' onChange={selectBatch} value={batchMasterId}
                                                list={batchMasterData.map((data: any) => (
                                                    { value: data.batchMasterId, label: data.batchName }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div> : null}

                                    {batchMasterId != '' ? <div className='col-3 mt-3'>
                                        <FormGroup id='semesterDetailsId' label='Semester' isFloating>
                                            <SearchableSelect isFloating ariaLabel='Semester' onChange={selectSemester} value={semesterDetailsId}
                                                list={semesterDetailsData.map((data: any) => (
                                                    { value: data.semesterDetailsId, label: data.semesterNumber }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div> : null}

                                    {semesterDetailsId != '' ? <div className='col-3 mt-3'>
                                        <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                            <SearchableSelect isFloating ariaLabel='Section' onChange={selectSection} value={sectionDetailsId}
                                                list={sectionDetailsData.map((data: any) => (
                                                    { value: data.sectionDetailsId, label: data.sectionName }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div> : null}

                                    {sectionDetailsId != '' ? <div className="col-3 mt-3">
                                        <FormGroup id='studentDetailsId' label='Student' isFloating>
                                            <SearchableSelect isFloating ariaLabel='Student' onChange={selectStudent} value={studentDetailsId}
                                                list={studentDetailsData.map((data: any) => (
                                                    { value: data.studentDetailsId, label: data.studentNameAndAdmissionNo }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div> : null}

                                    {studentDetailsId != '' ? <div className="col-3 mt-4">
                                        <Button icon='ArrowDownward' color='primary' isDisable={studentDetailsId == 0} onClick={viewParticularStudent}>View</Button>
                                    </div> : null}
                                </div>
                            </CardBody>}
                    </Card>
                </Collapse>

                <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                {dataSuccess ? <>
                    <Card>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-10">
                                <CardTitle tag='div' className='h5'>Add Manual Concession</CardTitle>
                            </CardLabel>
                            <div className='col-lg-2'>
                                <CardActions>
                                    <Button color='storybook' icon='Add' isLight onClick={() => { setAddAdvanceFeeOffCanvas(true); selectSemesterFee() }}>
                                        Add Advance Fees
                                    </Button>
                                </CardActions>
                            </div>
                        </CardHeader>

                        <CardBody className='table-responsive'>
                            <div className="mt-2">
                                {studentProfileData.map((student: any) => (
                                    <div className='row g-4' key={student.studentDetailsId}>
                                        <div className='col-xl-4'>
                                            <Card stretch className='border border-light rounded-1'>
                                                <CardBody>
                                                    <div className="row">
                                                        <div className='col-9'>
                                                            <CardLabel>
                                                                <CardTitle tag='div' className='h5 text-success'>{student.studentName}</CardTitle>
                                                            </CardLabel>
                                                            <div className='fw-bold text-muted fs-6 mb-0'>{student.admissionNo}</div>
                                                        </div>
                                                        <div className='col-2'>
                                                            <img style={{ borderRadius: '50%' }}
                                                                src={student.profilePath != null ? student.profilePath : profilePic(student.genderId)}
                                                                height='50' width='50' onError={(e: any) => pictNotLoading(e, student.genderId)}
                                                            />
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </div>
                                        <div className='col-xl-4'>
                                            <Card stretch className='border border-light rounded-1'>
                                                <CardBody>
                                                    <div className="row">
                                                        <div className='col-9'>
                                                            <CardLabel>
                                                                <CardTitle tag='div' className='h5 text-info'>{student.courseName}</CardTitle>
                                                            </CardLabel>
                                                            <div className='fw-bold text-muted fs-6 mb-0'>{student.departmentName}</div>
                                                        </div>
                                                        <div className='col-2'>
                                                            <Icon icon='school' size='3x' color='info' />
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </div>
                                        <div className='col-xl-4'>
                                            <Card stretch className='border border-light rounded-1'>
                                                <CardBody>
                                                    <div className="row">
                                                        <div className='col-9'>
                                                            <CardLabel>
                                                                <CardTitle tag='div' className='h5 text-danger'>{student.semesterNumber}</CardTitle>
                                                            </CardLabel>
                                                            <div className='fw-bold text-muted fs-6 mb-0'>{student.batchName}</div>
                                                        </div>
                                                        <div className='col-2'>
                                                            <Icon icon='school' size='3x' color='danger' />
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    </div>))}
                            </div>
                            {studentFeeDetails.map((feeType: any) => (
                                <table className='table table-modern table-hover text-center' key={feeType.studentDetailsId}>
                                    <thead>
                                        <tr className='table-primary'>
                                            <th scope='col' style={{ width: '20%' }}> {feeType.feeType}</th>
                                            <th scope='col' style={{ width: '15%' }}>TOTAL FEES</th>
                                            <th scope='col' style={{ width: '15%' }}>TOTAL RECEIVED{' '}</th>
                                            <th scope='col' style={{ width: '10%' }}>CONCESSION</th>
                                            <th scope='col' style={{ width: '15%' }}>BALANCE{' '}</th>
                                            {userTypeId == 1 ?
                                                <th scope='col' style={{ width: '15%' }}>ACTION</th>
                                                :
                                                <th scope='col' style={{ width: '15%' }}>AMOUNT</th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody className='align-middle'>
                                        {feeType.studentFees.map((feesData: any) => (
                                            <tr key={feesData.studentFeeDetailsId}>
                                                {userTypeId == 1 ?
                                                    <>
                                                        <td className='fs-6 text-center'>{feesData.semesterNumber}</td>
                                                        <td className='fs-6'>{feesData.totalFees}</td>
                                                        <td className='fs-6  text-success'>{feesData.totalFeesReceived}</td>
                                                        <td className='fs-6  text-info'>{feesData.concession}</td>
                                                        <>{feesData.balanceToBePaid == 0 ? <td className='fs-6 fw-bold text-success'>PAID</td> : <td className='fs-6 text-danger'>{feesData.balanceToBePaid}</td>}</>
                                                        <td className="d-print-none"><Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(feesData) }} />
                                                            <Button className="ms-2" color='success' isLight icon='History' aria-label='Edit' onClick={() => { viewHistory(feesData) }} /></td>
                                                    </>
                                                    : <>
                                                        <td className='fs-6 text-center'>{feesData.semesterNumber}</td>
                                                        <td className='fs-6'>{feesData.totalFees}</td>
                                                        <td className='fs-6 text-success'>{feesData.totalFeesReceived}</td>
                                                        <td className='fs-6 text-info'>{feesData.concession}</td>
                                                        <td className='fs-6 text-danger'>{feesData.balanceToBePaid}</td>
                                                        <>{feesData.balanceToBePaid == 0 ? <td className='fs-6 fw-bold text-success'>PAID</td> : <td><FormGroup id='amount'><Input className="w-100" placeholder="" value={feesData.amount} onInput={(e: any) => updateFeesReceivedValue(feesData.studentFeeDetailsId, e.target.value)} onKeyDown={(e: any) => onlyAllowNumber(e)} /></FormGroup></td>}</>
                                                    </>}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ))}
                        </CardBody>
                        {userTypeId != 1 ?
                            <CardBody>
                                <div className='row h-100 pb-3'>
                                    <div className="col-lg-5">
                                        <FormGroup id="remarks" label="Narration" isFloating>
                                            <Textarea placeholder='Enter Narration' onChange={approvedConcessionForm.handleChange} value={approvedConcessionForm.values.remarks} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3 mt-2">
                                        <Button color='info' icon='Save' type="submit" isDisable={concessionDetails.length == 0} onClick={payFeesSubmit}>Submit</Button>
                                    </div>
                                </div>
                            </CardBody>
                            : null}
                    </Card>
                </> : null}
            </Page>

            <OffCanvas
                setOpen={setAddAdvanceFeeOffCanvas}
                isOpen={addAdvanceFeeOffCanvas}
                titleId='addAdvanceFeeOffCanvas'
                placement='end'
            >
                <OffCanvasHeader setOpen={setAddAdvanceFeeOffCanvas} onClick={closeAndReset}>
                    <OffCanvasTitle id="addAdvanceFeeOffCanvas">Add Advance Fee</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='row'>
                        <div className='col-12'>
                            <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                }-success bg-l${darkModeStatus ? 'o50' : '10'
                                }-success-hover transition-base rounded-2 mb-4`}
                                shadow='sm'>
                                <CardBody>
                                    <div className='d-flex align-items-center pb-3'>
                                        <div className='flex-shrink-0 ms-2'>
                                            <Icon icon='PushPin' size='3x' color='danger' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <span className='text-muted'>
                                                {/* <Icon icon='PushPin' color='danger' className='mx-1' size='lg' />{' '} */}
                                                If future semester not listed. <br /> Please add {''}<Icon icon='East' color='danger' className='mx-1' size='lg' /><Button color='link' tag='a'
                                                    to={`../general/feeMaster`} >Click Here
                                                </Button>
                                            </span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>

                        {semesterFeesForTutionFeesData != '' ?
                            <div className='col-md-12 mt-2'>
                                <Card shadow='none' borderSize={0} stretch>
                                    <Label className="col-md-12 p-2 h5">
                                        <strong className='text-dark'><Icon icon='pending_actions' color='success' className='mx-1' size='2x' />{' '}Tution Fees</strong>
                                    </Label>

                                    <div className="table-responsive">
                                        <table className='table table-modern table-hover text-center'>
                                            <thead>
                                                <tr className='table-primary text-center'>
                                                    <th></th>
                                                    <th>SEMESTER </th>
                                                    <th>AMOUNT </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {semesterFeesForTutionFeesData.map((semesterFeesForTution: any) => (
                                                    <tr className='text-center' key={semesterFeesForTution.semesterDetailsId}>
                                                        <td width={"20px"}>
                                                            <ChecksGroup>
                                                                <Checks className='border border-info' checked={semesterFeesForTution.isChecked} onChange={(e) => addStudentAdvanceFeeForTutionValue(semesterFeesForTution.semesterDetailsId, semesterFeesForTution.courseFeeMasterId, semesterFeesForTution.feeTypeId, semesterFeesForTution.feeAmount)}
                                                                />
                                                            </ChecksGroup>
                                                        </td>
                                                        <td>{semesterFeesForTution.semesterNumber}</td>
                                                        <td>{semesterFeesForTution.feeAmount}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                </Card>
                            </div> :
                            // <div className='text-danger col-12 d-flex justify-content-center'>
                            //     <p className='fw-bold fs-5 mb-0'>{advanceTutionFeesNoData}</p>
                            // </div>
                            null}


                        {semesterFeesForTransportFeesData != '' ?
                            <div className='col-md-12 mt-2'>
                                <Card shadow='none' borderSize={0} stretch>
                                    <Label className="col-md-12 p-2 h5">
                                        <strong className='text-dark'><Icon icon='BusAlert' color='success' className='mx-1' size='2x' />{' '}Transport Fees</strong>
                                    </Label>

                                    <div className="table-responsive">
                                        <table className='table table-modern table-hover text-center'>
                                            <thead>
                                                <tr className='table-primary text-center'>
                                                    <th></th>
                                                    <th>SEMESTER </th>
                                                    <th>AMOUNT </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {semesterFeesForTransportFeesData.map((semesterFeesForTransport: any) => (
                                                    <tr className='text-center' key={semesterFeesForTransport.semesterDetailsId}>
                                                        <td width={"20px"}>
                                                            <ChecksGroup>
                                                                <Checks className='border border-info' checked={semesterFeesForTransport.isChecked} onChange={(e) => addStudentAdvanceFeeForTrasportValue(semesterFeesForTransport.semesterDetailsId,
                                                                    semesterFeesForTransport.studentTrasportDetailsId, semesterFeesForTransport.feeTypeId, semesterFeesForTransport.feeAmount)}
                                                                />
                                                            </ChecksGroup>
                                                        </td>
                                                        <td>{semesterFeesForTransport.semesterNumber}</td>
                                                        <td>{semesterFeesForTransport.feeAmount}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </Card>
                            </div>
                            :
                            // <div className='text-danger col-12 d-flex justify-content-center'>
                            //     <p className='fw-bold fs-5 mb-0'>{advanceTransportFeesNoData}</p>
                            // </div>
                            null}
                    </div>

                </OffCanvasBody>
                {semesterFeesForTutionFeesData != '' || semesterFeesForTransportFeesData != '' ?
                    <div className='row m-0'>
                        <div className='col-12 p-3'>
                            <Button color='info' className='w-100' type="submit" icon="Save" onClick={payAdvanceFeesSubmit}>
                                Save
                            </Button>
                        </div>
                    </div> : null}
            </OffCanvas>

            <OffCanvas
                setOpen={setEditConcessionFeesAmount}
                isOpen={editConcessionFeesAmount}
                isNotClose
                titleId='editConcessionFeesAmount'
                placement='end' tag="form" noValidate onSubmit={editConcessionFeesForm.handleSubmit}>
                <OffCanvasHeader setOpen={setEditConcessionFeesAmount} onClick={() => setEditConcessionFeesAmount(false)}>
                    <OffCanvasTitle id="editConcessionFeesAmount">Update Concession<br />
                        <span className='text-info'>{feeType}{''}<span className='text-success' style={{ marginLeft: '5px' }}>({semester})</span></span>
                    </OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='row g-4'>
                        <div className='col-12'>
                            <FormGroup id='totalFees' label='Total Fees'>
                                <Input placeholder='Enter Total Fees'
                                    onChange={editConcessionFeesForm.handleChange}
                                    value={editConcessionFeesForm.values.totalFees}
                                    onKeyDown={(e: any) => onlyAllowNumber(e)} disabled />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='feesReceived' label='Fees Received'>
                                <Input placeholder='Enter Fees Received'
                                    onChange={editConcessionFeesForm.handleChange}
                                    value={editConcessionFeesForm.values.feesReceived}
                                    onKeyDown={(e: any) => onlyAllowNumber(e)} disabled />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='concessionFees' label='Concession Fees'>
                                <Input placeholder='Enter Concession Fees'
                                    onChange={editConcessionFeesForm.handleChange}
                                    value={editConcessionFeesForm.values.concessionFees}
                                    onInput={EditConcession}
                                    onKeyDown={(e: any) => onlyAllowNumber(e)} />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='balance' label='Balance'>
                                <Input placeholder='Enter Balance'
                                    onChange={editConcessionFeesForm.handleChange}
                                    value={balance}
                                    onKeyDown={(e: any) => onlyAllowNumber(e)} disabled />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='remarks' label='Remarks'>
                                <Textarea rows={3} placeholder='Enter Remarks'
                                    onChange={editConcessionFeesForm.handleChange}
                                    value={editConcessionFeesForm.values.remarks} />
                            </FormGroup>
                        </div>
                    </div>
                </OffCanvasBody>
                <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                    <div className='row m-0'>
                        <div className='col-6 p-3'>
                            <Button color='danger' className='w-100' icon="Delete" onClick={() => { setIsDelete(true); setIsRemove(1) }}>
                                Delete
                            </Button>
                        </div>
                        <div className='col-6 p-3'>
                            <Button color='info' className='w-100' type="submit" icon="Save"> Update</Button>
                        </div>
                    </div>
                </div>
            </OffCanvas>

            <Modal
                setIsOpen={setIsDelete}
                isOpen={isDelete}
                titleId='isDelete'
                isStaticBackdrop
                size='sm'>
                <ModalHeader setIsOpen={() => { { setIsDelete(false); setIsRemove(0) }; setEditConcessionFeesAmount(true) }}>
                    {''}
                </ModalHeader>
                <ModalBody>
                    <div className="row g-3">
                        <div className="col-12 d-flex justify-content-center">
                            <span><b>Are you sure want to Delete ? {''}</b><br />
                                <span className='text-info'>{feeType}{''}<span className='text-success' style={{ marginLeft: '5px' }}>({semester})</span></span>
                            </span>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='remarks' label='Remarks'>
                                <Textarea rows={3} onChange={editConcessionFeesForm.handleChange}
                                    value={editConcessionFeesForm.values.remarks}
                                    placeholder="Enter Remarks" />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <div className='d-flex gap-2 justify-content-end'>
                                <Button color='danger' size={'sm'} onClick={editConcessionFeesForm.handleSubmit}>
                                    Yes
                                </Button>

                                <Button color='info' size={'sm'} onClick={() => { setIsDelete(false); setIsRemove(0); setEditConcessionFeesAmount(true); editConcessionFeesForm.values.remarks == '' }}>
                                    No
                                </Button>
                            </div>
                        </div>
                        <div className='col-10'></div>
                    </div>
                </ModalBody>
            </Modal>

            <Modal
                setIsOpen={setViewUpdateHistory}
                isOpen={viewUpdateHistory}
                titleId='viewUpdateHistory'
                isStaticBackdrop
                isScrollable
                size='lg'>
                <ModalHeader className="modal-header" setIsOpen={() => { setViewUpdateHistory(false) }}>
                    <ModalTitle id='viewUpdateHistory'>
                        Concession History - <span className='text-info'>{feeType}{''}<span className='text-success' style={{ marginLeft: '5px' }}>({semester})</span></span>
                    </ModalTitle>
                </ModalHeader>
                <ModalBody >
                    <div className='row g-4'>
                        <div className='col-lg-12'>
                            <table className='table table-modern table-hover text-center scrollable' >
                                <thead className='table-info'>
                                    <tr >
                                        <th scope='col' style={{ width: '15%' }}>S.NO</th>
                                        <th scope='col' style={{ width: '15%' }}>TOTAL FEE</th>
                                        <th scope='col' style={{ width: '15%' }}>TOTAL RECEIVED</th>
                                        <th scope='col' style={{ width: '10%' }}>CONCESSION</th>
                                        <th scope='col' style={{ width: '15%' }}>BALANCE</th>
                                        <th scope='col' style={{ width: '15%' }}>STATUS</th>
                                        <th scope='col' style={{ width: '15%' }}>DATE</th>
                                        <th scope='col' style={{ width: '15%' }}>REMARKS</th>
                                    </tr>
                                </thead>
                                <tbody className='align-middle'>
                                    {!dataStatus ?
                                        <tr>
                                            <td className='align-self-center' colSpan={8}>
                                                <TableLoader />
                                            </td>
                                        </tr>
                                        :
                                        <>
                                            {studentFeeDetailsHistoryData != '' ? (
                                                <>
                                                    {studentFeeDetailsHistoryData.map((feesHistoryData: any) => (
                                                        <tr key={feesHistoryData.studentFeeDetailsHistoryId}>
                                                            <td>{feesHistoryData.sno}</td>
                                                            <td className='align-self-center'>{feesHistoryData.totalFees}</td>
                                                            <td className='align-self-center'>{feesHistoryData.feeReceived}</td>
                                                            <td className='align-self-center'>{feesHistoryData.concession}</td>
                                                            <td className='align-self-center'>{feesHistoryData.balanceToBePaid}</td>
                                                            <td className='align-self-center'>{feesHistoryData.status}</td>
                                                            <td className='align-self-center'>{feesHistoryData.date}</td>
                                                            <td className='align-self-center'>{feesHistoryData.remarks}</td>
                                                        </tr>))}
                                                </>
                                            ) : (
                                                <NoDataMsg columnsCount={8} msg={noDataMsg} />
                                            )}
                                        </>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </PageWrapper>
    )
}
export default ApprovedConcession