import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
import Spinner from '../../../components/bootstrap/Spinner';
import { toasts } from '../../../services/toast.service';
import { useFormik } from 'formik';
import { loginForStaff, loginForStudent, validateStudent, validateUserFromEmail } from '../../../services/login.service';
import { getLicenseKey } from '../../../services/application.settings';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
				<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};

LoginHeader.defaultProps = {
	isNewUser: false,
};

interface ILoginProps {
	isSignUp?: boolean;
}

const Login: FC<ILoginProps> = ({ isSignUp }) => {

	const { setUserAccountId, setUserTypeId, setIsPayrollConnected } = useContext(AuthContext);

	const { darkModeStatus } = useDarkMode();

	const [signInPassword, setSignInPassword] = useState<boolean>(false);
	const [userName, setUserName] = useState<any>('');
	const [studentLoginStatus, setStudentLoginStatus] = useState<boolean>(false);

	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/dashboard'), [navigate]);
	const handleOnClickForStudent = useCallback(() => navigate('../academic/studentFeeDetails'), [navigate]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	// const [focused, setFocused] = useState<Focused>('number');
	// const handleInputFocus = ({ target }: { target: { name: Focused } }) => setFocused(target.name);

	const staffLoginForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			emailOrMobile: '',
			password: '',
		},
		validate: (values) => {
			const errors: { emailOrMobile?: string; password?: string } = {};

			if (!values.emailOrMobile) {
				errors.emailOrMobile = 'Required';
			} else if (
				!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailOrMobile)
			) {
				errors.emailOrMobile = 'Invalid email address';
			}

			if (!values.password) {
				errors.password = 'Required';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => { staffLogin() },
	});

	const studentLoginForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			admissionNo: '',
			dateOfBirth: '',
		},
		validate: (values) => {
			const errors: { admissionNo?: string; dateOfBirth?: string } = {};

			if (!values.admissionNo) {
				errors.admissionNo = 'Required';
			}
			if (!values.dateOfBirth || values.dateOfBirth.includes('_')) {
				errors.dateOfBirth = 'Required';
			}
			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => { studentLogin() },
	});

	function setLogin() {
		return {
			emailOrMobile: staffLoginForm.values.emailOrMobile,
			password: staffLoginForm.values.password
		}
	}

	function setStudentLogin() {
		return {
			admissionNo: studentLoginForm.values.admissionNo,
			dateOfBirth: formatDateForDB(studentLoginForm.values.dateOfBirth)
		}
	}

	function setValidateStaffDetails() {

		return ({
			email: staffLoginForm.values.emailOrMobile,
			licenseKey: getLicenseKey,
		})
	}

	function setValidateStudentDetails() {

		return ({
			admissionNo: studentLoginForm.values.admissionNo,
			licenseKey: getLicenseKey,
		})
	}

	const handleContinue = (emailOrMobile: any) => {
		setIsLoading(true);
		setTimeout(() => {
			if (emailOrMobile != undefined) {
				let validateUserPostData = setValidateStaffDetails();
				validateUserFromEmail(validateUserPostData,
					(response) => {
						const data = response.data;
						if (data.success == true) {
							setUserName(response.data.data[0].userName)
							setIsLoading(false);
							setSignInPassword(true);
						}
						else if (data.success == false) {
							staffLoginForm.setFieldError('emailOrMobile', data.message);

							setIsLoading(false);
						}
						else {
							let errorCode = response.data.error[0].error_code;
							let errorDescription = response.data.error[0].error_description;
							toasts(errorDescription, "Error")
							console.log(errorDescription);

						}
					}
					, (error) => {
						toasts(error, "Error")
					}
				)
			} else if (emailOrMobile == undefined) {
				staffLoginForm.setFieldError('emailOrMobile', 'Please fill all the details!');
				setIsLoading(false);
			}
		}, 1000);
	};

	const checkAdmissionNo = (admissionNo: any) => {
		setIsLoading(true);
		setTimeout(() => {

			if (admissionNo != undefined) {
				let validateUserPostData = setValidateStudentDetails();
				validateStudent(validateUserPostData,
					(response) => {
						const data = response.data;
						if (data.success == true) {
							setUserName(response.data.data[0].userName);
							setIsLoading(false);
							setSignInPassword(true);
						}
						else if (data.success == false) {
							studentLoginForm.setFieldError('admissionNo', data.message);
							setIsLoading(false);
						}
						else {
							let errorCode = response.data.error[0].error_code;
							let errorDescription = response.data.error[0].error_description;
							toasts(errorDescription, "Error")
						}
					}
					, (error) => {
						toasts(error, "Error")
					}
				)
			} else if (admissionNo == undefined) {
				staffLoginForm.setFieldError('admissionNo', 'Please fill all the details!');
				setIsLoading(false);
			}
		}, 1000);
	};

	function staffLogin() {
		setIsLoading(true);
		setTimeout(() => {
			if (staffLoginForm.isValid) {
				let loginData = setLogin();
				loginForStaff(loginData,
					(response) => {
						const data = response.data;
						if (data.success == true) {
							toasts(data.message, "Success");
							let loginData = response.data.data.loginForStaff;
							localStorage.setItem('userAccountId', loginData.staffDetailsId);
							localStorage.setItem('userTypeId', loginData.userTypeId);
							localStorage.setItem('isPayrollConnected', loginData.isPayrollConnected);

							if (setUserAccountId) {
								setUserAccountId(loginData.staffDetailsId);
							}
							if (setUserTypeId) {
								setUserTypeId(loginData.userTypeId);
							}
							if (setIsPayrollConnected) {
								setIsPayrollConnected(loginData.isPayrollConnected);
							}

							if (loginData != undefined) {
								let academicPeriodId = loginData.getAcademicPeriodId;
								if (academicPeriodId == 0) {
									navigate('../general/addAcademicPeriod')
									// toasts("Please Add Academic Period and Proceed", "Error")
								}
								else {
									handleOnClick();
								}
								setIsLoading(false);
							} else {
								toasts("Undefined Data", "Error")
							}
						}
						else if (data.success == false) {
							staffLoginForm.setFieldError('password', data.message);
							setIsLoading(false);
						}
						else {
							let errorCode = response.data.error[0].error_code;
							let errorDescription = response.data.error[0].error_description;
							staffLoginForm.setFieldError('password', errorDescription);
							setIsLoading(false);
						}
					}
					, (error) => {
						toasts(error, "Error")
					}
				)
			} else if (staffLoginForm.isValid == false) {

				staffLoginForm.setFieldError('password', 'Please fill all the details!');
				setIsLoading(false);
			}
		}, 1000);
	}

	function studentLogin() {
		setIsLoading(true);
		setTimeout(() => {

			if (studentLoginForm.isValid) {
				let loginData = setStudentLogin()
				loginForStudent(loginData,
					(response) => {
						const data = response.data;
						if (data.success == true) {
							toasts(data.message, "Success");
							let loginData = response.data.data.loginForStudent;
							localStorage.setItem('userAccountId', loginData.studentDetailsId);
							localStorage.setItem('userTypeId', "10");
							if (loginData != undefined) {
								if (setUserAccountId) {
									setUserAccountId(loginData.studentDetailsId);
								}
								if (setUserTypeId) {
									setUserTypeId('10');
								}
								setIsLoading(false);
								handleOnClickForStudent();
							} else {
								toasts("Undefined Data", "Error")
							}
						}
						else if (data.success == false) {
							studentLoginForm.setFieldError('dateOfBirth', data.message);
							setIsLoading(false);
						}
						else {
							let errorCode = response.data.error[0].error_code;
							let errorDescription = response.data.error[0].error_description;
							studentLoginForm.setFieldError('dateOfBirth', errorDescription);
							setIsLoading(false);
						}
					}
					, (error) => {
						toasts(error, "Error")
					}
				)
			} else if (studentLoginForm.isValid == false) {
				studentLoginForm.setFieldError('dateOfBirth', 'Please fill all the details!');
				setIsLoading(false);
			}
		}, 1000);
	}

	function resetForm() {
		staffLoginForm.resetForm();
		studentLoginForm.resetForm();
	}

	const formatDateForDB = (dateString: any): any => {
		const dateDB = dateString.split('/').reverse().join('/');
		return dateDB;
	};

	return (

		<PageWrapper
			isProtected={false}
			title={studentLoginStatus ? 'Student Login' : 'Login'}
			className={classNames({ 'bg-dark': !studentLoginStatus, 'bg-light': studentLoginStatus })}>
			<Page className='p-0' container='fluid'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/login'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}
										aria-label='Facit'>
										<Logo width={160} height={160} />
									</Link>
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-dark': darkModeStatus,
									})}>
									<div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={studentLoginStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setStudentLoginStatus(!studentLoginStatus);
													resetForm()
												}}>
												Staff
											</Button>
										</div>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={!studentLoginStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setStudentLoginStatus(!studentLoginStatus);
													resetForm()
												}}>
												Student
											</Button>
										</div>
									</div>
								</div>

								<LoginHeader isNewUser={studentLoginStatus} />


								{studentLoginStatus ? (
									<>
										<form className='row g-4'>
											<div className='col-12'>
												<FormGroup
													id='admissionNo'
													isFloating
													label='Your Admission No'
													className={classNames({
														'd-none': signInPassword,
													})}>
													<Input
														autoComplete='username'
														value={studentLoginForm.values.admissionNo}
														isTouched={studentLoginForm.touched.admissionNo}
														invalidFeedback={
															studentLoginForm.errors.admissionNo
														}
														isValid={studentLoginForm.isValid}
														onChange={studentLoginForm.handleChange}
														onBlur={studentLoginForm.handleBlur}
														onFocus={() => {
															studentLoginForm.setErrors({});
														}}
														onInput={studentLoginForm.handleBlur}
														onKeyDownCapture={(e: any) => {
															if (e.key === "Enter") {
																checkAdmissionNo(studentLoginForm.values.admissionNo)
															}
														}}
													/>
												</FormGroup>
												{signInPassword && (
													<div className='text-center h4 mb-3 fw-bold'>
														Hi, {userName}.
													</div>
												)}
												<FormGroup
													id='dateOfBirth'
													isFloating
													label='Date Of Birth'
													className={classNames({
														'd-none': !signInPassword,
													})}>

													<Input
														type='text'
														// autoComplete='current-password'
														autoComplete='cc-exp'
														placeholder='DD/MM/YYYY'
														mask="99/99/9999"
														value={studentLoginForm.values.dateOfBirth ? studentLoginForm.values.dateOfBirth : ''}
														isTouched={studentLoginForm.touched.dateOfBirth}
														invalidFeedback={
															studentLoginForm.errors.dateOfBirth
														}
														// onFocus={handleInputFocus}
														validFeedback='Looks good!'
														isValid={studentLoginForm.isValid}
														onChange={studentLoginForm.handleChange}
														onBlur={studentLoginForm.handleBlur}
														onInput={studentLoginForm.handleBlur}
														onKeyDownCapture={(e: any) => {
															if (e.key === "Enter") {
																studentLoginForm.handleSubmit()
															}
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												{!signInPassword ? (
													<Button
														color='warning'
														className='w-100 py-3'
														isDisable={!studentLoginForm.values.admissionNo}
														onClick={() => checkAdmissionNo(studentLoginForm.values.admissionNo)}>
														{isLoading && (
															<Spinner isSmall inButton isGrow />
														)}
														Continue
													</Button>
												) : (
													<Button
														color='warning'
														className='w-100 py-3'
														onClick={studentLoginForm.handleSubmit}
													>{isLoading && (
														<Spinner isSmall inButton isGrow />
													)}
														Login
													</Button>
												)}
											</div>
										</form>
									</>
								) : (
									<>
										<form className='row g-4'>
											<div className='col-12'>
												<FormGroup
													id='emailOrMobile'
													isFloating
													label='Your email'
													className={classNames({
														'd-none': signInPassword,
													})}>
													<Input
														autoComplete='username'
														value={staffLoginForm.values.emailOrMobile}
														isTouched={staffLoginForm.touched.emailOrMobile}
														invalidFeedback={
															staffLoginForm.errors.emailOrMobile
														}
														isValid={staffLoginForm.isValid}
														onChange={staffLoginForm.handleChange}
														onBlur={staffLoginForm.handleBlur}
														onFocus={() => {
															staffLoginForm.setErrors({});
														}}
														onInput={staffLoginForm.handleBlur}
														onKeyDownCapture={(e: any) => {
															if (e.key === "Enter") {
																handleContinue(staffLoginForm.values.emailOrMobile)
															}
														}}
													/>
												</FormGroup>
												{signInPassword && (
													<div className='text-center h4 mb-3 fw-bold'>
														Hi, {userName}.
													</div>
												)}
												<FormGroup
													id='password'
													isFloating
													label='Password'
													className={classNames({
														'd-none': !signInPassword,
													})}>
													<Input
														type='password'
														autoComplete='current-password'
														value={staffLoginForm.values.password}
														isTouched={staffLoginForm.touched.password}
														invalidFeedback={
															staffLoginForm.errors.password
														}
														validFeedback='Looks good!'
														isValid={staffLoginForm.isValid}
														onChange={staffLoginForm.handleChange}
														onBlur={staffLoginForm.handleBlur}
														onInput={staffLoginForm.handleBlur}
														onKeyDownCapture={(e: any) => {
															if (e.key === "Enter") {
																staffLoginForm.handleSubmit()
															}
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												{!signInPassword ? (
													<Button
														color='warning'
														className='w-100 py-3'
														isDisable={!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(staffLoginForm.values.emailOrMobile)}
														onClick={() => handleContinue(staffLoginForm.values.emailOrMobile)}
													>
														{isLoading && (
															<Spinner isSmall inButton isGrow />
														)}
														Continue
													</Button>
												) : (
													<Button
														color='warning'
														className='w-100 py-3'
														onClick={staffLoginForm.handleSubmit}
													>{isLoading && (
														<Spinner isSmall inButton isGrow />
													)}
														Login
													</Button>
												)}
											</div>
										</form>
									</>
								)}

								{/* BEGIN :: Social Login */}
								{!signInPassword && (
									<>
										{/* <div className='col-12 mt-3 text-center text-muted'>
											OR
										</div> */}
										{/* <div className='col-12 mt-3'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomApple'
													onClick={handleOnClick}>
													Sign in with Apple
												</Button>
											</div> */}
										{/* <div className='col-12 mt-3 align-middle'>
											<Button
												isOutline
												color={darkModeStatus ? 'light' : 'dark'}
												className={classNames('w-100 py-3', {
													'border-light': !darkModeStatus,
													'border-dark': darkModeStatus,
												})}
												tag='a'
												href='https://api.campus-uat.suncolleges.ac.in/images/Campus_0.2.3-uat.apk'>
												<Logo width={25} height={25} />  Continue with APP
											</Button>

										</div> */}
									</>
								)}
								{/* END :: Social Login */}
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': studentLoginStatus,
									'link-dark': !studentLoginStatus,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': studentLoginStatus,
									'link-dark': !studentLoginStatus,
								})}>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper >
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
