import React, { useContext, useEffect, useState } from 'react';
import Card, {
	CardBody,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Icon from '../../../../components/icon/Icon';
import useDarkMode from '../../../../hooks/useDarkMode';
import { toasts } from '../../../../services/toast.service';
import AuthContext from '../../../../contexts/authContext';
import { getDashboardDataByUserTypeId } from '../../../../services/dashboard.service';

const TodayAbsentCount = () => {
	const { userAccountId, userTypeId } = useContext(AuthContext);

	useEffect(() => {
		if (userTypeId != '' && userTypeId != '') {
			getDashboardDataByUserTypeIdList(userTypeId, userAccountId);
		}
	}, []);


	const { themeStatus, darkModeStatus } = useDarkMode();
	const [dataStatus, setDataStatus] = useState(false);
	const [isLoader, setIsLoader] = useState(false);
	const [dashboardDataByUserTypeData, setDashboardDataByUserTypeData] = useState<any>([])


	function getDashboardDataByUserTypeIdList(userTypeId: any, userAccountId: any) {
		setDataStatus(false);
		getDashboardDataByUserTypeId(userTypeId, userAccountId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.dashboardDataByUserTypeId[0];
					if (data != undefined) {
						setDataStatus(true);
						setDashboardDataByUserTypeData(data);
					}
					else {
						setDataStatus(true);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setDataStatus(true);
					// toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setDataStatus(true);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setDataStatus(true);
				toasts(error, 'Error');
			},
		);
	}
	return (
		<>
			{userTypeId != 6 ?
				<div className='row g-4 align-items-center'>
					<div className='col-xl-6'>
						<Card stretch className='border border-light rounded-1'>
							<CardBody>
								<div className="row">
									<div className='col-9'>
										<CardLabel>
											<CardTitle tag='div' className='h5 text-info'>{dashboardDataByUserTypeData.getTodayStudentAbsentCount != undefined ? dashboardDataByUserTypeData.getTodayStudentAbsentCount : 0}</CardTitle>
										</CardLabel>
										<div className='fw-bold fs-6 mb-0 text-muted'>Absent Student <span className="fs-6 text-muted">({dashboardDataByUserTypeData.getTotalStudentCount != undefined ? dashboardDataByUserTypeData.getTotalStudentCount : 0})</span></div>
									</div>
									<div className='col-2'>
										<Icon icon='PersonOff' size='3x' color='info' />
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
					<div className='col-xl-6'>
						<Card stretch className='border border-light rounded-1'>
							<CardBody>
								<div className="row">
									<div className='col-9'>
										<CardLabel>
											<CardTitle tag='div' className='h5' style={{ color: '#cc3300' }}>{dashboardDataByUserTypeData.getTodayStaffAbsentCount != undefined ? dashboardDataByUserTypeData.getTodayStaffAbsentCount : 0}</CardTitle>
										</CardLabel>
										<div className='fw-bold fs-6 mb-0 text-muted'>Absent Staff <span className="fs-6 text-muted">({dashboardDataByUserTypeData.getTotalStaffCount != undefined ? dashboardDataByUserTypeData.getTotalStaffCount : 0})</span></div>
									</div>

									<div className='col-2'>
										<Icon icon='PersonOff' size='3x' style={{ color: '#cc3300' }} />
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
				</div> :
				<div className='row g-4 align-items-center'>
					<div className='col-xl-3'>
						<Card stretch className='border border-light rounded-1'>
							<CardBody>
								<div className="row">
									<div className='col-9'>
										<CardLabel>
											<CardTitle tag='div' className='h5 text-info'>{dashboardDataByUserTypeData.getTotalStudentCount != undefined ? dashboardDataByUserTypeData.getTotalStudentCount : 0}</CardTitle>
										</CardLabel>
										<div className='fw-bold fs-6 mb-0 text-muted'>Total Student </div>
									</div>
									<div className='col-2'>
										<Icon icon='PeopleOutline' size='3x' color='info' />
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
					<div className='col-xl-3'>
						<Card stretch className='border border-light rounded-1'>
							<CardBody>
								<div className="row">
									<div className='col-9'>
										<CardLabel>
											<CardTitle tag='div' className='h5' style={{ color: '#cc3300' }}>{dashboardDataByUserTypeData.getTodayStudentAbsentCount != undefined ? dashboardDataByUserTypeData.getTodayStudentAbsentCount : 0}</CardTitle>
										</CardLabel>
										<div className='fw-bold fs-6 mb-0 text-muted'>Absent Student</div>
									</div>
									<div className='col-2'>
										<Icon icon='PersonOff' size='3x' style={{ color: '#cc3300' }} />
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
					<div className='col-xl-3'>
						<Card stretch className='border border-light rounded-1'>
							<CardBody>
								<div className="row">
									<div className='col-9'>
										<CardLabel>
											<CardTitle tag='div' className='h5 text-info'>{dashboardDataByUserTypeData.getTotalStaffCount != undefined ? dashboardDataByUserTypeData.getTotalStaffCount : 0}</CardTitle>
										</CardLabel>
										<div className='fw-bold fs-6 mb-0 text-muted'>Total Staff</div>
									</div>

									<div className='col-2'>
										<Icon icon='PeopleOutline' size='3x' color='info'/>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
					<div className='col-xl-3'>
						<Card stretch className='border border-light rounded-1'>
							<CardBody>
								<div className="row">
									<div className='col-9'>
										<CardLabel>
											<CardTitle tag='div' className='h5' style={{ color: '#cc3300' }}>{dashboardDataByUserTypeData.getTodayStaffAbsentCount != undefined ? dashboardDataByUserTypeData.getTodayStaffAbsentCount : 0}</CardTitle>
										</CardLabel>
										<div className='fw-bold fs-6 mb-0 text-muted'>Absent Staff</div>
									</div>

									<div className='col-2'>
										<Icon icon='PersonOff' size='3x' style={{ color: '#cc3300' }} />
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>}
		</>
	);
};

export default TodayAbsentCount;
