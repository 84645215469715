import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import useDarkMode from '../../../hooks/useDarkMode';
import Icon from '../../icon/Icon';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardSubTitle, CardTitle } from '../../bootstrap/Card';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import Avatar from '../../Avatar';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Input from '../../bootstrap/forms/Input';
import { toasts } from '../../../services/toast.service';
import AuthContext from '../../../contexts/authContext';
import UserImage2Webp from '../../../assets/img/wanna/wanna1.webp';
import UserImage2 from '../../../assets/img/wanna/wanna1.png';
import { useFormik } from 'formik';
import useSortableData from '../../../hooks/useSortableData';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import { downloadExcel, downloadPdfDocument } from '../../../services/ExportService';
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import { getProfileForStudent } from '../../../services/student.service';
import { getFeeCollectionDetailsByFeeCollectionId, getStudentFeeCollection, getStudentFeeDetails, getStudentTotalFeeCollection } from '../../../services/fee.service';
import { getStudentFeeConcession } from '../../../services/concession.service';
import { getColumnsForDataTable, getPaymentConfigDetails, onlyAllowNumber, pictNotLoading, profilePic, updateFilter } from '../../../services/common.service';
import Label from '../../bootstrap/forms/Label';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Select from '../../bootstrap/forms/Select';
import { getCollegeDetails } from '../../../services/college.service';
import dayjs from 'dayjs';
import { useReactToPrint } from "react-to-print";
import CollegeLogo from '../../../assets/logos/logo.jpg';
import signature from '../../../assets/img/signature.png';
import seal from '../../../assets/img/seal.png';
import '../../general/administration/fees-details/receipt.css'
import NoDataMsg from '../../../common/components/NoDataMsg';
import { position } from 'html2canvas/dist/types/css/property-descriptors/position';


const studentFeeDetails = () => {

    const { userAccountId, userTypeId } = useContext(AuthContext);

    useEffect(() => {
        getStudentTotalFeeCollectionList(userAccountId);
        getStudentProfile(userAccountId);
        getStudentFeeConcessionList(userAccountId);
        getStudentFeeCollectionList(userAccountId);
        getStudentFeeList(userTypeId, userAccountId);
        getPaymentConfig();
        getCollege();
    }, []);

    const { themeStatus, darkModeStatus } = useDarkMode();
    const [studentTotalFeeCollectionData, setStudentTotalFeeCollectionData] = useState<any>([])
    const [studentProfileData, setStudentProfileData] = useState<any>([])
    const [studentFeeCollectionData, setStudentFeeCollectionData] = useState<any>([])
    const [studentFeeConcessionData, setStudentFeeConcessionData] = useState<any>([])
    const [studentFeeReceiptData, setStudentFeeReceiptData] = useState<any>([])
    const [studentFeeDetails, setStudentFeeDetails] = useState<any>([])
    const [payingFeesData, setPayingFeesData] = useState<any>([])
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [columnVisibilityDataForFeeCollection, setColumnVisibilityDataForFeeCollection] = useState([]);


    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items: items1, requestSort: requestSort1, getClassNamesFor: getClassNamesFor1 } = useSortableData(studentFeeConcessionData);
    const { items: items, requestSort: requestSort, getClassNamesFor: getClassNamesFor } = useSortableData(studentFeeCollectionData);
    const onCurrentPageData1 = dataPagination(items1, currentPage, perPage);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [payNowModal, setPayNowModal] = useState<boolean>(false);
    const [filteredDataToRefresh, setFilteredDataToRefresh] = useState<any>([])
    const [studentFeesCollectionDetails, setStudentFeesCollectionDetails] = useState<any>([])
    const [totalFeesAmount, setTotalFeesAmount] = useState<any>(0)
    const tableFontSize = 'fs-4';
    const [pdfFileName, setPdfFileName] = useState('')
    const [concessionNoDataMsg, setConcessionNoDataMsg] = useState('')
    const [nodataForPaidBills, setNodataForPaidBills] = useState('')
    const [noDataMsgForStudentFeeDetails, setNoDataMsgForStudentFeeDetails] = useState<any>('')

    const TABS = {
        FEE_DETAILS: 'Fees Details',
        PAID: 'Paid Bills',
        CONCESSION: 'Concession Details',
    };

    const [activeTab, setActiveTab] = useState(TABS.FEE_DETAILS);
    const [receiptOpenModal, setReceiptOpenModal] = useState(false);
    const [payFees, setPayFees] = useState(true);
    const [collegeDetailsData, setCollegeDetailsData] = useState<any>([])
    const [collegeDetailsDataBill, setCollegeDetailsDataForBill] = useState<any>([])
    const [feeDetailsArray, setFeeDetailsArray] = useState<any>([])
    const [balanceAmt, setBalanceAmt] = useState<number>(0)

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityDataForFeeCollection, columnVisibilityForm);

    // Filter
    const filteredData1 = updateFilter(onCurrentPageData1, columnVisibilityData, columnVisibilityForm);

    function onActivate(i: any) {
        if (i != undefined) {
            let selectedStudent = i
            getFeeReceipt(selectedStudent.studentFeeCollectionId);
            setPdfFileName(selectedStudent.receiptNo)
        }
    }

    function getStudentProfile(studentDetailsId: number) {
        getProfileForStudent(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentProfile[0];
                    if (data != undefined) {
                        setStudentProfileData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentFeeCollectionList(studentDetailsId: number) {
        getStudentFeeCollection(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentFeeCollection;
                    if (data != undefined) {
                        setStudentFeeCollectionData(data);
                        getColumnsForTableStudentFeeCollection('getStudentFeeCollection', 'get')
                        getStudentFeeConcessionList(studentDetailsId);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    getColumnsForTableStudentFeeCollection('getStudentFeeCollection', 'get')
                    setStudentFeeCollectionData([]);
                    setNodataForPaidBills('Fees Not Collected Yet.')
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentTotalFeeCollectionList(studentDetailsId: number) {
        getStudentTotalFeeCollection(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentTotalFeeCollection;
                    if (data != undefined) {
                        setStudentTotalFeeCollectionData(data);
                        setBalanceAmt(data[0].semFees_netPayable + data[0].prevSemFees_netPayable + data[0].busFees_netPayable + data[0].prevBusFees_netPayable + data[0].extraFees_netPayable)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentFeeConcessionList(studentDetailsId: number) {
        getStudentFeeConcession(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentFeeConcessionListByStudentDetailsId;
                    if (data != undefined) {
                        setStudentFeeConcessionData(data);
                        getColumnsForTable('getStudentFeeConcessionListByStudentDetailsId', 'get');
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    getColumnsForTable('getStudentFeeConcessionListByStudentDetailsId', 'get');
                    setStudentFeeConcessionData([]);
                    setConcessionNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentFeeList(userTypeId: number, studentDetailsId: number) {
        getStudentFeeDetails(userTypeId, studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentFeeDetails[0]['feeType'];
                    if (data != undefined) {
                        setStudentFeeDetails(data);
                        let payingFeesData = [];
                        for (let i = 0; i < data.length; i++) {
                            let studentFeesData = data[i].studentFees;
                            for (let j = 0; j < studentFeesData.length; j++) {

                                if (Number(studentFeesData[j].balanceToBePaid > 0)) {
                                    payingFeesData.push(studentFeesData[j]);
                                    setPayingFeesData(payingFeesData)
                                }
                            }
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setStudentFeeDetails([]);
                    setNoDataMsgForStudentFeeDetails(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getFeeReceipt(studentFeeCollectionId: number) {
        getFeeCollectionDetailsByFeeCollectionId(studentFeeCollectionId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.feeCollectionDetailsByFeeCollectionId;
                    if (data != undefined) {
                        setStudentFeeReceiptData(data);
                        for (let i = 0; i < data.length; i++) {
                            let feeCollectionDetails = data[i].feeCollectionDetails
                            for (let i = 0; i < feeCollectionDetails.length; i++) {
                                feeCollectionDetails[i].sno = i + 1;
                            }
                        }

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCollege() {
        getCollegeDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.collegeDetails;
                    if (data != undefined) {
                        setCollegeDetailsData(data);
                        setCollegeDetailsDataForBill(data[0])
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(1, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            // setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            // updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function getColumnsForTableStudentFeeCollection(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityDataForFeeCollection = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityDataForFeeCollection(columnVisibilityDataForFeeCollection)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            // setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            // updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    const updateFeesReceivedValue = (cell: number, amount: any) => {

        studentFeeDetails.forEach((feeType: any) => {

            let feesData = feeType.studentFees
            const newData: any = [];

            feesData.forEach((item: any) => {

                if (item.studentFeeDetailsId == cell) {
                    item.amount = amount
                }
                newData.push(item)
                setFilteredDataToRefresh(newData)
            })
        });

        if (studentFeesCollectionDetails.length == 0) {
            const obj = {
                studentFeeDetailsId: cell,
                amount: amount,
            };
            studentFeesCollectionDetails.push(obj);
        } else {
            let selectedItem: any;
            studentFeesCollectionDetails.forEach((item: any, index: any) => {
                if (item['studentFeeDetailsId'] == cell) {
                    selectedItem = item
                    selectedItem['amount'] = amount
                }
            });
            if (selectedItem != undefined) {
                selectedItem['amount'] = amount
            } else {
                var obj
                obj = {
                    studentFeeDetailsId: cell,
                    amount: amount,
                };
                studentFeesCollectionDetails.push(obj);
            }
        }
        totalPayFees()
    }

    function totalPayFees() {
        let totalFeesAmount1 = 0;
        for (let data of studentFeesCollectionDetails) {
            let feeAmount = Number(data.amount)
            totalFeesAmount1 += feeAmount;
        }
        setTotalFeesAmount(totalFeesAmount1);
    }

    const componentRef = useRef(null);

    function checkout() {
        let element: HTMLElement = document.getElementById('paymentSubmit') as HTMLElement;
        element.click();
    }

    const [actionUrl, setActionUrl] = useState<any>('')
    // const [orderId, setOrderId] = useState<any>('')
    // const [merchantId, setActionUrl] = useState<any>('')

    const [paymentConfigDetails, setPaymentConfigDetails] = useState<any>([])

    function getPaymentConfig() {
        getPaymentConfigDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.paymentConfigDetails;
                    if (data != undefined) {
                        setPaymentConfigDetails(data[0])
                        setActionUrl(data[0]['ccavRedirectUrl']);
                    }
                    else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function closeAndReset() {
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function handlePDF() {
        downloadPdfDocument(componentRef.current, pdfFileName)
    }

    return (
        <>
            <PageWrapper title={`Fee Collection`}>
                {payFees ?
                    <SubHeader className='media-breakpoint-down'>
                        <SubHeaderLeft className='left'>
                            <></>
                        </SubHeaderLeft>
                        <SubHeaderRight className='right'>
                            <CardActions>
                                <Button isLight
                                    style={{
                                        animation: 'clickForFeePayment 2s infinite',
                                    }}
                                    icon='ArrowForward' onClick={() => setPayFees(!payFees)} className='text-white'>
                                    Click For Fee Payment
                                </Button>
                            </CardActions>
                        </SubHeaderRight>
                    </SubHeader>
                    :
                    <SubHeader className='media-breakpoint-down'>
                        <SubHeaderLeft className='left'>
                            <Button color='info' isLight icon='ArrowBack' onClick={() => setPayFees(!payFees)}>
                                Back
                            </Button>
                            <SubheaderSeparator className='subheader-separator' />
                        </SubHeaderLeft>
                        <SubHeaderRight className='right'>
                            <CardActions>
                                <Button icon='CreditCard' isLight={balanceAmt > 0 ? true : false} onClick={() => setPayNowModal(true)} isDisable={balanceAmt > 0 ? false : true}
                                    style={{
                                        animation: 'payFees 2s infinite'
                                    }} className='text-white'>
                                    Pay Now
                                </Button>
                            </CardActions>
                        </SubHeaderRight>
                    </SubHeader>
                }
                <Page container='fluid' className='pageDown'>
                    {studentTotalFeeCollectionData.map((studentTotalFee: any) => (
                        <div className='row g-4 ' key={studentTotalFee.studentDetailsId}>
                            <div className='col-md-3'>
                                <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                    }-primary bg-l${darkModeStatus ? 'o50' : '10'
                                    }-primary-hover transition-base rounded-2 mb-4`}
                                    shadow='sm'>
                                    <CardBody>
                                        <div className='d-flex align-items-center pb-3'>
                                            <div className='ratio ratio-1x1 me-3' style={{ width: 48 }}>
                                                <div
                                                    className={classNames(
                                                        'rounded-5',
                                                        'd-flex align-items-center justify-content-center',
                                                        'bg-primary',
                                                    )}>
                                                    <span className='avatar-1 fs-3 text-white fw-bold'>T</span>
                                                </div>
                                            </div>
                                            <div className='flex-grow-1 ms-3'>
                                                <div className='fw-bold fs-3 text-primary mb-0'>
                                                    ₹{studentTotalFee.semFees_grossPayable +
                                                        studentTotalFee.busFees_grossPayable + studentTotalFee.prevSemFees_netPayable + studentTotalFee.prevBusFees_netPayable + studentTotalFee.extraFees_grossPayable + studentTotalFee.prevExtraFees_netPayable}
                                                </div>
                                                <div className='text-muted'>
                                                    <b> TOTAL - {studentTotalFee.semesterNumber}</b>
                                                </div>
                                                {/* <div className='text-muted'>
                                                    <b>{studentTotalFee.semesterNumber}</b>
                                                </div> */}
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className='col-md-3'>
                                <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                    }-success bg-l${darkModeStatus ? 'o50' : '10'
                                    }-success-hover transition-base rounded-2 mb-4`}
                                    shadow='sm'>
                                    <CardBody>
                                        <div className='d-flex align-items-center pb-3'>
                                            <div className='ratio ratio-1x1 me-3' style={{ width: 48 }}>
                                                <div
                                                    className={classNames(
                                                        'rounded-5',
                                                        'd-flex align-items-center justify-content-center',
                                                        'bg-success',
                                                    )}>
                                                    <span className='avatar-1 fs-3 text-white fw-bold'>P</span>
                                                </div>
                                            </div>
                                            <div className='flex-grow-1 ms-3'>
                                                <div className='fw-bold fs-3 text-success mb-0'>
                                                    ₹ {studentTotalFee.semFees_amountPaid +
                                                        studentTotalFee.semFees_concession + studentTotalFee.busFees_amountPaid + studentTotalFee.busFees_concession + studentTotalFee.extraFees_amountPaid + studentTotalFee.extraFees_concession}
                                                </div>
                                                <div className='text-muted'>
                                                    <b>PAID</b>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className='col-md-3'>
                                <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                    }-danger bg-l${darkModeStatus ? 'o50' : '10'
                                    }-danger-hover transition-base rounded-2 mb-4`}
                                    shadow='sm'>
                                    <CardBody>
                                        <div className='d-flex align-items-center pb-3'>
                                            <div className='ratio ratio-1x1 me-3' style={{ width: 48 }}>
                                                <div
                                                    className={classNames(
                                                        'rounded-5',
                                                        'd-flex align-items-center justify-content-center',
                                                        'bg-danger',
                                                    )}>
                                                    <span className='avatar-1 fs-3 text-white fw-bold'>B</span>
                                                </div>
                                            </div>
                                            <div className='flex-grow-1 ms-3'>
                                                <div className='fw-bold fs-3 text-danger mb-0'>
                                                    ₹ {studentTotalFee.semFees_netPayable +
                                                        studentTotalFee.prevSemFees_netPayable + studentTotalFee.busFees_netPayable +
                                                        studentTotalFee.prevBusFees_netPayable + studentTotalFee.extraFees_netPayable}
                                                </div>
                                                <div className='text-muted'>
                                                    <b> BALANCE</b>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className='col-md-3'>
                                <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                    }-warning bg-l${darkModeStatus ? 'o50' : '10'
                                    }-warning-hover transition-base rounded-2 mb-4`}
                                    shadow='sm'>
                                    <CardBody>
                                        <div className='d-flex align-items-center pb-3'>
                                            <div className='ratio ratio-1x1 me-3' style={{ width: 48 }}>
                                                <div
                                                    className={classNames(
                                                        'rounded-5',
                                                        'd-flex align-items-center justify-content-center',
                                                        'bg-warning',
                                                    )}>
                                                    <span className='avatar-1 fs-3 text-white fw-bold'>F</span>
                                                </div>
                                            </div>
                                            <div className='flex-grow-1 ms-3'>
                                                <div className='fw-bold fs-3 text-warning mb-0'>
                                                    ₹ {studentTotalFee.totalFineAmount}
                                                </div>
                                                <div className='text-muted'>
                                                    <b>FINE</b>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    ))}
                    {payFees ?
                        <div className='row h-100 pb-3'>
                            <div className='col-lg-4 col-md-6'>
                                <Card stretch>
                                    <CardHeader borderSize={1}>
                                        <CardLabel icon='Person' iconColor='info'>
                                            <CardTitle tag='div' className='h5'>
                                                Account Details
                                            </CardTitle>
                                            <CardSubTitle tag='div' className='h6'>
                                                Personal Information
                                            </CardSubTitle>
                                        </CardLabel>
                                    </CardHeader>
                                    <CardBody>
                                        <div className='row g-3'>
                                            <div className='col-12'>
                                                <div className="row">
                                                    <div className="col-4 mt-3">
                                                        <img style={{ borderRadius: '50%' }}
                                                            src={studentProfileData.profilePath != null ? studentProfileData.profilePath : profilePic(studentProfileData.genderId)}
                                                            height='100' width='100' onError={(e: any) => pictNotLoading(e, studentProfileData.genderId)}
                                                        />
                                                    </div>
                                                    <div className="col-8">
                                                        <h5 className='fs-5 fw-bold me-2 text-uppercase mt-3'>{studentProfileData.studentName}</h5>
                                                        <div><span className="text-success">{studentProfileData.admissionNo} </span>({studentProfileData.semesterNumber})</div>
                                                        <div>{studentProfileData.courseName}</div>
                                                        <div>{studentProfileData.departmentName}</div>
                                                        <div>{studentProfileData.batchName}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <Button
                                                    icon='LocalPolice'
                                                    color='info'
                                                    className='w-100 p-3'
                                                    isLight={TABS.FEE_DETAILS !== activeTab}
                                                    onClick={() => setActiveTab(TABS.FEE_DETAILS)}>
                                                    {TABS.FEE_DETAILS}
                                                </Button>
                                            </div>

                                            <div className='col-12'>
                                                <Button
                                                    icon='PendingActions'
                                                    color='info'
                                                    className='w-100 p-3'
                                                    isLight={TABS.PAID !== activeTab}
                                                    onClick={() => setActiveTab(TABS.PAID)}>
                                                    {TABS.PAID}
                                                </Button>
                                            </div>
                                            <div className='col-12'>
                                                <Button
                                                    icon='ReceiptLong'
                                                    color='info'
                                                    className='w-100 p-3'
                                                    isLight={TABS.CONCESSION !== activeTab}
                                                    onClick={() => setActiveTab(TABS.CONCESSION)}>
                                                    {TABS.CONCESSION}
                                                </Button>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className='col-lg-8 col-md-6'>
                                {TABS.FEE_DETAILS === activeTab && (
                                    <Card stretch>
                                        <CardHeader className='d-flex flex-wrap'>
                                            <CardLabel icon='Task' iconColor='primary'>
                                                <CardTitle tag='div' className='h5'>
                                                    Fee Details
                                                </CardTitle>
                                            </CardLabel>
                                            {/* <CardActions>
                                                <Button 
                                                    style={{
                                                        animation: 'clickForFeePayment 2s infinite',
                                                    }}
                                                    isLight icon='ArrowForward' onClick={() => setPayFees(!payFees)}>
                                                    Click For Fee Payment
                                                </Button>
                                            </CardActions> */}
                                        </CardHeader>
                                        <CardBody className='table-responsive'>
                                            {studentTotalFeeCollectionData ?
                                                <div className="table-responsive">
                                                    <table className="table table-modern table-hover" >
                                                        <thead className="text-center text-uppercase align-middle">
                                                            <tr>
                                                                <th className='table-info'></th>
                                                                <th className='table-info'>Sem <br />Fees</th>
                                                                <th className='table-info'>Prev - Sem <br />Fees</th>
                                                                <th className='table-info'>Fine <br />Fees</th>
                                                                <th className='table-info'>Bus <br />Fees</th>
                                                                <th className='table-info'>Prev - Sem Bus <br />Fees</th>
                                                                <th className='table-info'>Sem - Fine <br />Fees</th>
                                                            </tr>
                                                        </thead>

                                                        {studentTotalFeeCollectionData.map((studentTotalFeeCollectionData: any) => (
                                                            <tbody className="text-center align-middle" key={studentTotalFeeCollectionData.studentDetailsId}>
                                                                <tr>
                                                                    <th>GROSS <br />PAYABLE</th>
                                                                    <td><strong>{studentTotalFeeCollectionData.semFees_grossPayable}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.prevSemFees_grossPayable}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.totalFineAmount}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.busFees_grossPayable}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.prevBusFees_grossPayable}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.extraFees_grossPayable}</strong></td>
                                                                </tr>
                                                                <tr>
                                                                    <th>AMOUNT PAID</th>
                                                                    <td><strong>{studentTotalFeeCollectionData.semFees_amountPaid}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.prevSemFees_amountPaid}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.totalFineAmount}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.busFees_amountPaid}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.prevBusFees_amountPaid}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.extraFees_amountPaid}</strong></td>
                                                                </tr>
                                                                <tr>
                                                                    <th>CONCESSION</th>
                                                                    <td><strong>{studentTotalFeeCollectionData.semFees_concession}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.prevSemFees_concession}</strong></td>
                                                                    <td><strong>0</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.busFees_concession}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.prevBusFees_concession}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.extraFees_concession}</strong></td>
                                                                </tr>
                                                                <tr>
                                                                    <th>NET PAYABLE</th>
                                                                    <td>
                                                                        {studentTotalFeeCollectionData.semFees_netPayable === 0 ?
                                                                            <strong className="text-success">{studentTotalFeeCollectionData.semFees_netPayable}</strong>
                                                                            :
                                                                            <strong className="text-danger">{studentTotalFeeCollectionData.semFees_netPayable}</strong>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {studentTotalFeeCollectionData.prevSemFees_netPayable === 0 ?
                                                                            <strong className="text-success">{studentTotalFeeCollectionData.prevSemFees_netPayable}</strong>
                                                                            :
                                                                            <strong className="text-danger">{studentTotalFeeCollectionData.prevSemFees_netPayable}</strong>
                                                                        }
                                                                    </td>

                                                                    <td><strong>0</strong></td>

                                                                    <td>
                                                                        {studentTotalFeeCollectionData.busFees_netPayable === 0 ?
                                                                            <strong className="text-success">{studentTotalFeeCollectionData.busFees_netPayable}</strong>
                                                                            :
                                                                            <strong className="text-danger">{studentTotalFeeCollectionData.busFees_netPayable}</strong>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {studentTotalFeeCollectionData.prevBusFees_netPayable === 0 ?
                                                                            <strong className="text-success">{studentTotalFeeCollectionData.prevBusFees_netPayable}</strong>
                                                                            :
                                                                            <strong className="text-danger">{studentTotalFeeCollectionData.prevBusFees_netPayable}</strong>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {studentTotalFeeCollectionData.extraFees_netPayable === 0 ?
                                                                            <strong className="text-success">{studentTotalFeeCollectionData.extraFees_netPayable}</strong>
                                                                            :
                                                                            <strong className="text-danger">{studentTotalFeeCollectionData.extraFees_netPayable}</strong>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        ))}
                                                    </table>
                                                    <br />

                                                    {studentTotalFeeCollectionData.map((studentTotalFeeCollectionData: any) => (
                                                        <div className="col-12 d-flex justify-content-end" key={studentTotalFeeCollectionData.studentDetailsId}>
                                                            <p className='fw-bold fs-5 mb-0'> Balance : </p>
                                                            <strong className={studentTotalFeeCollectionData.semFees_netPayable + studentTotalFeeCollectionData.prevSemFees_netPayable +
                                                                studentTotalFeeCollectionData.busFees_netPayable +
                                                                studentTotalFeeCollectionData.prevBusFees_netPayable +
                                                                studentTotalFeeCollectionData.extraFees_netPayable == 0 ? "text-success" : "text-danger"}   >
                                                                <p className='fw-bold fs-5 mb-0 ms-2'>
                                                                    {studentTotalFeeCollectionData.semFees_netPayable + studentTotalFeeCollectionData.prevSemFees_netPayable +
                                                                        studentTotalFeeCollectionData.busFees_netPayable +
                                                                        studentTotalFeeCollectionData.prevBusFees_netPayable +
                                                                        studentTotalFeeCollectionData.extraFees_netPayable}
                                                                </p>
                                                            </strong>
                                                        </div>
                                                    ))}

                                                </div>
                                                :

                                                <div className='text-danger col-12 d-flex justify-content-center mt-5'>
                                                    <p className='fw-bold fs-5 mb-0 mt-5'>Fees Not Collected Yet . </p>
                                                </div>
                                            }

                                        </CardBody>
                                    </Card>
                                )}
                                {
                                    TABS.PAID === activeTab && (

                                        <Card stretch>
                                            <CardHeader>
                                                <CardLabel icon='PendingActions' iconColor='primary'>
                                                    <CardTitle tag='div' className='h5'>
                                                        Paid Bill Details
                                                    </CardTitle>
                                                    <CardSubTitle tag='div' className='h6'>
                                                        Total Bills:{' '}
                                                        <small className='ms-2'>
                                                            {filteredData.length}
                                                        </small>
                                                    </CardSubTitle>
                                                </CardLabel>
                                                <CardActions>
                                                    <ButtonGroup className="col-lg-12 d-print-none" color='primary'>
                                                        <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                                        <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                                                    </ButtonGroup>
                                                </CardActions>
                                            </CardHeader>
                                            <CardBody className='table-responsive' isScrollable>

                                                <table className='table table-modern table-hover text-nowrap'>
                                                    <thead>
                                                        <tr>
                                                            <th scope='col' onClick={() => requestSort('sno')}
                                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                                            </th>
                                                            {columnVisibilityDataForFeeCollection.map((column: any) => (
                                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                                    className='cursor-pointer text-decoration-underline'>
                                                                    {column.columnName}
                                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                                </th>
                                                            ))}
                                                            <td className="d-print-none" />
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filteredData != '' ?
                                                            <>
                                                                {
                                                                    filteredData.map((rowData: any) => (
                                                                        <tr key={rowData.studentFeeCollectionId}>
                                                                            <td>{rowData.sno}</td>
                                                                            {columnVisibilityDataForFeeCollection.map((column: any) => (
                                                                                (column.isDisplay && rowData[column.keyName]) ?
                                                                                    <td key={`${rowData.studentFeeCollectionId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                                            ))}
                                                                            <td className="d-print-none" onClick={() => { onActivate(rowData) }}>
                                                                                <Button
                                                                                    isOutline
                                                                                    color='success'
                                                                                    isLight
                                                                                    icon='Print' onClick={() => setReceiptOpenModal(true)}>Preview
                                                                                </Button>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </>
                                                            : <NoDataMsg columnsCount={columnVisibilityDataForFeeCollection.length + 2} msg={nodataForPaidBills} />}
                                                    </tbody>
                                                </table>
                                            </CardBody>
                                            <PaginationButtons
                                                data={items}
                                                label='items'
                                                setCurrentPage={setCurrentPage}
                                                currentPage={currentPage}
                                                perPage={perPage}
                                                setPerPage={setPerPage}
                                            />
                                        </Card>
                                    )
                                }
                                {
                                    TABS.CONCESSION === activeTab && (
                                        <Card stretch>
                                            <CardHeader>
                                                <CardLabel icon='ReceiptLong' iconColor='primary'>
                                                    <CardTitle tag='div' className='h5'>
                                                        Given Concession
                                                    </CardTitle>
                                                    <CardSubTitle tag='div' className='h6'>
                                                        Total Concession:{''}
                                                        <small className='ms-2'>
                                                            {studentFeeConcessionData.length}
                                                        </small>
                                                    </CardSubTitle>
                                                </CardLabel>
                                                {/* {studentFeeConcessionData != "" ?
                                                    <>
                                                        <CardActions>
                                                            <Button color='info' icon='CloudDownload' isLight onClick={() => downloadExcel("ConcessionList", studentFeeConcessionData, columnVisibilityData)}>
                                                                Excel
                                                            </Button>
                                                        </CardActions> </> : null} */}
                                            </CardHeader>
                                            <CardBody className='table-responsive' isScrollable>
                                                <table className='table table-modern mb-0 text-center'>
                                                    <thead>
                                                        <tr>
                                                            <th scope='col' onClick={() => requestSort1('sno')}
                                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                                <Icon size='lg' className={getClassNamesFor1('sno')} icon='FilterList' />
                                                            </th>
                                                            {columnVisibilityData.map((column: any) => (
                                                                <th key={column.keyName} scope='col' onClick={() => requestSort1(column.keyName)}
                                                                    className='cursor-pointer text-decoration-underline'>
                                                                    {column.columnName}
                                                                    <Icon size='lg' className={getClassNamesFor1(column.keyName)} icon='FilterList' />
                                                                </th>
                                                            ))}
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filteredData1 != '' ?
                                                            <>
                                                                {filteredData1.map((rowData: any) => (
                                                                    <tr key={rowData.studentFeeConcessionId}>
                                                                        <td>{rowData.sno}</td>
                                                                        {columnVisibilityData.map((column: any) => (
                                                                            (column.isDisplay && rowData[column.keyName]) ?
                                                                                <td key={`${rowData.studentFeeConcessionId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                                        ))}
                                                                        <td>
                                                                            <Button
                                                                                isLink
                                                                                color={rowData.concessionStatusId == 1 ? 'success' : rowData.concessionStatusId == 2 ? 'danger' : 'warning'}
                                                                                icon='Circle'
                                                                                className='text-nowrap'>
                                                                                {rowData.concessionStatus}
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </> : <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={concessionNoDataMsg} />}
                                                    </tbody>
                                                </table>
                                            </CardBody>
                                            {studentFeeConcessionData != "" ? <PaginationButtons
                                                data={items1}
                                                label='items'
                                                setCurrentPage={setCurrentPage}
                                                currentPage={currentPage}
                                                perPage={perPage}
                                                setPerPage={setPerPage}
                                            /> : null}
                                        </Card>
                                    )
                                }
                            </div>
                        </div>
                        :
                        <>
                            {studentFeeDetails != '' ? <>
                                {studentFeeDetails.map((feeType: any) => (
                                    <Card key={feeType.feeTypeId}>
                                        <CardHeader borderSize={1}>
                                            <CardLabel icon='Style' iconColor='info' className="col-lg-5">
                                                <CardTitle tag='div' className='h5'> {feeType.feeType}</CardTitle>
                                            </CardLabel>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="row">
                                                {feeType.studentFees.map((feesData: any) => (
                                                    <div className='col-xl-3 col-lg-3 col-md-3 ' key={feesData.studentFeeDetailsId}>
                                                        <Card className='cursor-pointer shadow-3d-primary shadow-3d-hover'>
                                                            {/* <div className="ribbon-2 " style={{backgroundColor:'green'}}>
                                                                   {feesData.badgeStatusName} 
                                                            </div> */}
                                                            <CardBody>
                                                                <div className="row ms-1 mt-2">
                                                                    <div className="col-12">
                                                                        <CardTitle tag='div' className='h5'>
                                                                            {feesData.semesterNumber}
                                                                        </CardTitle>
                                                                        {feesData.feeTypeId == 4 ?
                                                                            <h6 className="text-muted tx-13 mg-lg-t-10">Area Name :
                                                                                <span className="fw-bold text-primary"> {feesData.areaName}</span>
                                                                            </h6> : null}
                                                                        <h6 className="text-muted tx-13 mg-lg-t-10">Total :
                                                                            <span className="fw-bold text-primary"> {feesData.totalFees}</span>
                                                                        </h6>
                                                                        <h6 className="text-muted tx-13">Paid :
                                                                            <span className="fw-bold text-success"> {feesData.totalFeesReceived}</span>
                                                                        </h6>
                                                                        <h6 className="text-muted tx-13">Concession :
                                                                            <span className="fw-bold text-dark"> {feesData.concession}</span>
                                                                        </h6>
                                                                        <h6 className="text-muted tx-13">Balance :
                                                                            <span className="fw-bold text-danger">
                                                                                <strong> {feesData.balanceToBePaid}</strong>
                                                                            </span>
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                ))}
                                            </div>
                                        </CardBody>
                                    </Card>
                                ))}
                            </>
                                : <p className='text-center'>{noDataMsgForStudentFeeDetails}</p>}
                        </>
                    }

                    <style type="text/css" media="print">
                        {"@page { size: A4 landscape; margin-left: 0.5in; margin-right: 0.5in; margin-top: 0.5in; margin-bottom: 0.5in; }\ }"}
                    </style>

                </Page>

                <Modal
                    setIsOpen={setPayNowModal}
                    isOpen={payNowModal}
                    size='lg'
                >
                    <ModalHeader className='px-4' setIsOpen={setPayNowModal} onClick={closeAndReset}>
                        <ModalTitle id='project-edit'>Pay Fee</ModalTitle>
                    </ModalHeader>
                    <form id="nonseamless" method="post" name="redirect" action={actionUrl}>
                        <Button hidden color='success' icon='CreditCard' isLight onClick={() => checkout()}>
                            Pay Now
                        </Button>
                        <ModalBody>
                            <div className='table-responsive'>
                                <table className='table table-modern table-hover text-center'>
                                    <thead>
                                        <tr className='table-primary text-uppercase'>
                                            <th scope='col' style={{ width: '20%' }}>Type</th>
                                            <th scope='col' style={{ width: '15%' }}>Total</th>
                                            <th scope='col' style={{ width: '15%' }}>Paid</th>
                                            <th scope='col' style={{ width: '15%' }}>Balance</th>
                                            <th scope='col' style={{ width: '15%' }}>Amount</th>
                                        </tr>
                                    </thead><tbody className='align-middle'>{payingFeesData.map((obj: any) => (<tr key={obj.studentFeeDetailsId}><td><div className="media"><div className="media-body my-auto"><div className="card-item-desc mt-0"><Label><h6 className="text-uppercase">{obj.feeType}</h6><h6 className="mt-1 text-info">{obj.semesterNumber}</h6></Label></div></div></div></td><td><Label><p className="text-dark mb-0 mt-1 tx-14 px-1 py-1 text-center">{obj.totalFees}</p></Label></td><td><Label><p className="text-success mb-0 mt-1 tx-14 px-1 py-1 text-center">{obj.totalFeesReceived + obj.concession}</p></Label></td><td><Label><p className="text-danger mb-0 mt-1 tx-14 px-1 py-1 text-center">{obj.balanceToBePaid}</p></Label></td>
                                        <td scope='col' ><FormGroup id='amount' style={{ justifyContent: 'space-around' }}><Input style={{ width: '100px', alignSelf: 'center' }} className='text-center' placeholder="0" name={`feeDetailsArray[${obj.studentFeeDetailsId}]`} value={obj.amount} onInput={(e: any) => updateFeesReceivedValue(obj.studentFeeDetailsId, e.target.value)} onKeyDown={(e: any) => onlyAllowNumber(e)} /></FormGroup></td>
                                    </tr>))}</tbody></table></div>
                            <div className="d-flex justify-content-end flex-nowrap">
                                <div className='h3 mb-3 mt-1' style={{ whiteSpace: 'nowrap' }}>Total Fee :  <span className='display-8 fw-bold text-primary'>{totalFeesAmount}</span>
                                </div>
                                <div style={{marginLeft:'20px'}}>
                                    <Button isDisable={totalFeesAmount > 0 ? false : true}
                                        icon='ArrowForward'
                                        color='primary'
                                        type='submit' id="paymentSubmit">
                                        Pay
                                    </Button>
                                </div>
                            </div>
                            <div className="d-flex col-mg-t-10">
                                <Input type="hidden" name="amount" value={totalFeesAmount} readOnly />
                                <Select ariaLabel='Payment Type' placeholder="Select Payment Type" name="npayment_option" value={'OPTCRDC'} hidden disabled>
                                    <option value="OPTCRDC">Credit Card</option>
                                    <option value="OPTDBCRD">Debit Card</option>
                                    <option value="OPTNBK">Net Banking</option>
                                    <option value="OPTCASHC">Cash Card</option>
                                    <option value="OPTMOBP">Mobile Payments</option>
                                    <option value="OPTEMI">EMI </option>
                                    <option value="OPTWLT">Wallet</option>
                                </Select>
                                <Input type="hidden" name="tid" id="tid" readOnly />
                                <Input type="hidden" name="merchant_id" value={paymentConfigDetails.merchantId} readOnly />
                                <Input type="hidden" name="currency" value={paymentConfigDetails.currency} readOnly />
                                <Input type="hidden" name="redirect_url" value={paymentConfigDetails.redirectUrl} readOnly />
                                <Input type="hidden" name="cancel_url" value={paymentConfigDetails.cancelUrl} readOnly />
                                <Input type="hidden" name="language" value={paymentConfigDetails.ccavLanguage} readOnly />
                                <Input type="hidden" id="card_number" name="card_number" value={paymentConfigDetails.cardNumber} readOnly />
                                <Input type="hidden" name="expiry_month" value={paymentConfigDetails.expiryMonth} readOnly />
                                <Input type="hidden" name="expiry_year" value={paymentConfigDetails.expiryYear} readOnly />
                                <Input type="hidden" name="cvv_number" value={paymentConfigDetails.cvvNumber} readOnly />
                                <Input type="hidden" name="issuing_bank" value={paymentConfigDetails.issuingBank} readOnly />
                                <Input type="hidden" name="mobile_number" value={paymentConfigDetails.mobileNumber} readOnly />
                                <Input type="hidden" name="mm_id" value={paymentConfigDetails.mmId} readOnly />
                                <Input type="hidden" name="otp" value={paymentConfigDetails.otp} readOnly />


                                <Input type="hidden" name="order_id" value={studentProfileData.studentDetailsId} readOnly />
                                <Input type="hidden" name="billing_name" value={studentProfileData.studentName} readOnly />
                                <Input type="hidden" name="billing_address" value={studentProfileData.address} readOnly />
                                <Input type="hidden" name="billing_city" value={studentProfileData.cityName} readOnly />
                                <Input type="hidden" name="billing_state" value={studentProfileData.stateName} readOnly />
                                <Input type="hidden" name="billing_zip" value={studentProfileData.commAddr_Pincode} readOnly />
                                <Input type="hidden" name="billing_country" value={studentProfileData.country} readOnly />
                                <Input type="hidden" name="billing_tel" value={studentProfileData.smsToBeSentNumber} readOnly />
                                <Input type="hidden" name="billing_email" value={studentProfileData.email ? studentProfileData.email : ''} readOnly />
                                <Input type="hidden" name="delivery_name" value={collegeDetailsDataBill.collegeName} readOnly /><Input type="hidden" name="delivery_address" value={collegeDetailsDataBill.address} readOnly />
                                <Input type="hidden" name="delivery_city" value={collegeDetailsDataBill.district} readOnly />
                                <Input type="hidden" name="delivery_state" value={collegeDetailsDataBill.stateName} readOnly />
                                <Input type="hidden" name="delivery_zip" value={collegeDetailsDataBill.pincode} readOnly />
                                <Input type="hidden" name="delivery_country" value={collegeDetailsDataBill.country} readOnly />
                                <Input type="hidden" name="delivery_tel" value={collegeDetailsDataBill.phone} readOnly />
                                <Input type="hidden" name="merchant_param1" value="additional Info." readOnly />
                                <Input type="hidden" name="merchant_param2" value="additional Info." readOnly />
                                <Input type="hidden" name="merchant_param3" value="additional Info." readOnly />
                                <Input type="hidden" name="merchant_param4" value="additional Info." readOnly />
                                <Input type="hidden" name="merchant_param5" value="additional Info." readOnly />
                                <div id="emi_div" hidden>
                                    <table width="100%"><thead><tr><td colSpan={2} >EMI Section </td></tr>
                                        <tr><td> Emi plan id: </td><td><Input readOnly type="text" id="emi_plan_id" name="emi_plan_id" value="" /></td></tr><tr><td> Emi tenure id: </td><td><Input readOnly type="text" id="emi_tenure_id" name="emi_tenure_id" value="" /></td></tr>
                                        <tr><td>Pay Through</td><td><select name="emi_banks" id="emi_banks" disabled></select></td></tr><tr><td colSpan={2}><div id="emi_duration" className="span12"><span className="span12 content-text emiDetails">EMI Duration</span></div></td></tr><tr><td id="processing_fee" colSpan={2}></td></tr></thead></table>
                                    <table id="emi_tbl" cellPadding={0} cellSpacing={0}></table>
                                    {/* <!-- EMI section end --> */}
                                    <Input type="hidden" id="card_type" name="card_type" value="" readOnly />
                                    <select name="card_name" id="card_name" hidden disabled>
                                        <option value="">Select Card Name</option>
                                    </select>
                                    <Input type="hidden" id="data_accept" name="data_accept" readOnly />
                                    <Input type="hidden" name="studentDetailsId" value={userAccountId} readOnly />
                                    <select name="promo_code" id="promo_code" hidden disabled>
                                        <option value="">All Promotions &amp; Offers</option>
                                    </select>
                                </div>
                            </div>
                        </ModalBody>
                    </form>
                </Modal>

                <Modal setIsOpen={setReceiptOpenModal} isOpen={receiptOpenModal} titleId='addCourseCapacity' isStaticBackdrop size='xl'>
                    <ModalHeader className="'modal-header'" setIsOpen={() => { setReceiptOpenModal(false) }}>
                        <div className='d-flex col-4'>
                            <Button size='sm' icon="Downloading" isLight color="success" onClick={handlePDF}>Download</Button>

                            <Button className="ms-2" size='sm' icon="Print" isLight color="primary" onClick={handlePrint}>Print</Button>
                        </div>
                    </ModalHeader>

                    <ModalBody ref={componentRef}>
                        {studentFeeReceiptData.map((studentFeeData: any) => (
                            <div className="modal-body" id='thForReceipt' key={studentFeeData.studentFeeCollectionId}>
                                <table className="table table-border border border-black table-responsive align-middle" style={{ fontFamily: 'Times New Roman' }} id="tdForReceipt">
                                    <tbody style={{ border: "1px solid black" }}>
                                        {collegeDetailsData.map((collegeData: any) => (
                                            <tr key={collegeData.collegeDetailsId}>
                                                <td width="15%" rowSpan={1} align="center">
                                                    <img height="100px" width="100px"
                                                        src={CollegeLogo} />
                                                </td>
                                                <th style={{ width: '64%' }} className="text-center table-border border border-black" align="center">
                                                    <h2 className="text-uppercase" style={{
                                                        fontFamily: 'Bookman Old Style',
                                                        color: 'rgb(61, 57, 57)'
                                                    }}>
                                                        <b style={{ fontSize: '120%' }}>{collegeData.collegeName}</b>
                                                    </h2>

                                                    <b className="fs-7 mg-t-10" style={{ fontFamily: 'Bookman Old Style', color: 'rgb(61, 57, 57)', width: '95%' }}>
                                                        <p className="fs-6">({collegeData.description})<br />{collegeData.address}<br />
                                                            <Icon icon="Email" /> {collegeData.website} <Icon icon="Phone" /> {collegeData.phone} <Icon icon="PhoneAndroid" /> {collegeData.cellNo}</p>
                                                    </b>
                                                </th>
                                                <th style={{ width: '21%' }} className={tableFontSize} align="center">
                                                    <div className='align-middle'>
                                                        <p>Receipt No
                                                            : <strong>{studentFeeData.receiptNo}</strong></p>
                                                        <p className="mg-lg-t-5">Date
                                                            : <strong>{dayjs(studentFeeData.receiptDate).format('DD/MM/YYYY')}</strong></p>
                                                    </div>
                                                </th>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tbody>
                                        <tr className='align-middle'>
                                            <td colSpan={3} className='align-middle'>
                                                <p className="mt-4 fs-4" style={{ marginLeft: "20px" }}>Roll Number : <b>{studentFeeData.studentRollNo}</b>
                                                    <span style={{ marginRight: "20px", float: "right" }}>Semester : <b>
                                                        {studentFeeData.studentSemesterNumber}</b></span>
                                                </p>
                                                <p className="mt-4 fs-4" style={{ marginLeft: "20px" }}>Student Name : <b>{studentFeeData.studentName}</b>
                                                    <span style={{ marginRight: "20px", float: "right" }}>Course / Section : <b>
                                                        {studentFeeData.studentCourseName} / {studentFeeData.sectionName}</b>
                                                    </span>
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr className={tableFontSize}>
                                            <th className={'text-left border border-black'}>SI No.</th>
                                            <th className={'text-left border border-black'}>Particulars</th>
                                            <th className={'text-left border border-black'}>Amount</th>
                                        </tr>
                                        {studentFeeData.feeCollectionDetails.map((feeData: any) => (
                                            <tr className={tableFontSize} key={feeData.feeTypeId}>
                                                <td className="text-left border border-black">
                                                    {feeData.sno}
                                                </td>
                                                <td className="text-left border border-black">
                                                    {feeData.feeType} / {feeData.semesterNumber}
                                                </td>
                                                <td className="text-left border border-black">
                                                    <b>{feeData.totalAmount}</b>
                                                </td>
                                            </tr>
                                        ))}
                                        <tr className={tableFontSize}>
                                            <td></td>
                                            <td className="text-left border border-black align-middle">
                                                Total amount
                                            </td>
                                            <td className="text-left border border-black">
                                                <b> {studentFeeData.feesCollected}</b>
                                            </td>
                                        </tr>
                                        <tr className={tableFontSize}>
                                            <td colSpan={2} align="left" className="align-middle">
                                                Amount in words : <b> {studentFeeData.feesCollectedInWords} Rupees Only/-</b>
                                            </td>
                                            <td align="left" className="align-middle" style={{ whiteSpace: 'nowrap' }}>
                                                Payment Type: <b>{studentFeeData.paymentType}</b>
                                            </td>
                                        </tr>
                                        {studentFeeData.paymentTypeId > 4 ?
                                            <tr style={{ borderTopColor: 'white' }} className={tableFontSize}>
                                                <td colSpan={3}>
                                                    <img height="200px" width="200px" src={signature} style={{ marginTop: '-60px', marginLeft: '30px', background: 'transparent' }} />
                                                    <img height="200px" width="200px" src={seal} style={{ marginTop: '-60px', float: 'right', background: 'transparent' }} />
                                                </td>
                                            </tr>
                                            :
                                            <tr id='tdForReceiptNoBorder' style={{ borderTopColor: 'white' }} className={tableFontSize}>
                                                <td style={{ borderRightColor: 'white', borderTopColor: 'white' }}></td>
                                                <td align="center"
                                                    style={{ borderRightColor: 'white', borderTopColor: 'white' }}>
                                                    <span style={{ float: 'left', marginLeft: '20px' }}>
                                                        <br />
                                                        <b>Seal</b>
                                                    </span>
                                                </td>
                                                <td aria-colspan={1} align="center">
                                                    <br />
                                                    <b>Signature</b>
                                                </td>
                                            </tr>}
                                    </tbody>
                                </table>
                                {studentFeeData.paymentTypeId > 4 ?
                                    <div className="row mt-4">
                                        <div className="col-12">
                                            <p>This is computer generated bill no signature required.</p>
                                        </div>
                                    </div> : null}
                            </div>
                        ))
                        }
                    </ModalBody>
                </Modal>

            </PageWrapper>
        </>
    );
};

export default studentFeeDetails;
