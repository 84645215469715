import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NoDataMsg from "../../../common/components/NoDataMsg";
import Card, { CardHeader, CardLabel, CardTitle, CardBody } from "../../bootstrap/Card";
import { TableLoader } from "../../../services/loader.services";
import { toasts } from "../../../services/toast.service";
import { getCircularDetails } from "../../../services/management.service";

const Circular = () => {

	useEffect(() => {
		getCircularList();
	}, []);

	const [dataStatus, setDataStatus] = useState(false);
	const [circularData, setCircularData] = useState<any>([]);

	const [noDataMsg, setNoDataMsg] = useState('');

	const navigate = useNavigate();

	function getCircularList() {
		getCircularDetails(0,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.circularDetails;
					if (data != undefined) {
						setDataStatus(true);
						setCircularData(data);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
					} else {
						setDataStatus(false);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setDataStatus(true);
					setCircularData([]);
					setNoDataMsg(response.data.message)
					// toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setDataStatus(true);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setDataStatus(true);
				toasts(error, 'Error');
			},
		);
	}

	return (

		<Card stretch data-tour='list'>
			<CardHeader borderSize={1} >
				<CardLabel icon='list' iconColor='info' className='col-lg-12 '>
					<CardTitle tag='div' className='h5 '>
						Circular
					</CardTitle>
				</CardLabel>
			</CardHeader>
			<CardBody style={{ height: '50vh' }} isScrollable>

				{!dataStatus ?
					<TableLoader /> :
					<table className='table table-modern table-hover text-nowrap'>
						<thead>
							<tr>
								<th scope='col'
									className='cursor-pointer text-decoration-underline'>S.No{' '}
								</th>
								<th scope='col'
									className='cursor-pointer text-decoration-underline'>Subject{' '}
								</th>
							</tr>
						</thead>
						<tbody >
							{circularData != '' ?
								<>
									{circularData.map((i: any) => (
										<tr key={i.circularDetailsId}>
											<td>{i.sno}</td>
											<td
												onClick={() =>
													navigate(`/circularContent/${i.circularDetailsId}`)
												}>
												{i.subject}
											</td>
										</tr>
									))}
								</>
								:
								<NoDataMsg columnsCount={2} msg={noDataMsg} />
							}
						</tbody>
					</table>
				}
			</CardBody>
		</Card>

	);
};

export default Circular;
