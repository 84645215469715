
import classNames from 'classnames';
import { useFormik } from 'formik';
import { useEffect, useContext, useState } from 'react';
import CollegeLogo from '../../../assets/logos/logo.jpg';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from 'react-router-dom';
import Button from '../../bootstrap/Button';
import Card, { CardLabel, CardTitle, CardHeader, CardSubTitle, CardActions, CardBody, CardFooter } from '../../bootstrap/Card';
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../../bootstrap/Dropdown';
import Modal, { ModalHeader, ModalTitle, ModalBody } from '../../bootstrap/Modal';
import FormGroup from '../../bootstrap/forms/FormGroup';
import AuthContext from '../../../contexts/authContext';
import useDarkMode from '../../../hooks/useDarkMode';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../layout/SubHeader/SubHeader';
import AlertService from '../../../services/AlertService';
import { getLicenseKey } from '../../../services/application.settings';
import { getCollegeDetails } from '../../../services/college.service';
import { showLoader } from '../../../services/loader.services';
import { toasts } from '../../../services/toast.service';
import Input from '../../bootstrap/forms/Input';
import { addCircularDetails, deleteCircularDetails, getCircularDetailsForCircularContent, updateCircularDetails } from '../../../services/management.service';
import Icon from '../../icon/Icon';


const AddCircularContent = () => {

	useEffect(() => {
		getCircularList();
		getCollege();
	}, []);

	const { userTypeId, userAccountId } = useContext(AuthContext);
	const [dataStatus, setDataStatus] = useState(false);
	const [circularData, setCircularData] = useState<any>([]);
	const [collegeDetailsData, setCollegeDetailsData] = useState<any>([]);
	const [noDataMsg, setNoDataMsg] = useState<any>('');

	const [updateCircularDetailsModal, setUpdateCircularDetailsModal] = useState(false);

	const { themeStatus, darkModeStatus } = useDarkMode();
	const navigate = useNavigate();

	// For Alert
	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);


	const [addContent, setAddContent] = useState<any>('');
	const [editContent, setEditContent] = useState<any>('');

	const addContentDetails = (content: any, delta: any, source: any, editor: any) => {
		setAddContent(editor.getHTML());
	};

	const editContentDetails = (content: any, delta: any, source: any, editor: any) => {
		setEditContent(editor.getHTML());
	};

	const addCircularDetailsForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			//referenceNo: '',
			subject: '',
			content: '',
			circularDate: '',
		},
		validate: (values) => {
			const errors: {
				//referenceNo?: string;
				subject?: string;
				content?: string;
				circularDate?: string;
			} = {};
			// if (!values.referenceNo) {
			// 	errors.referenceNo = 'Required';
			// }
			if (!values.subject) {
				errors.subject = 'Required';
			}
			if (!addContent) {
				errors.content = 'Required';
			}
			if (!values.circularDate) {
				errors.circularDate = 'Required';
			}
			return errors;
		},
		//validateOnChange: false,
		onSubmit: () => {
			addCircularDetailsDataSubmit();
		},
	});

	const updateCircularDetailsForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			circularDetailsId: '',
			referenceNo: '',
			subject: '',
			content: '',
			circularDate: '',
		},
		validate: (values) => {
			const errors: {
				referenceNo?: string;
				subject?: string;
				content?: string;
				circularDate?: string;
			} = {};
			if (!values.referenceNo) {
				errors.referenceNo = 'Required';
			}
			if (!values.subject) {
				errors.subject = 'Required';
			}
			if (!editContent) {
				errors.content = 'Required';
			}
			if (!values.circularDate) {
				errors.circularDate = 'Required';
			}
		},
		//validateOnChange: false,
		onSubmit: () => {
			updateCircularDetailsDataSubmit();
		},
	});

	function onActivate(i: any, type: any) {
		if (i != undefined) {
			let selectedCircular = i;
			setEditContent(i.content)
			updateCircularDetailsForm.setValues({
				referenceNo: selectedCircular.referenceNo,
				subject: selectedCircular.subject,
				content: selectedCircular.content,
				circularDate: selectedCircular.circularDate,
				circularDetailsId: selectedCircular.circularDetailsId,
			});
			if (type == 'edit') {
				setUpdateCircularDetailsModal(true);
			} else if (type == 'delete') {
				setIsDeleteOpen(true)
			}
		}
	}

	function onPrint(i: any) {
		if (i != undefined) {
			let selectedData = i;
			window.open(`/circularContent/${selectedData.circularDetailsId}`);
		}
	}

	function getCircularList() {
		getCircularDetailsForCircularContent(
			userTypeId,
			0,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.circularDetails;
					if (data != undefined) {
						setDataStatus(true);
						setCircularData(data);
					} else {
						setDataStatus(false);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setNoDataMsg(response.data.message)
					setDataStatus(true);
					// toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setDataStatus(true);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setDataStatus(true);
				toasts(error, 'Error');
			},
		);
	}


	function getCollege() {
		getCollegeDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.collegeDetails[0];
					if (data != undefined) {
						setCollegeDetailsData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function setAddCircularDataDetails() {
		return {
			licenseKey: getLicenseKey,
			userTypeId: userTypeId,
			//referenceNo: addCircularDetailsForm.values.referenceNo,
			subject: addCircularDetailsForm.values.subject,
			content: addContent,
			circularDate: addCircularDetailsForm.values.circularDate,
			staffDetailsId: userAccountId,
		};
	}

	function setUpdateCircularDataDetails() {
		return {
			licenseKey: getLicenseKey,
			circularDetailsId: updateCircularDetailsForm.values.circularDetailsId,
			userTypeId: userTypeId,
			//referenceNo: updateCircularDetailsForm.values.referenceNo,
			subject: updateCircularDetailsForm.values.subject,
			content: editContent,
			circularDate: updateCircularDetailsForm.values.circularDate,
			isActive: 1,
			staffDetailsId: userAccountId,
		};
	}

	function setDeleteCircularDataDetails() {
		return {
			licenseKey: getLicenseKey,
			circularDetailsId: updateCircularDetailsForm.values.circularDetailsId,
			staffDetailsId: userAccountId,
		};
	}

	function addCircularDetailsDataSubmit() {
		showLoader(true);
		if (addCircularDetailsForm.isValid) {
			let messageData = setAddCircularDataDetails();
			addCircularDetails(
				messageData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						getCircularList();
						addCircularDetailsForm.resetForm();
						setAddContent('')
						setEditContent('')
						setShowFirstDiv(!showFirstDiv);
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						showLoader(false);
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (addCircularDetailsForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function updateCircularDetailsDataSubmit() {
		showLoader(true);
		if (updateCircularDetailsForm.isValid) {
			let messageData = setUpdateCircularDataDetails();
			updateCircularDetails(
				messageData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						getCircularList();
						setUpdateCircularDetailsModal(false);
						updateCircularDetailsForm.resetForm();
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						showLoader(false);
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (updateCircularDetailsForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function deleteCircularDetailsDataSubmit(i: any) {
		showLoader(true);
		let messageData = setDeleteCircularDataDetails();
		deleteCircularDetails(
			messageData,
			(response) => {
				const data = response.data;
				if (data.success == true) {
					showLoader(false);
					setAlertStatus({ message: data.message, type: 'success' });
					setIsOpen(true);
					getCircularList();
					setUpdateCircularDetailsModal(false);
					setIsDeleteOpen(false)
				} else if (data.success == false) {
					showLoader(false);
					setAlertStatus({ message: data.message, type: 'error' });
					setIsOpen(true);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					showLoader(false);
					setAlertStatus({ message: errorDescription, type: 'error' });
					setIsOpen(true);
				}
			},
			(error) => {
				showLoader(false);
				setAlertStatus({ message: error, type: 'error' });
				setIsOpen(true);
			},
		);
	}

	const [showFirstDiv, setShowFirstDiv] = useState(true);
	const [isDeleteOpen, setIsDeleteOpen] = useState(false);

	// Function to toggle the visibility
	const toggleDiv = () => {
		addCircularDetailsForm.resetForm();
		setShowFirstDiv(!showFirstDiv); // Toggle between true and false
	};

	return (
		<PageWrapper title={'Circular'}>
			<SubHeader>
				<SubHeaderLeft>
					{!showFirstDiv ? (
						<Button color='primary' isLight icon={'ArrowBack'} onClick={toggleDiv}>
							Back to List
						</Button>
					) : (
						<CardLabel icon='Class' iconColor='info'>
							<CardTitle tag='div' className='h5'>
								Circular Details
							</CardTitle>
						</CardLabel>
					)}
				</SubHeaderLeft>

				{userTypeId == 1 ?
					<SubHeaderRight>
						{!showFirstDiv ? (
							<Button
								type='submit'
								color='info'
								isLight
								onClick={addCircularDetailsForm.handleSubmit}
								icon='Save'>
								Submit
							</Button>
						) : (
							<Button color='primary' isLight icon='Create' onClick={toggleDiv}>
								Create
							</Button>
						)}
					</SubHeaderRight> : null
				}
			</SubHeader>
			<Page>
				{showFirstDiv ? (
					<Card shadow={'none'}>
						<CardBody >
							<div className='row '>
								{dataStatus ? <>
									{circularData != '' ? <>
										{circularData.map((i: any) => (
											<Card key={i.circularDetailsId} className='col-lg-4 border border-light ms-3' style={{ width: '32%' }} stretch shadow={'md'}>
												<CardHeader>
													<CardLabel>
														<CardTitle tag='div' className='h5'>
															{i.subject}
														</CardTitle>
														<CardSubTitle tag='div' className='h6'><span className='text-success'>{i.circularDateForView}</span></CardSubTitle>
													</CardLabel>
													{userTypeId == 1 ?
														<CardActions>
															<Dropdown>
																<DropdownToggle hasIcon={false}>
																	<Button
																		icon='MoreHoriz'
																		color={themeStatus}
																		shadow='default'
																		aria-label='Edit'
																	/>
																</DropdownToggle>
																<DropdownMenu isAlignmentEnd>
																	<DropdownItem>
																		<Button
																			icon='Edit'
																			onClick={() => { onActivate(i, 'edit') }}>
																			Edit
																		</Button>
																	</DropdownItem>
																	<DropdownItem isDivider />
																	<DropdownItem>
																		<Button
																			icon='Delete'
																			onClick={() => { onActivate(i, 'delete') }}>
																			Delete
																		</Button>
																	</DropdownItem>
																</DropdownMenu>
															</Dropdown>
														</CardActions> : null
													}
												</CardHeader>
												{/* <CardBody>
											<div className='row align-items-center'>
												<div dangerouslySetInnerHTML={{ __html: i.content }} />
											</div>
										</CardBody> */}
												<CardBody isScrollable className='align-items-center' style={{ maxHeight: '70%' }}>
													<div className='row align-items-center'>
														<div dangerouslySetInnerHTML={{ __html: i.content }} />
													</div>
												</CardBody>
												<CardFooter className='shadow-3d-container'>
													<Button
														color='primary'
														className={`w-100 mb-4 shadow-3d-up-hover shadow-3d-${darkModeStatus ? 'light' : 'dark'
															}`}
														size='lg'
														onClick={() => onPrint(i)}>
														Preview
													</Button>
												</CardFooter>
											</Card>
										))} </> :
										<div className='row'>
											<div className="col-12" style={{ height: '20vh' }}></div>
											<div className='col-12 d-flex justify-content-center'>
												<Icon icon='DoNotDisturb' size={'10x'} />
											</div>
											<div className='fs-4 fw-bold text-center text-uppercase text-danger'>
												{noDataMsg}
											</div>
										</div>
									} </> : null
								}
							</div>
						</CardBody>
					</Card>
				) : (
					<Card>
						<form onSubmit={addCircularDetailsForm.handleSubmit}>
							<CardBody className='table-responsive'>
								<table
									cellPadding={10}
									cellSpacing={10}
									width='100%'
									style={{ fontFamily: 'Times New Roman' }}>
									<tbody style={{ borderBottom: 'solid 1px black' }}>
										<tr>
											<td align='center' width='20%'>
												<img
													height='100px'
													width='100px'
													src={CollegeLogo}
												/>
											</td>
											<th
												className='text-center'
												align='center'
												style={{
													fontFamily: 'Bookman Old Style',
													// color: 'rgb(61, 57, 57)',
													width: '60%',
												}}>
												<h1 className='text-uppercase'>
													<b style={{ fontSize: '90%' }}>
														{' '}
														{collegeDetailsData.schoolName}
													</b>
												</h1>

												<p className='fs-6'>
													({collegeDetailsData.description})<br />
													{collegeDetailsData.address}
													<br />
													<Icon icon='Email' />{' '}
													{collegeDetailsData.website}{' '}
													<Icon icon='Phone' /> {collegeDetailsData.phone}{' '}
													<Icon icon='PhoneAndroid' />{' '}
													{collegeDetailsData.cellNo}
												</p>
											</th>
											<td width='20%'></td>
										</tr>
									</tbody>
									<tbody>
										<tr>
											<th>
												<div className='d-flex flex-nowrap'>
													{/* <FormGroup
														id='referenceNo'
														label='ReferenceNo'
														isFloating>
														<Input
															type='text'
															placeholder='Enter ReferenceNo'
															onChange={
																addCircularDetailsForm.handleChange
															}
															value={
																addCircularDetailsForm.values
																	.referenceNo
															}
															onBlur={
																addCircularDetailsForm.handleBlur
															}
															isValid={addCircularDetailsForm.isValid}
															isTouched={
																addCircularDetailsForm.touched
																	.referenceNo
															}
															invalidFeedback={
																addCircularDetailsForm.errors
																	.referenceNo
															}
														/>
													</FormGroup> */}
												</div>
											</th>
											<th className='text-center' style={{ width: '200%' }}>
												<h1 className='text-uppercase'>
													<b style={{ fontSize: '90%' }}>
														<u>Circular</u>
													</b>
												</h1>
											</th>
											<th style={{ width: '20%' }}>
												<div className='d-flex flex-nowrap'>
													<FormGroup
														id='circularDate'
														label='CircularDate'
														isFloating>
														<Input
															type='date'
															placeholder='Enter Date'
															onChange={
																addCircularDetailsForm.handleChange
															}
															value={
																addCircularDetailsForm.values
																	.circularDate
															}
															onBlur={
																addCircularDetailsForm.handleBlur
															}
															isValid={addCircularDetailsForm.isValid}
															isTouched={
																addCircularDetailsForm.touched
																	.circularDate
															}
															invalidFeedback={
																addCircularDetailsForm.errors
																	.circularDate
															}
														/>
													</FormGroup>
												</div>
											</th>
										</tr>
										<tr>
											<th colSpan={2}>
												<div className='d-flex flex-nowrap'>
													<FormGroup
														id='subject'
														label='Subject'
														isFloating>
														<Input
															type='text'
															placeholder='Enter Subject'
															style={{ width: '400%' }}
															onChange={
																addCircularDetailsForm.handleChange
															}
															value={
																addCircularDetailsForm.values
																	.subject
															}
															onBlur={
																addCircularDetailsForm.handleBlur
															}
															isValid={addCircularDetailsForm.isValid}
															isTouched={
																addCircularDetailsForm.touched
																	.subject
															}
															invalidFeedback={
																addCircularDetailsForm.errors
																	.subject
															}
														/>
													</FormGroup>
												</div>
											</th>
											<th></th>
										</tr>
										<tr>
											<td
												width='100%'
												colSpan={4}
												className='fs-4'
												style={{
													fontFamily: 'Bookman Old Style',
													color: 'rgb(61, 57, 57)',
												}}>
												<ReactQuill
													style={{ height: '25vh' }}
													value={addContent}
													onChange={addContentDetails}
													placeholder="Enter Content"
												/>
												{/* <p>
													<FormGroup id='content'>
														<Textarea
															rows={5}
															style={{ maxBlockSize: 'fitToContent' }}
															placeholder='Enter Content'
															onChange={
																addCircularDetailsForm.handleChange
															}
															value={
																addCircularDetailsForm.values
																	.content
															}
															onBlur={
																addCircularDetailsForm.handleBlur
															}
															isValid={addCircularDetailsForm.isValid}
															isTouched={
																addCircularDetailsForm.touched
																	.content
															}
															invalidFeedback={
																addCircularDetailsForm.errors
																	.content
															}
														/>
													</FormGroup>
												</p> */}
												<br />
												<br />
												<br />
												<label
													style={{ float: 'right', marginRight: '20px' }}
													className={classNames('fw-bold', { 'text-dark': !darkModeStatus, 'text-white': darkModeStatus })}>
													PRINCIPAL
												</label>
											</td>
										</tr>
									</tbody>
								</table>
								<br />
								<br />
								<br />
							</CardBody>
						</form>
					</Card>
				)}
			</Page>

			<Modal
				setIsOpen={setUpdateCircularDetailsModal}
				isOpen={updateCircularDetailsModal}
				id='updateCircularDetailsModal'
				isStaticBackdrop={true}
				//isScrollable={true}
				isCentered
				size={'xl'}>
				<ModalHeader setIsOpen={setUpdateCircularDetailsModal}>
					<ModalTitle id='updateCircularDetailsModal'>Update Circular</ModalTitle>
				</ModalHeader>

				<ModalBody>
					<form noValidate onSubmit={updateCircularDetailsForm.handleSubmit}>
						<table
							cellPadding={10}
							cellSpacing={10}
							width='100%'
							style={{ fontFamily: 'Times New Roman' }}>
							<tbody style={{ borderBottom: 'solid 1px black' }}>
								<tr>
									<td align='center' width='20%'>
										<img
											height='100px'
											width='100px'
											src={CollegeLogo}
										/>
									</td>
									<th
										className='text-center'
										align='center'
										style={{
											fontFamily: 'Bookman Old Style',
											// color: 'rgb(61, 57, 57)',
											width: '60%',
										}}>
										<h1 className='text-uppercase'>
											<b style={{ fontSize: '90%' }}>
												{' '}
												{collegeDetailsData.schoolName}
											</b>
										</h1>

										<p className='fs-6'>
											({collegeDetailsData.description})<br />
											{collegeDetailsData.address}
											<br />
											<Icon icon='Email' /> {collegeDetailsData.website}{' '}
											<Icon icon='Phone' /> {collegeDetailsData.phone}{' '}
											<Icon icon='PhoneAndroid' /> {collegeDetailsData.cellNo}
										</p>
									</th>
									<td width='20%'></td>
								</tr>
							</tbody>
							<tbody>
								<tr>
									<th>
										<div className='d-flex flex-nowrap'>
											<FormGroup
												id='referenceNo'
												label='ReferenceNo'
												isFloating>
												<Input
													type='text'
													placeholder='Enter ReferenceNo'
													onChange={
														updateCircularDetailsForm.handleChange
													}
													value={
														updateCircularDetailsForm.values.referenceNo
													}
													onBlur={updateCircularDetailsForm.handleBlur}
													isValid={updateCircularDetailsForm.isValid}
													isTouched={
														updateCircularDetailsForm.touched
															.referenceNo
													}
													invalidFeedback={
														updateCircularDetailsForm.errors.referenceNo
													}
													disabled
												/>
											</FormGroup>
										</div>
									</th>
									<th style={{ width: '200%' }} className='text-center'>
										<h1 className='text-uppercase'>
											<b style={{ fontSize: '90%' }}>
												<u>Circular</u>
											</b>
										</h1>
									</th>
									<th style={{ width: '40%' }}>
										<div className='d-flex flex-nowrap'>
											<FormGroup
												id='circularDate'
												label='CircularDate'
												isFloating>
												<Input
													type='date'
													placeholder='Enter Date'
													onChange={
														updateCircularDetailsForm.handleChange
													}
													value={
														updateCircularDetailsForm.values
															.circularDate
													}
													onBlur={updateCircularDetailsForm.handleBlur}
													isValid={updateCircularDetailsForm.isValid}
													isTouched={
														updateCircularDetailsForm.touched
															.circularDate
													}
													invalidFeedback={
														updateCircularDetailsForm.errors
															.circularDate
													}
												/>
											</FormGroup>
										</div>
									</th>
								</tr>
								<tr>
									<th colSpan={2}>
										<div className='d-flex flex-nowrap'>
											<FormGroup id='subject' label='Subject' isFloating>
												<Input
													type='text'
													placeholder='Enter Subject'
													style={{ width: '400%' }}
													onChange={
														updateCircularDetailsForm.handleChange
													}
													value={updateCircularDetailsForm.values.subject}
													onBlur={updateCircularDetailsForm.handleBlur}
													isValid={updateCircularDetailsForm.isValid}
													isTouched={
														updateCircularDetailsForm.touched.subject
													}
													invalidFeedback={
														updateCircularDetailsForm.errors.subject
													}
												/>
											</FormGroup>
										</div>
									</th>
									<th></th>
								</tr>
								<tr>
									<td
										width='100%'
										colSpan={4}
										className='fs-4'
										style={{
											fontFamily: 'Bookman Old Style',
											color: 'rgb(61, 57, 57)',
										}}>
										<ReactQuill
											style={{ height: '25vh' }}
											value={editContent}
											onChange={editContentDetails}
											placeholder="Enter Content"
										/>
										{/* <p style={{ width: 'fitToContent' }}>
											<FormGroup id='content'>
												<Textarea
													rows={5}
													style={{ maxBlockSize: 'fitToContent' }}
													placeholder='Enter Content'
													onChange={
														updateCircularDetailsForm.handleChange
													}
													value={updateCircularDetailsForm.values.content}
													onBlur={updateCircularDetailsForm.handleBlur}
													isValid={updateCircularDetailsForm.isValid}
													isTouched={
														updateCircularDetailsForm.touched.content
													}
													invalidFeedback={
														updateCircularDetailsForm.errors.content
													}
												/>
											</FormGroup>
										</p> */}
										<br />
										<br />
										<br />
										<label
											style={{ float: 'right', marginRight: '20px' }}
											className={classNames('fw-bold', { 'text-dark': !darkModeStatus, 'text-white': darkModeStatus })}>
											PRINCIPAL
										</label>
									</td>
								</tr>
							</tbody>
						</table>

						<CardFooter className='d-flex justify-content-end'>
							<Button
								type='submit'
								color='info'
								icon='Save'
								isDisable={
									!updateCircularDetailsForm.isValid &&
									!!updateCircularDetailsForm.submitCount
								}>
								Update{' '}
							</Button>
						</CardFooter>
					</form>
				</ModalBody>
			</Modal>

			<Modal
				setIsOpen={setIsDeleteOpen}
				isOpen={isDeleteOpen}
				titleId='deleteBatchMaster'
				isStaticBackdrop
				size='sm'>
				<ModalHeader><></></ModalHeader>
				<ModalBody>
					<div className="row g-2">
						<div className="col-12 d-flex justify-content-center">
							<h5><strong>Are you sure want to delete ?</strong></h5>
						</div>
						<div className='col-10'>
							<div className='d-flex gap-2 justify-content-end'>
								<Button color='danger' size={'sm'} onClick={deleteCircularDetailsDataSubmit}>
									Yes
								</Button>

								<Button color='info' size={'sm'} onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
									No
								</Button>
							</div>
						</div>
						<div className='col-10'></div>
					</div>
				</ModalBody>
			</Modal>

			<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
		</PageWrapper>
	);
};

export default AddCircularContent;
