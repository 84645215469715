import { useContext, useEffect, useRef, useState } from 'react';
import useSortableData from '../../hooks/useSortableData';
import PaginationButtons, { dataPagination } from '../PaginationButtons';
import { useFormik } from 'formik';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import FormGroup from '../bootstrap/forms/FormGroup';
import Select from '../bootstrap/forms/Select';
import Input from '../bootstrap/forms/Input';
import Button, { ButtonGroup } from '../bootstrap/Button';
import Icon from '../icon/Icon';
import Checks, { ChecksGroup } from '../bootstrap/forms/Checks';
import { toasts } from '../../services/toast.service';
import { getColumnsForDataTable, getGenderDetails, onlyAllowNumber, updateColumnsForDataTable, updateFilter } from '../../services/common.service';
import { getLicenseKey } from '../../services/application.settings';
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../bootstrap/Dropdown';
import AuthContext from '../../contexts/authContext';
import { downloadExcel, convertJsonToPdf, downloadFile } from '../../services/ExportService';
import { useReactToPrint } from 'react-to-print';
import OffCanvas, { OffCanvasHeader, OffCanvasTitle, OffCanvasBody } from '../bootstrap/OffCanvas';
import AlertService from '../../services/AlertService';
import DeleteComponents from '../../common/components/DeleteComponents';
import { addTournamentDetails, deleteTournamentDetails, getEventType, getTournamentDetailsList, getTournamentNameList, updateTournamentDetails } from '../../services/sportsManagement.service';
import { useNavigate } from 'react-router';
import NoDataMsg from '../../common/components/NoDataMsg';
import { TableLoader, showLoader } from '../../services/loader.services';
import SearchableSelect from '../../common/components/SearchableSelect';

const EventManager = () => {

	useEffect(() => {
		getEventList();
		getGender();
		getTournament();
		getTournamentDetails();
	}, [])

	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [allColumnsData, setAllColumnsData] = useState<any>([]);
	const [isChecked, setIsChecked] = useState(false)
	const [eventTypeId,setEventTypeId] = useState<any>();
	const [genderId, setGenderId] = useState<any>([]);
	const [tournamentId, setTournamentId] = useState<any>([]);
	const [columnDataById, setColumnDataById] = useState([])
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const { userAccountId, userTypeId, addAccess, editAccess, deleteAccess } = useContext(AuthContext);
	const [eventManagerData, setEventManagerData] = useState<any>([]);
	const [genderData, setGenderData] = useState<any>([]);
	const [eventTypeData, setEventTypeData] = useState<any>([]);
	const [tournamentData, setTournamentData] = useState<any>([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { items, requestSort, getClassNamesFor } = useSortableData(eventManagerData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);
	const [addEventOffCanvas, setAddEventOffCanvas] = useState(false)
	const [isDeleteOpen, setIsDeleteOpen] = useState(false);
	const componentRef = useRef(null);
	const [isOpen, setIsOpen] = useState(false);
	const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
	const [editTournamentOffCanvas, setEditTournamentOffCanvas] = useState(false)
	const navigate = useNavigate();

	const [dataStatus, setDataStatus] = useState(false)
	const [noDataMsg, setNoDataMsg] = useState('')

	const addEventManagerForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			tournamentDate: '',
			tournamentId: '',
			genderId: '',
			eventTypeId: '',
			totalMember: ''
		},
		validate: (values) => {
			const errors: {
				tournamentDate?: string;
				eventTypeId?: string;
				genderId?: string;
				tournamentId?: string;
				totalMember?: string;
			} = {};

			if (!values.tournamentDate) {
				errors.tournamentDate = 'Required';
			}
			if (!eventTypeId) {
				errors.eventTypeId = 'Required';
			}
			if (!genderId) {
				errors.genderId = 'Required';
			}
			if (!tournamentId) {
				errors.tournamentId = 'Required';
			}
			if (!values.totalMember) {
				errors.totalMember = 'Required';
			}
			return errors;
		},
		//validateOnChange: false,
		onSubmit: () => { TournamentSubmit() },
	});

	const editEventManagerForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			tournamentDetailsId: '',
			tournamentDate: '',
			tournamentName: '',
			totalMember: '',
			genderId: '',
			eventTypeId: '',
		},
		validate: (values) => {
			const errors: {
				tournamentDetailsId?: string;
				tournamentDate?: string;
				tournamentName?: string;
				totalMember?: string;
			} = {};
			if (!values.tournamentDetailsId) {
				errors.tournamentDetailsId = 'Required';
			}
			if (!values.tournamentDate) {
				errors.tournamentDate = 'Required';
			}
			if (!values.tournamentName) {
				errors.tournamentName = 'Required';
			}
			if (!values.totalMember) {
				errors.totalMember = 'Required';
			}
			return errors;
		},
		//validateOnChange: false,
		onSubmit: () => { updateTournamentSubmit() },
	});

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			genderId: '',
			dateOfBirth: '',
			available: false,
		},
		validate: (values) => { },
		onSubmit: () => { },
	});

	function onActivate(i: any) {
		if (i != undefined) {
			let selectedTournament = i
			setEditTournamentOffCanvas(true)
			editEventManagerForm.setValues({
				tournamentDetailsId: selectedTournament.tournamentDetailsId,
				tournamentDate: selectedTournament.tournamentDate,
				tournamentName: selectedTournament.tournamentName,
				totalMember: selectedTournament.totalMember,
				genderId: selectedTournament.genderId,
				eventTypeId: selectedTournament.eventTypeName,
			});
		}
	}

	// Filter
	const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

	const selectEvent = (e: any) => {
		let eventTypeId = e;
		setEventTypeId(eventTypeId);
	}

	const selectGender = (e: any) => {
		let genderId = e;
		setGenderId(genderId);
	}

	const selectTournament = (e: any) => {
		let tournamentId = e;
		setTournamentId(tournamentId);
	}

	function setTournamentDetails() {

		return ({
			licenseKey: getLicenseKey,
			userAccountId: userAccountId,
			eventTypeId: eventTypeId?.value,
			genderId:genderId?.value,
			tournamentId:tournamentId?.value,
			tournamentDate: addEventManagerForm.values.tournamentDate,
			totalMember: addEventManagerForm.values.totalMember
		})
	}

	function setEditTournamentDetails() {
		return ({
			tournamentDetailsId: editEventManagerForm.values.tournamentDetailsId,
			tournamentName: editEventManagerForm.values.tournamentName,
			tournamentDate: editEventManagerForm.values.tournamentDate,
			totalMember: editEventManagerForm.values.totalMember,
			licenseKey: getLicenseKey,
			userAccountId: userAccountId,
		})
	}

	function getTournament() {
		setDataStatus(false)
		getTournamentDetailsList(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.tournamentDetailsList;
					if (data != undefined) {
						getColumnsForTable('getTournamentDetailsList', 'get');
						setEventManagerData(data);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
						setDataStatus(true)
					} else {
						toasts("Undefined Data", "Error")
						setDataStatus(true)
					}
				}
				else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setDataStatus(true)
					getColumnsForTable('getTournamentDetailsList', 'get');
					setEventManagerData([]);
					setNoDataMsg(response.data.message)
				} else {
					setDataStatus(true)
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				setDataStatus(true)
				toasts(error, "Error")
			}
		)
	}

	function getEventList() {
		getEventType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.eventType;
					if (data != undefined) {
						setEventTypeData(data);
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setEventTypeData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function getTournamentDetails() {
		getTournamentNameList(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.tournamentNameList;
					if (data != undefined) {
						setTournamentData(data);
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setTournamentData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function getGender() {
		getGenderDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.genderDetails;
					if (data != undefined) {
						setGenderData(data);
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setGenderData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function TournamentSubmit() {
		showLoader(true)
		if (addEventManagerForm.isValid) {
			let tournamentPostData = setTournamentDetails()
			addTournamentDetails(tournamentPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false)
						setAlertStatus({ message: data.message, type: "success" });
						setIsOpen(true);
						getTournament();
						closeAndFormReset();
					}
					else if (data.success == false) {
						showLoader(false)
						setAlertStatus({ message: data.message, type: "error" });
						setIsOpen(true);
					}
					else {
						showLoader(false)
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: "error" });
						setIsOpen(true);
					}
				}
				, (error) => {
					showLoader(false)
					setAlertStatus({ message: error, type: "error" });
					setIsOpen(true);
				}
			)
		} else if (addEventManagerForm.isValid == false) {
			showLoader(false)
			setAlertStatus({ message: 'Please fill all the details!', type: "error" });
			setIsOpen(true);
		}
	}

	function updateTournamentSubmit() {
		showLoader(true)
		if (editEventManagerForm.isValid) {
			let tournamentPostData = setEditTournamentDetails()
			updateTournamentDetails(tournamentPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false)
						setAlertStatus({ message: data.message, type: "success" });
						setIsOpen(true);
						getTournament();
						closeAndFormReset();
					}
					else if (data.success == false) {
						showLoader(false)
						setAlertStatus({ message: data.message, type: "error" });
						setIsOpen(true);
					}
					else {
						showLoader(false)
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: "error" });
						setIsOpen(true);
					}
				}
				, (error) => {
					showLoader(false)
					setAlertStatus({ message: error, type: "error" });
					setIsOpen(true);
				}
			)
		} else if (editEventManagerForm.isValid == false) {
			showLoader(false)
			setAlertStatus({ message: 'Please fill all the details!', type: "error" });
			setIsOpen(true);
		}
	}

	function deleteTournamentSubmit() {
		showLoader(true)
		// if (editEventManagerForm.isValid) {
		let deletePostData = setEditTournamentDetails()
		deleteTournamentDetails(deletePostData,
			(response) => {
				const data = response.data;
				if (data.success == true) {
					showLoader(false)
					setAlertStatus({ message: data.message, type: "success" });
					setIsOpen(true);
					getTournament();
					closeAndFormReset();
				}
				else if (data.success == false) {
					showLoader(false)
					setAlertStatus({ message: data.message, type: "error" });
					setIsOpen(true);
				}
				else {
					showLoader(false)
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setAlertStatus({ message: errorDescription, type: "error" });
					setIsOpen(true);
				}
			}
			, (error) => {
				showLoader(false)
				setAlertStatus({ message: error, type: "error" });
				setIsOpen(true);
			}
		)
		// } else if (editEventManagerForm.isValid == false) {
		// 	setAlertStatus({ message: 'Please fill all the details!', type: "error" });
		// 	setIsOpen(true);
		// }
	}

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(userTypeId, apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData)
						let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
						setColumnVisibilityData(columnVisibilityData)

						if (type == 'get') {
							const allData = allColumnsData.map((item: any) => item.isDisplay);
							const allColumns = allData.filter((item: any) => item == false);
							setIsChecked(allColumns == '' ? true : false);
						}
						else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

							for (let i = 0; i < nonDefaultValue.length; i++) {

								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
								}
								all.push(obj);
							}
							updateColumnsSubmit('', '', all)
						}
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)

	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

		if (columnVisibilityForm.isValid) {
			setColumnDataById(isDisplay)
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type ? type : [
					{
						columnVisibilityId: columnVisibilityId,
						isDisplay: isDisplay ? 0 : 1
					}
				]
			}

			updateColumnsForDataTable(columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForTable('getTournamentDetailsList', 'get');
					}
					else if (data.success == false) {
						// toasts(data.success, "Error")
					}
					else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, "Error")
					}
				}
				, (error) => {
					toasts(error, "Error")
				}
			)
		} else if (columnVisibilityForm.isValid == false) {
			toasts("Please fill all the details!", "Error")
		}
	}

	function closeAndFormReset() {
		setAddEventOffCanvas(false);
		setEditTournamentOffCanvas(false);
		setIsDeleteOpen(false);
		addEventManagerForm.resetForm();
	}

	// Export Configuration
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	// const minDate = new Date().toISOString().split('T')[0];

	return (
		<PageWrapper title={`Event Manager`}>
			<Page container='fluid'>
				<Card stretch data-tour='list' ref={componentRef} id='pdf'>
					<CardHeader borderSize={1}>
						<CardLabel icon='list' iconColor='info' className={addAccess?'col-lg-5':'col-lg-6'}>
							<CardTitle tag='div' className='h5'>
								Event Manager
							</CardTitle>
						</CardLabel>
						<CardActions className="d-print-none">
							<ButtonGroup color='primary'>
								<Icon className="mt-1" icon='Search' size='2x' color='primary' />
								<Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
							</ButtonGroup>
							<Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
								<DropdownToggle>
									<Button icon='FilterAlt' color='primary' isLight>Filter</Button>
								</DropdownToggle>
								<DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
									<DropdownItem>
										<div className='container py-2'>
											<form className='row g-3'>
												<div className='col-12'>
													<FormGroup>
														<h6>Select All</h6>
														<Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getTournamentDetailsList', 'post')} checked={isChecked} ariaLabel='Available status' />
													</FormGroup>
												</div>
												<div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
													<FormGroup>
														<h6>Columns</h6>
														<ChecksGroup>
															{allColumnsData.map((i: any) => (
																<Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
															))}
														</ChecksGroup>
													</FormGroup>
												</div>
											</form>
										</div>
									</DropdownItem>
								</DropdownMenu>
							</Dropdown>
							<Dropdown isButtonGroup>
								<DropdownToggle>
									<Button color='primary' isLight icon='CloudDownload'> Export </Button>
								</DropdownToggle>
								<DropdownMenu isAlignmentEnd>
									<DropdownItem>
										<Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("TournamentDetailsList", eventManagerData, columnVisibilityData)}> Excel </Button>
									</DropdownItem>
									<DropdownItem>
										<Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(eventManagerData, columnVisibilityData, "TournamentDetailsList")}> PDF
										</Button>
									</DropdownItem>
									<DropdownItem>
										<Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("TournamentDetailsList", eventManagerData, columnVisibilityData)}> CSV </Button>
									</DropdownItem>
									<DropdownItem>
										<Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
									</DropdownItem>
								</DropdownMenu>
							</Dropdown>
							{addAccess?
							<Button color='primary' icon='Add' isLight onClick={() => setAddEventOffCanvas(true)}>
								Add New
							</Button>
							:null
							}
						</CardActions>
					</CardHeader>
					<CardBody className='table-responsive' isScrollable>
						{!dataStatus ?
							<TableLoader /> :
							<table className='table table-modern table-hover text-nowrap'>
								<thead>
									<tr>
										<th scope='col' onClick={() => requestSort('sno')}
											className='cursor-pointer text-decoration-underline'>S.No{' '}
											<Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
										</th>
										{columnVisibilityData.map((column: any) => (
											<th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
												className='cursor-pointer text-decoration-underline'>
												{column.columnName}
												<Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
											</th>
										))}
										{editAccess?
										<td />
										:null
										}
									</tr>
								</thead>
								<tbody>
									{filteredData != "" ?
										<>
											{filteredData.map((rowData: any) => (<tr key={rowData.tournamentDetailsId}>
												<td>{rowData.sno}</td>
												{columnVisibilityData.map((column: any) => (
													(column.isDisplay && rowData[column.keyName]) ?
														<td key={`${rowData.tournamentDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />))}
														{editAccess?
														<td className='d-print-none'>
													<Dropdown isButtonGroup>
														<DropdownToggle>
															<Button color='dark' isLight icon='MoreHoriz' aria-label='MoreHoriz'>More</Button>
														</DropdownToggle>
														<DropdownMenu isAlignmentEnd className='card-body-scrollable' size='lg'>
															<DropdownItem>
																<Button icon="Replay" isLight color="info" onClick={() => { onActivate(rowData) }}>Edit</Button>
															</DropdownItem>
															<DropdownItem>
																<Button icon="Receipt" isLight color="success" onClick={() => navigate(`../sportsManagement/addParticipants/${rowData.tournamentDetailsId}`)}>Add Participants</Button>
															</DropdownItem>
															<DropdownItem>
																<Button icon="Receipt" isLight color="success" onClick={() => navigate(`../sportsManagement/ParticipantsList/${rowData.tournamentDetailsId}`)}>Participants List</Button>
															</DropdownItem>
														</DropdownMenu>
													</Dropdown>
												</td>
												:null
												}
											</tr>))}</>
										:
										<NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
								</tbody>
							</table>
						}
					</CardBody>
					<PaginationButtons className='d-print-none' data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
				</Card>
			</Page>

			<OffCanvas setOpen={setAddEventOffCanvas} isOpen={addEventOffCanvas} titleId='addDepartmentOffCanvas' placement='end' tag='form' onSubmit={addEventManagerForm.handleSubmit}>
				<OffCanvasHeader setOpen={closeAndFormReset}>
					<OffCanvasTitle id="addDepartmentOffCanvas">Add Tournament Details</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					<div className="row g-4">
						<div className='col-12'>
							<FormGroup id='eventTypeId' label='Select Event' >
								<SearchableSelect 
									ariaLabel=''
									placeholder='Select Event'
									onChange={selectEvent}
									onBlur={addEventManagerForm.handleBlur}
									value={eventTypeId}
									isValid={addEventManagerForm.isValid}
									isTouched={addEventManagerForm.touched.eventTypeId}
									invalidFeedback={addEventManagerForm.errors.eventTypeId}
									list={eventTypeData.map((data: any) => ({
										value: data.eventTypeId,
										label: data.eventType
									}))}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='genderId' label='Gender'>
								<SearchableSelect 
									ariaLabel=''
									placeholder='Select Gender'
									onChange={selectGender}
									onBlur={addEventManagerForm.handleBlur}
									value={genderId}
									isValid={addEventManagerForm.isValid}
									isTouched={addEventManagerForm.touched.genderId}
									invalidFeedback={addEventManagerForm.errors.genderId}
									list={genderData.map((data: any) => (
										{ value: data.genderId, label: data.gender }
									))}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='tournamentId' label='Tournament' >
								<SearchableSelect 
									ariaLabel=''
									placeholder='Tournament'
									onChange={selectTournament}
									onBlur={addEventManagerForm.handleBlur}
									value={tournamentId}
									isValid={addEventManagerForm.isValid}
									isTouched={addEventManagerForm.touched.tournamentId}
									invalidFeedback={addEventManagerForm.errors.tournamentId}
									list={tournamentData.map((data: any) => (
										{ value: data.tournamentId, label: data.tournamentName }
									))}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='tournamentDate' label='TournamentDate'>
								<Input
									placeholder='TournamentDate'
									onChange={addEventManagerForm.handleChange}
									onBlur={addEventManagerForm.handleBlur}
									value={addEventManagerForm.values.tournamentDate}
									isValid={addEventManagerForm.isValid}
									isTouched={addEventManagerForm.touched.tournamentDate}
									invalidFeedback={addEventManagerForm.errors.tournamentDate}
									type='date'
								// min={minDate}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='totalMember' label='No of Players'>
								<Input
									placeholder='No of Players'
									onChange={addEventManagerForm.handleChange}
									value={addEventManagerForm.values.totalMember}
									isValid={addEventManagerForm.isValid}
									onBlur={addEventManagerForm.handleBlur}
									isTouched={addEventManagerForm.touched.totalMember}
									invalidFeedback={addEventManagerForm.errors.totalMember}
									onKeyDown={(e: any) => onlyAllowNumber(e)}
								/>
							</FormGroup>
						</div>
					</div>
					<div className='row m-0'>
						<div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
							<Button color='info' icon="Save" className='w-100' type="submit" isDisable={!addEventManagerForm.isValid && !!addEventManagerForm.submitCount}>
								Save
							</Button>
						</div>
					</div>
				</OffCanvasBody>
			</OffCanvas>

			<OffCanvas setOpen={setEditTournamentOffCanvas} isOpen={editTournamentOffCanvas} titleId='editTournamentOffCanvas' placement='end'>
				<OffCanvasHeader setOpen={setEditTournamentOffCanvas} onClick={closeAndFormReset}>
					<OffCanvasTitle id="editTournamentOffCanvas">Update Tournament</OffCanvasTitle>
				</OffCanvasHeader>
				<form noValidate onSubmit={editEventManagerForm.handleSubmit}>
					<OffCanvasBody>
						<div className="row g-4">
							<div className='col-12'>
								<FormGroup id='eventTypeId' label='Event Type' >
									<Input
										placeholder='Enter Event'
										onChange={editEventManagerForm.handleChange}
										onBlur={editEventManagerForm.handleBlur}
										value={editEventManagerForm.values.eventTypeId}
										isValid={editEventManagerForm.isValid}
										isTouched={editEventManagerForm.touched.eventTypeId}
										invalidFeedback={editEventManagerForm.errors.eventTypeId}
										disabled
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='tournamentName' label='Tournament Name'>
									<Input
										placeholder='tournamentName'
										onChange={editEventManagerForm.handleChange}
										onBlur={editEventManagerForm.handleBlur}
										value={editEventManagerForm.values.tournamentName}
										isValid={editEventManagerForm.isValid}
										isTouched={editEventManagerForm.touched.tournamentName}
										invalidFeedback={editEventManagerForm.errors.tournamentName}
										disabled
									/>
								</FormGroup>
							</div>
							<FormGroup id='genderId' label='Gender'>
								<Select
									ariaLabel=''
									placeholder='Select Gender'
									onChange={editEventManagerForm.handleChange}
									onBlur={editEventManagerForm.handleBlur}
									value={editEventManagerForm.values.genderId}
									isValid={editEventManagerForm.isValid}
									isTouched={editEventManagerForm.touched.genderId}
									invalidFeedback={editEventManagerForm.errors.genderId}
									list={genderData.map((data: any) => (
										{ value: data.genderId, text: data.gender }
									))} disabled
								/>
							</FormGroup>
							<div className='col-12'>
								<FormGroup id='tournamentDate' label='Tournament Date'>
									<Input
										placeholder='TournamentDate'
										onChange={editEventManagerForm.handleChange}
										onBlur={editEventManagerForm.handleBlur}
										value={editEventManagerForm.values.tournamentDate}
										isValid={editEventManagerForm.isValid}
										isTouched={editEventManagerForm.touched.tournamentDate}
										invalidFeedback={editEventManagerForm.errors.tournamentDate}
										type='date'
									// min={minDate}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='totalMember' label='No of Players'>
									<Input
										placeholder='No of Players'
										onChange={editEventManagerForm.handleChange}
										value={editEventManagerForm.values.totalMember}
										isValid={editEventManagerForm.isValid}
										onBlur={editEventManagerForm.handleBlur}
										isTouched={editEventManagerForm.touched.totalMember}
										invalidFeedback={editEventManagerForm.errors.totalMember}
										onKeyDown={(e: any) => onlyAllowNumber(e)} disabled
									/>
								</FormGroup>
							</div>
							<div className="d-flex justify-content-center position-absolute top-65 start-50 translate-middle">
								<DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteTournamentSubmit} />
							</div>
						</div>
						<div className='row top-90 m-0'>
							{deleteAccess?
							<div className='col-6 position-absolute bottom-0 start-0 p-3'>
								<Button color='danger' className='w-100' icon="Delete" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
									Delete
								</Button>
							</div>
							:null
							}
							<div className={deleteAccess?'col-6 position-absolute bottom-0 end-0 p-3':'col-6 position-absolute bottom-0 start-0 p-3'}>
								<Button color='info' icon="Save" className='w-100' type="submit" isDisable={!editEventManagerForm.isValid && !!editEventManagerForm.submitCount}>Update</Button>
							</div>
						</div>
					</OffCanvasBody>
				</form>
			</OffCanvas>
			<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
		</PageWrapper>
	);
};

export default EventManager


