import classNames from "classnames";
import { useFormik } from "formik";
import { useEffect, useState, useRef, useContext } from "react";
import { useReactToPrint } from "react-to-print";
import useDarkMode from "../../../hooks/useDarkMode";
import useSortableData from "../../../hooks/useSortableData";
import Page from "../../../layout/Page/Page";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import Card, { CardHeader, CardLabel, CardTitle, CardActions, CardBody } from "../../bootstrap/Card";
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from "../../bootstrap/Dropdown";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Icon from "../../icon/Icon";
import Input from "../../bootstrap/forms/Input";
import Select from "../../bootstrap/forms/Select";
import { toasts } from "../../../services/toast.service";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../bootstrap/OffCanvas";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../services/ExportService";
import AlertService from "../../../services/AlertService";
import DeleteComponents from "../../../common/components/DeleteComponents";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import { addAdmissionFormFee, deleteAdmissionFormFee, getAdmissionFormFee, updateAdmissionFormFee } from "../../../services/fee.service";
import { getColumnsForDataTable, getGraduationType, onlyAllowNumber, updateColumnsForDataTable, updateFilter } from "../../../services/common.service";
import AuthContext from "../../../contexts/authContext";
import { getLicenseKey } from "../../../services/application.settings";
import { TableLoader, showLoader } from "../../../services/loader.services";
import NoDataMsg from "../../../common/components/NoDataMsg";
import SearchableSelect from "../../../common/components/SearchableSelect";


function AdmissionFeesMaster() {

    useEffect(() => {
        getAdmissionFormFeeList()
        getGraduationList();
    }, [])

    const { userAccountId, userTypeId, addAccess, editAccess, deleteAccess } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [admissionFormFeeData, setAdmissionFormFeeData] = useState([]);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [downloadData, setDownloadData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    const [dataStatus, setDataStatus] = useState(false)

    // ForModal
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(admissionFormFeeData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [admissionFormFeeDataById, setAdmissionFormFeeDataById] = useState(Object)

    const [addAdmissionFormFeeOffCanvas, setAddAdmissionFormFeeOffCanvas] = useState(false)
    const [editAdmissionFormFeeOffCanvas, setEditAdmissionFormFeeOffCanvas] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)

    const [graduationTypeData, setGraduationTypeData] = useState<any>([])
    const [graduationTypeId, setGraduationTypeId] = useState<any>('')

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('')

    const addAdmissionFeeForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            amount: '',
            graduationTypeId: '',
            particulars: '',
        },
        validate: (values: any) => {
            const errors: {
                graduationTypeId?: string;
                amount?: string;
            } = {};
            if (!graduationTypeId?.value) {
                errors.graduationTypeId = 'Required';
            }
            if (!values.amount) {
                errors.amount = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { admissionFeeMasterSubmit() },
    });

    const editAdmissionFormFee = useFormik({
        enableReinitialize: true,
        initialValues: {

            amount: '',
            admissionFormFeeId: '',
            graduationTypeId: '',
        },
        validate: (values: any) => {
            const errors: {
                graduationTypeId?: string;
                amount?: string;
            } = {};
            if (!graduationTypeId?.value) {
                errors.graduationTypeId = 'Required';
            }
            if (!values.amount) {
                errors.amount = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { updateAdmissionFeeMasterSubmit() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });



    const selectGraduationType = (e: any) => {
        let graduationTypeId = e
        setGraduationTypeId(graduationTypeId)
    }

    function onActivate(i: any) {
        if (i != undefined) {

            let selectedAdmissionFee = i
            setEditAdmissionFormFeeOffCanvas(true)
            editAdmissionFormFee.setValues({
                amount: selectedAdmissionFee.amount,
                admissionFormFeeId: selectedAdmissionFee.admissionFormFeeId,
                graduationTypeId: selectedAdmissionFee.graduationTypeId,
            })
        }
    }

    function setAddAdmissionFeeMaster() {

        return ({
            licenseKey: getLicenseKey,
            amount: addAdmissionFeeForm.values.amount,
            graduationTypeId: graduationTypeId?.value,
            particulars: 'Application Form Fees',
            userAccountId: userAccountId
        })
    }

    function setEditAdmissionFeeMaster() {
        return ({
            amount: editAdmissionFormFee.values.amount,
            graduationTypeId: editAdmissionFormFee.values.graduationTypeId,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
            admissionFormFeeId: editAdmissionFormFee.values.admissionFormFeeId,
        })
    }

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function getAdmissionFormFeeList() {
        setDataStatus(false)
        getAdmissionFormFee(0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.admissionFormFee;
                    if (data != undefined) {
                        getColumnsForTable('getAdmissionFormFee', 'get');
                        setAdmissionFormFeeData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        setDataStatus(true)
                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataStatus(true)
                    getColumnsForTable('getAdmissionFormFee', 'get');
                    setAdmissionFormFeeData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setDataStatus(true)
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        setGraduationTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGraduationTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function admissionFeeMasterSubmit() {
        showLoader(true)
        if (addAdmissionFeeForm.isValid) {
            let admissionFeeMasterPostData = setAddAdmissionFeeMaster()
            addAdmissionFormFee(admissionFeeMasterPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeModal();
                        getAdmissionFormFeeList()
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (addAdmissionFeeForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function updateAdmissionFeeMasterSubmit() {
        showLoader(true)
        if (editAdmissionFormFee.isValid) {
            let updateAdmissionFeeMasterPostData = setEditAdmissionFeeMaster()
            updateAdmissionFormFee(updateAdmissionFeeMasterPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeModal();
                        getAdmissionFormFeeList()
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (editAdmissionFormFee.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function deleteAdmissionFeeMasterSubmit() {
        showLoader(true)
        // if (editAdmissionFormFee.isValid) {
        let deleteAdmissionFeeMasterPostData = setEditAdmissionFeeMaster()
        deleteAdmissionFormFee(deleteAdmissionFeeMasterPostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "success" });
                    setIsOpen(true);
                    closeModal();
                    getAdmissionFormFeeList()
                }
                else if (data.success == false) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "error" });
                    setIsOpen(true);
                }
                else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    showLoader(false)
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }
            , (error) => {
                showLoader(false)
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
        // } else if (editAdmissionFormFee.isValid == false) {
        //     showLoader(false)
        //     setAlertStatus({ message: 'Please fill all the details!', type: "error" });
        //     setIsOpen(true);
        // }
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getAdmissionFormFee', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeModal() {
        addAdmissionFeeForm.resetForm();
        setAddAdmissionFormFeeOffCanvas(false);
        setEditAdmissionFormFeeOffCanvas(false);
        editAdmissionFormFee.resetForm();
        setIsDeleteOpen(false)
        setGraduationTypeId('')
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <PageWrapper title="Admission Fees Master">
                <Page container='fluid'>
                    <Card stretch data-tour='list' ref={componentRef}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className={addAccess ? "col-lg-5" : "col-lg-6"}>
                                <CardTitle tag='div' className='h5'>Admission Fee Form List</CardTitle>
                            </CardLabel>&nbsp;&nbsp;

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getAdmissionFormFee', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("Admission Fee Form List", admissionFormFeeData, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(admissionFormFeeData, columnVisibilityData, "Admission Fee Form List")}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("Admission Fee Form List", admissionFormFeeData, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                {addAccess ?
                                    <Button color='primary' icon='Add' isLight onClick={() => setAddAdmissionFormFeeOffCanvas(true)}>
                                        Add New
                                    </Button>
                                    : null
                                }
                            </CardActions>
                        </CardHeader>

                        <CardBody className='table-responsive' isScrollable>
                            {!dataStatus ?
                                <TableLoader /> :
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}
                                            {editAccess ?
                                                <th scope='col' className='d-print-none'>
                                                    Action
                                                </th>
                                                : null
                                            }

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != "" ? <>{filteredData.map((rowData: any) => (<tr key={rowData.admissionFormFeeId}><td>{rowData.sno}</td>{columnVisibilityData.map((column: any) => ((column.isDisplay && rowData[column.keyName]) ? <td className='text-nowrap' key={`${rowData.admissionFormFeeId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />))}
                                            {editAccess ?
                                                <td className="d-print-none" ><Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} /></td>
                                                : null
                                            }
                                        </tr>))}</> :
                                            <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}</tbody></table>}
                        </CardBody>
                        <PaginationButtons
                            className='d-print-none'
                            data={items}
                            label='items'
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            perPage={perPage}
                            setPerPage={setPerPage}
                        />
                    </Card>
                </Page>

                <OffCanvas
                    setOpen={setAddAdmissionFormFeeOffCanvas}
                    isOpen={addAdmissionFormFeeOffCanvas}
                    titleId='addAdmissionFormFeeOffCanvas'
                    isBodyScroll
                    placement='end'
                    tag='form' noValidate onSubmit={addAdmissionFeeForm.handleSubmit}>
                    <OffCanvasHeader setOpen={setAddAdmissionFormFeeOffCanvas} onClick={closeModal}>
                        <OffCanvasTitle id="addAdmissionFormFeeOffCanvas">Add Admission Form Fee</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody >
                        <div className="row g-4">
                            <div className='col-12'>
                                <FormGroup id='graduationTypeId' label='Graduation Type' >
                                    <SearchableSelect onChange={selectGraduationType} placeholder="Select Graduation Type"
                                        onBlur={addAdmissionFeeForm.handleBlur}
                                        isValid={addAdmissionFeeForm.isValid}
                                        isTouched={addAdmissionFeeForm.touched.graduationTypeId}
                                        invalidFeedback={addAdmissionFeeForm.errors.graduationTypeId}
                                        value={graduationTypeId}
                                        list={graduationTypeData.map((data: any) => (
                                            { value: data.graduationTypeId, label: data.graduationType }
                                        ))} ariaLabel={" Select Graduation Type"} />
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='amount' label='Amount'>
                                    <Input placeholder="Enter Amount" onChange={addAdmissionFeeForm.handleChange}
                                        onBlur={addAdmissionFeeForm.handleBlur}
                                        isValid={addAdmissionFeeForm.isValid}
                                        isTouched={addAdmissionFeeForm.touched.amount}
                                        invalidFeedback={addAdmissionFeeForm.errors.amount}
                                        value={addAdmissionFeeForm.values.amount} type='text' required
                                        onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                </FormGroup>
                            </div>
                        </div>
                        <div className='row m-0'>
                            <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                                <Button
                                    color='info' icon="Save"
                                    className='w-100' type="submit" isDisable={!addAdmissionFeeForm.isValid && !!addAdmissionFeeForm.submitCount} >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </OffCanvasBody>

                </OffCanvas>

                <OffCanvas
                    setOpen={setEditAdmissionFormFeeOffCanvas}
                    isOpen={editAdmissionFormFeeOffCanvas}
                    titleId='editAdmissionFormFeeOffCanvas'
                    isBodyScroll
                    placement='end'>
                    <OffCanvasHeader setOpen={setEditAdmissionFormFeeOffCanvas} onClick={closeModal}>
                        <OffCanvasTitle id="editAdmissionFormFeeOffCanvas">Update Admission Form Fee</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody tag="form" onSubmit={editAdmissionFormFee.handleSubmit}>
                        <div className="row g-4">
                            <div className='col-12'>
                                <FormGroup id='graduationTypeId' label='Graduation Type'>
                                    <Select onChange={editAdmissionFormFee.handleChange}
                                        value={editAdmissionFormFee.values.graduationTypeId}
                                        onBlur={editAdmissionFormFee.handleBlur}
                                        isValid={editAdmissionFormFee.isValid}
                                        isTouched={editAdmissionFormFee.touched.graduationTypeId}
                                        invalidFeedback={editAdmissionFormFee.errors.graduationTypeId}
                                        required ariaLabel={""}
                                        list={graduationTypeData.map((data: any) => (
                                            { value: data.graduationTypeId, text: data.graduationType }
                                        ))} disabled />

                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='amount' label='Amount'>
                                    <Input placeholder="Enter Amount" onChange={editAdmissionFormFee.handleChange}
                                        value={editAdmissionFormFee.values.amount}
                                        onBlur={editAdmissionFormFee.handleBlur}
                                        isValid={editAdmissionFormFee.isValid}
                                        isTouched={editAdmissionFormFee.touched.amount}
                                        invalidFeedback={editAdmissionFormFee.errors.amount}
                                        type='text' required onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                </FormGroup>
                            </div>
                            <div className="d-flex justify-content-center position-absolute top-60 start-50 translate-middle">
                                <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteAdmissionFeeMasterSubmit} />
                            </div>
                        </div>
                        <div className='row top-90 m-0'>
                            {deleteAccess ?
                                <div className='col-6 position-absolute bottom-0 start-0 p-3'>
                                    <Button
                                        color='danger'
                                        className='w-100'
                                        icon="Delete" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                                        Delete
                                    </Button>
                                </div>
                                : null
                            }
                            <div className={deleteAccess ? 'col-6 position-absolute bottom-0 end-0 p-3' : 'col-12 position-absolute bottom-0 start-0 p-3'}>
                                <Button color='info' icon="Save" className='w-100' type="submit" isDisable={!editAdmissionFormFee.isValid && !!editAdmissionFormFee.submitCount}>Update</Button>
                            </div>
                        </div>
                    </OffCanvasBody>

                </OffCanvas>

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </PageWrapper>
        </>
    )
} export default AdmissionFeesMaster;