import { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../../../contexts/authContext";
import { getProfileForStudent } from "../../../../services/student.service";
import { toasts } from "../../../../services/toast.service";
import { getCollegeDetails } from "../../../../services/college.service";
import { getAluminiStudentFeeCollection, getFeeCollectionDetailsByFeeCollectionId } from "../../../../services/fee.service";
import Page from "../../../../layout/Page/Page";
import Card, { CardBody, CardHeader } from "../../../bootstrap/Card";
import Avatar from "../../../Avatar";
import Button from "../../../bootstrap/Button";
import Label from "../../../bootstrap/forms/Label";
import Icon from "../../../icon/Icon";
import dayjs from "dayjs";
import UserImage2Webp from '../../../../assets/img/wanna/wanna1.webp';
import UserImage2 from '../../../../assets/img/wanna/wanna1.png';
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import CollegeLogo from '../../../../assets/logos/logo.jpg';
import { useReactToPrint } from "react-to-print";
import { downloadPdfDocument } from "../../../../services/ExportService";
import '../../../general/administration/fees-details/receipt.css';
import signature from '../../../../assets/img/signature.png';
import seal from '../../../../assets/img/seal.png';
import { getPaymentConfigDetails, pictNotLoading, profilePic } from "../../../../services/common.service";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../../bootstrap/Modal";
import Input from "../../../bootstrap/forms/Input";
import Select from "../../../bootstrap/forms/Select";

const AluminiStudentFeeDetails = () => {

    const [studentProfileData, setStudentProfileData] = useState<any>([])
    const [noFeesCollected, setNoFeesCollected] = useState(false);
    const tableFontSize = 'fs-4';
    const [studentFeeReceiptData, setStudentFeeReceiptData] = useState<any>([])
    const [collegeDetailsData, setCollegeDetailsData] = useState<any>([])
    const [collegeDetailsDataBill, setCollegeDetailsDataForBill] = useState<any>([])
    const [studentFeeCollectionData, setStudentFeeCollectionData] = useState<any>([])
    const [noDataMsg, setNoDataMsg] = useState<any>('')
    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [pdfFileName, setPdfFileName] = useState('')

    useEffect(() => {
        getStudentProfile(userAccountId)
        getAluminiStudentFeeCollectionDetails(userAccountId)
        getCollege()
        getPaymentConfig();
    }, [])

    function getStudentProfile(studentDetailsId: number) {
        getProfileForStudent(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentProfile[0];
                    if (data != undefined) {
                        setStudentProfileData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")

                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCollege() {
        getCollegeDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.collegeDetails;
                    if (data != undefined) {
                        setCollegeDetailsData(data);
                        setCollegeDetailsDataForBill(data[0]);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getFeeReceipt(studentFeeCollectionId: number) {
        getFeeCollectionDetailsByFeeCollectionId(studentFeeCollectionId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.feeCollectionDetailsByFeeCollectionId;
                    if (data != undefined) {
                        setStudentFeeReceiptData(data);
                        for (let i = 0; i < data.length; i++) {
                            let feeCollectionDetails = data[i].feeCollectionDetails
                            for (let i = 0; i < feeCollectionDetails.length; i++) {
                                feeCollectionDetails[i].sno = i + 1;
                            }
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getAluminiStudentFeeCollectionDetails(studentDetailsId: number) {
        getAluminiStudentFeeCollection(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentFeeCollection;
                    if (data != undefined) {
                        setStudentFeeCollectionData(data);
                        setNoFeesCollected(false)
                        getFeeReceipt(data[0].studentFeeCollectionId);
                        setPdfFileName(data[0].receiptNo);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setNoDataMsg(response.data.message)
                    setNoFeesCollected(true)

                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }


    const [actionUrl, setActionUrl] = useState<any>('')
    const [paymentConfigDetails, setPaymentConfigDetails] = useState<any>([])


    function getPaymentConfig() {
        getPaymentConfigDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.paymentConfigDetails;
                    if (data != undefined) {
                        setPaymentConfigDetails(data[0])
                        setActionUrl(data[0]['ccavRedirectUrl']);
                    }
                    else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const componentRef = useRef(null);

    const [payNowModal, setPayNowModal] = useState<boolean>(false);

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function handlePDF() {
        downloadPdfDocument(componentRef.current, pdfFileName)
    }

    function checkout() {
        let element: HTMLElement = document.getElementById('paymentSubmit') as HTMLElement;
        element.click();
    }

    function closeAndReset() {
    }

    return (
        <PageWrapper title='Alumini'>
            <Page container='fluid'>
                <div className='row'>
                    <div className='col-lg-3'>
                        <Card stretch>
                            <CardBody>
                                <div className="col-12 d-flex justify-content-center">
                                    <img style={{ borderRadius: '50%' }}
                                        src={studentProfileData.profilePath != null ? studentProfileData.profilePath : profilePic(studentProfileData.genderId)}
                                        height='120' width='120' onError={(e: any) => pictNotLoading(e, studentProfileData.genderId)}
                                    />
                                </div>
                                <h5 className='fs-5 fw-bold me-2 text-uppercase mt-3 col-12 d-flex justify-content-center'>{studentProfileData.studentName}</h5>
                                <div className="col-12 d-flex justify-content-center text-primary">
                                    {studentProfileData.uniRegNo}
                                </div>
                                <div className="col-12 d-flex justify-content-center">{studentProfileData.courseName}</div>
                                {studentFeeReceiptData == '' ?
                                    <div className='col-12'>
                                    </div> :
                                    <>
                                        <div className='col-12 d-flex justify-content-center'>
                                            <Label className='fw-bold text-success'>PAID</Label>
                                        </div>
                                        <div className='col-12 justify-content-center'>
                                            <div className="row justify-content-center">
                                                <div className='col-6'>
                                                    <Button size='sm' icon="Downloading" isLight color="success" onClick={handlePDF}>Download</Button>
                                                </div>
                                                <div className='col-6'>
                                                    <Button size='sm' icon="Print" isLight color="primary" onClick={handlePrint}>Print</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </CardBody>
                        </Card>
                    </div>

                    <style type="text/css" media="print">
                        {"@page { size: A4 landscape; margin-left: 0.5in; margin-right: 0.5in; margin-top: 0.5in; margin-bottom: 0.5in; }\ }"}
                    </style>

                    {noFeesCollected ?
                        <div className='col-lg-9'>
                            <Card stretch>
                                <>
                                    <br />
                                    <br />
                                    <div className='text-danger col-12 d-flex justify-content-center'>
                                        <p className='fw-bold fs-5 mb-0 mt-3'>{noDataMsg}</p>
                                    </div>

                                    <div className='col-12 d-flex justify-content-center'>
                                        <p className='fw-bold fs-3 mb-0 mt-3'>GRADUATION FEE : <span className='text-primary'>{studentProfileData.graduationDayAmount}</span></p>
                                    </div>

                                    <div className='d-flex justify-content-center row-5 mt-2'>
                                        <Button color='info' icon='CreditCard' isLight onClick={() => setPayNowModal(true)}>
                                            Pay
                                        </Button>
                                    </div>
                                </>
                            </Card>
                        </div> :
                        <div className='col-lg-9'>
                            <Card stretch>
                                <CardBody ref={componentRef}>
                                    {studentFeeReceiptData.map((studentFeeData: any) => (
                                        <div className="modal-body table-responsive" id='thForReceipt' key={studentFeeData.studentFeeCollectionId}>
                                            <table className="table table-border border border-black table-responsive align-middle" style={{ fontFamily: 'Times New Roman' }} id="tdForReceipt">
                                                <tbody style={{ border: "1px solid black" }}>
                                                    {collegeDetailsData.map((collegeData: any) => (
                                                        <tr key={collegeData.collegeDetailsId}>
                                                            <td width="15%" rowSpan={1} align="center">
                                                                <img height="100px" width="100px"
                                                                    src={CollegeLogo} />
                                                            </td>
                                                            <th style={{ width: '64%' }} className="text-center table-border border border-black" align="center">
                                                                <h2 className="text-uppercase" style={{
                                                                    fontFamily: 'Bookman Old Style',
                                                                    color: 'rgb(61, 57, 57)'
                                                                }}>
                                                                    <b style={{ fontSize: '120%' }}>{collegeData.collegeName}</b>
                                                                </h2>

                                                                <b className="fs-7 mg-t-10" style={{ fontFamily: 'Bookman Old Style', color: 'rgb(61, 57, 57)', width: '95%' }}>
                                                                    <p className="fs-6">({collegeData.description})<br />{collegeData.address}<br />
                                                                        <Icon icon="Email" /> {collegeData.website} <Icon icon="Phone" /> {collegeData.phone} <Icon icon="PhoneAndroid" /> {collegeData.cellNo}</p>
                                                                </b>
                                                            </th>
                                                            <th style={{ width: '21%' }} className={tableFontSize} align="center">
                                                                <div className='align-middle'>
                                                                    <p>Receipt No
                                                                        : <strong>{studentFeeData.receiptNo}</strong></p>
                                                                    <p className="mg-lg-t-5">Date
                                                                        : <strong>{dayjs(studentFeeData.receiptDate).format('DD/MM/YYYY')}</strong></p>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={3}>
                                                            <p className="mt-4 fs-4" style={{ marginLeft: "20px" }}>Roll Number : <b>{studentFeeData.studentRollNo}</b>
                                                                <span style={{ marginRight: "20px", float: "right" }}>Semester : <b>
                                                                    {studentFeeData.studentSemesterNumber}</b></span>
                                                            </p>
                                                            <p className="mt-4 fs-4" style={{ marginLeft: "20px" }}>Student Name : <b>{studentFeeData.studentName}</b>
                                                                <span style={{ marginRight: "20px", float: "right" }}>Course / Section : <b>
                                                                    {studentFeeData.studentCourseName} / {studentFeeData.sectionName}</b></span>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </tbody>

                                                <tbody>
                                                    <tr className={tableFontSize}>
                                                        <th className={'text-left border border-black'}>SI No.</th>
                                                        <th className={'text-left border border-black'}>Particulars</th>
                                                        <th className={'text-left border border-black'}>Amount</th>
                                                    </tr>
                                                    {studentFeeData.feeCollectionDetails.map((feeData: any) => (
                                                        <tr className={tableFontSize} key={feeData.feeTypeId}>
                                                            <td className="text-left border border-black">
                                                                {feeData.sno}
                                                            </td>
                                                            <td className="text-left border border-black">
                                                                {feeData.feeType}
                                                            </td>
                                                            <td className="text-left border border-black">
                                                                <b>{feeData.totalAmount}</b>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    <tr className={tableFontSize}>
                                                        <td></td>
                                                        <td className="text-left border border-black align-middle">
                                                            Total amount
                                                        </td>
                                                        <td className="text-left border border-black">
                                                            <b> {studentFeeData.feesCollected}</b>
                                                        </td>
                                                    </tr>
                                                    <tr className={tableFontSize}>
                                                        <td colSpan={2} align="left" className="align-middle">
                                                            Amount in words : <b> {studentFeeData.feesCollectedInWords} Rupees Only/-</b>
                                                        </td>
                                                        <td align="left" className="align-middle" style={{ whiteSpace: 'nowrap' }}>
                                                            Payment Type: <b>{studentFeeData.paymentType}</b>
                                                        </td>
                                                    </tr>

                                                    <tr style={{ borderTopColor: 'white' }} className={tableFontSize}>
                                                        <td colSpan={3}>
                                                            <img height="200px" width="200px" src={signature} style={{ marginTop: '-60px', marginLeft: '30px', background: 'transparent' }} />
                                                            <img height="200px" width="200px" src={seal} style={{ marginTop: '-60px', float: 'right', background: 'transparent' }} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    ))
                                    }
                                </CardBody >
                            </Card>
                        </div>
                    }
                </div>

                <Modal
                    setIsOpen={setPayNowModal}
                    isOpen={payNowModal}
                    size='sm'
                >
                    <ModalHeader className='px-4' setIsOpen={setPayNowModal} onClick={closeAndReset}>
                        <ModalTitle id='project-edit'>Pay Fee</ModalTitle>
                    </ModalHeader>
                    <form id="nonseamless" method="post" name="redirect" action={actionUrl}>
                        <Button hidden color='success' icon='CreditCard' isLight onClick={() => checkout()}>
                            Pay Now
                        </Button>
                        <ModalBody>

                            <div className='table-responsive'>
                                <div className='col-12 d-flex justify-content-center'>
                                    <p className='fw-bold h4 mb-0'>GRADUATION FEE : <span className='text-danger'>{studentProfileData.graduationDayAmount}</span></p>
                                </div>
                                <br />
                                <div className='col-12 d-flex justify-content-center mt-2'>
                                    <Button
                                        icon='ArrowForward'
                                        color='info'
                                        type='submit' id="paymentSubmit">
                                        Pay
                                    </Button>
                                </div>
                                <br />
                            </div>
                            <Input type="hidden" name="amount" value={studentProfileData.graduationDayAmount} readOnly />
                            <Input type="hidden" name="feeDetailsArray[0]" readOnly />
                            <Input type="hidden" name="forConvacation" value="1" readOnly />

                            <Select ariaLabel='Payment Type' placeholder="Select Payment Type" name="npayment_option" value={'OPTCRDC'} hidden disabled>
                                <option value="OPTCRDC">Credit Card</option>
                                <option value="OPTDBCRD">Debit Card</option>
                                <option value="OPTNBK">Net Banking</option>
                                <option value="OPTCASHC">Cash Card</option>
                                <option value="OPTMOBP">Mobile Payments</option>
                                <option value="OPTEMI">EMI </option>
                                <option value="OPTWLT">Wallet</option>
                            </Select>
                            <Input type="hidden" name="tid" id="tid" readOnly />
                            <Input type="hidden" name="merchant_id" value={paymentConfigDetails.merchantId} readOnly />
                            <Input type="hidden" name="currency" value={paymentConfigDetails.currency} readOnly />
                            <Input type="hidden" name="redirect_url" value={paymentConfigDetails.redirectUrl} readOnly />
                            <Input type="hidden" name="cancel_url" value={paymentConfigDetails.cancelUrl} readOnly />
                            <Input type="hidden" name="language" value={paymentConfigDetails.ccavLanguage} readOnly />
                            <Input type="hidden" id="card_number" name="card_number" value={paymentConfigDetails.cardNumber} readOnly />
                            <Input type="hidden" name="expiry_month" value={paymentConfigDetails.expiryMonth} readOnly />
                            <Input type="hidden" name="expiry_year" value={paymentConfigDetails.expiryYear} readOnly />
                            <Input type="hidden" name="cvv_number" value={paymentConfigDetails.cvvNumber} readOnly />
                            <Input type="hidden" name="issuing_bank" value={paymentConfigDetails.issuingBank} readOnly />
                            <Input type="hidden" name="mobile_number" value={paymentConfigDetails.mobileNumber} readOnly />
                            <Input type="hidden" name="mm_id" value={paymentConfigDetails.mmId} readOnly />
                            <Input type="hidden" name="otp" value={paymentConfigDetails.otp} readOnly />


                            <Input type="hidden" name="order_id" value={studentProfileData.studentDetailsId} readOnly />
                            <Input type="hidden" name="billing_name" value={studentProfileData.studentName} readOnly />
                            <Input type="hidden" name="billing_address" value={studentProfileData.address} readOnly />
                            <Input type="hidden" name="billing_city" value={studentProfileData.cityName} readOnly />
                            <Input type="hidden" name="billing_state" value={studentProfileData.stateName} readOnly />
                            <Input type="hidden" name="billing_zip" value={studentProfileData.commAddr_Pincode} readOnly />
                            <Input type="hidden" name="billing_country" value={studentProfileData.country} readOnly />
                            <Input type="hidden" name="billing_tel" value={studentProfileData.smsToBeSentNumber} readOnly />
                            <Input type="hidden" name="billing_email" value={studentProfileData.email ? studentProfileData.email : ''} readOnly />
                            <Input type="hidden" name="delivery_name" value={collegeDetailsDataBill.collegeName} readOnly /><Input type="hidden" name="delivery_address" value={collegeDetailsDataBill.address} readOnly />
                            <Input type="hidden" name="delivery_city" value={collegeDetailsDataBill.district} readOnly />
                            <Input type="hidden" name="delivery_state" value={collegeDetailsDataBill.stateName} readOnly />
                            <Input type="hidden" name="delivery_zip" value={collegeDetailsDataBill.pincode} readOnly />
                            <Input type="hidden" name="delivery_country" value={collegeDetailsDataBill.country} readOnly />
                            <Input type="hidden" name="delivery_tel" value={collegeDetailsDataBill.phone} readOnly />
                            <Input type="hidden" name="merchant_param1" value="additional Info." readOnly />
                            <Input type="hidden" name="merchant_param2" value="additional Info." readOnly />
                            <Input type="hidden" name="merchant_param3" value="additional Info." readOnly />
                            <Input type="hidden" name="merchant_param4" value="additional Info." readOnly />
                            <Input type="hidden" name="merchant_param5" value="additional Info." readOnly />
                            <div id="emi_div" hidden>
                                <table width="100%"><thead><tr><td colSpan={2} >EMI Section </td></tr>
                                    <tr><td> Emi plan id: </td><td><Input readOnly type="text" id="emi_plan_id" name="emi_plan_id" value="" /></td></tr><tr><td> Emi tenure id: </td><td><Input readOnly type="text" id="emi_tenure_id" name="emi_tenure_id" value="" /></td></tr>
                                    <tr><td>Pay Through</td><td><select name="emi_banks" id="emi_banks" disabled></select></td></tr><tr><td colSpan={2}><div id="emi_duration" className="span12"><span className="span12 content-text emiDetails">EMI Duration</span></div></td></tr><tr><td id="processing_fee" colSpan={2}></td></tr></thead></table>
                                <table id="emi_tbl" cellPadding={0} cellSpacing={0}></table>
                                {/* <!-- EMI section end --> */}
                                <Input type="hidden" id="card_type" name="card_type" value="" readOnly />
                                <select name="card_name" id="card_name" hidden disabled>
                                    <option value="">Select Card Name</option>
                                </select>
                                <Input type="hidden" id="data_accept" name="data_accept" readOnly />
                                <Input type="hidden" name="studentDetailsId" value={userAccountId} readOnly />
                                <select name="promo_code" id="promo_code" hidden disabled>
                                    <option value="">All Promotions &amp; Offers</option>
                                </select>
                            </div>

                        </ModalBody>
                    </form>
                </Modal>
            </Page>
        </PageWrapper>
    )
}

export default AluminiStudentFeeDetails


