import React, { useEffect, useState } from 'react';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardSubTitle, CardTitle, } from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import '../../../../pages/documentation/charts/chart-columns/custom.scss';
import { getCourseDetails } from '../../../../services/master.service';
import { toasts } from '../../../../services/toast.service';
import useDarkMode from '../../../../hooks/useDarkMode';
import { getStudentAttendancePercentageByCourseId } from '../../../../services/dashboard.service';
import { convertDateToEpoch, getCurrentDateAndTime } from '../../../../services/common.service';
import Chart from '../../../../components/extras/Chart';
import { TableLoader } from '../../../../services/loader.services';
import { useNavigate } from 'react-router-dom';

const AttendancePercentage = () => {

	useEffect(() => {
		getCourseList()
	}, [])

	const navigate = useNavigate();
	const [courseDetailsData, setCourseDetailsData] = useState<any>([]);
	const [attendancePercentage, setAttendancePercentage] = useState<any>([]);
	const [dataStatus, setDataStatus] = useState(false)
	const [activeTab, setActiveTab] = useState('');
	const [dateView, setDateView] = useState('')

	function getCourseList() {
		setDataStatus(false)
		getCourseDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.course;
					if (data != undefined) {
						setCourseDetailsData(data);
						setActiveTab(data[0].courseId)
						getStudentAttendancePercentageByCourse(data[0].courseId)

						setDataStatus(true)
					} else {
						setDataStatus(true)
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					setDataStatus(true)
					setCourseDetailsData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setDataStatus(true)
					toasts(errorDescription, "Error")
				}
			}, error => {
				setDataStatus(true)
				toasts(error, "Error")
			}
		)
	}

	function chartData(e: any) {
		setAttendancePercentage([])
		if (e !== undefined) {
			getStudentAttendancePercentageByCourse(e)
		}
		else {
			getStudentAttendancePercentageByCourse(activeTab)
		}
	}

	function getStudentAttendancePercentageByCourse(courseId: any) {
		setDataStatus(false)
		getStudentAttendancePercentageByCourseId(courseId, convertDateToEpoch(getCurrentDateAndTime('date')),
			(response) => {
				if (response.data.success) {
					setDateView(response.data.data.studentAttendancePercentageByCourseId[0].dateOfAttendanceForView)
					let overAllDetails = response.data.data.studentAttendancePercentageByCourseId

					const overAll = [{
						semesterNumber: 'Over All',
						totalStudents: overAllDetails[0].overAllTotalStudentCount,
						totalPresent: 0,
						totalPresentPercentage: overAllDetails[0].overAllTotalPresentPercentage,
						totalAbsent: 0,
						totalAbsentPercentage: overAllDetails[0].overAllTotalAbsentPercentage,
					}]

					let data = [...overAll, ...response.data.data.studentAttendancePercentageByCourseId[0].semesterWiseStudentAttendancePercentage];

					if (data != undefined) {
						setAttendancePercentage(
							{
								series: [
									{
										name: 'Total Present Percentage',
										data: data.map((i: any) => i.totalPresentPercentage !== null ? i.totalPresentPercentage : 0),
									},
									{
										name: 'Total Absent Percentage',
										data: data.map((i: any) => i.totalAbsentPercentage !== null ? i.totalAbsentPercentage : 0),
									},
								],
								options: {
									chart: {
										type: 'bar',
										height: 350,
										stacked: true,
										stackType: '100%',
									},
									responsive: [
										{
											breakpoint: 480,
											options: {
												legend: {
													position: 'bottom',
													offsetX: -10,
													offsetY: 0,
												},
											},
										},
									],
									xaxis: {
										categories: data.map((i: any) => i.semesterNumber !== null ? i.semesterNumber : 'No semester'),
									},
									fill: {
										opacity: 1,
									},
									title: {
										text: overAllDetails[0].courseName,
										floating: true,
										offsetX: -5,
										offsetY: 2,
										align: 'center',
										style: {
											color: '#444',
										},
									},
								},
							}
						);

						setDataStatus(true);
					} else {
						setDataStatus(true)
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					setDataStatus(true)
					setAttendancePercentage([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setDataStatus(true)
					toasts(errorDescription, "Error")
				}
			}, error => {
				setDataStatus(true)
				toasts(error, "Error")
			}
		)
	}

	const handleOpenModal = () => {
		navigate(`../reports/attendancePercentage`);
	};

	return (
		<Card stretch className='rounded-1 border border-light'>
			<CardHeader borderSize={1}>
				<CardLabel icon='BarChart'>
					<CardTitle tag='div' className='h5'>
						Attendance Percentage
					</CardTitle>
				</CardLabel>
				<CardActions>
					<span className='h5 text-info p-3 fw-bold'>{dateView}</span>
					{/* // <Button
					// 	color='info'
					// 	isLink
					// 	icon='Summarize'
					// 	onClick={handleOpenModal}>
					// 	Details
					// </Button> */}
				</CardActions>
			</CardHeader>

			<CardBody className=' d-flex align-items-center'>
				{courseDetailsData != '' ?
					<div className='row'>
						<div className='col-xl-5 mt-2'>
							<div className='row g-3' style={{ maxHeight: '60vh', overflow: 'scroll' }}>
								{courseDetailsData.map((company: any) => (
									<div key={company.courseId} className='col-xl-12' >
										<Button
											isLight={activeTab !== company.courseId}
											onClick={() => { chartData(company.courseId), setActiveTab(company.courseId) }}
											color='info'
											className='w-100 py-4'>
											{company.courseName}
										</Button>
									</div>
								))}
							</div>
						</div>

						{attendancePercentage != '' ?
							<div className='col-xl-7'>
								<Chart
									series={attendancePercentage.series}
									options={attendancePercentage.options}
									type='bar'
									height={400}
								/>
							</div>
							:
							<div className='col-xl-7 d-flex align-items-center'>
								<TableLoader />
							</div>}

					</div> :
					<TableLoader />}
			</CardBody>
		</Card>
	);
};

export default AttendancePercentage;