import { AxiosResponse } from 'axios';
import service from '../api/api.services';
import { getAPIURL, getLicenseKey } from './application.settings';

const api = service();

export const getStudentTodayAttendanceReport = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,
) => api.get(getAPIURL() + `getStudentTodayAttendanceReport/${getLicenseKey}`,
	(response) => {
		success(response);
	},
	(error) => {
		failure('getStudentTodayAttendanceReport - ' + error.message);
		console.log('error caught in service : getStudentTodayAttendanceReport');
	},
);


export const getNotSubmittedStudentAttendanceReport = (today: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,
) => api.get(
	getAPIURL() + `getNotSubmittedStudentAttendanceReport/${getLicenseKey}/${today}`,
	(response) => {
		success(response);
	},
	(error) => {
		failure('getNotSubmittedStudentAttendanceReport - ' + error.message);
		console.log('error caught in service : getNotSubmittedStudentAttendanceReport');
	},
);

export const getTimeTableListByStaffDetailsId = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) => api.get(getAPIURL() + `getTimeTableListByStaffDetailsId/${getLicenseKey}/${228}`,
	(response) => {
		success(response);
	},
	(error) => {
		failure('getTimeTableListByStaffDetailsId - ' + error.message);
		console.log('error caught in service : getTimeTableListByStaffDetailsId');
	},
);
export const getFeeCollectionReportByPaymentType = (userAccountId: any, fromDay: any, today: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,
) => api.get(
	getAPIURL() + `getFeeCollectionReportByPaymentType/${getLicenseKey}/${userAccountId}/${fromDay}/${today}`,
	(response) => {
		success(response);
	},
	(error) => {
		failure('getFeeCollectionReportByPaymentType - ' + error.message);
		console.log('error caught in service : getFeeCollectionReportByPaymentType');
	},
);
export const getOverAllPendingFees = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,
) => api.get(getAPIURL() + `getOverAllPendingFees/${getLicenseKey}`,
	(response) => {
		success(response);
	},
	(error) => {
		failure('getOverAllPendingFees - ' + error.message);
		console.log('error caught in service : getOverAllPendingFees');
	},
);

export const getDashboardDataByUserTypeId = (userTypeId: any, userAccountId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,
) => api.get(
	getAPIURL() + `getDashboardDataByUserTypeId/${getLicenseKey}/${userTypeId}/${userAccountId}`,
	(response) => {
		success(response);
	},
	(error) => {
		failure('getDashboardDataByUserTypeId - ' + error.message);
		console.log('error caught in service : getDashboardDataByUserTypeId');
	},
);

export const getStudentFeeTransactionDetails = (feeCollectionDate: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,
) => api.get(
	getAPIURL() + `getStudentFeeTransactionDetails/${getLicenseKey}/${feeCollectionDate}`,
	(response) => {
		success(response);
	},
	(error) => {
		failure('getStudentActivityLog - ' + error.message);
		console.log('error caught in service : getStudentFeeTransactionDetails');
	},
);

export const getStudentAttendancePercentageByCourseId = (courseId: any, attendanceDate: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,
) => api.get(
	getAPIURL() + `getStudentAttendancePercentageByCourseId/${getLicenseKey}/${courseId}/${attendanceDate}`,
	(response) => {
		success(response);
	},
	(error) => {
		failure('getStudentAttendancePercentageByCourseId - ' + error.message);
		console.log('error caught in service : getStudentAttendancePercentageByCourseId');
	},
);